import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class NavigationScrollService {

    private updateNavPostion: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    update$: Observable<boolean> = this.updateNavPostion.asObservable();

    updateBoolean(fixedNav: boolean) {
        this.updateNavPostion.next(fixedNav);
    }
}
