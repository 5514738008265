import { Component, OnInit } from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { ApiService } from "../../shared";
import { ApiError } from "../../shared/models/api-error.model";
import { LbTitle } from "../../shared/services/title.service";
import { EMAIL_REGEX } from "../../shared/validators/email";
import { AlertDialogComponent } from "./../../shared/components/alert-dialog/alert-dialog.component";

@Component({ 
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
    registerForm: UntypedFormGroup;
    isSubmitting: boolean = false;
    public requestTalentUrl: string = environment.request_talent_url;
    public timecardLoginUrl: string = environment.timecard_login_url;
    formTimelySubmitted: boolean = false;
    registrationSubmitted: string = null;

    constructor(
        private fb: UntypedFormBuilder,
        private apiService: ApiService,
        private router: Router,
        private dialog: MatDialog,
        private titleService: LbTitle
    ) {
        this.titleService.setTitleFromKeywords("Register");
        this.registerForm = this.fb.group({
            email: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.pattern(EMAIL_REGEX),
                    Validators.maxLength(255)
                ])
            ]
        });

        this.registrationSubmitted = localStorage.getItem('r_submitted');
    }

    public get email(): AbstractControl {
        return this.registerForm.get("email");
    }

    ngOnInit() {}

    submitForm() {
        if (this.registrationSubmitted !== null &&
            new Date(Number(this.registrationSubmitted)).getTime() > 0 &&
            Date.now() < new Date(Number(this.registrationSubmitted)).getTime() + (10 * 60 * 1000)
        ) {
            this.formTimelySubmitted = true;
            this.registerForm
                .get('email')
                .setErrors([]);
            return false;
        }

        this.formTimelySubmitted = false;
        this.isSubmitting = true;
        // TODO handle timeout
        this.apiService.post("/invite", this.registerForm.value).subscribe(
            data => {
                this.isSubmitting = false;
                let dialog = this.dialog.open(AlertDialogComponent, {
                    width: "auto",
                    data: {
                        title: "Email sent!",
                        message:
                            "Please check your email for further instructions."
                    }
                });

                localStorage.setItem('r_submitted', String(+new Date));

                dialog.afterClosed().subscribe(result => {
                    this.router.navigateByUrl("/");
                });
            },
            (err: ApiError) => {
                this.isSubmitting = false;

                if (err.statusCode === 400) {
                    for (let field in err.errors) {
                        this.registerForm
                            .get(field)
                            .setErrors(err.errors[field]);
                    }
                } else if (err.statusCode === 500) {
                    this.registerForm.setErrors({ serverError: true });
                }
            }
        );
    }
}
