<app-loading-error [apiError]="loadingError" (onRetry)="populateCandidateList()"></app-loading-error>

<div class="loading-shade" *ngIf="isLoadingResults && !loadingError">
    <svg class="loading-gif" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
        <line x1="12" y1="2" x2="12" y2="6"></line>
        <line x1="12" y1="18" x2="12" y2="22"></line>
        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
        <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
        <line x1="2" y1="12" x2="6" y2="12"></line>
        <line x1="18" y1="12" x2="22" y2="12"></line>
        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
        <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
    </svg>
</div>

<div *ngIf="!isLoadingResults" class="candidates-table-container scrollable">
    <div class="no-candidates" *ngIf="isNoResultsToFilter && !showNotesView">
        <p>
            You have no recent candidates to display.<br class="no-display-mobile" />
            Refresh your pipeline with a talent request.
        </p>
        <a class="btn" (click)="openRequestTalentDialog()">Request Talent</a>
    </div>

    <div class="no-candidates" *ngIf="candidatesList && candidatesList.length == 0 && !isNoResultsToFilter && !showNotesView">
        <p>
            None of your candidates match your currently selected filters.<br class="no-display-mobile" />
            Remove a filter or refresh your pipeline with a talent request.
        </p>
        <a class="btn" (click)="openRequestTalentDialog()">Request Talent</a>
    </div>

    <table class="scrollable" *ngIf="showNotesView">
        <thead class="header notes-header">
            <tr>
                <th>
                    Name
                </th>
                <th>
                    SEARCH RESULTS
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="no-notes" *ngIf="candidateNoteList && noteCount === 0">
                <p>No notes found that match your search. Please try again.</p>
            </tr>
            <div *ngIf="candidateNoteList && noteCount !== 0">
                <tr class="notes-row" *ngFor="let candidate of candidateNoteList" (click)="showSortOverlay || openCandidateDetailsDialog(candidate)">
                    <td class="name">
                        <div class="star-icon" [class.disable]="isLimitedImpersonation">
                            <svg width="24" height="23" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg"><path d="M9 2.314C13.438-2.248 24.534 5.735 9 16-6.534 5.736 4.562-2.248 9 2.314Z" fill-rule="nonzero" stroke="#979797" fill="none" [ngClass]="{ favored: candidate.favorited }" (click)="isLimitedImpersonation || updateFavorited($event, candidate.officeId, candidate.candidateId, candidate.favorited)"/></svg>
                        </div>
                        <span> {{ candidate.candidateFirstName }} {{ candidate.candidateLastName }} </span>
                        <span *ngIf="candidate.interviewed" class="tag interviewed">Interviewed</span>
                        <span *ngIf="candidate.inTheRunning" class="tag running">In the Running</span>
                        <span *ngIf="candidate.placedBefore && !candidate.active" class="tag placed">Hired Before</span>
                        <span *ngIf="candidate.active" class="tag active">Active</span>
                    </td>
                    <td>
                        <div class="note-container">
                            <div class="stamp">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#7B868B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock">
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <polyline points="12 6 12 12 16 14"></polyline>
                                    </svg>
                                    <span>{{ candidate.noteDateCreated | date: "MMM d, yyyy" }}</span>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#7B868B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <span>{{ candidate.noteCreatorFirstName }} {{ candidate.noteCreatorLastName }}</span>
                                </div>
                                <div *ngIf="candidate.jobId">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#7B868B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase">
                                        <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                                        <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                                    </svg>
                                    <span>Job#{{ candidate.jobId }}</span>
                                </div>
                            </div>
                            <div class="text">
                                <p [innerHTML]="parseText(candidate.note)"></p>
                            </div>
                        </div>
                    </td>
                </tr>
            </div>
        </tbody>
    </table>
    <div class="pagination" *ngIf="noteCount > 10 && showNotesView">
        <select class="rows-per-page" id="per-page-rows" (change)="onRowPerPageChange($event)">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
        </select>
        <span>{{ dictatePaginationView() }}</span>
        <div class="btn-container" (click)="moreCandidatesLeft() && paginatePrevious()" [class.inactive]="!moreCandidatesLeft()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left">
                <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
        </div>
        <div class="btn-container" (click)="moreCandidatesRight() && paginateNext()" [class.inactive]="!moreCandidatesRight()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
        </div>
    </div>

    <table class="scrollable" *ngIf="candidatesList && candidatesList.length > 0 && !showNotesView">
        <thead class="header">
            <tr>
                <th (click)="changeSort('CandidateFirstName')">
                    Name
                    <span *ngIf="sortByField === 'CandidateFirstName'" class="arrow" [class.transform]="sortByDirection === 'DESC'">
                        <svg width="16px" height="11px" viewBox="0 0 16 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                <g id="Candidates-3.1" transform="translate(-435.000000, -152.000000)" stroke="#b3e1ea" stroke-width="2.52">
                                    <g id="Group-9" transform="translate(381.000000, 148.000000)">
                                        <g id="arrow-up-circle-copy" transform="translate(61.500000, 9.000000) rotate(-180.000000) translate(-61.500000, -9.000000) translate(55.000000, 5.000000)">
                                            <polyline id="Shape" points="12.5185185 5.71428571 6.25925926 0 0 5.71428571"></polyline>
                                            <path d="M6.25925926,7.14285714 L6.25925926,-3.55271368e-15" id="Shape"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                </th>
                <th (click)="changeSort('BioHeadline')">
                    Headline
                    <span *ngIf="sortByField === 'BioHeadline'" class="arrow" [class.transform]="sortByDirection === 'DESC'">
                        <svg width="16px" height="11px" viewBox="0 0 16 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                <g id="Candidates-3.1" transform="translate(-435.000000, -152.000000)" stroke="#b3e1ea" stroke-width="2.52">
                                    <g id="Group-9" transform="translate(381.000000, 148.000000)">
                                        <g id="arrow-up-circle-copy" transform="translate(61.500000, 9.000000) rotate(-180.000000) translate(-61.500000, -9.000000) translate(55.000000, 5.000000)">
                                            <polyline id="Shape" points="12.5185185 5.71428571 6.25925926 0 0 5.71428571"></polyline>
                                            <path d="M6.25925926,7.14285714 L6.25925926,-3.55271368e-15" id="Shape"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                </th>
                <th (click)="changeSort('TrtyName')">
                    Area
                    <span *ngIf="sortByField === 'TrtyName'" class="arrow" [class.transform]="sortByDirection === 'DESC'">
                        <svg width="16px" height="11px" viewBox="0 0 16 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                <g id="Candidates-3.1" transform="translate(-435.000000, -152.000000)" stroke="#b3e1ea" stroke-width="2.52">
                                    <g id="Group-9" transform="translate(381.000000, 148.000000)">
                                        <g id="arrow-up-circle-copy" transform="translate(61.500000, 9.000000) rotate(-180.000000) translate(-61.500000, -9.000000) translate(55.000000, 5.000000)">
                                            <polyline id="Shape" points="12.5185185 5.71428571 6.25925926 0 0 5.71428571"></polyline>
                                            <path d="M6.25925926,7.14285714 L6.25925926,-3.55271368e-15" id="Shape"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let candidate of candidatesList" (click)="showSortOverlay || openCandidateDetailsDialog(candidate)">
                <td class="name-container">
                    <div class="star-icon" *ngIf="!candidate.blocked" (click)="isLimitedImpersonation || updateFavorited($event, candidate.officeId, candidate.candidateId, candidate.favorited)" [class.disable]="isLimitedImpersonation">
                        <svg width="24" height="23" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg" [class.disable]="isLimitedImpersonation" (click)="isLimitedImpersonation || updateFavorited($event, candidate.officeId, candidate.candidateId, candidate.favorited)"><path d="M9 2.314C13.438-2.248 24.534 5.735 9 16-6.534 5.736 4.562-2.248 9 2.314Z" fill-rule="nonzero" stroke="#979797" fill="none" [ngClass]="{ favored: candidate.favorited }"/></svg>
                    </div>

                    <span> {{ candidate.candidateFirstName }} {{ candidate.candidateLastName }} </span>

                    <span *ngIf="candidate.interviewed" class="tag interviewed">Interviewed</span>
                    <span *ngIf="candidate.inTheRunning" class="tag running">In the Running</span>
                    <span *ngIf="candidate.placedBefore && !candidate.active" class="tag placed">Hired Before</span>
                    <span *ngIf="candidate.active" class="tag active">Active</span>
                </td>
                <td class="headline headline-container">
                    <span *ngIf="candidate.bioHeadline">
                        {{ candidate.bioHeadline | uppercase }}
                    </span>
                    <span *ngIf="!candidate.bioHeadline">
                        Not Listed
                    </span>
                </td>
                <td class="territory-container">
                    <span *ngIf="candidate.trtyName && candidate.trtyName.length == 1">
                        {{ candidate.trtyName[0] }}
                    </span>
                    <span *ngIf="candidate.trtyName && candidate.trtyName.length == 2"> {{ candidate.trtyName[0] }}, {{ candidate.trtyName[1] }} </span>
                    <span *ngIf="candidate.trtyName && candidate.trtyName.length > 2"> {{ candidate.relevantTrtyName }} and {{ candidate.trtyName.length - 1 }} more </span>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="pagination" *ngIf="count > 10 && !showNotesView">
        <select class="rows-per-page" id="per-page-rows" (change)="onRowPerPageChange($event)">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
        </select>
        <span>{{ dictatePaginationView() }}</span>
        <div class="btn-container" (click)="moreCandidatesLeft() && paginatePrevious()" [class.inactive]="!moreCandidatesLeft()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left">
                <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
        </div>
        <div class="btn-container" (click)="moreCandidatesRight() && paginateNext()" [class.inactive]="!moreCandidatesRight()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
        </div>
    </div>
</div>
