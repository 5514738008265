import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class RescheduledInterviewService {

    private rescheduledInterview: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    update$: Observable<boolean> = this.rescheduledInterview.asObservable();

    updateBoolean(rescheduled: boolean) {
        this.rescheduledInterview.next(rescheduled);
    }
}
