import { Injectable } from '@angular/core';
import { ApiService } from '../../shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from "../../shared/models/paginated-response.model";
import { AdminMessage } from "../../shared/models/admin-message.model";

@Injectable()
export class MessagesService {
    private messages: Array<AdminMessage>;

    public get currentMessages(): Array<AdminMessage> {
        return this.messages;
    }

    constructor(
        private apiService: ApiService
    ) {
    }

    getMessages(limit: number, offset: number): Observable<PaginatedResponse<AdminMessage>> {
        return this.apiService.get('/admin/message', {
            limit: limit.toString(),
            offset: offset.toString(),
        }).pipe(map(
            (res: PaginatedResponse<AdminMessage>) => {
                this.messages = res.results;

                return res;
            }
        ));
    }

    createMessage(data: any): Observable<any> {
        return this.apiService.post('/admin/message', data);
    }

    deleteMessage(messageID: number): Observable<any> {
        return this.apiService.delete('/admin/message/' + String(messageID));
    }

    editMessage(messageID: number, data: any): Observable<any> {
        return this.apiService.put('/admin/message/' + String(messageID), data);
    }
}