import { Component, OnInit, ViewChild } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { MatSort, MatSortable, Sort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscribing } from "../../shared/components/subscribing.component";
import { LbTitle } from "../../shared/services/title.service";
import { ILBTemplateConfiguration } from "./models/template-configuration.model";
import { TemplatesService } from "./templates.service";
import { ApiError } from "../../shared/models/api-error.model";

@Component({
    selector: "app-admin-templates",
    templateUrl: "./templates.component.html",
    styleUrls: ["./templates.component.scss"]
})
export class TemplatesComponent extends Subscribing implements OnInit {
    displayedColumns = [
        "DisplayName",
        "Subject",
        "Type",
        "Updated",
        "UpdatedBy",
        "Action"
    ];

    dataSource = new MatTableDataSource<ILBTemplateConfiguration>();
    isLoadingResults: boolean = true;
    loadingError: string = null;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    constructor(
        private templatesService: TemplatesService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        titleService: LbTitle
    ) {
        super();

        titleService.setTitleFromKeywords(["Templates", "Admin"]);

        iconRegistry.addSvgIcon(
            "edit",
            sanitizer.bypassSecurityTrustResourceUrl("assets/icons/edit.svg")
        );
    }

    ngOnInit() {
        this.subscriptions.push(
            this.templatesService.getTemplateConfigurations().subscribe({
                next: templateConfigs => {
                    this.isLoadingResults = false;

                    this.dataSource.data = templateConfigs;

                    this.sort.sort(<MatSortable>{
                        id: "DisplayName",
                        start: "asc"
                    });
                },
                error: (err: ApiError) => {
                    this.isLoadingResults = false;

                    if (err.timeout) {
                        this.loadingError =
                            "The server did not respond in time while trying to retrieve the template data. Please check your connection and refresh the page.";
                    } else {
                        this.loadingError =
                            "An occurred while trying to retrieve the template data. Please try again.";
                    }
                }
            }),
            this.sort.sortChange.subscribe((sort: Sort) => {
                this.dataSource.data = this.dataSource.data.sort(
                    this.sortTemplateConfigsBy(sort)
                );
            })
        );
    }

    private sortTemplateConfigsBy(sort: Sort) {
        return (a: ILBTemplateConfiguration, b: ILBTemplateConfiguration) => {
            const directionMultiplier = sort.direction === "desc" ? -1 : 1;

            if (a[sort.active] === null && b[sort.active] !== null)
                return -1 * directionMultiplier;
            if (a[sort.active] === null && b[sort.active] === null)
                return 0 * directionMultiplier;
            if (a[sort.active] !== null && b[sort.active] === null)
                return 1 * directionMultiplier;

            if (a[sort.active].changingThisBreaksApplicationSecurity) {
                // SafeHtml strings (subject)
                return (
                    a[
                        sort.active
                    ].changingThisBreaksApplicationSecurity.localeCompare(
                        b[sort.active].changingThisBreaksApplicationSecurity
                    ) * directionMultiplier
                );
            }

            return (
                a[sort.active].localeCompare(b[sort.active]) *
                directionMultiplier
            );
        };
    }

    formatUpdated(dateString: string): string {
        let date = new Date(dateString),
            mon = this.padDateNumber(date.getMonth() + 1),
            day = this.padDateNumber(date.getDate()),
            hr = this.padDateNumber(date.getHours()),
            min = this.padDateNumber(date.getMinutes());

        return `${mon}-${day}-${date.getFullYear()} ${hr}:${min}`;
    }

    private padDateNumber(n: number): string {
        let nStr: string = "" + n,
            digits: string = "00";

        return digits.substring(0, digits.length - nStr.length) + nStr;
    }
}
