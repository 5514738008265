import {
    Component,
    OnInit,
    Input,
    ElementRef,
    ViewChild,
    OnChanges
} from "@angular/core";
import {
    Candidate,
    UserService,
    JobOrderCandidate,
    CandidatesService,
    JobService
} from "./../../../shared";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AnalyticsService } from "./../../../shared/services/analytics.service";
import { AlertDialogComponent } from "./../../../shared/components/alert-dialog/alert-dialog.component";
import {
    PerfReviewDialogComponent,
    PerfReviewDialogData
} from "./../../../shared/components/perf-review-dialog/perf-review-dialog.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ApiError } from "../../../shared/models/api-error.model";
import { NetworkErrorSnackbarService } from "../../../shared/services/network-error-snackbar.service";
import { Subscription } from "rxjs";
import {switchMap} from 'rxjs/operators';

@Component({
    selector: "app-candidate-details-notes",
    templateUrl: "./candidate-details-notes.component.html",
    styleUrls: ["./candidate-details-notes.component.scss"]
})
export class CandidateDetailsNotesComponent implements OnInit {
    @ViewChild("notes_form", { static: true })
    notes_form: ElementRef;

    @Input()
    details: any;

    @Input()
    patchCandidateNotes: boolean = false;

    public loadingError: ApiError;

    public form: UntypedFormGroup;
    public defaultValue: string = "";
    public isSubmitting: boolean = false;
    public formError: string;
    public keepPrivate: boolean = false;
    public shareCC: boolean = true;
    public shareHiring: boolean = false;
    public showNotesForm: boolean = false;
    public showSharingForm: boolean = false;
    public noteId: number;
    public showWholeNote: number = -1;
    public expanded: boolean = false;
    public isLimitedImpersonation: boolean = false;
    public expandedNotes: any[] = [];
    public expandedReviews: any[] = [];
    public countExceeded: boolean = false;
    public countMax: number = 4000;

    public sharePreferencesLoading: {
        sharedCC: boolean;
        sharedHiringManagers: boolean;
    } = {
        sharedCC: false,
        sharedHiringManagers: false
    };

    constructor(
        private fb: UntypedFormBuilder,
        private analyticsService: AnalyticsService,
        private jobOrdersService: JobService,
        private dialog: MatDialog,
        private userService: UserService,
        private candidateService: CandidatesService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService
    ) {
        this.form = this.fb.group({
            note: ["",
                Validators.compose([Validators.required, Validators.maxLength(4000)])
            ],
            private: [false],
            sharedCC: [true],
            sharedHiringManagers: [false]
        });
    }

    ngOnInit() {
        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });
    }

    ngOnChanges() {
        this.resetNotesField();
    }

    truncateHTML(text: string): string {
        let charlimit = 350;
        if(!text || text.length <= charlimit )
        {
            return text;
        }
      let without_html = text.replace(/<(?:.|\n)*?>/gm, '');
      let shortened = without_html.substring(0, charlimit) + "...";
      return shortened;
    }

    readMoreNote(note) {
        if (this.expandedNotes.includes(note.noteId)) {
            let index = this.expandedNotes.indexOf(note.noteId);
            if (index !== -1) {
                this.expandedNotes.splice(index, 1);
            }
        } else {
            this.expandedNotes.push(note.noteId);
        }
    }

    readMoreReview(note) {
        if (this.expandedReviews.includes(note.reviewId)) {
            let index = this.expandedReviews.indexOf(note.reviewId);
            if (index !== -1) {
                this.expandedReviews.splice(index, 1);
            }
        } else {
            this.expandedReviews.push(note.reviewId);
        }
    }

    readMore(id) {
        this.showWholeNote = id;
        this.expanded = true;
    }

    collapse() {
        this.showWholeNote = -1;
        this.expanded = false;
    }

    determineInput(event) {
        let value = event.target.value;
        let checked = event.target.checked;

        if (value == "private" && checked) {
            this.keepPrivate = true;
            this.form.controls["sharedCC"].setValue(false);
        } else if (value == "private" && !checked) {
            this.keepPrivate = false;
        } else if (value == "creative-circle" && checked) {
            this.keepPrivate = false;
            this.form.controls["private"].setValue(false);
        } else if (value == "creative-circle" && !checked) {
            this.shareCC = true;
        }
    }

    resetNotesField() {
        this.showNotesForm = false;
        this.keepPrivate = false;
        this.defaultValue = "";
        this.form.controls["sharedCC"].setValue(true);
        this.form.controls["sharedHiringManagers"].setValue(false);
        this.form.controls["private"].setValue(false);
        this.form.controls["note"].setValue("");
    }

    toggleNotesSection() {
        this.form.controls["note"].valueChanges.subscribe(content =>
            {
                this.countMax = 4000 - content.length;
            }
        );

        let textarea = this.notes_form.nativeElement.getElementsByClassName(
            "noteForm"
        );
        this.notes_form.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});

        if (textarea[0]) {
            if (textarea[0].value != "") {
                let dialog = this.dialog.open(AlertDialogComponent, {
                    width: "350px",
                    data: {
                        title: "Close note without finishing?"
                    }
                });

                dialog.afterClosed().subscribe(result => {
                    if (result == true) {
                        this.resetNotesField();
                        this.showNotesForm = false;
                        this.jobOrdersService.formChangedSubject.next(false);
                    }
                });
            } else {
                this.showNotesForm = !this.showNotesForm;
            }
        } else {
            this.showNotesForm = !this.showNotesForm;
        }
    }

    updateSharingPreferences(noteId) {
        this.noteId = noteId;
        this.showSharingForm = true;
    }

    closeSharedForm() {
        this.showSharingForm = false;
    }

    submitNote() {
        if (!this.patchCandidateNotes) {
            this.analyticsService.fireEvent(
                "Job Candidates",
                "Note Added",
                this.shareOptionsFromForm(this.form),
                {
                    dimension6: this.details.jobCandidateId,
                    dimension3:
                        this.details.clientId.toString() +
                        "." +
                        this.details.officeId.toString(),
                    dimension5:
                        this.details.jobId.toString() +
                        "." +
                        this.details.officeId.toString()
                }
            );

            let postJobCandidateNotes = this.jobOrdersService
                .postJobCandidateNotes(
                    this.details.officeId,
                    this.details.jobId,
                    this.details.candidateId,
                    this.details.jobCandidateId,
                    this.form.value
                );

            // Add to LastClientComment if the note is shared with CC
            if (this.form.get("sharedCC").value) {
                const patchJobCandidate = this.jobOrdersService.patchJobCandidate(
                    this.details.officeId,
                    this.details.jobId,
                    this.details.jobCandidateId,
                    {
                        statusId: this.details.statusId,
                        details: this.form.controls['note'].value,
                        addingLastClientComment: true
                    }
                );

                postJobCandidateNotes = postJobCandidateNotes.pipe(
                    switchMap(() => patchJobCandidate)
                );
            }

            postJobCandidateNotes
                // TODO: show error alert
                .subscribe(data => {
                    this.refreshCandidate();
                    this.resetNotesField();
                });
        } else {
            this.analyticsService.fireEvent(
                "Candidates",
                "Note Added",
                this.shareOptionsFromForm(this.form),
                { dimension7: this.details.candidateId }
            );
            this.candidateService
                .postCandidateNote(
                    this.details.officeId,
                    this.details.candidateId,
                    this.form.value
                )
                // TODO: show error alert
                .subscribe(data => {
                    this.refreshCandidateModal();
                    this.resetNotesField();
                });
        }

        this.jobOrdersService.formChangedSubject.next(false);
    }

    private shareOptionsFromForm(form: UntypedFormGroup): string {
        let options = [];

        if (form.get("private").value) {
            options.push("private");
        }
        if (form.get("sharedCC").value) {
            options.push("sharedCC");
        }
        if (form.get("sharedHiringManagers").value) {
            options.push("sharedHiringManagers");
        }

        return options.join(",");
    }

    changePreferences(e: Event, note, prop) {
        e.preventDefault();

        this.sharePreferencesLoading[prop] = true;

        let params = {
            sharedCC: note.sharedCC,
            sharedHiringManagers: note.sharedHiringManagers,
            note: note.note
        };

        params[prop] = !params[prop];

        this.jobOrdersService
            .patchJobCandidateNotes(note.noteId, params)
            .subscribe(
                () => {
                    this.showNotesForm = false;

                    let sub: Subscription;

                    if (this.details.jobCandidateId) {
                        sub = this.refreshCandidate();
                    } else {
                        sub = this.refreshCandidateModal();
                    }

                    sub.add(() => (this.sharePreferencesLoading[prop] = false));
                },
                (err: ApiError) => {
                    this.networkErrorSnackbarService
                        .networkError(err, "editing note preferences.")
                        .subscribe(() => {
                            this.changePreferences(e, note, prop);
                        });
                    this.sharePreferencesLoading[prop] = false;
                }
            );
    }

    pastHire(id) {
        // todo, add PastHire status code
        return [8].includes(id);
    }

    openPerformanceReviewDialog(joc: JobOrderCandidate) {
        this.analyticsService.fireEvent(
            "Job Candidates",
            "Write Review Clicked",
            "",
            {
                dimension6: joc.jobCandidateId,
                dimension3:
                    joc.clientId.toString() + "." + joc.officeId.toString(),
                dimension5: joc.jobId.toString() + "." + joc.officeId.toString()
            }
        );

        let dialog = this.dialog.open(PerfReviewDialogComponent, {
            width: "600px",
            maxWidth: "90%",
            maxHeight: "90%",
            panelClass: "mobileContainer",
            autoFocus: false,
            data: new PerfReviewDialogData(
                joc.officeId,
                joc.jobId,
                joc.candidateId,
                joc.firstName,
                joc.lastName,
                joc.favorited
            )
        });

        dialog.afterClosed().subscribe(result => {
            if (result === "SUCCESS") {
                this.analyticsService.fireEvent(
                    "Job Candidates",
                    "Review Submitted",
                    "",
                    {
                        dimension6: joc.jobCandidateId,
                        dimension3:
                            joc.clientId.toString() +
                            "." +
                            joc.officeId.toString(),
                        dimension5:
                            joc.jobId.toString() + "." + joc.officeId.toString()
                    }
                );

                this.dialog.open(AlertDialogComponent, {
                    width: "350px",
                    data: {
                        title: "Thanks!",
                        message: "Your review has been submitted."
                    }
                });
                this.refreshCandidate();
            }
        });
    }

    refreshCandidateModal(): Subscription {
        return (
            this.candidateService
                .getCandidatesDetails(
                    this.details.officeId,
                    this.details.candidateId
                )
                // TODO: handle timeout
                .subscribe(data => {
                    this.details.notes = data.notes;
                })
        );
    }

    refreshCandidate(): Subscription {
        if (!this.details || !this.details.officeId) {
            return;
        }
        let officeId = this.details.officeId;
        let jobId = this.details.jobId;
        let jobCandidateId = this.details.jobCandidateId;

        return (
            this.jobOrdersService
                .getJobCandidate(officeId, jobId, jobCandidateId)
                // TODO: handle timeout
                .subscribe(
                    jobCandidate => {
                        this.details = jobCandidate;
                    },
                    (apiError: ApiError) => {
                        this.loadingError = apiError;
                    }
                )
        );
    }

    formUpdated() {
        if (this.form.controls["note"].value.trim() === "") {
            this.jobOrdersService.formChangedSubject.next(false);
        } else {
            this.jobOrdersService.formChangedSubject.next(true);
        }
    }
}
