<div class="large-container">
    <div class="loading-shade" *ngIf="isLoadingResults">
        <svg class="loading-gif" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#131e27" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
            <line x1="12" y1="2" x2="12" y2="6"></line>
            <line x1="12" y1="18" x2="12" y2="22"></line>
            <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
            <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
            <line x1="2" y1="12" x2="6" y2="12"></line>
            <line x1="18" y1="12" x2="22" y2="12"></line>
            <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
            <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
        </svg>
    </div>

    <mat-table #table [dataSource]="dataSource" matSort matSortDisableClear [ngClass]="{ hidden: isLoadingResults || loadingError }">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Region Name</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="numberofusers">
            <mat-header-cell *matHeaderCellDef>Number of Users</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.numberofusers }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button aria-label="Users" mat-button title="Users" [disabled]="row.numberofusers < 1" routerLink="/admin/demo-users/{{ row.id }}">
                    <mat-icon svgIcon="users" aria-label="Users"></mat-icon>
                    Users
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
</div>
