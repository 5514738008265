<div class="candidate-header">
  <app-search-bar placeHolder="SEARCH CANDIDATES" [formControl]="searchControl" ngDefaultControl></app-search-bar>
  <div class="filter-and-sort-container" (clickOutside)="closeFilter()">
    <button aria-label="Filter" class="filters" [class.active]="filterActive" [class.inactive]="noCandidates" (click)="handleFilter()">
      <span class="svg-container">
        <svg width="20px" height="11px" viewBox="0 0 20 11" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs></defs>
          <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.600000024">
            <g id="Panel:-Talent-Requests" transform="translate(-36.000000, -85.000000)" fill="#131e27">
              <g id="Search+Dropdowns" transform="translate(21.000000, 20.000000)">
                <g id="Group-15" transform="translate(0.000000, 50.000000)">
                  <g id="Group-13" transform="translate(15.000000, 15.000000)">
                    <rect id="Rectangle-6" x="0" y="0" width="20" height="2.2" rx="1.1"></rect>
                    <rect id="Rectangle-6-Copy" x="3.57142857" y="4.4" width="12.8571429" height="2.2" rx="1.1"></rect>
                    <rect id="Rectangle-6-Copy-2" x="6.42857143" y="8.8" width="7.14285714" height="2.2"
                          rx="1.1"></rect>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </span>
      <span class="desktop">FILTER BY <span *ngIf="totalFiltered > 0">({{totalFiltered}})</span></span>
      <span class="mobile">FILTER BY <span *ngIf="totalFiltered > 0">({{totalFiltered}})</span></span>
    </button>
    <button aria-label="Sort" class="sorts" [class.active]="sortActive" [class.inactive]="noCandidates" (click)="handleSort()">
      <span>
        <svg class="sort-svg" width="12px" height="14px" viewBox="0 0 15 17" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs></defs>
          <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.600000024"
             stroke-linecap="round" stroke-linejoin="round">
            <g id="Panel:-Talent-Requests" transform="translate(-182.000000, -82.000000)" stroke="#131e27"
               stroke-width="1.8">
              <g id="Search+Dropdowns" transform="translate(21.000000, 20.000000)">
                <g id="Group-14" transform="translate(140.000000, 50.000000)">
                  <g id="Group-11-Copy" transform="translate(22.000000, 13.000000)">
                    <g id="arrow-up-circle">
                      <polyline id="Shape" points="8.66666667 4.28571429 4.33333333 0 0 4.28571429"></polyline>
                      <path d="M4.33333333,5.35714286 L4.33333333,0" id="Shape"></path>
                    </g>
                    <g id="arrow-up-circle-copy"
                       transform="translate(8.500000, 12.000000) rotate(-180.000000) translate(-8.500000, -12.000000) translate(4.000000, 9.000000)">
                      <polyline id="Shape" points="8.66666667 4.28571429 4.33333333 0 0 4.28571429"></polyline>
                      <path d="M4.33333333,5.35714286 L4.33333333,-3.55271368e-15" id="Shape"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </span>
      SORT BY
    </button>
    <div [class.active]="filterActive" class="filter-container">
      <div class="filter-by-dropdown-container">
        <div class="filter-dropdown">
          <form>
            <div class="mobile-only">
              <span>FILTER</span>
              <p class="clear-all" (click)="clearAllFiltersOnMobile()">CLEAR ALL</p>
            </div>
            <hr class="mobile-only">
            <p class="clear-all" (click)="clearAllFilters()">CLEAR</p>
            <div class="radio-btn-container filter-inputs">
              <p class="section-header">STATUS</p>
            </div>
            <div class="input-fields" #input_fields>
              <div class="row">
                <div class="column">
                  <div class="input" *ngIf="clickedJobOrderStatus === 'OPEN' || clickedJobOrderStatus === 'CANCELLED'">
                    <label>
                      <input type="checkbox" value="3" class="filter-check" (click)="filterBy()">
                      Interview Requested
                    </label>
                  </div>
                  <div class="input" *ngIf="clickedJobOrderStatus === 'OPEN' || clickedJobOrderStatus === 'CANCELLED'">
                    <label>
                      <input type="checkbox" value="2" class="filter-check" (click)="filterBy()">
                      Interview Scheduled
                    </label>
                  </div>
                  <div class="input" *ngIf="clickedJobOrderStatus === 'OPEN' || clickedJobOrderStatus === 'CANCELLED'">
                    <label>
                      <input type="checkbox" value="7" class="filter-check" (click)="filterBy()">
                      Hire Pending
                    </label>
                  </div>
                  <div class="input" *ngIf="clickedJobOrderStatus !== 'OPEN'">
                    <label>
                      <input type="checkbox" value="8" class="filter-check" (click)="filterBy()">
                      Hired
                    </label>
                  </div>
                  <div class="input" *ngIf="clickedJobOrderStatus === 'OPEN' || clickedJobOrderStatus === 'CANCELLED'">
                    <label>
                      <input type="checkbox" value="12" class="filter-check" (click)="filterBy()">
                      No Longer Available
                    </label>
                  </div>
                  <div class="input" *ngIf="clickedJobOrderStatus === 'ACTIVE' || clickedJobOrderStatus === 'CLOSED'">
                    <label>
                      <input type="checkbox" value="not-selected" class="filter-check" (click)="filterBy()">
                      Not Selected
                    </label>
                  </div>
                </div>
                <div class="column">
                  <div class="input" *ngIf="clickedJobOrderStatus === 'OPEN' || clickedJobOrderStatus === 'CANCELLED'">
                    <label>
                      <input type="checkbox" value="1" class="filter-check" (click)="filterBy()">
                      Not Reviewed
                    </label>
                  </div>
                  <div class="input" *ngIf="clickedJobOrderStatus === 'OPEN' || clickedJobOrderStatus === 'CANCELLED'">
                    <label>
                      <input type="checkbox" value="6" class="filter-check" (click)="filterBy()">
                      Considering
                    </label>
                  </div>
                  <div class="input" *ngIf="clickedJobOrderStatus === 'OPEN' || clickedJobOrderStatus === 'CANCELLED'">
                    <label>
                      <input type="checkbox" value="5" class="filter-check" (click)="filterBy()">
                      Passed
                    </label>
                  </div>
                  <div class="input">
                    <label>
                      <input type="checkbox" value="blocked" class="filter-check" (click)="filterBy()">
                      Blocked
                    </label>
                  </div>
                </div>
              </div>
              <hr>
              <div class="favorites">
                <div class="input">
                  <label>FAVORITES</label>
                  <div class="right">
                    <label class="no-bold favorites-label">
                      <input type="checkbox" value="favorited" class="filter-check favorites-check" (click)="filterBy()">
                      Only Show Favorites
                    </label>
                  </div>
                </div>
              </div>
              <hr>
              <div class="favorites">
                <div class="input">
                  <label>INTERVIEWED</label>
                  <div class="right">
                    <label class="no-bold">
                      <input type="checkbox" value="interviewed" class="filter-check interview-check" (click)="filterBy()">
                      Only Show Interviewed
                    </label>
                  </div>
                </div>
              </div>
              <div class="view-all-close">
                <button (click)="closeFilter()">VIEW RESULTS</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div [class.active]="sortActive" class="sort-container popover">
      <div class="sort-by-dropdown-container">
        <div class="sort-by-dropdown" #sort_dropdown>
          <div class="filter-on-mobile">
            <div>
              <span class="header-mobile">SORT</span>
            </div>
            <button aria-label="Close" class="close-btn" (click)="closeSort()">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <form>
            <div class="radio-btn-container filter-inputs">
              <div class="submitted-date-desc area" [class.active-sort]="activeSort == 'submitted-date-desc'" (click)="sortBy($event)">
                <div class="check-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <p>Date Submitted - New to Old</p>
              </div>
              <div class="submitted-date-asc area" [class.active-sort]="activeSort == 'submitted-date-asc'" (click)="sortBy($event)">
                <div class="check-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <p>Date Submitted - Old to New</p>
              </div>
              <div class="first-name-desc area" [class.active-sort]="activeSort == 'first-name-desc'" (click)="sortBy($event)">
                <div class="check-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <p>Candidate First Name - A to Z</p>
              </div>
              <div class="first-name-asc area" [class.active-sort]="activeSort == 'first-name-asc'" (click)="sortBy($event)">
                <div class="check-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <p>Candidate First Name - Z to A</p>
              </div>
              <div class="status area" [class.active-sort]="activeSort == 'status'" (click)="sortBy($event)">
                <div class="check-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <p>Status</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <hr>
</div>
<div class="candidate-container" [class.scrollbar-jobs]="isWindows" #candidates_container>
  <div class="no-candidates" *ngIf="noCandidates && clickedJobOrderStatus !== 'CANCELLED'">
    <p>We're still searching for candidates for this Opening. Check back soon!</p>
  </div>

  <div class="no-candidates" *ngIf="noCandidates && clickedJobOrderStatus === 'CANCELLED'">
    <p>There are no candidates for this canceled opening. If you'd like to re-open the request, give us a shout.</p>
  </div>
  <div *ngIf="!customFilter && showCompleteJobHeadlines && !noCandidates" [class.opacity-change]="filterActive || sortActive">
    <h2 *ngIf="clickedJobOrderStatus == 'ACTIVE' || clickedJobOrderStatus == 'FT_PLACEMENT'" class="label">Selected Candidate</h2>
    <h2 *ngIf="clickedJobOrderStatus != 'ACTIVE' && clickedJobOrderStatus != 'CANCELLED' && clickedJobOrderStatus != 'FT_PLACEMENT'" class="label">Selected Candidate</h2>
    <ng-container *ngFor="let candidate of _candidatesHired">
      <div class="candidate" [class.active-candidate]='clickedCandidate?.jobCandidateId === candidate.jobCandidateId' (click)="(filterActive || sortActive) || expandedCandidateDetails(candidate)" [attr.data-candidate-id]="candidate.jobCandidateId">
        <div class="row">
          <div>
            <div class="flex">
              <span class="star" [ngClass]="{'transparent' : !candidate.favorited }" [class.transparent]="candidate.sunsetFlag == 1 || candidate.blocked || (!candidate.activeFlag && !candidate.deleteFlag) || !candidate.favorited">
                <svg width="14" height="13" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg"><path d="M9 2.314C13.438-2.248 24.534 5.735 9 16-6.534 5.736 4.562-2.248 9 2.314Z" fill-rule="nonzero" stroke="#7B868B" fill="none" [ngClass]="{ favored: candidate.favorited }"/></svg>
              </span>
              <span *ngIf="candidate.lastName" class="name">{{candidate.firstName}}
                {{candidate.lastName.substring(0, 1)}}.
              </span>
            </div>
            <div class="flex">
              <span>
                <svg *ngIf="candidate.statusId != 2 && candidate.statusId != 3" [ngClass]="{'hide-svg' : !candidate.hurry }" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-flag"><path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path><line x1="4" y1="22" x2="4" y2="15"></line>
                </svg>

                <svg *ngIf="candidate.statusId == 2 || candidate.statusId == 3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
              </span>
              <div class="status hired">
                HIRED
              </div>
            </div>
            <div class="flex">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
              </span>
              <div class="date">Submitted {{candidate.submittedDate | date:'MMM d, y'}}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div>
            <span class="chevron right"></span>
          </div>
        </div>
      </div>
      <hr>
    </ng-container>
  </div>
  <div *ngIf="!customFilter && !showCompleteJobHeadlines" [class.opacity-change]="filterActive || sortActive">
    <h2 class="label" *ngIf='_candidatesNewSubmittals?.length > 0'>New Submittals</h2>
    <ng-container *ngFor="let candidate of _candidatesNewSubmittals">
      <div class="candidate" [class.active-candidate]='parseNumber(_jobCandidateIdParam) === candidate.jobCandidateId' (click)="(filterActive || sortActive) || expandedCandidateDetails(candidate)" [attr.data-candidate-id]="candidate.jobCandidateId">
        <div class="row">
          <div>
            <div class="flex">
              <span class="star" [ngClass]="{'transparent' : !candidate.favorited }" [class.transparent]="candidate.sunsetFlag == 1 || candidate.blocked || (!candidate.activeFlag && !candidate.deleteFlag) || !candidate.favorited">
                <svg width="14" height="13" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg"><path d="M9 2.314C13.438-2.248 24.534 5.735 9 16-6.534 5.736 4.562-2.248 9 2.314Z" fill-rule="nonzero" stroke="#7B868B" fill="none" [ngClass]="{ favored: candidate.favorited }"/></svg>
              </span>
              <span *ngIf="candidate.lastName" class="name">{{candidate.firstName}}
                {{candidate.lastName.substring(0, 1)}}.
              </span>
            </div>
            <div class="flex">
              <span>
                <svg *ngIf="candidate.statusId != 2 && candidate.statusId != 3" [ngClass]="{'hide-svg' : !candidate.hurry }" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-flag"><path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path><line x1="4" y1="22" x2="4" y2="15"></line>
                </svg>

                <svg *ngIf="candidate.statusId == 2 || candidate.statusId == 3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
              </span>
              <div class="status new-submittals">
                {{determineStatus(candidate.statusId)}}
              </div>
            </div>
            <div class="flex">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
              </span>
              <div class="date">Submitted {{candidate.submittedDate | date:'MMM d, y'}}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div>
            <span class="chevron right"></span>
          </div>
        </div>
      </div>
      <hr>
    </ng-container>
  </div>
  <div *ngIf="!customFilter && !showCompleteJobHeadlines" [class.opacity-change]="filterActive || sortActive">
    <h2 class="label" *ngIf='_candidatesUnderConsideration?.length > 0'>In the Running</h2>
    <ng-container *ngFor="let candidate of _candidatesUnderConsideration">
      <div class="candidate" [class.active-candidate]='parseNumber(_jobCandidateIdParam) === candidate.jobCandidateId' (click)="(filterActive || sortActive) || expandedCandidateDetails(candidate)" [attr.data-candidate-id]="candidate.jobCandidateId">
        <div class="row">
          <div>
            <div class="flex">
              <span class="star" [ngClass]="{'transparent' : !candidate.favorited }" [class.transparent]="candidate.sunsetFlag == 1 || candidate.blocked || (!candidate.activeFlag && !candidate.deleteFlag) || !candidate.favorited">
                <svg width="14" height="13" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg"><path d="M9 2.314C13.438-2.248 24.534 5.735 9 16-6.534 5.736 4.562-2.248 9 2.314Z" fill-rule="nonzero" stroke="#7B868B" fill="none" [ngClass]="{ favored: candidate.favorited }"/></svg>
              </span>
              <span *ngIf="candidate.lastName" class="name">{{candidate.firstName}}
                {{candidate.lastName.substring(0, 1)}}.
              </span>
            </div>
            <div class="flex">
              <span>
                <svg *ngIf="candidate.statusId != 2 && candidate.statusId != 3" [ngClass]="{'hide-svg' : !candidate.hurry }" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-flag"><path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path><line x1="4" y1="22" x2="4" y2="15"></line>
                </svg>

                <svg *ngIf="candidate.statusId == 2 || candidate.statusId == 3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
              </span>
              <div class="status under-consideration" [class.hired]="candidate.statusId == 8">
                {{determineStatus(candidate.statusId)}}
              </div>
            </div>
            <div class="flex">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
              </span>
              <div class="date">Submitted {{candidate.submittedDate | date:'MMM d, y'}}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div>
            <span class="chevron right"></span>
          </div>
        </div>
      </div>
      <hr>
    </ng-container>
  </div>
  <div *ngIf="!customFilter && showCompleteJobHeadlines && _candidatesNotSelected?.length > 0" [class.opacity-change]="filterActive || sortActive">
    <h2 class="label">Not Selected</h2>
    <ng-container *ngFor="let candidate of _candidatesNotSelected">
      <div class="candidate" [class.active-candidate]='parseNumber(_jobCandidateIdParam) === candidate.jobCandidateId' (click)="(filterActive || sortActive) || expandedCandidateDetails(candidate)" [attr.data-candidate-id]="candidate.jobCandidateId">
        <div class="row">
          <div>
            <div class="flex">
              <span class="star" [ngClass]="{'transparent' : !candidate.favorited }" [class.transparent]="candidate.sunsetFlag == 1 || candidate.blocked || (!candidate.activeFlag && !candidate.deleteFlag) || !candidate.favorited">
                <svg width="14" height="13" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg"><path d="M9 2.314C13.438-2.248 24.534 5.735 9 16-6.534 5.736 4.562-2.248 9 2.314Z" fill-rule="nonzero" stroke="#7B868B" fill="none" [ngClass]="{ favored: candidate.favorited }"/></svg>
              </span>
              <span *ngIf="candidate.lastName" class="name">{{candidate.firstName}}
                {{candidate.lastName.substring(0, 1)}}.
              </span>
            </div>
            <div class="flex">
              <span>
                <svg *ngIf="candidate.statusId != 2 && candidate.statusId != 3" [ngClass]="{'hide-svg' : !candidate.hurry }" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-flag"><path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path><line x1="4" y1="22" x2="4" y2="15"></line>
                </svg>

                <svg *ngIf="candidate.statusId == 2 || candidate.statusId == 3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
              </span>
              <div class="status passed-on">
                {{determineStatus(candidate.statusId)}}
              </div>
            </div>
            <div class="flex">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
              </span>
              <div class="date">Submitted {{candidate.submittedDate | date:'MMM d, y'}}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div>
            <span class="chevron right"></span>
          </div>
        </div>
      </div>
      <hr>
    </ng-container>
  </div>
  <div *ngIf="!customFilter && !showCompleteJobHeadlines" [class.opacity-change]="filterActive || sortActive">
    <h2 class="label" *ngIf='_candidatesPassedOn?.length > 0'>Passed On</h2>
    <ng-container *ngFor="let candidate of _candidatesPassedOn">
      <div class="candidate" [class.active-candidate]='parseNumber(_jobCandidateIdParam) === candidate.jobCandidateId' (click)="(filterActive || sortActive) || expandedCandidateDetails(candidate)" [attr.data-candidate-id]="candidate.jobCandidateId">
        <div class="row">
          <div>
            <div class="flex">
              <span class="star" [ngClass]="{'transparent' : !candidate.favorited }" [class.transparent]="candidate.sunsetFlag == 1 || candidate.blocked || (!candidate.activeFlag && !candidate.deleteFlag) || !candidate.favorited">
                <svg width="14" height="13" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg"><path d="M9 2.314C13.438-2.248 24.534 5.735 9 16-6.534 5.736 4.562-2.248 9 2.314Z" fill-rule="nonzero" stroke="#7B868B" fill="none" [ngClass]="{ favored: candidate.favorited }"/></svg>
              </span>
              <span *ngIf="candidate.lastName" class="name">{{candidate.firstName}}
                {{candidate.lastName.substring(0, 1)}}.
              </span>
            </div>
            <div class="flex">
              <span>
                <svg *ngIf="candidate.statusId != 2 && candidate.statusId != 3" [ngClass]="{'hide-svg' : !candidate.hurry }" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-flag"><path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path><line x1="4" y1="22" x2="4" y2="15"></line>
                </svg>

                <svg *ngIf="candidate.statusId == 2 || candidate.statusId == 3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
              </span>
              <div class="status passed-on">
                {{determineStatus(candidate.statusId)}}
              </div>
            </div>
            <div class="flex">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
              </span>
              <div class="date">Submitted {{candidate.submittedDate | date:'MMM d, y'}}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div>
            <span class="chevron right"></span>
          </div>
        </div>
      </div>
      <hr>
    </ng-container>
  </div>
  <div *ngIf="customFilter" [class.opacity-change]="filterActive || sortActive">
    <div *ngIf="_allCandidates?.length == 0" class="no-results-state">
      <p>There were no results based on your search criteria</p>
    </div>
    <ng-container *ngFor="let candidate of _allCandidates">
      <div class="candidate" [class.active-candidate]='parseNumber(_jobCandidateIdParam) === candidate.jobCandidateId' (click)="(filterActive || sortActive) || expandedCandidateDetails(candidate)" [attr.data-candidate-id]="candidate.jobCandidateId">
        <div class="row">
          <div>
            <div class="flex">
              <span class="star" [ngClass]="{'transparent' : !candidate.favorited }" [class.transparent]="candidate.sunsetFlag == 1 || candidate.blocked || (!candidate.activeFlag && !candidate.deleteFlag) || !candidate.favorited">
                <svg width="14" height="13" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg"><path d="M9 2.314C13.438-2.248 24.534 5.735 9 16-6.534 5.736 4.562-2.248 9 2.314Z" fill-rule="nonzero" stroke="#7B868B" fill="none" [ngClass]="{ favored: candidate.favorited }"/></svg>
              </span>
              <span *ngIf="candidate.lastName" class="name">{{candidate.firstName}}
                {{candidate.lastName.substring(0, 1)}}.
              </span>
            </div>
            <div class="flex">
              <span>
                <svg *ngIf="candidate.statusId != 2 && candidate.statusId != 3" [ngClass]="{'hide-svg' : !candidate.hurry }" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-flag"><path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path><line x1="4" y1="22" x2="4" y2="15"></line>
                </svg>

                <svg *ngIf="candidate.statusId == 2 || candidate.statusId == 3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
              </span>
              <div class="status under-consideration" [class.new-submittals]="candidate.statusId == 1" [class.passed-on]="candidate.statusId == 5 || candidate.statusId == 9 || candidate.statusId == 12 || candidate.statusId == 17" [class.hired]="candidate.statusId == 8">
                {{determineStatus(candidate.statusId)}}
              </div>
            </div>
            <div class="flex">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
              </span>
              <div class="date">Submitted {{candidate.submittedDate | date:'MMM d, y'}}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div>
            <span class="chevron right"></span>
          </div>
        </div>
      </div>
      <hr>
    </ng-container>
  </div>
</div>
