import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, interval, Observable } from "rxjs";
import { distinctUntilChanged, map, mergeMap } from "rxjs/operators";
import { Subscription } from "rxjs";
import { JwtHelper } from "../helpers/jwt_helper";
import { Company } from "../models/company.model";
import { Message, MESSAGE_TYPE_ERROR } from "../models/message.model";
import { User } from "../models/user.model";
import { AnalyticsService } from "./analytics.service";
import { ApiService } from "./api.service";
import { JwtService } from "./jwt.service";
import { MessageService } from "./message.service";
import { IndependentTerritory } from "../models/independent-territory.model";
import { HttpClient } from "@angular/common/http";
import { Token } from "../../shared/models/token.model";

export const ROLE_ADMIN = "admin";
export const ROLE_INTERNAL = "internal";
export const ROLE_CLIENT = "client";
export const ROLE_DEMO = "demo";

// const TOKEN_CHECK_INTERVAL = 200; // milliseconds
const TOKEN_CHECK_INTERVAL = 5 * 60 * 1e3; // milliseconds
// const REFRESH_TOKEN_INTERVAL = 1; // seconds
const REFRESH_TOKEN_INTERVAL = 1 * 24 * 60 * 60; // seconds

const setAuth = (
    userSubject: BehaviorSubject<User>,
    userTypeSubject: BehaviorSubject<string>
) => (user: User) => {
    userSubject.next(user);

    if (userTypeSubject) {
        userTypeSubject.next(user.userType);
    }

    return user;
};

const getUser = (
    apiService: ApiService,
    userSubject: BehaviorSubject<User>,
    userTypeSubject: BehaviorSubject<string>
) => (): Observable<User> =>
    apiService.get("/user").pipe(map(setAuth(userSubject, userTypeSubject)));

const saveToken = (jwtService: JwtService) => (data: Token): string =>
    jwtService.saveToken(data.token);

@Injectable()
export class UserService {
    private currentUserSubject = new BehaviorSubject<User>({} as User);
    public currentUser = this.currentUserSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    private previousTokenSubject = new BehaviorSubject<string>(null);
    public previousToken = this.previousTokenSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    private currentUserTypeSubject = new BehaviorSubject<string>(null);
    public currentUserType = this.currentUserTypeSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    private isImpersonationSubject = new BehaviorSubject<boolean>(false);
    public isImpersonation = this.isImpersonationSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    private isLimitedImpersonationSubject = new BehaviorSubject<boolean>(false);
    public isLimitedImpersonation = this.isLimitedImpersonationSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    private tokenTimer: Subscription;

    // User companies list subject and observable
    public companySubject: BehaviorSubject<Company[]> = new BehaviorSubject<
        Company[]
    >([]);
    public company: Observable<Company[]> = this.companySubject.asObservable();

    // User companies loading indicator (boolean) subject and observable
    private companyLoadingSubject: BehaviorSubject<
        boolean
    > = new BehaviorSubject<boolean>(false);
    public companyLoading: Observable<
        boolean
    > = this.companyLoadingSubject.asObservable();

    // Territories list subject and observable
    public territorySubject: BehaviorSubject<
        IndependentTerritory[]
    > = new BehaviorSubject<IndependentTerritory[]>([]);
    public territory: Observable<
        IndependentTerritory[]
    > = this.territorySubject.asObservable();

    // Territories loading indicator (boolean) subject and observable
    private territoryLoadingSubject: BehaviorSubject<
        boolean
    > = new BehaviorSubject<boolean>(false);
    public territoryLoading: Observable<
        boolean
    > = this.territoryLoadingSubject.asObservable();

    constructor(
        private apiService: ApiService,
        private jwtService: JwtService,
        private router: Router,
        private messageService: MessageService,
        private analyticsServices: AnalyticsService
    ) {
        this.isImpersonation.subscribe(bool => {
            this.analyticsServices.isImpersonation(bool);
        });

        this.currentUser.subscribe((user: User) => {
            this.analyticsServices.setUserId(user.userId);
            this.analyticsServices.setUserGlobal(user.userId);
        });

        this.currentUserType.subscribe(userType => {
            this.analyticsServices.setUserType(userType);
        });
    }

    // Verify JWT in localstorage with server
    // This runs once on application startup.
    populate(): void {
        // reset error messaging
        this.messageService.clearMessages();

        // If JWT detected, attempt to get & store user's info
        if (this.jwtService.getToken()) {
            const previousToken = this.jwtService.getPreviousToken();
            this.previousTokenSubject.next(previousToken);

            const parsedPreviousToken = this.decodeToken(previousToken);

            if (parsedPreviousToken !== null) {
                this.isImpersonationSubject.next(true);
                this.isLimitedImpersonationSubject.next(
                    parsedPreviousToken &&
                        parsedPreviousToken["userType"] !== ROLE_ADMIN
                );
            }

            let parsedToken = this.decodeCurrentToken();

            if (parsedToken) {
                this.populateUserType(parsedToken);
                this.startTokenTimer();
            }

            getUser(
                this.apiService,
                this.currentUserSubject,
                this.currentUserTypeSubject
            )().subscribe(/* TODO handle timeout */{
                error: this.purgeAndRedirectHome.bind(this)
            });
        } else {
            // Remove any potential remnants of previous auth states
            this.purgeAuth();
        }
    }

    private purgeAndRedirectHome() {
        this.purgeAuth();
        this.messageService.addMessage(
            new Message(
                MESSAGE_TYPE_ERROR,
                "An error occurred while retrieving your data. Please sign in."
            )
        );
        this.router.navigateByUrl("/home");
    }

    private populateUserType(parsedToken) {
        this.currentUserTypeSubject.next(parsedToken.userType);
    }

    startTokenTimer(): void {
        this.tokenTimer = interval(TOKEN_CHECK_INTERVAL).subscribe(() => {
            const parsedToken = this.decodeCurrentToken();

            this.refreshTokenDaily(parsedToken);
            this.checkIfTokenExpired(parsedToken);
        });
    }

    private refreshTokenDaily(parsedToken) {
        if (
            parsedToken["now"] >=
            parsedToken["created"] + REFRESH_TOKEN_INTERVAL
        ) {
            this.setNewToken();
        }
    }

    private setNewToken() {
        this.apiService.put("/token").subscribe(/* TODO handle timeout */
            data => {
                this.jwtService.saveToken(data["token"]);
            },
            () => this.purgeAuth
        );
    }

    private checkIfTokenExpired(parsedToken) {
        if (parsedToken["now"] > parsedToken["expired"]) {
            this.purgeAuth();
            this.messageService.addMessage(
                new Message(
                    MESSAGE_TYPE_ERROR,
                    "You have been logged out due to inactivity"
                )
            );
            this.router.navigateByUrl("/home");
        }
    }

    private decodeCurrentToken(): object | null {
        let currentToken = this.jwtService.getToken();

        return this.decodeToken(currentToken);
    }

    private decodeToken(token: string): object | null {
        if (token) {
            let jwtHelper = new JwtHelper(),
                parsedToken = jwtHelper.decodeToken(token),
                expired = parsedToken.exp,
                now = Math.round(new Date().getTime() / 1000),
                created = parsedToken.created,
                userType = parsedToken["usertype"];

            return { expired, now, created, userType };
        }

        return null;
    }

    purgeAuth(): void {
        // Remove JWT from localstorage
        this.jwtService.destroyTokens();
        // Set current user to an empty object
        this.currentUserSubject.next({} as User);
        // clear current user type
        this.currentUserTypeSubject.next(null);
        // clear timer that refreshes token
        this.tokenTimer && this.tokenTimer.unsubscribe();
    }

    attemptAuth(credentials): Observable<User> {
        credentials.username = credentials.username.trim();
        return this.createToken(credentials)
            .pipe(
                map(data => {
                    this.jwtService.saveToken(data.token);
                    this.startTokenTimer();
                })
            )
            .pipe(
                mergeMap(
                    getUser(
                        this.apiService,
                        this.currentUserSubject,
                        this.currentUserTypeSubject
                    )
                )
            );
    }

    createToken(credentials: object): Observable<Token> {
        return this.apiService.post("/token", credentials);
    }

    impersonate(userId: number): Observable<User> {
        const previousToken = this.jwtService.getToken();

        this.previousTokenSubject.next(previousToken);
        this.jwtService.savePreviousToken(previousToken);

        const parsedPreviousToken = this.decodeToken(previousToken);
        if (parsedPreviousToken !== null) {
            this.isImpersonationSubject.next(true);
            this.isLimitedImpersonationSubject.next(
                parsedPreviousToken &&
                    parsedPreviousToken["userType"] !== ROLE_ADMIN
            );
        }

        return this.createImpersonationToken(userId)
            .pipe(map(saveToken(this.jwtService)))
            .pipe(
                mergeMap(
                    getUser(
                        this.apiService,
                        this.currentUserSubject,
                        this.currentUserTypeSubject
                    )
                )
            );
    }

    createImpersonationToken(userId: number): Observable<Token> {
        return this.apiService.post("/admin/token", { userId });
    }

    endImpersonation(): Observable<User> {
        this.jwtService.saveToken(this.jwtService.getPreviousToken());
        this.jwtService.destroyPreviousToken();
        this.previousTokenSubject.next(null);
        this.isImpersonationSubject.next(false);
        this.isLimitedImpersonationSubject.next(false);

        return getUser(
            this.apiService,
            this.currentUserSubject,
            this.currentUserTypeSubject
        )();
    }

    patchUserPreferences(params = {}): Observable<any> {
        return this.apiService.patch(`/user/me`, params);
    }

    passwordReset(credentials): Observable<User> {
        return this.apiService.post("/user/password-reset", credentials);
    }

    updatePassword(credentials): Observable<User> {
        return this.apiService.patch("/user/password-reset", credentials);
    }

    getUser() {
        return this.apiService.get("/user").pipe(map(data => data));
    }

    getUserCompanies(limit = 10, offset = 0): Observable<Company> {
        // Fetches user companies
        return this.apiService
            .get("/user/companies", {
                limit: limit.toString(),
                offset: offset.toString()
            })
            .pipe(
                map(
                    data => {
                        // Pushes the user companies through the stream and changes the loading indicator's value
                        this.companySubject.next(data["results"]);
                        this.companyLoadingSubject.next(false);

                        return data["results"];
                    },
                    err => {
                        // Indicates that the company list is done loading in case of an error
                        this.companyLoadingSubject.next(false);
                        console.error(err);
                    }
                )
            );
    }

    getGiantToken(): Observable<string> {
        return this.apiService.get("/giant/token").pipe(
           map(
                 data => {
                     return data;
                 },
                 err => {
                     console.error(err);
                 }
             )
        );
    }

    getTerritories(): Observable<Company> {
        // Fetches the available territories
        return this.apiService.get("/territories").pipe(
            map(
                data => {
                    // Pushes the fetched territories through the stream and changes the loading indicator's value
                    this.territorySubject.next(data["results"]);
                    this.territoryLoadingSubject.next(false);

                    return data["results"];
                },
                err => {
                    // Indicates that the territory list is done loading in case of an error
                    this.territoryLoadingSubject.next(false);
                    console.error(err);
                }
            )
        );
    }

    requestTalent(params): Observable<any> {
        return this.apiService.postStruct("/api/genep", params);
    }

    getDemoUsers(regionId: number): Observable<any> {
        let params = {RegionID: regionId};
        return this.apiService.getStruct("DemoGetRegionUsers", params);
    }

    getDemoRegions(): Observable<any> {
        return this.apiService.getStruct("DemoGetRegions");
    }
}
