import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, Form, UntypedFormGroup, FormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { JobService } from '../../services/job-orders.service';
import { ApiError } from '../../models/api-error.model';
import { switchMap } from 'rxjs/operators';
import { CLOSE_SUCCESS } from '../perf-review-dialog/perf-review-dialog.component';
import { UserService } from '../../services';
import { NetworkErrorSnackbarService } from '../../../shared/services/network-error-snackbar.service';

@Component({
    selector: 'app-passed-on-dialog',
    templateUrl: './passed-on-dialog.component.html',
    styleUrls: ['./passed-on-dialog.component.scss']
})
export class PassedOnDialogComponent {
    public form: UntypedFormGroup;
    public isSubmitting: boolean = false;
    public formError: string;
    public addToNotes: boolean = true;
    public isLimitedImpersonation: boolean = false;
    public countMax: number = 4000;
    public typingLength = 0;
    public submittedFirstPart: boolean = false;
    public experienceLevel: number = 50;
    public experienceLevelTouched: boolean = false;
    public softSkills: number = 50;
    public softSkillsTouched: boolean = false;
    public portfolio: number = 50;
    public portfolioTouched: boolean = false;
    public noPortfolio: boolean = false;
    public comments: string = '';
    public advantages: string = '';
    public disadvantages: string = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<PassedOnDialogComponent>,
        private jobOrdersService: JobService,
        private userService: UserService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
    ) {
        this.form = this.fb.group({
            advantages: [''],
            disadvantages: [''],
            officeId: [
                this.data.officeId,
                Validators.compose([Validators.required])
            ],
            jobId: [this.data.jobId, Validators.compose([Validators.required])],
            jobCandidateId: [
                this.data.jobCandidateId,
                Validators.compose([Validators.required])
            ],
            candidateId: [
                this.data.candidateId,
                Validators.compose([Validators.required])
            ],
            statusId: [
                this.data.statusId,
                Validators.compose([Validators.required])
            ],
            addToNotes: new FormControl({
                value: true,
                disabled: true
            }),
            sharedCC: [false],
            sharedHiringManagers: new FormControl({
                value: false,
                disabled: true
            }),
            rangesGroup: this.fb.group({
                experienceLevel: [
                    50,
                    Validators.compose([Validators.required])
                ],
                softSkills: [
                    50,
                    Validators.compose([Validators.required])
                ],
                portfolio: [
                    50,
                    Validators.compose([Validators.required])
                ],
                noPortfolio: [false],
                comments: [''],
            }),
        });

        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });

        this.form.controls['advantages'].valueChanges.subscribe(() => {
            this.lengthCounter();
        });

        this.form.controls['disadvantages'].valueChanges.subscribe(() => {
            this.lengthCounter();
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    lengthCounter() {
        this.typingLength =
            this.form.controls['advantages'].value.length +
            this.form.controls['disadvantages'].value.length +
            this.form.value['rangesGroup'].comments.length;
        this.countMax = 4000 - this.typingLength;

        if (!this.submittedFirstPart && this.typingLength === 0) {
            this.addToNotes = true;
            this.form.controls['addToNotes'].setValue(true);
            this.form.controls['addToNotes'].disable();
            this.form.controls['sharedHiringManagers'].setValue(false);
            this.form.controls['sharedHiringManagers'].disable();
        }
    }

    submit() {
        let detailsSubmitted = false;
        let details = ' details provided.';
        let lastComment = 'No details provided.';

        if (this.submittedFirstPart === false && this.countMax === 4000) {
            this.submittedFirstPart = true;
        } else if (this.submittedFirstPart === false && this.countMax !== 4000) {
            if (
                this.form.value['advantages'] !== '' ||
                this.form.value['disadvantages'] !== ''
            ) {
                detailsSubmitted = true;
                details =
                    '<strong>Where This Candidate Came Up Short:</strong> ' +
                    this.form.value['disadvantages'] +
                    '<br /><strong>Things I Like:</strong> ' +
                    this.form.value['advantages'];
                lastComment =
                    'Where This Candidate Came Up Short: ' +
                    this.form.value['disadvantages'] +
                    '<br />Things I Like: ' +
                    this.form.value['advantages'];
            }

            this.submitForm(details, detailsSubmitted, lastComment);
        } else {
            detailsSubmitted = true;
            if (this.submittedFirstPart &&
                this.comments == '' &&
                (!this.experienceLevelTouched && !this.softSkillsTouched && !this.portfolioTouched)
            ) {
                details = lastComment = '[Nothing Entered]';
            } else {
                const experienceLevel = (!this.experienceLevelTouched) ? '[not changed]' :
                    Math.floor(this.form.value['rangesGroup'].experienceLevel / 10) + '/10';
                const softSkills = (!this.softSkillsTouched) ? '[not changed]' :
                    Math.floor(this.form.value['rangesGroup'].softSkills / 10) + '/10';
                let portfolio = (!this.portfolioTouched) ? '[not changed]' :
                    Math.floor(this.form.value['rangesGroup'].portfolio / 10) + '/10';
                portfolio = (this.noPortfolio) ? 'No Portfolio' : portfolio;

                details =
                    '<strong>Experience:</strong> ' +
                    experienceLevel +
                    '<br /><strong>Soft Skills:</strong> ' +
                    softSkills +
                    '<br /><strong>Portfolio:</strong> ' +
                    portfolio +
                    '<br /><strong>Comments:</strong> ' +
                    this.form.value['rangesGroup'].comments;
                lastComment =
                    'Experience: ' +
                    experienceLevel +
                    '<br />Soft Skills: ' +
                    softSkills +
                    '<br />Portfolio: ' +
                    portfolio +
                    '<br />Comments: ' +
                    this.form.value['rangesGroup'].comments;
            }

            this.submitForm(details, detailsSubmitted, lastComment);
        }
    }

    submitNoFeedback() {
        this.submitForm('No details provided.', false, 'No details provided.');
    }

    determineInput(event) {
        const value = event.target.value;
        const checked = event.target.checked;

        if (value === 'addToNotes' && checked) {
            this.addToNotes = true;
            this.form.controls['sharedCC'].setValue(true);
        } else if (value === 'addToNotes' && !checked) {
            this.addToNotes = false;
            this.form.controls['sharedCC'].setValue(false);
            this.form.controls['sharedHiringManagers'].setValue(false);
        }

        if (value === 'creative-circle' && this.addToNotes === true) {
            return false;
        }
    }

    submitForm(details, detailsSubmitted, lastComment) {
        if (this.form.valid && !this.isSubmitting && this.typingLength <= 4000) {
            this.isSubmitting = true;
            const params = {
                statusId: this.form.value['statusId'],
                details: lastComment
            };

            let patchJobCandidate = this.jobOrdersService.patchJobCandidate(
                this.form.value['officeId'],
                this.form.value['jobId'],
                this.form.value['jobCandidateId'],
                params
            );

            if (this.addToNotes === true && detailsSubmitted === true) {

                const addNotes = this.jobOrdersService.postJobCandidateNotes(
                    this.form.value['officeId'],
                    this.form.value['jobId'],
                    this.form.value['candidateId'],
                    this.form.value['jobCandidateId'],
                    {
                        note: details,
                        private: false,
                        sharedCC: true,
                        sharedHiringManagers: this.form.get("sharedHiringManagers").value,
                        sendAlert: false
                    }
                );

                patchJobCandidate = patchJobCandidate.pipe(
                    switchMap(() => addNotes)
                );
            }

            patchJobCandidate.subscribe(/* TODO handle timeout */
                _ => this.dialogRef.close(CLOSE_SUCCESS),
                (err: ApiError) => {
                    this.isSubmitting = false;
                    if (err.statusCode === 400) {
                        this.formError =
                            'Error! Please close the dialog and try again.';
                    } else {
                        this.networkErrorSnackbarService.networkError(err, '');
                    }
                }
            );
        }

    }
}
