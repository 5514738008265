<div class="candidate-details-container" *ngIf="candidateDetail">
    <app-candidate-details-modal-header [candidate]="candidateDetail" [hideArrows]="data.hideArrow"></app-candidate-details-modal-header>
    <div class="row-divider"></div>
    <app-candidate-details-links-and-docs [candidate]="candidateDetail"></app-candidate-details-links-and-docs>
    <div class="row-divider"></div>
    <app-candidate-details-about [candidate]="candidateDetail" [showSalaryDetails]="false" [lookingFor]="true"></app-candidate-details-about>
    <div class="row-divider"></div>
    <app-candidate-details-work-history [candidate]="candidateDetail"></app-candidate-details-work-history>
    <div class="row-divider"></div>
    <app-candidate-details-notes [details]="candidateDetail" [patchCandidateNotes]="true"></app-candidate-details-notes>
    <app-candidate-details-footer></app-candidate-details-footer>
</div>
