import { Component, OnInit } from "@angular/core";

import { UserService } from "./shared";
import { Router, NavigationEnd } from "@angular/router";
import { AnalyticsService } from "./shared/services/analytics.service";
import { AppVersionService } from './shared/services/app-version.service';

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {
    constructor(
        public router: Router,
        public analytics: AnalyticsService,
        private userService: UserService,
        private appVersionService: AppVersionService
    ) {
        router.events.subscribe(e => {
            if (e instanceof NavigationEnd) this.pageTrack();
        });
    }

   pageTrack() {
    setTimeout(() => {
       this.analytics.trackPage()
    }, 1500);
   }

    ngOnInit() {
        this.userService.populate();
        this.appVersionService.resetAppVersionChecking();
    }
}
