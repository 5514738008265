
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { CandidatesService, JobCategory, SortActiveService } from '../../../shared';
import { Subscribing } from '../../../shared/components/subscribing.component';
import { JobClassCategory } from '../../../shared/models/job-class-category.model';
import { Territory } from '../../../shared/models/territory.model';


@Component({
    selector: 'app-candidate-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class FiltersComponent extends Subscribing implements OnInit, OnChanges {
    @Input()
    noResults;
    @Input()
    territories;

    private sortByValue;

    @Output()
    sortByChange = new EventEmitter();

    @Input()
    get sortBy() {
        return this.sortByValue;
    }

    set sortBy(val) {
        this.sortByValue = val;
        this.sortByChange.emit(this.sortByValue);
    }

    @Output()
    categoryChange = new EventEmitter<JobClassCategory>();
    @Output()
    filterChange = new EventEmitter();
    @Output()
    searchChange = new EventEmitter();

    public mobileFilterDropdownActive: boolean = false;
    public mobileSortDropdownActive: boolean = false;

    public numFiltersApplied: number = 0;

    public searchFormControl = new UntypedFormControl();
    public jobCategories: JobCategory[];

    public appliedFilters = {
        favorited: false,
        interviewed: false,
        active: false,
        inTheRunning: false,
        placedBefore: false,
        blocked: false,
        notSelected: false
    };

    constructor(
        private candidatesService: CandidatesService,
        private sortActiveService: SortActiveService
    ) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(
            this.searchFormControl.valueChanges.pipe(
                debounceTime(1000))
                .subscribe(newValue => this.searchChange.emit(newValue))
        );

        this.subscriptions.push(
            this.candidatesService
                .getCandidatesJobCategory()
                        // TODO handle timeout
                        .subscribe(jobCategories => {
                    jobCategories.forEach(jobCategory => {
                        const filterName = this.filterNameFromJobCategoryId(
                            jobCategory.jobCategoryId
                        );

                        if (typeof this.appliedFilters[filterName] === 'undefined') {
                            this.appliedFilters[filterName] = false;
                        }
                    });

                    this.jobCategories = jobCategories;
                })
        );

        this.filterChange.subscribe(_ => {
            this.numFiltersApplied = Object.keys(this.appliedFilters).reduce(
                (total, key) => {
                    if (this.appliedFilters[key]) total++;
                    return total;
                },
                0
            );
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.territories) {
            changes.territories.currentValue.forEach((trty: Territory) => {
                const filterName = this.filterNameFromTerritoryId(trty.territoryId);

                if (typeof this.appliedFilters[filterName] === 'undefined') {
                    this.appliedFilters[filterName] = false;
                }
            });
        }
    }

    filterNameFromTerritoryId(territoryId) {
        return `territoryId-${territoryId}`;
    }

    filterNameFromJobCategoryId(territoryId) {
        return `jobCategoryId-${territoryId}`;
    }

    filterCandidateList() {
        let values = Object.keys(this.appliedFilters).filter(
            key => this.appliedFilters[key]
        );

        this.filterChange.emit(values);
    }

    changeSort(field, direction) {
        this.sortBy = field + '-' + direction;
        this.closeSort();
    }

    toggleMobileFilterDropdown() {
        this.mobileFilterDropdownActive = !this.mobileFilterDropdownActive;
    }

    toggleMobileSortDropdown() {
        this.mobileSortDropdownActive = !this.mobileSortDropdownActive;
        this.sortActiveService.updateBoolean(true);
    }

    closeSort(): void {
        this.mobileSortDropdownActive = false;
        this.sortActiveService.updateBoolean(false);
    }

    closeFilter() {
        this.mobileFilterDropdownActive = false;
    }

    clearFilters(fieldsGroup) {
        if (fieldsGroup === 'all') {
            Object.keys(this.appliedFilters).forEach(
                key => (this.appliedFilters[key] = false)
            );
        } else if (fieldsGroup === 'pipeline') {
            this.appliedFilters.favorited = false;
            this.appliedFilters.interviewed = false;
            this.appliedFilters.active = false;
            this.appliedFilters.inTheRunning = false;
            this.appliedFilters.placedBefore = false;
            this.appliedFilters.blocked = false;
            this.appliedFilters.notSelected = false;
        } else if (fieldsGroup === 'area') {
            Object.keys(this.appliedFilters).forEach(
                key =>
                    key.search(this.filterNameFromTerritoryId('')) === 0 &&
                    (this.appliedFilters[key] = false)
            );
        } else if (fieldsGroup === 'jobcategories') {
            Object.keys(this.appliedFilters).forEach(
                key =>
                    key.search(this.filterNameFromJobCategoryId('')) === 0 &&
                    (this.appliedFilters[key] = false)
            );
        }

        this.filterCandidateList();
    }
}
