<div class="header">
    <svg (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x on-white" [class.svg-stretch]="showToolTip">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
    <div>
        <h1 mat-dialog-title class="title">SHARE CANDIDATE</h1>
        <div class="hide-mobile">
            <div class="tool-tip-container" (click)="displayToolTipText()">
                <span class="tool-tip">i</span>
                <p>WHAT DETAILS GET SHARED?</p>
            </div>
            <div class="text" *ngIf="showToolTip">
                <p class="body">The recipient will see the candidate's About section, Links & Documents, and your message but will not see any rate information nor notes. The recipient does not need to have a Lightbox account.</p>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-content>
    <form [formGroup]="form" (submit)="submit()">
        <input type="hidden" formControlName="candidateId">
        <input type="hidden" formControlName="officeId">

        <div class="text-area-favorite-wrapper">
            <div class="show-mobile">
                <div class="tool-tip-container" (click)="displayToolTipText()">
                    <span class="tool-tip">i</span>
                    <p>WHAT DETAILS GET SHARED?</p>
                </div>
                <div class="text" *ngIf="showToolTip">
                    <p class="body">The recipient will see the candidate's About section, Links & Documents, and your message but will not see any rate information nor notes. The recipient does not need to have a Lightbox account.</p>
                </div>
            </div>
            <div class="name">
                <div>
                    <label>First Name</label>
                    <input class="name-field" formControlName="firstName" placeholder="First Name" type="text"/>
                </div>
                <div>
                    <label>Last Name</label>
                    <input class="name-field" formControlName="lastName" placeholder="Last Name" type="text"/>
                </div>
            </div>
            <label>Recipient Email Address</label>
            <input class="email" formControlName="email"
                   placeholder="recipient@example.com" type="text"/>
            <label>Your Message</label>
            <textarea formControlName="comment" placeholder="Let the recipient know why you're sharing this candidate profile."></textarea>
        </div>
    </form>
</div>
<div mat-dialog-content>
    <div class="error">
        <p *ngIf="formError" class="form-error">{{ formError }}</p>
    </div>
</div>
<div mat-dialog-actions>
    <button aria-label="Send" [disabled]="isLimitedImpersonation || !form.valid" (click)="submit()">
        <span class="not-submitting">Send</span>
    </button>
</div>
