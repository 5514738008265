import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Candidate, CandidateDetail, JobCategory, Territory } from "../models";
import { ApiService } from "./api.service";
import { PaginatedResponse } from "../../shared/models/paginated-response.model";

@Injectable()
export class CandidatesService {
    constructor(private apiService: ApiService) {}

    getCandidatesList(params): Observable<PaginatedResponse<Candidate>> {
        // clear empties
        let newParams = Object.assign(params);

        Object.keys(newParams).forEach(
            key =>
                (newParams[key] == null || newParams[key].length == 0) &&
                delete newParams[key]
        );

        return this.apiService.get("/candidate", newParams).pipe(
            map(
                data => {
                    return data;
                },
                err => {
                    console.error(err);
                }
            )
        );
    }

    getCandidatesDetails(officeId, candidateId): Observable<CandidateDetail> {
        return this.apiService
            .get("/candidate/" + officeId + "/" + candidateId)
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    getCandidatesTerritory(): Observable<Territory[]> {
        return this.apiService.get("/candidate/territory").pipe(
            map(
                data => {
                    return data;
                },
                err => {
                    console.error(err);
                }
            )
        );
    }

    getCandidatesJobCategory(): Observable<JobCategory[]> {
        return this.apiService.get("/candidate/job-category").pipe(
            map(
                data => {
                    return data;
                },
                err => {
                    console.error(err);
                }
            )
        );
    }

    postShareCandidate(officeId, candidateId, params) {
        return this.apiService
            .post("/share/" + officeId + "/" + candidateId, params)
            .pipe(map(data => data, err => console.error(err)));
    }

    postCandidateNote(officeId, candidateId, params) {
        return this.apiService
            .post(
                "/candidate/" + officeId + "/" + candidateId + "/note",
                params
            )
            .pipe(map(data => data, err => console.error(err)));
    }

    patchCandidateNote(noteId, params) {
        return this.apiService
            .patch("/note/" + noteId, params)
            .pipe(map(data => data, err => console.error(err)));
    }

    getLegalText(): Observable<string> {
        return this.apiService
            .get("/template/legal")
            .pipe(map(data => data, err => console.error(err)));
    }

    getLoginLegalText(): Observable<string> {
        return this.apiService
            .get("/template/login-legal")
            .pipe(map(data => data, err => console.error(err)));
    }

    getEqualOpportunityText(): Observable<string> {
        return this.apiService
            .get("/template/equal-opportunity")
            .pipe(map(data => data, err => console.error(err)));
    }
}
