<form (submit)="submit()">
    <h1 mat-dialog-title>Send Test Email</h1>
    <div mat-dialog-content>
        <!-- <p class="lead-in">Please enter the recipient email address.</p> -->
        <mat-form-field class="example-full-width">
            <input placeholder="Recipient email address" [(ngModel)]="toAddress" id="name" name="email" #email="ngModel" matInput email required>
            <mat-error *ngIf="email.invalid && (email.dirty || email.touched)">
                Please enter a valid email address.
            </mat-error>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button aria-label="Cancel" (click)="cancel()" type="button" class="cancel">Cancel</button>
        <button aria-label="Send" type="submit" [disabled]="email.invalid">Send</button>
    </div>
</form>
