import { Component, OnInit, OnDestroy, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { JobDetails, JobOrderCandidate } from "../../../shared";
import { Job } from "../../../shared/models/job.model";
import { JobService } from "../../../shared/services/job-orders.service";
import { Location } from "@angular/common";
import { ApiError } from "../../../shared/models/api-error.model";
import { Subscribing } from "../../../shared/components/subscribing.component";
import { NetworkErrorSnackbarService } from "../../../shared/services/network-error-snackbar.service";

class JobParams {
    public officeId: number;
    public jobId: number;
    constructor(officeId: number, jobId: number) {
        this.officeId = officeId;
        this.jobId = jobId;
    }
}

class JobCandParams {
    public jobCandidateId: number;
    constructor(jobCandidateId: number) {
        this.jobCandidateId = jobCandidateId;
    }
}

@Component({
    selector: "app-job-order-details",
    templateUrl: "./job-order-details.component.html",
    styleUrls: ["./job-order-details.component.scss"]
})
export class JobOrderDetailsComponent extends Subscribing implements OnInit, OnChanges {
    public job: Job;
    private allJobs: Job[] = [];
    public noJobs: boolean = false;

    public jobDetails: JobDetails;
    public jobDetailsLoading: boolean = false;

    public showDetailsModal: boolean;

    public jobCand: JobOrderCandidate;

    public jobParams: JobParams = new JobParams(0, 0);
    public jobCandParams: JobCandParams = new JobCandParams(0);
    public routeParam: string = '';

    private routeParams: any;

    @ViewChild('details_container')
    details_container: ElementRef;

    constructor(
        private jobsService: JobService,
        private route: ActivatedRoute,
        private router: Router,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
    ) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(
            this.jobsService.jobDetailsLoading.subscribe(
                isLoading => (this.jobDetailsLoading = isLoading)
            ),

            this.jobsService.jobsSubject.subscribe(jobs => {
                this.allJobs = jobs;
                this.updateJob(this.routeParams);
            }),

            this.route.params.subscribe(routeParams => {
                this.routeParams = routeParams;

                if (routeParams['section']) {
                    this.routeParam = routeParams['section'];
                } else {
                    this.routeParam = '';
                }
            
                if (!this.jobRouteParamsSet(this.routeParams)) {
                    this.clearSelections();
                    return;
                }

                if (this.jobRouteParamsChanged(this.routeParams)) {
                    this.updateJob(this.routeParams);
                } else if (this.jobRouteCandParamsChanged(this.routeParams)) {
                    this.updateJobCand(this.routeParams);
                }
            })
        );
    }

    ngOnChanges() {
        this.details_container.nativeElement.scroll(0, 0);
    }

    private jobRouteParamsSet(params) {
        return params.officeId && params.jobId;
    }

    private updateJobCand(params) {
        if (
            typeof params.jobCandidateId === "undefined" ||
            !params.jobCandidateId
        ) {
            this.jobCandParams = new JobCandParams(0);
            return;
        }

        this.jobCandParams = new JobCandParams(Number(params.jobCandidateId));

        this.jobCand = this.jobDetails.candidates.find(
            jc => jc.jobCandidateId === this.jobCandParams.jobCandidateId
        );

        if (!this.jobCand) {
            this.router.navigateByUrl("/not-found");
        }

        this.details_container.nativeElement.scroll(0, 0);
    }

    private updateJob(params) {
        if (!params) return;

        this.jobParams = new JobParams(
            Number(params.officeId),
            Number(params.jobId)
        );

        if (!params.officeId || !params.jobId) {
            return;
        }

        const job = this.allJobs.find(
            job =>
                job.officeId === this.jobParams.officeId &&
                job.jobId === this.jobParams.jobId
        );
        if (job) this.job = job;

        if (typeof this.details_container !== 'undefined') {
            this.details_container.nativeElement.scroll(0, 0);
        }

        this.refreshJobDetails(params);
    }

    private jobRouteCandParamsChanged(params) {
        return (
            Number(params.jobCandidateId) !== this.jobCandParams.jobCandidateId
        );
    }

    private jobRouteParamsChanged(params) {
        return (
            this.jobParams.officeId !== Number(params.officeId) ||
            this.jobParams.jobId !== Number(params.jobId)
        );
    }

    private clearSelections() {
        this.jobParams = new JobParams(0, 0);
        this.jobCandParams = new JobCandParams(0);
        this.jobCand = null;
        this.jobDetails = null;
        this.job = null;
    }

    private refreshJobDetails(routeParams: any): void {
        this.jobsService
            .getJobDetails(this.jobParams.officeId, this.jobParams.jobId)
                        // TODO handle timeout
                        .subscribe(
                (jobDetails: JobDetails) => {
                    this.jobDetails = jobDetails;

                    if (!routeParams.jobCandidateId) {
                        this.jobCandParams = new JobCandParams(0);
                        this.jobCand = null;
                        return;
                    }

                    if (this.jobRouteCandParamsChanged(routeParams)) {
                        this.updateJobCand(routeParams);
                    }
                },
                (err: ApiError) => {
                    if (err.statusCode == 404) {
                        this.jobCandParams = new JobCandParams(0);
                        this.router.navigateByUrl("/not-found");
                    } else {
                        this.networkErrorSnackbarService.networkError(err,'')
                    }
                }
            );

        if (typeof this.details_container !== 'undefined') {
            this.details_container.nativeElement.scroll(0, 0);
        }
    }

    backToJobs() {
        this.router.navigate(["jobs"]);
    }

    routeChange() {
        if (this.routeParam === 'active-placements') {
            this.router.navigate(["dashboard", {"section": "active-candidates"}]);
        } else if (this.routeParam === 'current-openings') {
            this.router.navigate(["dashboard", {"section": "open-searches"}]);
        } else {
            this.router.navigate(["dashboard"]);
        }
    }

    paramToText() {
        return this.routeParam.split("-").join(' ').toUpperCase();
    }

    closeCandidateDetailsModal() {
        this.showDetailsModal = false;
    }

    backToCandidates() {
        if (this.routeParam === 'active-placements') {
            this.router.navigate(["jobs", {officeId: this.jobParams.officeId, jobId: this.jobParams.jobId, "section": "active-candidates"}]);
        } else if (this.routeParam === 'current-openings') {
            this.router.navigate(["jobs", {officeId: this.jobParams.officeId, jobId: this.jobParams.jobId, "section": "current-openings"}]);
        } else if (this.routeParam === 'interviews') {
            this.router.navigate(["dashboard", {"section": "interviews"}]);
        } else {
            this.router.navigate(["jobs", {officeId: this.jobParams.officeId, jobId: this.jobParams.jobId}]);
        }
    }
}
