<div class="background-container">
    <div class="small-container">
        <mat-card>
            <div class="logo-container">
                <img src="../assets/images/circle-logo.png" alt="Creative Circle Lightbox">
            </div>
            <mat-card-title>
                Create an Account
            </mat-card-title>
            <mat-card-content>
                <p class="help" *ngIf="hasValidEmail">
                    Please provide the following information to finish setting up your account for <strong>{{ this.email }}</strong>
                </p>
                <form [formGroup]="registerForm" (ngSubmit)="submitForm()">
                    <div [class.active]="hasValidEmail" class="input-container">
                        <div>
                            <mat-form-field>
                                <input type="text" matInput formControlName="username" placeholder="Username">
                                <mat-error *ngIf="username.invalid && username.touched && !(username.errors && username.errors['conflict'])">
                                    Please enter a username between 8 and 20 alphanumeric characters.
                                </mat-error>
                                <mat-error *ngIf="username.errors && username.errors['conflict']">
                                    This username is already in use. Please choose another.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <mat-form-field>
                            <input type="password" matInput formControlName="password" placeholder="Password" (focus)="hasActivePopover = true" (blur)="hasActivePopover = false">
                            <a [class.active]="hasActivePopover" class="popover-icon">
                                <div class="popover-content">
                                    <h6>Password Requirements</h6>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" formControlName="minimumChar">
                                            <span></span>
                                            At least 8 characters
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" formControlName="upperCaseChar">
                                            <span></span>
                                            At least 1 upper-case character
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" formControlName="lowerCaseChar">
                                            <span></span>
                                            At least 1 lower-case character
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" formControlName="numericChar">
                                            <span></span>
                                            At least 1 numeral
                                        </label>
                                    </div>
                                </div>
                            </a>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-placeholder>Confirm Password</mat-placeholder>
                            <input type="password" matInput formControlName="passwordConfirmation">
                            <mat-error>Does not match the password entered</mat-error>
                        </mat-form-field>
                        <button aria-label="Create My Account" mat-raised-button class="btn" [class.invalid-btn]="registerForm.invalid" [class.is-submitting]="isSubmitting" color="primary" type="submit" [disabled]="!registerForm.valid || isSubmitting">
                            <span class="not-submitting">Create My Account</span>
                            <span class="submitting">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                                    <line x1="12" y1="2" x2="12" y2="6"></line>
                                    <line x1="12" y1="18" x2="12" y2="22"></line>
                                    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                                    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                                    <line x1="2" y1="12" x2="6" y2="12"></line>
                                    <line x1="18" y1="12" x2="22" y2="12"></line>
                                    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                                    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                                </svg>
                            </span>
                        </button>
                        <div class="server-error">
                            <mat-error *ngIf="registerForm.errors && registerForm.errors['serverError']">
                                A server error has occurred. Please contact your Account Executive or
                                <a href="mailto:lightboxfeedback@creativecircle.com">lightboxfeedback@creativecircle.com</a>.
                            </mat-error>
                            <mat-error *ngIf="registerForm.errors && registerForm.errors['badRequest']">
                                The information you submitted does not seem to be valid.
                                Please check your information and try again.
                            </mat-error>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="show-on-mobile">
        <div class="container">
            <div class="link">
                <a class="nav-link" href="https://www.creativecircle.com/roles-we-place/" target="_blank" rel="noopener">
                    ROLES WE PLACE
                </a>
            </div>
            <div class="link">
                <a class="nav-link" [href]="timecardLoginUrl" target="_blank" rel="noopener">
                    TIMECARD LOGIN
                </a>
            </div>
            <div class="link">
                <a class="nav-link" [href]="requestTalentUrl" target="_blank" rel="noopener">
                    REQUEST TALENT
                </a>
            </div>
        </div>
    </div>
</div>
