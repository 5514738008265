<div class="name">
  <div>
      <p *ngIf="!public && candidate.sunsetFlag === 0 && !doNotUse">{{ candidate.firstName }} {{ candidate.lastName }}</p>
      <p *ngIf="public || candidate.sunsetFlag === 1 || doNotUse">{{ candidate.firstName }} {{ candidate.lastName[0] }}.</p>
  </div>
  <div *ngIf="candidate.sunsetFlag === 1 || doNotUse" class="close" (click)="closeCandidateDetails()">
      <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="-5 0 24 24" fill="none" stroke="#1c2b39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </div>
</div>
