<div class="modal-header">
    <h1 mat-dialog-title>
        Request Talent
    </h1>
    <svg (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x on-white">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
</div>

<div mat-dialog-content>
    <form [formGroup]="form" (submit)="submit()">
        <div class="text-area-favorite-wrapper">
            <label *ngIf="companyList.length > 1" for="companies">Please select which company you are requesting talent
                for:</label>
            <select *ngIf="companyList.length > 1" id="companies" class="input" [formControlName]="'companies'">
                <option disabled selected value>Select Company...</option>
                <option [ngValue]="company" *ngFor="let company of companyList">{{company.cpnyName}}
                    ({{company.territoryName}})
                </option>
            </select>
            <label for="territories">Please select which area you are requesting talent for:</label>
            <select class="input" id="territories" [formControlName]="'territories'">
                <option disabled selected value>Select Area...</option>
                <option [ngValue]="territory.trtyID"
                        *ngFor="let territory of territoryList">{{territory.trtyName}}</option>
            </select>
            <div class="group">
                <label>Type of position:</label>
                <div class="control">
                    <div class="checkbox">
                        <input 
                            type="checkbox" class="preference-input creative-circle"
                            value="Full-Time"
                            id="fullTimePosition"
                            (click)="onChangePositions($event)" />
                        <label for="fullTimePosition">Full-Time</label>
                    </div>
                </div>

                <div class="control">
                    <div class="checkbox">
                        <input 
                            type="checkbox" class="preference-input creative-circle"
                            value="Freelance"
                            id="freelancePosition"
                            (click)="onChangePositions($event)" />
                        <label for="freelancePosition">Freelance</label>
                    </div>
                </div>

                <div class="control">
                    <div class="checkbox">
                        <input 
                            type="checkbox" class="preference-input creative-circle"
                            value="Freelance to Full-Time"
                            id="freelanceToFulltimePosition"
                            (click)="onChangePositions($event)" />
                        <label for="freelanceToFulltimePosition">Freelance to Full-Time</label>
                    </div>
                </div>

                <div class="control">
                    <div class="checkbox">
                        <input 
                            type="checkbox" class="preference-input creative-circle"
                            value="Not Sure"
                            id="notSurePosition"
                            (click)="onChangePositions($event)" />
                        <label for="notSurePosition">Not Sure</label>
                    </div>
                </div>
            </div>
            <div class="group">
                <label for="note">Tell us more:</label>
                <textarea id="note" formControlName="note" maxlength="1000"></textarea>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-content>
    <div class="error">
        <p *ngIf="formError" class="form-error">{{ formError }}</p>
    </div>
</div>
<div mat-dialog-actions>
    <button aria-label="Submit" [ngClass]="{'is-submitting': isSubmitting}" [disabled]="isLimitedImpersonation || !form.valid || positionList.length === 0"
            (click)="submit()">
        <span class="not-submitting">Submit</span>
        <span class="submitting">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
           stroke="currentColor"
           stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
        <line x1="12" y1="2" x2="12" y2="6"></line>
        <line x1="12" y1="18" x2="12" y2="22"></line>
        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
        <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
        <line x1="2" y1="12" x2="6" y2="12"></line>
        <line x1="18" y1="12" x2="22" y2="12"></line>
        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
        <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
      </svg>
    </span>
    </button>
</div>
