import { Component, OnInit } from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
    CandidatesService,
    MessageService,
    User,
    UserService
} from "../../shared";
import { Subscribing } from "../../shared/components/subscribing.component";
import { ApiError } from "../../shared/models/api-error.model";
import { Message, MESSAGE_TYPE_ERROR } from "../../shared/models/message.model";
import { AnalyticsService } from "../../shared/services/analytics.service";
import { LbTitle } from "../../shared/services/title.service";
import requireTrimmed from "../../shared/validators/require-trimmed";
import { environment } from "../../../environments/environment";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"]
})
export class HomeComponent extends Subscribing implements OnInit {
    authForm: UntypedFormGroup;
    public legalText: SafeHtml = "";
    public isSubmitting: boolean = false;
    public routeError: string = "";
    public requestTalentUrl: string = environment.request_talent_url;
    public timecardLoginUrl: string = environment.timecard_login_url;
    public returnUrl: string;

    constructor(
        private sanitizer: DomSanitizer,
        private router: Router,
        private userService: UserService,
        private candidatesService: CandidatesService,
        private fb: UntypedFormBuilder,
        private messageService: MessageService,
        private titleService: LbTitle,
        private analyticsService: AnalyticsService,
        private route: ActivatedRoute
    ) {
        super();

        this.routeError = route.snapshot.queryParamMap.get("error");

        this.titleService.setTitleFromKeywords("Home", "Landing");

        this.authForm = this.fb.group({
            username: [null, Validators.compose([
                Validators.required,
                requireTrimmed()
            ])],
            password: ['', Validators.required]
        });
    }

    public get username(): AbstractControl {
        return this.authForm.get("username");
    }

    public get password(): AbstractControl {
        return this.authForm.get("password");
    }

    fireContactEvent() {
        this.analyticsService.fireEvent("Login", "Contact Us Click", "");
    }

    ngOnInit() {
        this.returnUrl =
            this.route.snapshot.queryParams["returnUrl"] || "/dashboard";

        this.candidatesService.getLoginLegalText().subscribe(
            data => {
                this.legalText = this.sanitizer.bypassSecurityTrustHtml(
                    data["template"]
                );
            },
            (apiError: ApiError) => {
                if (apiError.timeout) {
                    this.legalText =
                        "It took too long to load the log in agreement. Please check your network connection.";
                } else {
                    this.legalText =
                        "An error has occurred while loading the log in agreement.";
                }
            }
        );
    }

    submitForm() {
        this.isSubmitting = true;

        this.subscriptions.push(
            this.userService
                .attemptAuth(this.authForm.value)
                .subscribe({
                    next: (user: User) => {
                        if (user) {
                            this.analyticsService.fireEvent(
                                "Login",
                                "Login Complete",
                                ""
                            );
                            this.router.navigateByUrl(this.returnUrl);
                        } else {
                            this.analyticsService.fireEvent(
                                "Login",
                                "Login Error",
                                "Invalid User Type"
                            );
                            this.userService.purgeAuth();
                            this.messageService.addMessage(
                                new Message(
                                    MESSAGE_TYPE_ERROR,
                                    "You do not have permission to access this area."
                                )
                            );
                        }
                    },
                    error: (err: ApiError) => {
                        this.isSubmitting = false;

                        if (err.statusCode === 401) {
                            this.analyticsService.fireEvent(
                                "Login",
                                "Login Error",
                                "Invalid login"
                            );
                            this.authForm.setErrors({ unauthorized: true });
                        } else if (err.statusCode === 400) {
                            this.analyticsService.fireEvent(
                                "Login",
                                "Login Error",
                                "Incomplete login parameters"
                            );
                            this.authForm.setErrors({ invalid: true });
                        } else if (err.statusCode === 403) {
                            this.analyticsService.fireEvent(
                                "Login",
                                "Login Error",
                                "Account deactivated"
                            );
                            this.authForm.setErrors({ deactivated: true });
                        } else if (err.timeout) {
                            this.authForm.setErrors({ timeout: true });
                        } else {
                            this.analyticsService.fireEvent(
                                "Login",
                                "Login Error",
                                "Server error"
                            );
                            this.authForm.setErrors({ server: err.requestId || true });
                        }
                    },
                    complete: () => (this.isSubmitting = false)
                })
        );
    }
}
