<div class="header-row desktop">
  <div class="arrows arrow-right">
    <div class="btn-container" *ngIf="!data.recentAcivity" [class.hide]="hideArrows" (click)="modalArrowClicked('right')">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
        <polyline points="9 18 15 12 9 6"></polyline>
      </svg>
    </div>
  </div>
  <div class="arrows arrow-left">
    <div class="btn-container" *ngIf="!data.recentAcivity" [class.hide]="hideArrows" (click)="modalArrowClicked('left')">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left">
        <polyline points="15 18 9 12 15 6"></polyline>
      </svg>
    </div>
  </div>
  <div class="share-and-close">
    <div class="share" (click)="openShareDialog()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#1C2B39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-share">
        <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
        <polyline points="16 6 12 2 8 6"></polyline>
        <line x1="12" y1="2" x2="12" y2="15"></line>
      </svg>
      <span>SHARE</span>
    </div>
    <div class="close" (click)="close()">
      <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="-5 0 24 24" fill="none" stroke="#1C2B39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </div>
  </div>
</div>
<div class="candidate-row">
  <div class="same-row">
    <div class="name">
      <p>{{ candidate.firstName }} {{ candidate.lastName }}</p>
      <p class="legal-name" *ngIf="candidate.legalFirstName && candidate.legalFirstName.toLowerCase().trim() != candidate.firstName.toLowerCase().trim()">
        {{ candidate.legalFirstName }} {{ candidate.lastName }}
      </p>
      <div class="labels">
        <p *ngIf="candidate.active" class="status-label active">Active</p>
        <p *ngIf="candidate.placedBefore && !candidate.active" class="status-label hired">Hired Before</p>
        <p *ngIf="candidate.inTheRunning" class="status-label in-the-running">In the Running</p>
        <p *ngIf="candidate.interviewed" class="status-label interviewed">Interviewed</p>
      </div>
    </div>

    <div class="header-row mobile">
      <div class="arrows arrow-right" [class.hide]="hideArrows">
        <div class="btn-container" *ngIf="!data.recentAcivity" (click)="modalArrowClicked('right')">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </div>
      <div class="arrows arrow-left" [class.hide]="hideArrows">
        <div class="btn-container" *ngIf="!data.recentAcivity" (click)="modalArrowClicked('left')">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left">
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </div>
      </div>
      <div class="share-and-close">
        <div class="share" (click)="openShareDialog()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#1C2B39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-share">
            <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
            <polyline points="16 6 12 2 8 6"></polyline>
            <line x1="12" y1="2" x2="12" y2="15"></line>
          </svg>
          <span>SHARE</span>
        </div>
        <div class="close" (click)="close()">
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24" fill="none" stroke="#1C2B39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="status-and-favorite">
    <div class="star" *ngIf="!candidate.blocked" [class.disable]="isLimitedImpersonation" (click)="isLimitedImpersonation || favoritedCandidate(candidate.candidateId, candidate.officeId, candidate.favorited)">
      <svg width="24" height="23" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg"><path d="M9 2.314C13.438-2.248 24.534 5.735 9 16-6.534 5.736 4.562-2.248 9 2.314Z" fill-rule="nonzero" stroke="#979797" fill="none" [ngClass]="{ favored: candidate.favorited }"/></svg>
      <span *ngIf="!candidate.favorited">FAVORITE?</span>
      <span *ngIf="candidate.favorited">FAVORITED</span>
    </div>
    <div class="blocked" [class.disable]="isLimitedImpersonation" (click)="isLimitedImpersonation || blockCandidate(candidate.candidateId, candidate.officeId, candidate.blocked, candidate.favorited)">
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="#1C2B39" stroke-width="2" stroke-linecap="round" [ngClass]="{'blocked-icon' : candidate.blocked}" stroke-linejoin="round" class="feather feather-slash">
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line>
      </svg>
      <span *ngIf='!candidate.blocked'>BLOCK?</span>
      <span *ngIf='candidate.blocked'>BLOCKED</span>
    </div>
  </div>
</div>
<div class="request-row">
  <div class="status-details">
    <div class="status" *ngIf="candidate.potentiallyWorking">
      <p class="header-font">STATUS</p>
      <p class="info">Potentially Working</p>
    </div>
    <div *ngIf="candidate.potentiallyWorking" class="divider"></div>
    <div class="last-updated">
      <p class="header-font">LAST UPDATED</p>
      <p class="info" *ngIf="candidate.lastUpdated">{{ candidate.lastUpdated | date: 'MMM d, y
        h:mm a' }}</p>
      <p class="info" *ngIf="!candidate.lastUpdated">Not Listed</p>
    </div>
  </div>
  <div class="request-actions">
    <div *ngIf="!requestCall" class="request" (click)="openEmailContactDialog('CallMe', 1)">
      <p>Request Call About Candidate
        <span class="chevron right"></span>
      </p>
    </div>
    <div *ngIf="requestCall" class="request">
      <p>Call Requested About Candidate</p>
    </div>
    <div class="request" (click)="openEmailContactDialog('MoreLikeThis', 2)">
      <p>Send More Like This
        <span class="chevron right"></span>
      </p>
    </div>
  </div>
</div>
