import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../shared';
import { Router } from '@angular/router';

@Component({
    selector: 'app-reports-component',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
    constructor(
        private router: Router,
        private userService: UserService,
    ) {
    }

    ngOnInit() {
    }
}
