import { Component, OnInit, Input } from "@angular/core";
import { ActiveCandidate, Job, User } from "../../shared/models";
import { DashboardService, UserService, JobService } from "../../shared";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import {
    ExtensionDialogComponent,
    CLOSE_SUCCESS
} from "../../shared/components/extension-dialog/extension-dialog.component";
import { AlertDialogComponent } from "../../shared/components/alert-dialog/alert-dialog.component";
import { AnalyticsService } from "../../shared/services/analytics.service";
import { Router } from "@angular/router";
import { ApiError } from "../../shared/models/api-error.model";
import { NetworkErrorSnackbarService } from "../../shared/services/network-error-snackbar.service";

@Component({
    selector: "dashboard-active-candidates",
    templateUrl: "./active-candidates.component.html",
    styleUrls: ["./active-candidates.component.scss"]
})
export class ActiveCandidatesComponent implements OnInit {
    @Input()
    public activeSection: boolean;

    public activeCandidates: ActiveCandidate[];
    public isLimitedImpersonation: boolean = false;
    public jobs: Job[];

    public isLoadingResults: boolean = true;
    public loadingError: ApiError;
    public user: User;

    constructor(
        private dashboardService: DashboardService,
        private dialog: MatDialog,
        private userService: UserService,
        private analyticsService: AnalyticsService,
        private router: Router,
        private jobService: JobService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService
    ) {}

    ngOnInit() {
        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });

        this.jobService.jobsSubject.subscribe(jobs => {
            this.jobs = jobs;
        });

        this.getActiveCandidates();
        this.getUser();
    }

    getUser() {
        this.userService.getUser().subscribe(data => {
            this.user = data;
        })
    }

    updateFavorited(candidateId, officeId, alreadyFavorited) {
        const favoritedAction = alreadyFavorited ? false : true;
        const params = { favorited: favoritedAction };

        this.getUser();

        if (!this.user.hideFavMessageFlag && params.favorited) {
            let dialog = this.dialog.open(AlertDialogComponent, {
                panelClass: 'confirm_status_change_alert',
                data: {
                    buttonText: "Favorite",
                    showCheckbox: true,
                    favoritedMessage: true
                }
            })

            dialog.afterClosed().subscribe(result => {
                if (result) {
                    // never show favorite modal again
                    if (result.isChecked) {
                        const params = {
                            hideFavMessageFlag: true
                        }

                        this.userService.patchUserPreferences(params)
                            .subscribe(data =>
                                this.getUser()
                            )

                        this.user.hideFavMessageFlag = true;
                    }

                    this.dashboardService
                        .updateCandidatePreference(officeId, candidateId, params)
                        .subscribe(
                            () => {
                                let cand = this.activeCandidates.find(
                                    ac =>
                                        ac.candidateId == candidateId &&
                                        ac.officeId == officeId
                                );
                                if (cand) {
                                    cand.favorited = favoritedAction;
                                }
                            },
                            (err: ApiError) => {
                                const favActionText = !favoritedAction
                                    ? "unfavoriting"
                                    : "favoriting";

                                const errorOccurredWhile =
                                    favActionText + " this candidate.";

                                this.networkErrorSnackbarService
                                    .networkError(err, errorOccurredWhile)
                                    .subscribe(() => {
                                        this.updateFavorited(
                                            candidateId,
                                            officeId,
                                            alreadyFavorited
                                        );
                                });
                            }
                        );
                }
            })
        } else {
            this.dashboardService
                .updateCandidatePreference(officeId, candidateId, params)
                .subscribe(
                    () => {
                        let cand = this.activeCandidates.find(
                            ac =>
                                ac.candidateId == candidateId &&
                                ac.officeId == officeId
                        );
                        if (cand) {
                            cand.favorited = favoritedAction;
                        }
                    },
                    (err: ApiError) => {
                        const favActionText = !favoritedAction
                            ? "unfavoriting"
                            : "favoriting";

                        const errorOccurredWhile =
                            favActionText + " this candidate.";

                        this.networkErrorSnackbarService
                            .networkError(err, errorOccurredWhile)
                            .subscribe(() => {
                                this.updateFavorited(
                                    candidateId,
                                    officeId,
                                    alreadyFavorited
                                );
                        });
                    }
                );
        }
    }

    withinSevenDays(date) {
        const dateEnd = new Date(date);
        const today = new Date();
        today.setDate(today.getDate() + 7);

        if (dateEnd < today) {
            return true;
        } else {
            return false;
        }
    }

    adjust(candidate: ActiveCandidate) {
        let job = this.jobs.filter(
            job =>
                job.jobId === candidate.jobId &&
                job.officeId === candidate.officeId
        )[0];
        this.analyticsService.fireEvent(
            "Job Candidates",
            "Adjust End Date",
            "Click",
            {
                dimension6: candidate.jobCandidateId,
                dimension3:
                    job.clientId.toString() + "." + job.officeId.toString()
            }
        );

        const dialog = this.dialog.open(ExtensionDialogComponent, {
            width: "600px",
            autoFocus: false,
            panelClass: ["mobileContainer", "extendDialog"],
            data: {
                jobId: candidate.jobId,
                officeId: candidate.officeId,
                candidate: candidate,
                clientId: job.clientId,
                currentDate: new Date(
                    candidate.targetEndDate.replace(/-/g, "/")
                )
            }
        });

        dialog.afterClosed().subscribe(result => {
            if (result === CLOSE_SUCCESS) {
                this.dialog.open(AlertDialogComponent, {
                    width: "400px",
                    data: {
                        title: "Thanks for the update.",
                        message:
                            "We are working on confirming the End Date change and will be back in touch soon."
                    }
                });
            }
        });
    }

    routeJob(jobOrder) {
        this.analyticsService.fireEvent(
            "Job Candidates",
            "Job Candidate Click",
            "Active Placement"
        );
        const officeId = jobOrder.officeId;
        const jobId = jobOrder.jobId;
        this.router.navigate(["jobs", { officeId, jobId, "section": "active-placements"}]);
    }

    private getActiveCandidates() {
        this.isLoadingResults = true;
        this.loadingError = null;

        this.dashboardService.getActiveCandidates().subscribe(
            activeCandidates => {
                this.isLoadingResults = false;
                this.activeCandidates = activeCandidates;
            },
            (err: ApiError) => {
                this.isLoadingResults = false;
                this.loadingError = err;
            }
        );
    }
}
