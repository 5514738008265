import { Component, OnInit, ViewChild } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { DomSanitizer } from "@angular/platform-browser";
import { of } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { AlertDialogComponent } from "../../shared/components/alert-dialog/alert-dialog.component";
import { ConfirmDialogComponent } from "../../shared/components/confirm-dialog/confirm-dialog.component";
import { Subscribing } from "../../shared/components/subscribing.component";
import { ApiError } from "../../shared/models/api-error.model";
import { LbTitle } from "../../shared/services/title.service";
import { MessagesService } from "./messages.service";
import { PaginatedResponse } from "../../shared/models/paginated-response.model";
import { AdminMessage } from "../../shared/models/admin-message.model";

@Component({
    selector: "app-admin-messages",
    templateUrl: "./messages.component.html",
    styleUrls: ["./messages.component.scss"]
})
export class MessagesComponent extends Subscribing implements OnInit {
    displayedColumns = [
        "active",
        "title",
        "endDate",
        "dateCreated",
        "dateUpdated",
        "action"
    ];
    dataSource = new MatTableDataSource();

    resultsLength: number = 0;
    isLoadingResults: boolean = true;
    loadingError: string = null;
    limit: number = 10;

    currentDate: Date = new Date();

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    constructor(
        private messagesService: MessagesService,
        private dialog: MatDialog,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        titleService: LbTitle
    ) {
        super();

        titleService.setTitleFromKeywords(["Messages", "Admin"]);

        iconRegistry.addSvgIcon(
            "active",
            sanitizer.bypassSecurityTrustResourceUrl(
                "assets/icons/check-circle.svg"
            )
        );
        iconRegistry.addSvgIcon(
            "edit",
            sanitizer.bypassSecurityTrustResourceUrl("assets/icons/edit.svg")
        );
        iconRegistry.addSvgIcon(
            "remove",
            sanitizer.bypassSecurityTrustResourceUrl("assets/icons/x.svg")
        );
    }

    ngOnInit() {
        this.subscriptions.push(
            this.paginator.page
                .pipe(
                    startWith({}),
                    switchMap(() => {
                        this.isLoadingResults = true;
                        return this.messagesService.getMessages(
                            this.limit,
                            this.paginator.pageIndex * this.limit
                        );
                    }),
                    map(
                        (data: PaginatedResponse<any>): Array<AdminMessage> => {
                            this.isLoadingResults = false;
                            this.resultsLength = data.count;
                            let tzOffsetMinutes = new Date().getTimezoneOffset();

                            return data.results.map((msg: AdminMessage) => {
                                msg.dateCreated = new Date(msg.dateCreated);
                                msg.dateCreated.setMinutes(
                                    msg.dateCreated.getMinutes() -
                                        tzOffsetMinutes
                                );

                                msg.endDate = new Date(msg.endDate);
                                msg.endDate.setMinutes(
                                    msg.endDate.getMinutes() - tzOffsetMinutes
                                );

                                if (msg.dateUpdated) {
                                    msg.dateUpdated = new Date(msg.dateUpdated);
                                    msg.dateUpdated.setMinutes(
                                        msg.dateUpdated.getMinutes() -
                                            tzOffsetMinutes
                                    );
                                } else {
                                    msg.dateUpdated = null;
                                }

                                return msg;
                            });
                        }
                    ),
                    catchError((err: ApiError) => {
                        this.isLoadingResults = false;

                        if (err.timeout) {
                            this.loadingError =
                                "The server did not respond in time while trying to retrieve messages. Please check your connection and refresh the page.";
                        } else {
                            this.loadingError =
                                "An occurred while trying to retrieve messages. Please try again.";
                        }

                        return of([]);
                    })
                )
                .subscribe(results => (this.dataSource.data = results))
        );
    }

    private deleting: Array<number> = [];

    delete(id) {
        if (this.deleting.indexOf(id) !== -1) {
            return;
        }

        let confirm = this.dialog.open(ConfirmDialogComponent, {
            width: "400px",
            data: {
                title: "Are you sure?",
                message: "Deleting is permanent and cannot be undone."
            }
        });

        confirm.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.deleting.push(id);

                this.subscriptions.push(
                    this.messagesService.deleteMessage(id).subscribe({
                        next: () => {
                            delete this.deleting[this.deleting.indexOf(id)];

                            let dialog = this.dialog.open(
                                AlertDialogComponent,
                                {
                                    width: "300px",
                                    data: {
                                        title: "SUCCESS!",
                                        message: "Message removed successfully."
                                    }
                                }
                            );

                            this.dataSource.data = this.dataSource.data.filter(
                                (msg: AdminMessage) => {
                                    return msg.messageID !== id;
                                }
                            );
                        },
                        error: (err: ApiError) => {
                            delete this.deleting[this.deleting.indexOf(id)];

                            let errorMessage: string;

                            if (err.timeout) {
                                errorMessage = "A timeout occurred while deleting this message. Try refreshing the page and try again."
                            } else {
                                errorMessage = "An error occurred while deleting this message. Try refreshing the page and trying again."
                            }

                            this.dialog.open(AlertDialogComponent, {
                                width: "400px",
                                data: {
                                    title: "ERROR!",
                                    message: errorMessage
                                }
                            });

                        }
                    })
                );
            }
        });
    }
}
