<div class="about-section">
  <div class="about-row">
    <div class="name">
      <p>About {{ candidate.firstName }}</p>
      <span class="subtitle">{{ candidate.bioHeadline }}</span>
    </div>
    <div class="bioIntro" *ngIf="candidate.bioIntro" [innerHTML]="candidate.safeBioIntro"></div>
    <div *ngIf="(!candidate.jobId && candidate.bioBody) || (!candidate.jobCandBioBody)" class="copy" [innerHTML]="candidate.safeBioBody"></div>
    <div *ngIf="candidate.jobId && candidate.jobCandBioBody" class="copy" [innerHTML]="candidate.safeJobCandBioBody"></div>
  </div>
  <div class="section-details">
    <div>
      <div>
        <p class="header-font">AREA<span *ngIf="candidate.territories && candidate.territories.length > 1">S</span></p>
        <p class="info" *ngIf="candidate.area && !candidate.territories">{{ candidate.area }}</p>
        <div *ngIf="candidate.territories">
          <div *ngFor="let territory of candidate.territories">
            <p class="info">{{ territory.territoryName }}</p>
          </div>
        </div>
        <p class="info" *ngIf="candidate.territories && candidate.territories.length === 0">None listed</p>
      </div>

      <div *ngIf="showSalaryDetails">
        <div class="freelance" *ngIf="candidate.statusId != 8 && candidate.billQuoted != 0">
          <p class="header-font">HOURLY RATE</p>
          <p class="info">{{ candidate.billQuoted | currency : 'USD': 'symbol' }}/hr</p>
        </div>
        <div class="salary" *ngIf="candidate.statusId != 8 && candidate.salaryQuoted != 0">
          <p class="header-font">SALARY RANGE</p>
          <p class="info">{{ candidate.salaryQuoted | currency : 'USD': 'symbol' }}</p>
        </div>
        <div class="freelance" *ngIf="candidate.statusId == 8 && candidate.rateBillReg != 0">
          <p class="header-font">HOURLY RATE</p>
          <p class="info">{{ candidate.rateBillReg | currency : 'USD': 'symbol' }}/hr</p>
        </div>
        <ng-container *ngIf="false">
          <div class="freelance" *ngIf="candidate.statusId == 8">
            <p class="header-font">HOURS TO DATE</p>
            <p class="info">{{ candidate.hoursToDate }}</p>
          </div>
        </ng-container>
        <div class="freelance" *ngIf="candidate.statusId == 8 && candidate.placementSalary != 0">
          <p class="header-font">SALARY</p>
          <p class="info">{{ candidate.placementSalary | currency : 'USD': 'symbol'}}</p>
        </div>
      </div>
    </div>
    <div class="looking-for" *ngIf="lookingFor">
      <div class="info-flag"
           *ngIf="candidate.considerFreeToFull
            || candidate.considerFreelance
            || candidate.considerFulltime
            || candidate.considerParttime
            || candidate.considerOffsite
            || candidate.considerOnsite"
      >
        <p class="header-font looking-for-header">LOOKING FOR:</p>
        <span class="tool-tip desktop">i</span>
        <span class="tool-tip mobile-only" (click)="toggleToolTip()">i</span>
        <div class="text">
          <p class="tool-tip-header-font">Freelance</p>
          <p class="body">Hourly roles up to 40 hours per week, usually with a set duration.</p>
          <p class="tool-tip-header-font">Freelance to Full-time</p>
          <p class="body">An hourly role that may transition into a full-time hire (try-before-you-hire).</p>
          <p class="tool-tip-header-font">Full-time</p>
          <p class="body">A direct hire permanent position.</p>
          <p class="tool-tip-header-font">Part-time</p>
          <p class="body">Roles typically up to 25 hours per week, usually ongoing/open ended.</p>
          <p class="tool-tip-header-font">Offsite</p>
          <p class="body">Roles that require a personal workspace and equipment.</p>
          <p class="tool-tip-header-font">Onsite</p>
          <p class="body">Roles that require an in-person presence.</p>
        </div>
      </div>
      <p class="info" *ngIf="candidate.considerFreelance">Freelance</p>
      <p class="info" *ngIf="candidate.considerFreeToFull">Freelance to Full-time</p>
      <p class="info" *ngIf="candidate.considerFulltime">Full-time</p>
      <p class="info" *ngIf="candidate.considerParttime">Part-time</p>
      <p class="info" *ngIf="candidate.considerOffsite">Offsite</p>
      <p class="info" *ngIf="candidate.considerOnsite">Onsite</p>
    </div>
  </div>
</div>
