<div class="stretch-container">
    <div class="job-order-name-container">
        <form class="job-order-name-form">
            <h2 *ngIf='!_editMode' class="job-order-name">{{_initialJobTitle}}</h2>
            <div class="job-order-name-input-section" [class.expand]="_editMode">
                <input type="text" class="form-control job-order-name-input" autocomplete="off" [class.form-input-errors]='jobOrderNameEdit.errors' [class.hidden]='!_editMode' id="jobOrderNameEdit" required maxlength="100" [(ngModel)]="jobDetails.jobTitle" name="jobOrderNameEdit" #jobOrderNameEdit="ngModel" (keydown.enter)="saveJobTitle()">
                <div class="svg-container">
                    <svg *ngIf='!_editMode' (click)='isLimitedImpersonation || enableEditMode()' class="job-order-name-edit" [class.disable]="isLimitedImpersonation" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <defs></defs>
                        <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g id="Job-Orders-2" transform="translate(-1103.000000, -144.000000)" stroke="#b3e1ea" stroke-width="1.98776471">
                                <g id="Job-Details" transform="translate(721.000000, 137.000000)">
                                    <g id="Job-Headlines">
                                        <g id="Group-27">
                                            <g id="edit-2" transform="translate(383.000000, 8.000000)">
                                                <polygon id="Shape" points="13 0 18 5 5 18 0 18 0 13"></polygon>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <div *ngIf='_editMode' class="edit-buttons-mobile">
                        <div>
                            <svg (click)='cancelEditMode()' class="cancel" width="14px" height="16px" viewBox="0 0 17 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <defs></defs>
                                <g id="UI-Guide" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                    <g transform="translate(-460.000000, -2722.000000)" id="Group" stroke="#2FC8DC" stroke-width="3">
                                        <g transform="translate(60.000000, 2615.000000)">
                                            <g id="Job-Orders:-Sort-By" transform="translate(388.000000, 56.000000)">
                                                <g id="Group-45" transform="translate(12.000000, 50.000000)">
                                                    <g id="Group-3-Copy-2" transform="translate(0.000000, 2.000000)">
                                                        <path d="M15.5,0.5 L2,15.5454545" id="Shape"></path>
                                                        <path d="M15.7071918,1.00902274 L0.858107263,14.7435695" id="Shape" transform="translate(8.358107, 8.150915) rotate(90.000000) translate(-8.358107, -8.150915) "></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span (click)='cancelEditMode()'>CANCEL</span>
                        </div>
                        <div>
                            <button aria-label="Save" class="button" (click)='!jobOrderNameEdit.errors && saveJobTitle()'>SAVE</button>
                        </div>
                    </div>
                    <div class="edit-buttons">
                        <svg (click)='!jobOrderNameEdit.errors && saveJobTitle()' class="save" *ngIf='_editMode' width="24px" height="16px" viewBox="0 0 24 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <defs></defs>
                            <g id="UI-Guide" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                <g transform="translate(-460.000000, -2730.000000)" id="Group" stroke="#2FC8DC" stroke-width="3">
                                    <g transform="translate(60.000000, 2615.000000)">
                                        <g id="Job-Orders:-Sort-By" transform="translate(388.000000, 56.000000)">
                                            <g id="Group-45" transform="translate(14.000000, 50.000000)">
                                                <g id="Group-3-Copy-2" transform="translate(0.000000, 11.000000)">
                                                    <polyline id="Shape" points="20 0 6.25 12 0 6.54545455"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <svg (click)='cancelEditMode()' class="cancel" *ngIf='_editMode' width="17px" height="19px" viewBox="0 0 17 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <defs></defs>
                            <g id="UI-Guide" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                <g transform="translate(-460.000000, -2722.000000)" id="Group" stroke="#2FC8DC" stroke-width="3">
                                    <g transform="translate(60.000000, 2615.000000)">
                                        <g id="Job-Orders:-Sort-By" transform="translate(388.000000, 56.000000)">
                                            <g id="Group-45" transform="translate(12.000000, 50.000000)">
                                                <g id="Group-3-Copy-2" transform="translate(0.000000, 2.000000)">
                                                    <path d="M15.5,0.5 L2,15.5454545" id="Shape"></path>
                                                    <path d="M15.7071918,1.00902274 L0.858107263,14.7435695" id="Shape" transform="translate(8.358107, 8.150915) rotate(90.000000) translate(-8.358107, -8.150915) "></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </form>
        <div *ngIf="jobOrderNameEdit.errors && (jobOrderNameEdit.dirty || jobOrderNameEdit.touched)" class="input-errors">
            <div [hidden]="!jobOrderNameEdit.errors.required">
                Job order name is required.
            </div>
            <div [hidden]="!jobOrderNameEdit.errors.maxlength">
                Job order name cannot be more than 100 characters long.
            </div>
            <div [hidden]="!jobOrderNameEdit.errors.pattern">
                Job order name can only contain numbers, letters, spaces, dashes and hyphens.
            </div>
        </div>
    </div>
    <div class="other-metadata">
        <div class="job-id-and-location">
            <div class="metadata job-id">
                <svg width="19px" height="18px" viewBox="0 0 19 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <defs></defs>
                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                        <g id="Job-Orders-2" transform="translate(-1050.000000, -198.000000)" stroke="#7E868C" stroke-width="1.6128">
                            <g id="Job-Details" transform="translate(721.000000, 137.000000)">
                                <g id="Job-Headlines">
                                    <g id="Group-26" transform="translate(0.000000, 59.000000)">
                                        <g id="Group-6-Copy-2" transform="translate(330.000000, 0.000000)">
                                            <g id="briefcase" transform="translate(0.000000, 3.600000)">
                                                <rect id="Rectangle-path" x="0" y="3.24892562" width="16.2271663" height="11.3712397" rx="1.5095808"></rect>
                                                <path d="M11.3590164,14.6201653 L11.3590164,1.62446281 C11.3590164,0.727296773 10.6325014,0 9.73629977,0 L6.49086651,0 C5.59466486,0 4.86814988,0.727296773 4.86814988,1.62446281 L4.86814988,14.6201653" id="Shape"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                #{{jobDetails.jobId}}
            </div>
            <div class="vertical-separator vertical-separator-1"></div>
            <div class="metadata job-area">
                <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <defs></defs>
                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                        <g id="Job-Orders-2" transform="translate(-865.000000, -198.000000)" stroke="#7E868C" stroke-width="1.54112">
                            <g id="Job-Details" transform="translate(721.000000, 137.000000)">
                                <g id="Job-Headlines">
                                    <g id="Group-26" transform="translate(0.000000, 59.000000)">
                                        <g id="Group-6" transform="translate(145.000000, 0.000000)">
                                            <g id="globe" transform="translate(0.000000, 3.000000)">
                                                <circle id="Oval" cx="8" cy="8" r="8"></circle>
                                                <path d="M0,8 L16,8" id="Shape"></path>
                                                <path d="M8,1.77635684e-16 C10.0010243,2.19068232 11.1382022,5.03362708 11.2,8 C11.1382022,10.9663729 10.0010243,13.8093177 8,16 C5.9989757,13.8093177 4.86179779,10.9663729 4.8,8 C4.86179779,5.03362708 5.9989757,2.19068232 8,0 Z" id="Shape"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                {{jobDetails.area}}
            </div>
        </div>
    </div>
</div>
<ng-container>
    <div class="confirm-bill-rate-container" *ngIf="jobDetails.brcApprover && ((jobDetails.brcStatus | lowercase) === 'pending' || (jobDetails.brcStatus | lowercase) === 'open')">
        <div class="confirm-container" *ngIf="(jobDetails.placementType | lowercase) === 'freelance'" (click)="isLimitedImpersonation || openBRCDialog()">
            <div class="check-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="2 0 22 20" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-dollar-sign">
                    <line x1="12" y1="1" x2="12" y2="23"></line>
                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                </svg>
            </div>
            <button aria-label="Confirm Bill Rate" class="bill-rate confirm-bill-rate">
                Confirm Bill Rate
            </button>
        </div>

        <div class="confirm-container" *ngIf="(jobDetails.placementType | lowercase) === 'full time'" (click)="isLimitedImpersonation || openBRCDialog()">
            <div class="check-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="2 0 22 20" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-dollar-sign">
                    <line x1="12" y1="1" x2="12" y2="23"></line>
                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                </svg>
            </div>
            <button aria-label="Confirm Fee" class="bill-rate confirm-bill-rate">
                Confirm Fee
            </button>
        </div>
    </div>
</ng-container>
