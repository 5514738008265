<div class="container">
    <div class='header-container'>
        <div class="close-container">
            <div class="name-and-type">
                <div>
                    <p class="name">{{data.name}}</p>
                </div>
                <p class="type">{{determineType(data.document.type)}}</p>
            </div>
            <button aria-label="Cancel" type="button" class="close close-mobile" (click)="cancel()">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </button>
            <div class="cta">
                <div class="description-container">
                    <a class="new-tab-button" target="_blank" [href]="url">
                        <span>OPEN IN NEW TAB</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link">
                            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                            <polyline points="15 3 21 3 21 9"></polyline>
                            <line x1="10" y1="14" x2="21" y2="3"></line>
                        </svg>
                    </a>
                    <a class="download-button" href="{{data.document.url}}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="7 10 12 15 17 10"></polyline>
                            <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                        DOWNLOAD
                    </a>
                </div>
                <button aria-label="Close" type="button" class="close close-desktop" (click)="cancel()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-content>
    <img *ngIf="isImage" src="url" alt="Placeholder" />
    <iframe *ngIf="!isImage && !isPdf" [src]="url"></iframe>
    <div *ngIf="!isImage && isPdf" id='pdf-div' class='pdf-view'></div>
</div>
