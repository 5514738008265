<div class="no-job-order-clicked-loader" *ngIf="isLoadingResults">
    <span class="submitting">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
            <line x1="12" y1="2" x2="12" y2="6"></line>
            <line x1="12" y1="18" x2="12" y2="22"></line>
            <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
            <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
            <line x1="2" y1="12" x2="6" y2="12"></line>
            <line x1="18" y1="12" x2="22" y2="12"></line>
            <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
            <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
        </svg>
    </span>
</div>

<div class="candidate-details-container padded-top" *ngIf="loadingError">
    <app-loading-error [apiError]="loadingError" (onRetry)="callSequence()" [lightMode]="true"></app-loading-error>
</div>


<div class="candidate-details-container scrollbar details-unavailable" *ngIf="!isLoadingResults && !loadingError && doNotUse">
    <app-candidate-details-name class="extra-padding" [doNotUse]="true" [candidate]="candidateDetails"></app-candidate-details-name>
    <app-candidate-details-tab-information [job]="jobOrder" [mobile]="mobile" [candidate]="candidateDetails" [doNotUse]="true" [details]="jobOrderDetails" [candidateObject]="candidate"></app-candidate-details-tab-information>
    <app-candidate-details-footer></app-candidate-details-footer>
</div>

<div class="candidate-details-container scrollbar" *ngIf="!isLoadingResults && !loadingError && jobOrder?.jobId && candidate && !doNotUse">
    <app-candidate-details-status *ngIf="candidateDetails.sunsetFlag === 0" [hiredCandId]="hiredCandId" [candidateDetails]="candidateDetails" [candidate]="candidate" [jobOrderCandidate]="jobOrderCandidate"></app-candidate-details-status>
    <app-candidate-details-name [class.extra-padding]="candidateDetails.sunsetFlag === 1" [candidate]="candidateDetails"></app-candidate-details-name>
    <app-candidate-details-action [candidate]="candidateDetails" [jobOrderCandidate]="jobOrderCandidate" [job]="jobOrder" [candidateObject]="candidate"></app-candidate-details-action>
    <app-candidate-details-tab-information [job]="jobOrder" [mobile]="mobile" [candidate]="candidateDetails" [details]="jobOrderDetails" [candidateObject]="candidate"></app-candidate-details-tab-information>
    <div class="row-divider" *ngIf="candidateDetails.sunsetFlag === 0"></div>
    <app-candidate-details-links-and-docs *ngIf="candidateDetails.sunsetFlag === 0" [candidate]="candidateDetails"></app-candidate-details-links-and-docs>
    <div class="row-divider" *ngIf="candidateDetails.sunsetFlag === 0"></div>
    <app-candidate-details-about *ngIf="candidateDetails.sunsetFlag === 0" [candidate]="candidateDetails"></app-candidate-details-about>
    <div class="row-divider not-mobile" *ngIf="jobOrderDetails.recruiterEmail"></div>
    <app-candidate-details-notes [details]="candidateDetails"></app-candidate-details-notes>
    <div class="row-divider"></div>
    <app-candidate-details-footer></app-candidate-details-footer>
</div>
