import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, timeout, delay } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { ApiError } from "../models/api-error.model";
import { throwError } from "rxjs";
import { TimeoutError } from "rxjs";
import { ConnectionService, ConnectionState } from "ng-connection-service";
import { StructApiPayload } from '../models/struct-api-payload.model';
import { tap } from "rxjs/operators";

const OFFLINE_RETRY_DELAY = 400;

@Injectable()
export class ApiService {
    private readonly HTTP_TIMEOUT: number = 30e3;
    private online: boolean = true;
    currentState!: ConnectionState;
    private payload: StructApiPayload = {
        sid: null,
        srvconsumer: 'LBAngWebSite',
        env: '',
        datetime: '',
        ip: null,
        service: 'LBMT',
        data: {
            ep: 'USPSaveRT',
            ip: [],
            op: [
                {
                    pn: 'Status',
                    pt: 'bit'
                },
                {
                    pn: 'OutMsg',
                    pt: 'nvarchar(4000)'
                }
            ],
            dsout: true
        },
        splnote: {
            dbops: 'Create',
            secure: false,
            key: false,
            comments: '',
            splhndlr: {
                email: null,
                sendalertflag: false,
                dbsaveflag: true,
                opformat: 'JSon'
            }
        }
    };

    constructor(
        private http: HttpClient,
        connectionService: ConnectionService
    ) {
        connectionService.monitor().pipe(
            tap((newState: ConnectionState) => {
                this.currentState = newState;
                this.online = this.currentState.hasNetworkConnection;
            })
          ).subscribe();
    }


    private formatErrors(
        error: HttpErrorResponse | TimeoutError
    ): Observable<never> {
        let status: number =
            error instanceof HttpErrorResponse ? error.status : 0;
        let requestId: string =
            error instanceof HttpErrorResponse &&
            error.headers &&
            error.headers.get("X-REQUEST-ID");
        let isTimeout: boolean =
            error.name === "TimeoutError" || error instanceof TimeoutError;
        let isOffline: boolean = !this.online;
        let errors: object =
            (error instanceof HttpErrorResponse && error.error) || {};

        return throwError(
            new ApiError(status, requestId, isTimeout, isOffline, errors)
        );
    }

    get(
        path: string,
        params: { [param: string]: string | string[] } = {}
    ): Observable<any> {
        return this.http
            .get(this.getAbsoluteUrlFromPath(path), { params })
            .pipe(
                delay(!this.online ? OFFLINE_RETRY_DELAY : 0),
                timeout(this.HTTP_TIMEOUT),
                catchError(this.formatErrors.bind(this))
            );
    }

    patch(path: string, body: Object = {}): Observable<any> {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('X-HTTP-Method-Override', 'PATCH');
        return this.http
            .post(this.getAbsoluteUrlFromPath(path), JSON.stringify(body), {headers})
            .pipe(
                delay(!this.online ? OFFLINE_RETRY_DELAY : 0),
                timeout(this.HTTP_TIMEOUT),
                catchError(this.formatErrors.bind(this))
            );
    }

    put(path: string, body: Object = {}): Observable<any> {
        return this.http
            .put(this.getAbsoluteUrlFromPath(path), JSON.stringify(body))
            .pipe(
                delay(!this.online ? OFFLINE_RETRY_DELAY : 0),
                timeout(this.HTTP_TIMEOUT),
                catchError(this.formatErrors.bind(this))
            );
    }

    post(path: string, body: Object = {}): Observable<any> {
        return this.http
            .post(this.getAbsoluteUrlFromPath(path), JSON.stringify(body))
            .pipe(
                delay(!this.online ? OFFLINE_RETRY_DELAY : 0),
                timeout(this.HTTP_TIMEOUT),
                catchError(this.formatErrors.bind(this))
            );
    }

    delete(path: string): Observable<any> {
        return this.http.delete(this.getAbsoluteUrlFromPath(path)).pipe(
            delay(!this.online ? OFFLINE_RETRY_DELAY : 0),
            timeout(this.HTTP_TIMEOUT),
            catchError(this.formatErrors.bind(this))
        );
    }

    postStruct(path: string, body: Object = {}): Observable<any> {
        const translatedArray = [];
        Object.entries(body).forEach(([key, value]) => {
            translatedArray.push({pn: `@${key}`, pv: value});
        });
        this.payload.env = environment.struct_api_env;
        this.payload.datetime = (new Date()).toISOString();
        this.payload.data.ip = translatedArray;

        return this.http
            .post(this.getStructPath(), JSON.stringify(this.payload))
            .pipe(
                delay(!this.online ? OFFLINE_RETRY_DELAY : 0),
                timeout(this.HTTP_TIMEOUT),
                catchError(this.formatErrors.bind(this))
            );
    }

    getStruct(spName: string, body: Object = {}): Observable<any> {
        const translatedArray = [];
        Object.entries(body).forEach(([key, value]) => {
            translatedArray.push({pn: `${key}`, pv: value});
        });
        this.payload.env = environment.struct_api_env;
        this.payload.datetime = (new Date()).toISOString();
        this.payload.data.ep = spName;
        this.payload.data.ip = (translatedArray.length > 0) ? translatedArray : null;
        this.payload.data.op = [
            {
                "pn": "Status",
                "pt": "bit"
            },
            {
                "pn": "OutMessage",
                "pt": "varchar"
            }
        ]
        this.payload.splnote.dbops = null;

        return this.http
            .post(this.getStructPath(), JSON.stringify(this.payload))
            .pipe(
                delay(!this.online ? OFFLINE_RETRY_DELAY : 0),
                timeout(this.HTTP_TIMEOUT),
                catchError(this.formatErrors.bind(this))
            );
    }

    private getAbsoluteUrlFromPath(path: string): string {
        if ((<any>window).networkErrorMode) {
            return `${environment.api_url}/error`;
        }

        return `${environment.api_url}${path}`;
    }

    private getStructPath(): string {
        if ((<any>window).networkErrorMode) {
            return `${environment.api_url}/error`;
        }

        return environment.struct_api_url;
    }
}
