import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

    public isChecked: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA)
        public data: any,
        private dialogRef: MatDialogRef<AlertDialogComponent>,
    ) {
    }

    cancel() {
        this.dialogRef.close();
    }

    updatePref() {
        this.isChecked = !this.isChecked;
    }

    submitData() {
        this.dialogRef.close({isChecked: this.isChecked});
    }

    ngOnInit() {
    }

}
