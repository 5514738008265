<div class="large-container">

    <div class="search">
        <button aria-label="Create New" mat-raised-button type="button" color="primary" routerLink="/admin/messages/create">Create New</button>

        <div class="clearfix"></div>
        <div class="loading-error" *ngIf="loadingError">
            <p>{{ loadingError }}</p>
        </div>
        <div class="clearfix"></div>
    </div>

    <div class="loading-shade" *ngIf="isLoadingResults">
        <svg class="loading-gif" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"
             fill="none" stroke="#ffffff"
             stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
            <line x1="12" y1="2" x2="12" y2="6"></line>
            <line x1="12" y1="18" x2="12" y2="22"></line>
            <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
            <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
            <line x1="2" y1="12" x2="6" y2="12"></line>
            <line x1="18" y1="12" x2="22" y2="12"></line>
            <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
            <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
        </svg>
    </div>

    <mat-table #table [dataSource]="dataSource" matSort matSortActive="messageID" matSortDisableClear matSortDirection="asc" [ngClass]="{'hidden': isLoadingResults || loadingError}">

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.title.length > 20? ((row.title | slice:0:25) + '...'): row.title }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="endDate">
            <mat-header-cell *matHeaderCellDef>End Date</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.endDate | date:'medium' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateCreated">
            <mat-header-cell *matHeaderCellDef>Created</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.dateCreated | date:'medium' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="active">
            <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-icon *ngIf="row.endDate > currentDate" svgIcon="active"
                          aria-label="Active"></mat-icon>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdBy">
            <mat-header-cell *matHeaderCellDef>Created By</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.createdBy }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateUpdated">
            <mat-header-cell *matHeaderCellDef>Updated</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.dateUpdated | date: 'medium' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="updatedBy">
            <mat-header-cell *matHeaderCellDef>Updated By</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.updatedBy }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button aria-label="Edit" mat-button routerLink="/admin/messages/edit/{{ row.messageID }}" title="Edit Message">
                    <mat-icon svgIcon="edit" aria-label="Edit"></mat-icon>
                    Edit
                </button>
                <button aria-label="Delete" mat-button (click)="delete(row.messageID)" title="Delete Message">
                    <mat-icon svgIcon="remove" aria-label="Delete"></mat-icon>
                    Delete
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator [length]="resultsLength" [pageSize]="limit" [ngClass]="{'hidden': isLoadingResults || loadingError}">
    </mat-paginator>
</div>