import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-candidate-details-tab-contact',
    templateUrl: './candidate-details-tab-contact.component.html',
    styleUrls: ['./candidate-details-tab-contact.component.scss']
})
export class CandidateDetailsTabContactComponent implements OnInit {
    @Input()
    details: any;

    constructor(
       private sanitizer: DomSanitizer
    ) {}

    formatPhoneNumber(number) {
        let s2 = ('' + number).replace(/\D/g, '');
        let m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return !m ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3];
    }

    ngOnInit() {
        this.details['textNumberSafe'] = this.sanitizer.bypassSecurityTrustUrl('sms:' + this.details.recruiterMobilePhoneNumber)
    }

}
