import { Component, Input, EventEmitter, Output } from "@angular/core";
import { ApiError } from "../../shared/models/api-error.model";
import { timeout, delay } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
    selector: "app-loading-error",
    templateUrl: "./loading-error.component.html",
    styleUrls: ["./loading-error.component.scss"]
})
export class LoadingErrorComponent {
    @Input()
    public apiError: ApiError;

    @Input()
    public isTimeoutError: boolean;

    @Input()
    public loadingErrorId: string;

    @Input()
    public showShade: boolean;

    @Input()
    public lightMode: boolean;

    @Output()
    public onRetry: EventEmitter<null> = new EventEmitter();

    retry() {
        this.onRetry.emit();
    }
}
