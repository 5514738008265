import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DashboardService } from '../../services';
import { ActionItem } from '../../models';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit {
    public title: string;
    public message: SafeHtml;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ActionItem,
        private dialogRef: MatDialogRef<MessageDialogComponent>,
        private dashboardService: DashboardService,
        private sanitizer: DomSanitizer
    ) {
    }

    cancel() {
        this.dialogRef.close();
    }

    ngOnInit() {
        /* TODO handle timeout */
        this.dashboardService.getMessage(this.data.messageID).subscribe(
            data => {
                this.title = data.title;
                this.message = this.sanitizer.bypassSecurityTrustHtml(data.text);
            },
            _ => {
                this.title = 'Error';
                this.message = `Something went wrong in trying to display this message. Please try again later.`;
            }
        );
    }
}
