<div class="header">
    <svg (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x on-white">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
    <h1 mat-dialog-title class="bold">
        PERFORMANCE REVIEW
    </h1>
</div>
<div mat-dialog-content>
    <form [formGroup]="form" (submit)="submit()">
        <p>Please rate this candidate:</p>
        <div class="rating-wrapper">
            <input formControlName="rating" id="rating-5" type="radio" value="5">
            <label for="rating-5"></label>
            <input formControlName="rating" id="rating-4" type="radio" value="4">
            <label for="rating-4"></label>
            <input formControlName="rating" id="rating-3" type="radio" value="3">
            <label for="rating-3"></label>
            <input formControlName="rating" id="rating-2" type="radio" value="2">
            <label for="rating-2"></label>
            <input formControlName="rating" id="rating-1" type="radio" value="1">
            <label for="rating-1"></label>
        </div>
        <div class="tool-tip">
            <p>Tell us about your experience working with
                <br>
                <strong>{{ data.firstName }} {{ data.lastName }}</strong>:</p>
            <a class="popover-icon">
                <div class="popover-content">
                    <label>Your review will be shared with Creative Circle and displayed to the other contacts who are
                        related to this job, as listed in the job detail.</label>
                </div>
            </a>
            <div class="tip-on-mobile">
                <span (click)="toolTip()">i</span>
            </div>
        </div>
        <div class="text-area-favorite-wrapper">
            <textarea formControlName="feedback" placeholder="Write your Performance Review here..."></textarea>
            <label *ngIf="data.favorited">
                <input type="checkbox" formControlName="favorited"> Favorited
            </label>
            <label *ngIf="!data.favorited">
                <input type="checkbox" formControlName="favorited"> Add Candidate to Favorites
            </label>
        </div>
        <div class="counter">
            <span [class.error]="countMax < 0">Characters remaining: {{countMax}}</span>
        </div>
    </form>
</div>
<div mat-dialog-content>
    <div class="error">
        <p *ngIf="formError" class="form-error">{{ formError }}</p>
    </div>
</div>
<div mat-dialog-actions>
    <button aria-label="Submit" [ngClass]="{'is-submitting': isSubmitting}" [disabled]="isLimitedImpersonation || !form.valid" (click)="submit()">
        <span class="not-submitting">Submit</span>
        <span class="submitting">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
           stroke="currentColor" stroke-width="2"
           stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
        <line x1="12" y1="2" x2="12" y2="6"></line>
        <line x1="12" y1="18" x2="12" y2="22"></line>
        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
        <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
        <line x1="2" y1="12" x2="6" y2="12"></line>
        <line x1="18" y1="12" x2="22" y2="12"></line>
        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
        <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
      </svg>
    </span>
    </button>
</div>
