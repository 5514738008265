<div class="header">
	<svg (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
	     stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x on-white">
	    <line x1="18" y1="6" x2="6" y2="18"></line>
	    <line x1="6" y1="6" x2="18" y2="18"></line>
	</svg>
	<h1 mat-dialog-title>Are you sure you want to change your username?</h1>
</div>
<mat-dialog-content><p>For security purposes, you will be signed out after changing your username and will need to sign
    in again with your password.</p>
    <mat-dialog-actions>
        <button aria-label="Cancel" class="confirm-button-no" mat-button mat-dialog-close>CANCEL</button>
        <button aria-label="Change Username" class="confirm-button-yes" mat-button [mat-dialog-close]="true">CHANGE USERNAME</button>
    </mat-dialog-actions>
