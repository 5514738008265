import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ActivatedRoute, Router } from "@angular/router";
import * as QuillNameSpace from "quill";
import { AlertDialogComponent } from "../../../shared/components/alert-dialog/alert-dialog.component";
import { ConfirmDialogComponent } from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import { Subscribing } from "../../../shared/components/subscribing.component";
import { SysTemplate } from "../../../shared/models/sys-template.model";
import { LbTitle } from "../../../shared/services/title.service";
import { SendTestEmailDialogData } from "../send-test-email-dialog/send-test-email-dialog-data";
import { SendTestEmailDialogComponent } from "../send-test-email-dialog/send-test-email-dialog.component";
import { TemplatesService } from "../templates.service";
import { ILBTemplateConfiguration } from "../models/template-configuration.model";
import { EmailTemplateConfiguration } from "../models/email-template-configuration.model";
import { ApiError } from "../../../shared/models/api-error.model";
import { catchError } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { PaginatedResponse } from "../../../shared/models/paginated-response.model";

let Quill: any = QuillNameSpace;

let QuillEmbed = Quill.import("blots/embed");

class LBVarEmbed extends QuillEmbed {
    static create(paramValue) {
        let node = super.create();
        node.innerText = paramValue;
        return node;
    }

    static value(val: HTMLElement) {
        return val.innerText;
    }
}

LBVarEmbed.blotName = "lbvar";
LBVarEmbed.tagName = "lb-template-var";

Quill.register(LBVarEmbed);

class FormData {
    public Main: string;
    public Subject: string;
    public Text: string;
    public Url: string;
}

@Component({
    selector: "app-edit",
    templateUrl: "./edit.component.html",
    styleUrls: ["./edit.component.scss"]
})
export class EditTemplateComponent extends Subscribing {
    isSubmitting: boolean = false;
    isLoading: boolean = true;
    isValid: boolean = true;
    submitErrorMessage: string = null;
    private isFormChanged: boolean = false;

    templateConfiguration: ILBTemplateConfiguration;

    mainTemplate: SysTemplate;
    subjectTemplate: SysTemplate;
    linkTextTemplate: SysTemplate;
    linkUrlTemplate: SysTemplate;

    formData: FormData = new FormData();

    mainQuill: QuillNameSpace.Quill;
    subjectQuill: QuillNameSpace.Quill;
    textQuill: QuillNameSpace.Quill;
    urlQuill: QuillNameSpace.Quill;

    mainQuillModules = {
        toolbar: {
            container: [
                [{ lbvar: [] }],
                ["bold", "italic", "underline"],
                [{ header: 1 }, { header: 2 }],
                ["link"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["clean"]
            ],
            handlers: {
                lbvar: (value: String) =>
                    this.insertLBVarEmbed(this.mainQuill, value)
            }
        }
    };

    subjectQuillModules = {
        toolbar: {
            container: [[{ lbvar: [] }]],
            handlers: {
                lbvar: (value: String) =>
                    this.insertLBVarEmbed(this.subjectQuill, value)
            }
        }
    };

    textQuillModules = {
        toolbar: {
            container: [[{ lbvar: [] }]],
            handlers: {
                lbvar: (value: String) =>
                    this.insertLBVarEmbed(this.textQuill, value)
            }
        }
    };

    urlQuillModules = {
        toolbar: {
            container: [[{ lbvar: [] }]],
            handlers: {
                lbvar: (value: String) =>
                    this.insertLBVarEmbed(this.urlQuill, value)
            }
        }
    };

    constructor(
        public templateService: TemplatesService,
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private location: Location,
        titleService: LbTitle
    ) {
        super();

        titleService.setTitleFromKeywords(["Edit Template", "Admin"]);

        this.subscriptions.push(
            this.route.params.subscribe(params => {
                let mainTemplateKey = params["mainTemplateKey"];

                this.templateService
                    .getTemplateConfigurations()
                    .subscribe({
                        next: templateConfigurations => {
                        this.templateConfiguration = templateConfigurations.find(
                            tc => tc.MainTemplateKey === mainTemplateKey
                        );

                        if (!this.templateConfiguration)
                            return this.redirectBackToList();

                        this.templateService
                            .getTemplates()
                            .subscribe(
                                this.handleTemplatesResponse(mainTemplateKey),
                                this.handleTemplatesError.bind(this)
                            );
                    },
                    error: this.handleTemplatesError.bind(this)
                });
            })
        );
    }

    private handleTemplatesError(err: ApiError) {
        let errorMessage: string;

        if (err.timeout) {
            errorMessage =
                "A timeout occurred while loading template data. Please try again.";
        } else {
            errorMessage =
                "An error occurred while loading template data. Please try again.";
        }

        const dialog = this.dialog.open(AlertDialogComponent, {
            width: "400px",
            data: {
                title: "ERROR!",
                message: errorMessage,
                buttonText: "Back to List"
            }
        });

        dialog
            .afterClosed()
            .subscribe(() => this.router.navigateByUrl("/admin/templates"));
    }

    private handleTemplatesResponse(
        mainTemplateKey: any
    ): (value: PaginatedResponse<SysTemplate>) => void {
        return (response: PaginatedResponse<SysTemplate>) => {
            this.mainTemplate = response.results.find(
                t => t.templateKey === mainTemplateKey
            );
            if (
                this.templateConfiguration instanceof EmailTemplateConfiguration
            ) {
                let subjectTemplate = response.results.find(
                    t =>
                        t.templateKey ===
                        (<EmailTemplateConfiguration>this.templateConfiguration)
                            .SubjectTemplateKey
                );
                let linkTextTemplate = response.results.find(
                    t =>
                        t.templateKey ===
                        (<EmailTemplateConfiguration>this.templateConfiguration)
                            .LinkTextTemplateKey
                );
                let linkUrlTemplate = response.results.find(
                    t =>
                        t.templateKey ===
                        (<EmailTemplateConfiguration>this.templateConfiguration)
                            .LinkUrlTemplateKey
                );
                subjectTemplate.template =
                    "<p>" + subjectTemplate.template + "</p>";
                linkTextTemplate.template =
                    "<p>" + linkTextTemplate.template + "</p>";
                linkUrlTemplate.template =
                    "<p>" + linkUrlTemplate.template + "</p>";
                this.subjectTemplate = subjectTemplate;
                this.linkTextTemplate = linkTextTemplate;
                this.linkUrlTemplate = linkUrlTemplate;
            }
            if (!this.mainTemplate) {
                return this.redirectBackToList();
            }
            this.formData.Main = this.mainTemplate.template;
            this.formData.Subject =
                this.subjectTemplate && this.subjectTemplate.template;
            this.formData.Text =
                this.linkTextTemplate && this.linkTextTemplate.template;
            this.formData.Url =
                this.linkUrlTemplate && this.linkUrlTemplate.template;
            if (this.templateConfiguration.Variables.length > 0) {
                // add available variables to dropdown
                [
                    this.mainQuillModules,
                    this.subjectQuillModules,
                    this.textQuillModules,
                    this.urlQuillModules
                ].forEach(
                    mods =>
                        (mods.toolbar.container[0][0][
                            "lbvar"
                        ] = this.templateConfiguration.Variables)
                );
            } else {
                // no variables, remove dropdown
                [
                    this.mainQuillModules,
                    this.subjectQuillModules,
                    this.textQuillModules,
                    this.urlQuillModules
                ].forEach(mods => mods.toolbar.container.shift());
            }
            this.isLoading = false;
        };
    }

    sendTest() {
        let dialog = this.dialog.open(SendTestEmailDialogComponent, {
            width: "380px",
            disableClose: true,
            data: new SendTestEmailDialogData(
                this.mainTemplate.templateKey,
                this.removeBomsFromString(
                    this.ensureLbVarTagsLowerCased(
                        this.cleanPlainTextFieldData(this.formData.Subject)
                    )
                ),
                this.removeBomsFromString(
                    this.ensureLbVarTagsLowerCased(this.formData.Main)
                ),
                this.removeBomsFromString(
                    this.ensureLbVarTagsLowerCased(
                        this.cleanPlainTextFieldData(this.formData.Text)
                    )
                ),
                this.removeBomsFromString(
                    this.ensureLbVarTagsLowerCased(
                        this.cleanPlainTextFieldData(this.formData.Url)
                    )
                )
            )
        });
        this.subscriptions.push(
            dialog.afterClosed().subscribe(sent => {
                if (sent === true) {
                    this.dialog.open(AlertDialogComponent, {
                        width: "350px",
                        data: {
                            title: "SUCCESS!",
                            message:
                                "Test email sent. You should receive it in a moment."
                        }
                    });
                } else {
                    let errorMessage: string;
                    if (sent.errors.timeout) {
                        errorMessage =
                            "A timeout occurred while sending the test email. Please try again if you don't receive the email.";
                    } else {
                        errorMessage =
                            "An error occurred while sending the test email. Please try again.";
                    }

                    this.dialog.open(AlertDialogComponent, {
                        width: "350px",
                        data: {
                            title: "ERROR!",
                            message: errorMessage
                        }
                    });
                }
            })
        );
    }

    submit() {
        this.isSubmitting = true;

        let observables = [
            this.templateService.updateTemplate(
                this.mainTemplate.lbSysTemplateID,
                {
                    template: this.removeBomsFromString(
                        this.ensureLbVarTagsLowerCased(this.formData.Main)
                    )
                }
            )
        ];

        if (this.templateConfiguration.Type === "Email") {
            observables.push(
                this.templateService.updateTemplate(
                    this.subjectTemplate.lbSysTemplateID,
                    {
                        template: this.removeBomsFromString(
                            this.ensureLbVarTagsLowerCased(
                                this.cleanPlainTextFieldData(
                                    this.formData.Subject
                                )
                            )
                        )
                    }
                ),
                this.templateService.updateTemplate(
                    this.linkUrlTemplate.lbSysTemplateID,
                    {
                        template: this.removeBomsFromString(
                            this.ensureLbVarTagsLowerCased(
                                this.cleanPlainTextFieldData(this.formData.Url)
                            )
                        )
                    }
                ),
                this.templateService.updateTemplate(
                    this.linkTextTemplate.lbSysTemplateID,
                    {
                        template: this.removeBomsFromString(
                            this.ensureLbVarTagsLowerCased(
                                this.cleanPlainTextFieldData(this.formData.Text)
                            )
                        )
                    }
                )
            );
        }

        const observable = forkJoin(observables).pipe(
            catchError((err: ApiError) => {
                if (err.timeout) {
                    this.submitErrorMessage =
                        "The server did not respond in time while trying to save the template. Please check your connection.";
                } else {
                    this.submitErrorMessage =
                        "An occurred while trying to save the template. Please refresh the page and try again.";
                }

                return of([]);
            })
        );
        this.subscriptions.push(
            observable.subscribe(() => {
                this.isSubmitting = false;

                let dialog = this.dialog.open(AlertDialogComponent, {
                    width: "350px",
                    data: {
                        title: "SUCCESS!",
                        message: "Template updated."
                    }
                });

                dialog
                    .afterClosed()
                    .subscribe(_ =>
                        this.router.navigateByUrl("/admin/templates")
                    );
            })
        );
    }

    cancel() {
        if (this.isFormChanged) {
            let dialog = this.dialog.open(ConfirmDialogComponent, {
                width: "400px",
                data: {
                    title: "Are you sure?",
                    message: "Your changes will be lost",
                    cancelText: "Nevermind"
                }
            });

            dialog.afterClosed().subscribe(res => res && this.location.back());

            return;
        }

        this.location.back();
    }

    onTextEditorChange(field: string, { html }: { [s: string]: string }) {
        this.isFormChanged = true;

        if (field) {
            if (html) {
                html = this.removeBomsFromString(
                    this.ensureLbVarTagsLowerCased(
                        this.cleanPlainTextFieldData(html)
                    )
                );
            }

            this.formData[field] = html;
        }

        this.validate();
    }

    private cleanPlainTextFieldData(str: string) {
        if (str && str.trim()) {
            str = str.replace(/<p>|<\/p>|<br>/g, " ");
            str = str.replace(/\s\s+/g, " ");
            str = str.replace(/\r?\n|\r/g, "");

            str = str.trim();
        }

        return str;
    }

    private removeBomsFromString(str: string) {
        if (str && str.trim()) {
            str = str.replace(/\uFEFF/g, "");
        }

        return str;
    }

    /**
     * Solves for Edge browser capitalizing our tags for some reason
     */
    private ensureLbVarTagsLowerCased(html: string): string {
        if (html && html.trim())
            html = html.replace(/LB-TEMPLATE-VAR/g, "lb-template-var");

        return html;
    }

    private validate() {
        let isValid = true;

        if (!this.formData.Main || !this.formData.Main.trim()) isValid = false;

        if (isValid && this.templateConfiguration.Type === "Email") {
            if (!this.formData.Subject || !this.formData.Subject.trim())
                isValid = false;

            const cleanSubjectFieldData =
                this.formData.Subject &&
                this.removeBomsFromString(
                    this.ensureLbVarTagsLowerCased(
                        this.cleanPlainTextFieldData(this.formData.Subject)
                    )
                ).trim();
            const cleanUrlFieldData =
                this.formData.Url &&
                this.removeBomsFromString(
                    this.ensureLbVarTagsLowerCased(
                        this.cleanPlainTextFieldData(this.formData.Url.trim())
                    )
                );
            const cleanTextFieldData =
                this.formData.Text &&
                this.removeBomsFromString(
                    this.ensureLbVarTagsLowerCased(
                        this.cleanPlainTextFieldData(this.formData.Text)
                    )
                ).trim();

            if (
                !cleanSubjectFieldData ||
                (cleanUrlFieldData && !cleanTextFieldData) ||
                (!cleanUrlFieldData && cleanTextFieldData)
            )
                isValid = false;
        }

        this.isValid = isValid;
    }

    private redirectBackToList() {
        console.error("Main template not found!");
        this.router.navigateByUrl("admin/templates");
    }

    private insertLBVarEmbed(quill: QuillNameSpace.Quill, content) {
        quill.insertEmbed(
            quill.getSelection() && quill.getSelection().index
                ? quill.getSelection().index
                : 0,
            "lbvar",
            "[" + content + "]",
            "user"
        );

        quill.setSelection(
            quill.getSelection() && quill.getSelection().index
                ? quill.getSelection().index + 1
                : 1,
            Quill.sources.silent
        );
    }
}
