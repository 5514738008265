<div class="open-job-orders-container dashboard-section open-searches" [class.active-section]="activeSection">
    <div class="headline-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase">
            <rect x="2" y="7" width="20" height="14" rx="2" ry="2" />
            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
        </svg>
        <p class="category-title">Current Openings</p>
        <span>
            <a routerLink="/jobs">
                <button aria-label="View All" *ngIf="openJobs && openJobs.length > 0" class="view-all-btn">
                    View All
                </button>
            </a>
            <button aria-label="Request Talent" class="empty-btn" (click)="openRequestTalentDialog()">
                REQUEST TALENT
            </button>
        </span>
        <button aria-label="View All" *ngIf="!openJobs" class="view-all-btn-inactive">View All</button>
    </div>
    <div class="open-job-orders-background">
        <div *ngIf="!isLoadingResults && openJobs && openJobs.length > 0" class="filter-container"
            #filter_dropdown_container>
            <div class="filter-by" [class.filter-expanded]="filterDropdownActive"
                (clickOutside)="closeFilterDropdown()">
                <button aria-label="Toggle" class="blue-btn filter-btn" (click)="toggleFilterDropdown()">
                    <svg width="20" height="11" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#131e27" fill-rule="evenodd" opacity=".6">
                            <rect width="20" height="2.2" rx="1.1" />
                            <rect x="3.571" y="4.4" width="12.857" height="2.2" rx="1.1" />
                            <rect x="6.429" y="8.8" width="7.143" height="2.2" rx="1.1" />
                        </g>
                    </svg>
                    <p class="filter-header">
                        FILTER BY
                        <span *ngIf="totalFiltered > 0">({{ totalFiltered }})</span>
                    </p>
                </button>
                <div class="filter-sort-by-dropdown-container">
                    <div class="filter-dropdown" [class.expand]="filterDropdownActive">
                        <div class="radio-btn-container filter-inputs">
                            <div class="column">
                                <p class="section-header">AREA</p>
                                <div *ngFor="let jobArea of jobAreas" (click)="addArea($event)">
                                    <label class="area">
                                        <input type="checkbox"
                                            class="filter-input area-location" id="{{jobArea}}" />
                                        <p>{{ jobArea }}</p>
                                    </label>
                                </div>
                            </div>
                            <p class="clear-all" (click)="clearAllFilters()">CLEAR</p>
                        </div>
                        <hr />
                        <div class="submittals">
                            <p class="section-header">SUBMITTALS</p>
                            <label class="area">
                                <input type="checkbox" class="filter-input only-show-new" (click)="applyFilters()" />
                                <p>Only Show New</p>
                            </label>
                        </div>
                        <hr />
                        <div class="po-number">
                            <p class="section-header">PO NUMBER</p>
                            <div class="area">
                                <input type="text" placeholder="PO Number" class="filter-input-text" name="po-number"
                                    [(ngModel)]="filters.poNumber" (input)="applyFilters()" />
                            </div>
                        </div>
                        <div class="view-all-results">
                            <button (click)="closeFilterDropdown()">VIEW RESULTS</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sort-by" (click)="toggleSortByDropdown()" [class.sort-by-expanded]="sortByDropdownActive"
                (clickOutside)="closeSortByDropdown()">
                <button aria-label="Sort" class="blue-btn filter-btn">
                    <svg width="18" height="20" viewBox="0 0 15 17" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="#131e27" stroke-width="1.8" fill="none" fill-rule="evenodd" opacity=".6"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path d="M9.667 5.286L5.333 1 1 5.286M5.333 6.357V1" />
                            <g>
                                <path d="M5.333 11.714L9.667 16 14 11.714M9.667 10.643V16" />
                            </g>
                        </g>
                    </svg>
                    <p class="filter-header">SORT BY</p>
                </button>

                <div class="filter-sort-by-dropdown-container">
                    <div class="sort-by-dropdown" [class.expand]="sortByDropdownActive">
                        <div class="radio-btn-container">
                            <div class="column sort-by-column">
                                <div class="date-desc area" (click)="sortBy('date-desc')">
                                    <div class="check-container">
                                        <svg [class.active]="activeSort === 'date-desc'"
                                            xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                            viewBox="0 0 24 24" fill="none" stroke="#1C2B39" stroke-width="4"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"
                                            [class.active-sort]="sortBy === 'CandidateFirstName-ASC'">
                                            <polyline points="20 6 9 17 4 12"></polyline>
                                        </svg>
                                    </div>
                                    <p>Date Opened &ndash; New to Old</p>
                                </div>
                                <div class="date-asc area" (click)="sortBy('date-asc')">
                                    <div class="check-container">
                                        <svg [class.active]="activeSort === 'date-asc'"
                                            xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                            viewBox="0 0 24 24" fill="none" stroke="#1C2B39" stroke-width="4"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"
                                            [class.active-sort]="sortBy === 'CandidateFirstName-ASC'">
                                            <polyline points="20 6 9 17 4 12"></polyline>
                                        </svg>
                                    </div>
                                    <p>Date Opened &ndash; Old to New</p>
                                </div>
                                <div class="candidates-asc area" (click)="sortBy('candidates-asc')">
                                    <div class="check-container">
                                        <svg [class.active]="activeSort === 'candidates-asc'"
                                            xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                            viewBox="0 0 24 24" fill="none" stroke="#1C2B39" stroke-width="4"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"
                                            [class.active-sort]="sortBy === 'CandidateFirstName-ASC'">
                                            <polyline points="20 6 9 17 4 12"></polyline>
                                        </svg>
                                    </div>
                                    <p># of Candidates to Review &ndash; Low to High</p>
                                </div>
                                <div class="candidates-desc area" (click)="sortBy('candidates-desc')">
                                    <div class="check-container">
                                        <svg [class.active]="activeSort === 'candidates-desc'"
                                            xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                            viewBox="0 0 24 24" fill="none" stroke="#1C2B39" stroke-width="4"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"
                                            [class.active-sort]="sortBy === 'CandidateFirstName-ASC'">
                                            <polyline points="20 6 9 17 4 12"></polyline>
                                        </svg>
                                    </div>
                                    <p># of Candidates to Review &ndash; High to Low</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div #filter_dropdown_container_mobile [ngClass]="filterDropdownActive ? 'filter-on-mobile show' : 'filter-on-mobile hide'">
            <div class="header-container">
                <span>FILTER</span>
                <button aria-label="Close" (click)="clearAllFilters()" class="close">
                    CLEAR ALL
                </button>
            </div>
            <hr>
            <div class="container-mobile">
                <div class="radio-btn-container filter-inputs">
                    <div class="column">
                        <p class="section-header">AREA</p>
                        <div *ngFor="let jobArea of jobAreas">
                            <label class="area" (click)="addArea($event, true)">
                                <input type="checkbox" class="filter-input area-location-mobile" id="{{jobArea}}"/>
                                <p>{{ jobArea }}</p>
                            </label>
                        </div>
                    </div>
                </div>
                <p class="clear-all" (click)="clearAllFilters()">CLEAR</p>
            </div>
            <hr />
            <div class="container-mobile">
                <div class="submittals column">
                    <p class="section-header">SUBMITTALS</p>
                    <label class="area">
                        <input type="checkbox" class="filter-input only-show-new" (click)="applyFilters(true)" />
                        <p>Only Show New</p>
                    </label>
                </div>
            </div>
            <hr />
            <div class="container-mobile">
                <div class="po-number column">
                    <p class="section-header">PO NUMBER</p>
                    <div class="area">
                        <input type="text" id="po-mobile" placeholder="PO Number" class="filter-input-text"
                            name="po-number-mobile" [(ngModel)]="filters.poNumber" (input)="applyFilters()" />
                    </div>
                </div>
            </div>
            <div class="view-all-results">
                <button (click)="closeFilterOnMobile()">VIEW RESULTS</button>
            </div>
        </div>

        <div *ngIf="sortByDropdownActive" class="sort-on-mobile">
            <div class="header-flex">
                <span>SORT</span>
                <button aria-label="Close" (click)="closeSortByDropdown()" class="close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </button>
            </div>
            <hr class="sort-hr">
            <div class="radio-btn-container filter-inputs">
                <div class="column sort-by-column">
                    <div class="date-desc area sort-area" (click)="sortBy('date-desc')">
                        <div class="check-container">
                            <svg [class.active]="activeSort === 'date-desc'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                fill="none" stroke="#b3e1ea" stroke-width="4" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-check"
                                [class.active-sort]="sortBy === 'CandidateFirstName-ASC'">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </div>
                        <p>Date Opened - New to Old</p>
                    </div>
                    <div class="date-asc area sort-area" (click)="sortBy('date-asc')">
                        <div class="check-container">
                            <svg [class.active]="activeSort === 'date-asc'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                fill="none" stroke="#b3e1ea" stroke-width="4" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-check"
                                [class.active-sort]="sortBy === 'CandidateFirstName-ASC'">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </div>
                        <p>Date Opened - Old to New</p>
                    </div>
                    <div class="candidates-asc area sort-area" (click)="sortBy('candidates-asc')">
                        <div class="check-container">
                            <svg [class.active]="activeSort === 'candidates-asc'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                fill="none" stroke="#b3e1ea" stroke-width="4" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-check"
                                [class.active-sort]="sortBy === 'CandidateFirstName-ASC'">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </div>
                        <p># of Candidates to Review &ndash; Low to High</p>
                    </div>
                    <div class="candidates-desc area sort-area" (click)="sortBy('candidates-desc')">
                        <div class="check-container">
                            <svg [class.active]="activeSort === 'candidates-desc'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                fill="none" stroke="#b3e1ea" stroke-width="4" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-check"
                                [class.active-sort]="sortBy === 'CandidateFirstName-ASC'">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </div>
                        <p># of Candidates to Review &ndash; High to Low</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="loading-shade" *ngIf="isLoadingResults">
            <svg class="feather feather-loader" xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round">
                <path
                    d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83" />
            </svg>
        </div>

        <app-loading-error [apiError]="loadingError" (onRetry)="loadJobs()"></app-loading-error>

        <div *ngIf="!loadingError && !isLoadingResults && openJobs && openJobs.length == 0" class="empty-state">
            <p>You have no current openings listed with us.</p>
            <p>Get started with a talent request.</p>
            <button aria-label="Request Talent" class="no-action-btn" (click)="openRequestTalentDialog()">REQUEST
                TALENT</button>
        </div>

        <div *ngIf="!isLoadingResults && filteredOpenJobs && filteredOpenJobs.length == 0 && openJobs && openJobs.length > 0"
            class="no-filtered-results">
            <p>There are no results based on the filters you have selected.</p>
        </div>

        <ng-container *ngIf="!isLoadingResults && !loadingError">
            <div *ngFor="let job of filteredOpenJobs; let i = index">
                <app-job-order-card class="job-card-dashboard"
                    [class.opacity-change]="filterDropdownActive || sortByDropdownActive" [dashboardView]="'true'"
                    [job]="job" (click)="sortByDropdownActive || (windowWidth <= 1044 && routeToJob(job))">
                </app-job-order-card>
            </div>
            <div class="show-more-container">
                <button *ngIf="currentPage < pages" [ngClass]="{'button': true, 'disabled invalid-btn': currentPage >= pages}" (click)="showMoreItems()" [disabled]="currentPage >= pages">Show More</button>
                <p *ngIf="currentPage >= pages">Showing All Current Openings</p>
            </div>
        </ng-container>
    </div>
</div>
