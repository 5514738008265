import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertDialogComponent } from "../../../shared/components/alert-dialog/alert-dialog.component";
import { ConfirmDialogComponent } from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import { Subscribing } from "../../../shared/components/subscribing.component";
import { ApiError } from "../../../shared/models/api-error.model";
import { LbTitle } from "../../../shared/services/title.service";
import { MessagesService } from "../messages.service";
import * as QuillNameSpace from "quill";

let Quill: any = QuillNameSpace;

@Component({
    templateUrl: "./form.component.html",
    styleUrls: ["./form.component.scss"]
})
export class EditComponent extends Subscribing implements OnInit {
    createForm: UntypedFormGroup;
    isSubmitting: boolean = false;
    currDate: Date = new Date();
    messageID: number;

    public get title(): AbstractControl {
        return this.createForm.get("title");
    }

    public get message(): AbstractControl {
        return this.createForm.get("message");
    }

    public get endDate(): AbstractControl {
        return this.createForm.get("endDate");
    }

    public action = "Edit";
    private formChanged: boolean = false;

    quill: QuillNameSpace.Quill;
    quillModules = {
        toolbar: {
            container: [
                ["bold", "italic", "underline"],
                [{ 'align': [] }],
                [{ header: 1 }, { header: 2 }],
                ["link"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ 'color': [] }, { 'background': [] }],
                ["clean"]
            ]
        }
    };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messagesService: MessagesService,
        private fb: UntypedFormBuilder,
        private dialog: MatDialog,
        private location: Location,
        titleService: LbTitle
    ) {
        super();

        titleService.setTitleFromKeywords(["Edit Message", "Admin"]);

        this.subscriptions.push(
            this.route.params.subscribe(params => {
                let msg;

                if (
                    this.messagesService.currentMessages &&
                    this.messagesService.currentMessages.length
                ) {
                    msg = this.messagesService.currentMessages.find(
                        msg => msg.messageID == params.messageID
                    );
                }

                if (!msg) {
                    this.router.navigateByUrl("admin/messages");
                    return;
                }

                this.messageID = msg.messageID;

                let timeString =
                    this.getPaddedTimeString(msg.endDate.getHours()) +
                    ":" +
                    this.getPaddedTimeString(msg.endDate.getMinutes());

                this.createForm = this.fb.group({
                    title: [
                        msg.title,
                        Validators.compose([
                            Validators.required,
                            Validators.maxLength(100)
                        ])
                    ],
                    message: [
                        msg.message,
                        Validators.compose([
                            Validators.required,
                            Validators.maxLength(4000)
                        ])
                    ],
                    endDate: [
                        new Date(msg.endDate),
                        Validators.compose([Validators.required])
                    ],
                    endTime: [
                        timeString,
                        Validators.compose([Validators.required])
                    ]
                });

                this.createForm.valueChanges.subscribe(
                    _ => (this.formChanged = true)
                );
            })
        );
    }

    ngOnInit() {
        this.createForm.get("endTime").valueChanges.subscribe(val => {
            let match = val.match(/(\d{2}):(\d{2})/);
            if (match && match.length == 3) {
                let currDate: Date = this.endDate.value;

                currDate.setHours(Number(match[1]));
                currDate.setMinutes(Number(match[2]));
            }
        });
    }

    submitForm() {
        this.isSubmitting = true;
        this.subscriptions.push(
            this.messagesService
                .editMessage(this.messageID, this.createForm.value)
                .subscribe({
                    next: () => {
                        this.isSubmitting = false;

                        let dialog = this.dialog.open(AlertDialogComponent, {
                            width: "300px",
                            data: {
                                title: "SUCCESS!",
                                message: "Message saved successfully."
                            }
                        });

                        dialog
                            .afterClosed()
                            .subscribe(_ =>
                                this.router.navigateByUrl("/admin/messages")
                            );
                    },
                    error: (err: ApiError) => {
                        this.isSubmitting = false;

                        if (err.statusCode === 400) {
                            for (let field in err.errors) {
                                this.createForm
                                    .get(field)
                                    .setErrors(err.errors[field]);
                            }
                        } else if (err.statusCode === 500) {
                            this.createForm.setErrors({ serverError: true });
                        } else if (err.timeout) {
                            this.createForm.setErrors({ timeout: true });
                        }
                    }
                })
        );
    }

    public cancel() {
        if (this.formChanged) {
            let dialog = this.dialog.open(ConfirmDialogComponent, {
                width: "400px",
                data: {
                    title: "Are you sure?",
                    message: "Your changes will be lost",
                    cancelText: "Nevermind"
                }
            });

            dialog.afterClosed().subscribe(res => res && this.location.back());

            return;
        }

        this.location.back();
    }

    private getPaddedTimeString(num: number): string {
        return String("00" + num).substr(-2);
    }
}
