import { Component, OnInit, Input } from '@angular/core';
import {
    CandidateDetail,
} from './../../../shared';
import { Router } from '@angular/router';

@Component({
    selector: 'app-candidate-details-name',
    templateUrl: './candidate-details-name.component.html',
    styleUrls: ['./candidate-details-name.component.scss']
})
export class CandidateDetailsNameComponent implements OnInit {

    @Input()
    candidate: CandidateDetail;

    @Input()
    doNotUse: boolean = false;

    @Input()
    public: boolean = false;

    constructor(
        private router: Router,
    ) {}

    ngOnInit() {
    }

    closeCandidateDetails() {
        this.router.navigate([
            'jobs',
            {
                officeId: this.candidate.officeId,
                jobId: this.candidate.jobId
            }
        ]);
    }

}
