import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Message, MESSAGE_TYPE_ERROR } from "../models/message.model";
import { MaintenanceService } from "../services/maintenance.service";
import { MessageService } from "../services/message.service";
import { UserService } from "../services/user.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private messageService: MessageService,
        private userService: UserService,
        private maintenanceService: MaintenanceService
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                event => {
                    return {};
                },
                err => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 503) {
                            this.userService.purgeAuth();
                            this.maintenanceService.updateBoolean(true);
                            this.router.navigateByUrl("/home");
                        } else if (err.status === 401 || err.status === 403) {
                            if (this.router.url !== "/") {
                                // ignore on login page
                                this.logOutAndShowError(
                                    "Please log in to continue."
                                );
                            }
                        } else if (err.status === 431) {
                            this.logOutAndShowError(
                                "An error has occurred. Please log in to continue."
                            );
                        } else if (
                            err.status === 400 &&
                            err.error.search &&
                            err.error.search(
                                /Bad Request - Header Field Too Long/
                            )
                        ) {
                            this.userService.purgeAuth();
                            window.location.href = "/?error=An error has occurred. Please log in to continue."
                        }
                    }
                }
            )
        );
    }

    private logOutAndShowError(err: string) {
        this.messageService.addMessage(new Message(MESSAGE_TYPE_ERROR, err));
        this.userService.purgeAuth();
        this.router.navigateByUrl("/home");
    }
}
