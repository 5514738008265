import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../shared/services/analytics.service';

@Component({
    selector: 'app-layout-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    constructor(
        private analyticsService: AnalyticsService
    ) {
    }

    ngOnInit() {
    }

    linkOutClick(text, url) {
        this.analyticsService.fireEvent(
            'Outbound Link Click',
            'Footer : ' + text,
            url
        );
    }

}
