import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-looking-for-dialog',
    templateUrl: './looking-for-dialog.component.html',
    styleUrls: ['./looking-for-dialog.component.scss']
})
export class LookingForDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA)
                public data: any,
                private dialogRef: MatDialogRef<LookingForDialogComponent>,
                private dialog: MatDialog) {
    }

    cancel() {
        this.dialogRef.close();
    }

    ngOnInit() {
    }

}
