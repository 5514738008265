import { Component, OnInit } from '@angular/core';

import { DashboardService, JobService } from '../../shared';
import { JOB_STATUS_OPEN } from '../../shared/services/job-orders.service';

@Component({
    selector: 'app-side-navigation-bar',
    templateUrl: './side-navigation-bar.component.html',
    styleUrls: ['./side-navigation-bar.component.scss']
})
export class SideNavigationBarComponent implements OnInit {
    public numActionsItems: number = null;
    public numberOfNewSubmittals: number = null;

    constructor(
        private dashboardService: DashboardService,
        private jobOrdersService: JobService
    ) {
    }

    ngOnInit() {
        this.jobOrdersService.jobs.subscribe(jobs => {
            this.numberOfNewSubmittals = jobs
                .filter(job => {
                    return job.status.toUpperCase() === JOB_STATUS_OPEN;
                })
                .reduce(
                    (accumulator, currentValue) =>
                        accumulator + currentValue.numNewSubmittals,
                    0
                );
        });

        this.dashboardService.actionItemsCount.subscribe(count => {
            this.numActionsItems = count
        })
    }
}
