export const environment = {
    production: true,
    api_url: 'https://lightboxuat.creativecircle.com/api/v1',
    struct_api_url: '/api/genep',
    struct_api_env: 'Uat',
    ga_id: 'UA-120899165-2',
    request_talent_url: 'http://uat.creativecircle.com/clients/request-talent/?lightbox=true&utm_source=cv&utm_medium=lightbox&utm_campaign=LB_RT',
    help_url: 'https://test-cc-web.pantheonsite.io/#lightbox-help-section',
    timecard_login_url: 'https://testcreativec.backofficeportal.com/Login/Login.aspx',
    version_number: '2.0.0'
};
