import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators, Form, UntypedFormGroup } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ApiService } from "../../services/api.service";
import { CandidatesService } from "../../services/candidates.service";
import { JobOrderCandidate } from "../../models/job-order-candidate.model";
import { ApiError } from "../../models/api-error.model";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { EMAIL_REGEX } from "../../validators/email";
import { AnalyticsService } from "../../../shared/services/analytics.service";
import { UserService } from '../../services';
import { NetworkErrorSnackbarService } from "../../../shared/services/network-error-snackbar.service";

export const CLOSE_SUCCESS = "SUCCESS";

@Component({
    selector: "app-share-dialog",
    templateUrl: "./share-candidate-dialog.component.html",
    styleUrls: ["./share-candidate-dialog.component.scss"]
})
export class ShareCandidateDialogComponent {
    public form: UntypedFormGroup;
    public isSubmitting: boolean = false;
    public formError: string;
    public showToolTip: boolean = false;
    public isLimitedImpersonation: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<ShareCandidateDialogComponent>,
        private candidatesService: CandidatesService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
        private userService: UserService
    ) {
        this.form = this.fb.group({
            email: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.pattern(EMAIL_REGEX)
                ])
            ],
            comment: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(1)
                ])
            ],
            firstName: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(1)
                ])
            ],
            lastName: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(1)
                ])
            ],
            candidateId: [
                this.data.candidateId,
                Validators.compose([Validators.required])
            ],
            officeId: [
                this.data.officeId,
                Validators.compose([Validators.required])
            ]
        });

        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    displayToolTipText() {
        this.showToolTip = !this.showToolTip;
    }

    submit() {
        if (this.form.valid && !this.isSubmitting) {
            this.isSubmitting = true;
            let params = {
                firstName: this.form.value["firstName"],
                lastName: this.form.value["lastName"],
                email: this.form.value["email"],
                comment: this.form.value["comment"]
            };

            let officeId = this.form.value["officeId"];
            let candidateId = this.form.value["candidateId"];

            this.candidatesService
                .postShareCandidate(officeId, candidateId, params)
                .subscribe(/* TODO handle timeout */
                    _ => this.dialogRef.close(CLOSE_SUCCESS),
                    (err: ApiError) => {
                        this.isSubmitting = false;
                        if (err.statusCode === 400) {
                            this.formError =
                                "Error! Please close the dialog and try again.";
                        } else {
                            this.networkErrorSnackbarService.networkError(err,'')
                        }
                    }
                );
        }
    }
}
