import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DashboardService, JobDetails, JobOrderCandidate, JOB_STATUS_CLOSED } from '../../../../shared';
import { CLOSE_SUCCESS, ExtensionDialogComponent } from '../../../../shared/components/extension-dialog/extension-dialog.component';
import { AlertDialogComponent } from '../../../../shared/components/alert-dialog/alert-dialog.component';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BRCDialogComponent } from '../../../../shared/components/brc-dialog/brc-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from "../../../../shared";
import { PerfReviewDialogComponent, PerfReviewDialogData } from '../../../../shared/components/perf-review-dialog/perf-review-dialog.component';
import { ApiError } from '../../../../shared/models/api-error.model';
import { NetworkErrorSnackbarService } from '../../../../shared/services/network-error-snackbar.service';

@Component({
    selector: 'app-job-order-details-additional-details',
    templateUrl: './job-order-details-additional-details.component.html',
    styleUrls: ['./job-order-details-additional-details.component.scss']
})
export class JobOrderDetailsAdditionalDetailsComponent implements OnInit, OnChanges {

    @Input() jobOrderDetails: JobDetails;
    public isLimitedImpersonation: boolean = false;
    public activeCandidate: JobOrderCandidate = null;

    constructor(private dialog: MatDialog,
        private analyticsService: AnalyticsService,
        private sanitizationService: DomSanitizer,
        private userService: UserService,
        private dashboardService: DashboardService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
    ) {}

    ngOnInit() {
        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.activeCandidate = null;
        const activeCandidateList = this.jobOrderDetails.candidates.filter(candidate => candidate.statusId === 8);
        if (activeCandidateList.length === 1) {
            this.activeCandidate = activeCandidateList[0];
        }
    }

    adjustForTimezone(date:Date):Date{
        let timeOffsetInMS:number = date.getTimezoneOffset() * 60000;
        date.setTime(date.getTime() + timeOffsetInMS);
        return date
    }

    openBRCDialog() {
        let result = false;
        if (this.jobOrderDetails.brcStatus === 'Open') {
            result = true;
        }
        this.dialog.open(BRCDialogComponent, {
            width: '90vw',
            height: '90vh',
            maxWidth: '90vw',
            maxHeight: '90vh',
            data: {
                showAlert: result,
                brcLink: this.sanitizationService.bypassSecurityTrustResourceUrl(this.jobOrderDetails.brcLink)
            }
        });
        this.analyticsService.fireEvent('Jobs', 'View Bill Rate Click', this.jobOrderDetails.status, {'dimension5': this.jobOrderDetails.jobId.toString() + "." + this.jobOrderDetails.officeId.toString(), 'dimension3' : this.jobOrderDetails.clientId.toString() + "." + this.jobOrderDetails.officeId.toString()});
    }

    adjust() {
        this.analyticsService.fireEvent('Job Candidates', 'Adjust End Date', 'Click', {
            'dimension6': this.activeCandidate.jobCandidateId,
            'dimension5': this.jobOrderDetails.jobId.toString() + "." + this.jobOrderDetails.officeId.toString(),
            'dimension3': this.jobOrderDetails.clientId.toString() + '.' + this.jobOrderDetails.officeId.toString()
        });

        const dialog = this.dialog.open(ExtensionDialogComponent, {
            width: '600px',
            autoFocus: false,
            panelClass: ['mobileContainer', 'extendDialog'],
            data: {
                jobId: this.activeCandidate.jobId,
                officeId: this.activeCandidate.officeId,
                candidate: this.activeCandidate,
                clientId: this.jobOrderDetails.clientId,
                currentDate: this.adjustForTimezone(new Date(this.jobOrderDetails.dateEnd))
            }
        });

        dialog.afterClosed().subscribe(result => {
            if (result === CLOSE_SUCCESS) {
                this.dialog.open(AlertDialogComponent, {
                    width: '400px',
                    data: {
                        title: 'Thanks for the update.',
                        message:
                            'We are working on confirming the End Date change and will be back in touch soon.'
                    }
                });
            }
        });
    }

    openPerformanceReviewDialog(joc: JobOrderCandidate) {
        this.analyticsService.fireEvent(
            "Job Candidates",
            "Write Review Clicked",
            "",
            {
                dimension6: joc.jobCandidateId,
                dimension3:
                    this.jobOrderDetails.clientId.toString() + "." + joc.officeId.toString(),
                dimension5: joc.jobId.toString() + "." + joc.officeId.toString()
            }
        );

        let dialog = this.dialog.open(PerfReviewDialogComponent, {
            width: "600px",
            maxWidth: "90%",
            maxHeight: "90%",
            panelClass: "mobileContainer",
            autoFocus: false,
            data: new PerfReviewDialogData(
                joc.officeId,
                joc.jobId,
                joc.candidateId,
                joc.firstName,
                joc.lastName,
                joc.favorited
            )
        });

        dialog.afterClosed().subscribe(result => {
            if (result === "SUCCESS") {
                this.analyticsService.fireEvent(
                    "Job Candidates",
                    "Review Submitted",
                    "",
                    {
                        dimension6: joc.jobCandidateId,
                        dimension3:
                        this.jobOrderDetails.clientId.toString() +
                            "." +
                            joc.officeId.toString(),
                        dimension5:
                            joc.jobId.toString() + "." + joc.officeId.toString()
                    }
                );

                this.dialog.open(AlertDialogComponent, {
                    width: "350px",
                    data: {
                        title: "Thanks!",
                        message: "Your review has been submitted."
                    }
                });
            }
        });
    }

    /**
     * Determines if a job is a full-time placement
     *
     * @param jobOrderDetails JobDetails The details of the job
     *
     * @return bool Whether the job is a full-time placement or not
     */
    isFullTime(jobOrderDetails: JobDetails) {
        return jobOrderDetails.status === JOB_STATUS_CLOSED && jobOrderDetails.placementType === 'Full Time';
    }

    favoritedCandidate(candidateId, officeId, currentStatus) {
        let action = currentStatus ? false : true;
        let params = {favorited: action};

        this.dashboardService
            .updateJobCandidatePreference(officeId, this.jobOrderDetails.jobId, candidateId, params)
            .subscribe(data => {
                    this.activeCandidate.favorited = action;
                },
                (err: ApiError) => {
                    this.networkErrorSnackbarService.networkError(err,'')
                }
            );

        if (params.favorited) {
            this.analyticsService.fireEvent('Job Candidates', 'Favorited');
        } else {
            this.analyticsService.fireEvent('Job Candidates', 'Unfavorited');
        }
    }
}
