import { ILBTemplateConfiguration } from "./template-configuration.model";
import { SafeHtml } from '@angular/platform-browser';

export class EmailTemplateConfiguration implements ILBTemplateConfiguration {
    public Type: string = "Email";

    constructor(
        public DisplayName: string,
        public MainTemplateKey: string,
        public SubjectTemplateKey: string,
        public LinkUrlTemplateKey: string,
        public LinkTextTemplateKey: string,
        public Variables: Array<string>,
        public Subject: SafeHtml = null,
        public Updated: Date = null,
        public UpdatedBy: string = null
    ) {}
}
