import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService, ROLE_ADMIN, ROLE_INTERNAL } from '../../shared';
import { take, map } from 'rxjs/operators';

@Injectable()
export class NoAuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private userService: UserService
    ) {
    }

    canActivate = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => this.getObservable();
    canActivateChild = (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => this.getObservable();

    private getObservable(): Observable<boolean> {
        return this.userService.currentUserType.pipe(take(1), map(userType => {
            if (!userType) {
                return true;
            }

            if (userType === ROLE_ADMIN || userType === ROLE_INTERNAL) {
                this.router.navigateByUrl('/admin');
            } else {
                this.router.navigateByUrl('/dashboard');
            }

            return false;
        }));
    }
}
