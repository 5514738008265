<div class="container">
    <button aria-label="Close" type="button" class="close" (click)="cancel()">
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
    </button>
    <div>
        <p class="title">{{ title }}</p>
        <p class="description">{{ description }}</p>
        <a href="{{ data.document.url }}" target="_blank" rel="noopener">
            <button aria-label="Launch" class="launch">LAUNCH</button>
        </a>
    </div>
</div>
