import { Component, OnInit, Input } from '@angular/core';
import { JobDetails } from '../../../../shared';

@Component({
    selector: 'app-job-order-details-team',
    templateUrl: './job-order-details-team.component.html',
    styleUrls: ['./job-order-details-team.component.scss']
})
export class JobOrderDetailsTeamComponent implements OnInit {

    @Input() jobOrderDetails: JobDetails;

    constructor() {
    }

    ngOnInit() {
    }

}
