import { Injectable } from '@angular/core';

@Injectable()
export class JwtService {
    getToken(): string {
        return localStorage.getItem('jwtToken');
    }

    saveToken(token: string): string {
        localStorage.setItem('jwtToken', token);

        return token;
    }

    getPreviousToken(): string {
        return localStorage.getItem('prevJwtToken');
    }

    savePreviousToken(token: string): string {
        localStorage.setItem('prevJwtToken', token);

        return token;
    }

    destroyPreviousToken() {
        localStorage.removeItem('prevJwtToken');
    }

    destroyTokens() {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('prevJwtToken');
    }
}
