<div>
    <app-maintenance *ngIf="inMaintenanceMode"></app-maintenance>

    <ng-container *ngIf="!inMaintenanceMode">
        <app-layout-header *ngIf="!loggedIn"></app-layout-header>
        <app-fixed-navigation-bar *ngIf="loggedIn"></app-fixed-navigation-bar>
        <router-outlet></router-outlet>
        <app-side-navigation-bar *ngIf="loggedIn"></app-side-navigation-bar>
        <app-layout-footer *ngIf="loggedIn" class="global-footer"></app-layout-footer>
    </ng-container>
</div>