import { Component } from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    FormGroupDirective,
    Validators
} from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { UserService } from "../../shared";
import { AlertDialogComponent } from "../../shared/components/alert-dialog/alert-dialog.component";
import { ApiError } from "../../shared/models/api-error.model";
import { LbTitle } from "../../shared/services/title.service";
import { EMAIL_REGEX } from "../../shared/validators/email";

@Component({
    selector: "app-forgot-password",
    templateUrl: "./forgot-password.component.html",
    styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent {
    forgotPasswordForm: UntypedFormGroup;
    isSubmitting: boolean = false;
    public requestTalentUrl: string = environment.request_talent_url;
    public timecardLoginUrl: string = environment.timecard_login_url;

    public get email(): AbstractControl {
        return this.forgotPasswordForm.get("email");
    }

    constructor(
        private router: Router,
        private userService: UserService,
        private fb: UntypedFormBuilder,
        private dialog: MatDialog,
        private titleService: LbTitle
    ) {
        this.titleService.setTitleFromKeywords("Forgot Password");

        this.forgotPasswordForm = this.fb.group({
            email: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.pattern(EMAIL_REGEX)
                ])
            ]
        });
    }

    submitForm(f: FormGroupDirective) {
        if (this.isSubmitting) return;

        this.isSubmitting = true;
                        // TODO handle timeout
                        this.userService.passwordReset(this.forgotPasswordForm.value).subscribe(
            data => {
                this.isSubmitting = false;

                this.dialog
                    .open(AlertDialogComponent, {
                        panelClass: "forgot-password-dialog",
                        data: {
                            lineOne: "Thanks for using Lightbox!",
                            lineTwo: "We have sent an email to",
                            lineThree: this.email.value,
                            lineFour: " with next steps."
                        }
                    })
                    .afterClosed()
                    .subscribe(_ => this.router.navigateByUrl("/"));

                f.resetForm();

                return data;
            },
            (err: ApiError) => {
                this.isSubmitting = false;
                if (err.statusCode === 400) {
                    for (let field in err.errors) {
                        this.forgotPasswordForm
                            .get(field)
                            .setErrors(err.errors[field]);
                    }
                } else {
                    this.dialog.open(AlertDialogComponent, {
                        width: "400px",
                        data: {
                            title: "Error",
                            message:
                                "An unknown error has occurred. Please check your information and try again."
                        }
                    });
                }
            }
        );
    }
}
