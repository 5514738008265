import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { environment } from "../../../environments/environment";

interface UserProps {
    userId: number;
    territoryId: number;
    userType: string;
}

@Injectable()
export class AnalyticsService {
    private ga: any;

    constructor(private location: Location) {
        const w = <any>window;

        // init google analytics
        w.ga =
            w.ga ||
            function() {
                (w.ga.q = w.ga.q || []).push(arguments);
            };

        w.ga.l = +new Date();

        this.ga = w.ga;
        this.ga.l = +new Date();
        this.ga("create", environment.ga_id, "none");
    }

    public setUserId(userId: number) {
        this.ga("set", "dimension1", userId);
    }

    public setUserGlobal(userId: number) {
        this.ga('set', 'userId', userId);
    }

    public isImpersonation(bool: boolean) {
        this.ga("set", "dimension4", bool);
    }

    public setUserType(userType: string) {
        this.ga("set", "dimension2", userType);
    }

    public trackPage() {
        this.ga("send", "pageview", this.location.path(false));
    }

    public fireEvent(
        category: string,
        action: string,
        label: string = null,
        dimensions: object = {}
    ): void {
        this.ga("send", "event", category, action, label, dimensions);
    }
}
