<div class="links-and-documents-row">
  <div class="header-font">
    <div class="title">
      <p>Links & Documents</p>
    </div>
    <div class="counter" *ngIf="candidate.files && (candidate.files.length > 2 || (candidate.files.length > 1 && enableArrows))">
      <button aria-label="Scroll Forward" class="view-all-btn links-pagination" [class.disable]="invalidForward" (click)="scrollContainer('forward')">
        <span class='forward-chevron'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                 stroke="#000000"
                 stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </span>
      </button>
      <button aria-label="Scroll Backwards" class="view-all-btn links-pagination" [class.disable]="invalidBack" (click)="scrollContainer('back')">
        <span class='back-chevron'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                 stroke="#000000"
                 stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left">
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          </span>
      </button>
    </div>
  </div>
  <div class="no-files" *ngIf="candidate.files && candidate.files.length == 0">
    <p>There are currently no files for this candidate.</p>
  </div>
  <div #document_container>
    <div *ngIf="candidate.files && candidate.files.length > 0" class="documents-container scroll-container horizontal-scrollbar">
      <div *ngFor="let document of sortedFiles">
        <div class="document" (click)="registerClick(document)" [class.resume]="document.type == 'RESUME'" [class.portfolio]="document.type == 'PORTFOLIO'">
          <div class="action-header">
            <div *ngIf="!previewDocument(document)" class="title">
              <a href="{{document.url}}" target="_blank" rel="noopener">
                <p *ngIf="document.label">{{document.label}}</p>
                <p *ngIf="!document.label">{{determineType(document.type)}}</p>
              </a>
            </div>
            <div *ngIf="previewDocument(document)" class="title" (click)="openDocumentPreviewDialog(document)">
              <p *ngIf="document.label">{{document.label}}</p>
              <p *ngIf="!document.label">{{determineType(document.type)}}</p>
            </div>
            <div class="action">
              <a href="{{document.url}}" target="_blank" *ngIf="document.fileType == 'DOWNLOAD'" rel="noopener">DOWNLOAD
                <svg *ngIf="document.fileType == 'DOWNLOAD'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline points="7 10 12 15 17 10"></polyline>
                <line x1="12" y1="15" x2="12" y2="3"></line>
              </svg>
              </a>
              <a href="{{document.url}}" target="_blank" rel="noopener"
                *ngIf="document.fileType == 'LINK' && document.type != 'R'">LAUNCH
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up-right">
                <line x1="7" y1="17" x2="17" y2="7"></line>
                <polyline points="7 7 17 7 17 17"></polyline>
                </svg>
              </a>
              <span *ngIf="document.type == 'R'" (click)="openDocumentPreviewDialog(document)">LAUNCH
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up-right">
                <line x1="7" y1="17" x2="17" y2="7"></line>
                <polyline points="7 7 17 7 17 17"></polyline>
                </svg>
              </span>
            </div>
          </div>
          <div *ngIf="!previewDocument(document)" class="action-body">
            <div *ngIf="!document.description">
              <a href="{{document.url}}" target="_blank">
                  <p class="title">View {{determineType(document.type)}}</p>
              </a>
            </div>

            <div *ngIf="document.description && document.description.length < 149">
              <a href="{{document.url}}" class="hover" target="_blank" rel="noopener">
                  <p class="title">View {{determineType(document.type)}}</p>
                  <p class="body">{{document.description}}</p>
              </a>
            </div>

            <div *ngIf="document.description && document.description.length > 149">
              <a href="{{document.url}}" target="_blank" rel="noopener">
                  <p class="title">View {{determineType(document.type)}}</p>
              </a>
              <p class="body">{{document.description | truncatetext: 150 }} <span (click)="openLinkPreview(document)" class="read-more">MORE</span></p>
            </div>


          </div>
          <div *ngIf="previewDocument(document)" class="action-body" (click)="openDocumentPreviewDialog(document)">
            <p class="title">View {{determineType(document.type)}}</p>
            <p class="body hover" *ngIf="document.description">{{document.description | truncatetext: 150 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
