export const MESSAGE_TYPE_ERROR = 'danger';
export const MESSAGE_TYPE_INFO = 'info';
export const MESSAGE_TYPE_SUCCESS = 'success';

export class Message {
    type: string;
    message: string;

    constructor(_type: string, _message: string) {
        this.type = _type;
        this.message = _message;
    }
}