import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AlertDialogComponent } from "./../../../shared/components/alert-dialog/alert-dialog.component";

@Component({
    selector: 'app-brc-dialog',
    templateUrl: './brc-dialog.component.html',
    styleUrls: ['./brc-dialog.component.scss']
})
export class BRCDialogComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) 
        public  data: any,
        private dialogRef: MatDialogRef<BRCDialogComponent>,
        private dialog: MatDialog,
    ) {
    }

    ngOnInit() {
    }

    close() {
        if (this.data.showAlert) {
            let dialog = this.dialog.open(AlertDialogComponent, {
                width: "500px",
                panelClass: 'brcAlert',
                data: {
                    title: "Please Note: If you just accepted (or declined) a bill rate or fee, it may take a few minutes for this update to be reflected in Lightbox."
                }
            });

            dialog.afterClosed().subscribe(result => {
                if (result == true) {
                    this.dialogRef.close();
                }
            });

        } else {
           this.dialogRef.close();     
        }
    }
}
