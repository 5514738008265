import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CandidateDetailsStatusComponent } from "./candidate-details-status/candidate-details-status.component";
import { CandidateDetailsAboutComponent } from "./candidate-details-about/candidate-details-about.component";
import { CandidateDetailsNameComponent } from "./candidate-details-name/candidate-details-name.component";
import { CandidateDetailsActionComponent } from "./candidate-details-action/candidate-details-action.component";
import { CandidateDetailsLinksAndDocsComponent } from "./candidate-details-links-and-docs/candidate-details-links-and-docs.component";
import { CandidateDetailsNotesComponent } from "./candidate-details-notes/candidate-details-notes.component";
import { CandidateDetailsFooterComponent } from "./candidate-details-footer/candidate-details-footer.component";
import { CandidateDetailsWorkHistoryComponent } from "./candidate-details-work-history/candidate-details-work-history.component";
import { CandidateDetailsModalHeaderComponent } from "./candidate-details-modal-header/candidate-details-modal-header.component";
import { CandidateDetailsTabInformationComponent } from "./candidate-details-tab-information/candidate-details-tab-information.component";
import { CandidateDetailsTabContactComponent } from "./candidate-details-tab-information/candidate-details-tab-contact/candidate-details-tab-contact.component";
import { CandidateDetailsTabSubmittedComponent } from "./candidate-details-tab-information/candidate-details-tab-submitted/candidate-details-tab-submitted.component";
import { CandidateDetailsTabInterviewsComponent } from "./candidate-details-tab-information/candidate-details-tab-interviews/candidate-details-tab-interviews.component";
import { CandidateDetailsTabEmailComponent } from "./candidate-details-tab-information/candidate-details-tab-email/candidate-details-tab-email.component";
import { TruncateTextPipe } from "../../shared/pipes/truncate.pipe";
import { LoadingErrorModule } from "../loading-error/loading-error.module";
import { LoadingSpinnerComponent } from "../loading-spinner/loading-spinner.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingErrorModule
    ],
    declarations: [
        CandidateDetailsStatusComponent,
        CandidateDetailsAboutComponent,
        CandidateDetailsNameComponent,
        CandidateDetailsActionComponent,
        CandidateDetailsLinksAndDocsComponent,
        CandidateDetailsNotesComponent,
        CandidateDetailsFooterComponent,
        CandidateDetailsWorkHistoryComponent,
        CandidateDetailsModalHeaderComponent,
        CandidateDetailsTabInformationComponent,
        CandidateDetailsTabContactComponent,
        CandidateDetailsTabSubmittedComponent,
        CandidateDetailsTabInterviewsComponent,
        CandidateDetailsTabEmailComponent,
        TruncateTextPipe,
        LoadingSpinnerComponent
    ],
    exports: [
        CandidateDetailsStatusComponent,
        CandidateDetailsAboutComponent,
        CandidateDetailsNameComponent,
        CandidateDetailsActionComponent,
        CandidateDetailsLinksAndDocsComponent,
        CandidateDetailsNotesComponent,
        CandidateDetailsFooterComponent,
        CandidateDetailsWorkHistoryComponent,
        CandidateDetailsModalHeaderComponent,
        CandidateDetailsTabInformationComponent,
        CandidateDetailsTabContactComponent,
        CandidateDetailsTabSubmittedComponent,
        CandidateDetailsTabInterviewsComponent,
        CandidateDetailsTabEmailComponent,
        TruncateTextPipe
    ]
})
export class CandidateDetailsModule {}
