<div class="team-member">
    <h3 class="recruiter-name">{{name}}</h3>
    <p class="location">{{title}}</p>
    <div class="contact-detail" *ngIf="phone">
        <a href="tel:{{phone | phone}}" (click)="contactRecruiter('Call')">
            <div class="action-type-container">
                <div class="action-text">
                    <p>CALL</p>
                </div>
            </div>
            <div class="text">
                <p>
                    <span *ngIf="!phoneExtension">{{formatPhoneNumber(phone)}}</span>
                    <span *ngIf="phoneExtension">x {{formatPhoneNumber(phone)}}</span>
                </p>
            </div>
        </a>
    </div>
    <div class="contact-detail" *ngIf="mobile">
        <a [href]="safeMobileNumber" (click)="contactRecruiter('Text')">
            <div class="action-type-container">
                <div class="action-text">
                    <p>TEXT</p>
                </div>
            </div>
            <div class="text">
                <p>
                    <span>{{formatPhoneNumber(mobile)}}</span>
                </p>
            </div>
        </a>
    </div>
    <div class="contact-detail" *ngIf="email">
        <a href="mailto:{{email}}" (click)="contactRecruiter('Email')">
            <div class="action-type-container">
                <div class="action-text">
                    <p>EMAIL</p>
                </div>
            </div>
            <div class="text">
                <p>
                    <span>{{ email }}</span>
                </p>
            </div>
        </a>
    </div>
</div>