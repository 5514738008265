<div>
    <div *ngIf="candidate.sunsetFlag === 0 && !doNotUse && !candidate.submittedDate && !candidate.legalFirstName && (!candidate.potentiallyWorking && !candidate.lastUpdated)">
        <p class="info-none">There are currently no submission details for this candidate.</p>
    </div>
    <div *ngIf="candidate.sunsetFlag === 1 && !doNotUse">
        <p class="info-none">It's been a while since we sent this candidate your way. Please contact your Creative Circle team for updated information.</p>
    </div>
    <div *ngIf="doNotUse">
        <p class="info-none">Sorry, this candidate's details are no longer available. Please contact Creative Circle if you have questions.</p>
    </div>
    <div *ngIf="candidate.sunsetFlag === 0 && !doNotUse" class="details">
        <div *ngIf='candidate.submittedDate' class="submitted">
            <span class="header">SUBMITTED</span>
            <p class="info">{{ candidate.submittedDate | date: 'MMM d, y h:mm a' }} <span>{{ candidate.submittedDate | date: '(zzz)' }}</span></p>
        </div>
        <div><button *ngIf="job.status === 'ACTIVE' && candidate.statusId === 8" aria-label="End Date" class="adjust" (click)="adjust()">Adjust End Date</button></div>
        <div *ngIf="candidate.legalFirstName && candidate.legalFirstName.toLowerCase().trim() != candidate.firstName.toLowerCase().trim()">
            <span class="header">LEGAL NAME</span>
            <p class="info"> {{ candidate.legalFirstName}} {{ candidate.lastName }}</p>
        </div>
    </div>
    <div *ngIf="candidate.potentiallyWorking || candidate.lastUpdated">
        <div class="status" *ngIf="candidate.potentiallyWorking">
            <p class="header">STATUS</p>
            <p class="info">Potentially Working</p>
        </div>
        <div class="last-updated">
            <p class="header">LAST UPDATED</p>
            <p class="info" *ngIf="candidate.lastUpdated">{{ candidate.lastUpdated | date: 'MMM d, y
                h:mm a' }}</p>
            <p class="info" *ngIf="!candidate.lastUpdated">Not Listed</p>
        </div>
    </div>
</div>
