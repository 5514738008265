import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-document-link-preview-dialog',
    templateUrl: './document-link-preview-dialog.component.html',
    styleUrls: ['./document-link-preview-dialog.component.scss']
})
export class DocumentLinkPreviewDialogComponent {
    public title: string = '';
    public description: string = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DocumentLinkPreviewDialogComponent>,
        private dialog: MatDialog,
    ) {
    }

    cancel() {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.title = this.data.document.title
        this.description = this.data.document.description
    }
}
