<div class="contact-information-container dashboard-section contact-information your-team" #contact_information [class.active-section]="activeSection">
    <div class="headline-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea"
             stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard">
            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
            <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
        </svg>
        <p class="category-title">Creative Circle Team</p>
        <div class="view-all-btn auto-btn" *ngIf="numOfContacts > 1">
            <span [ngClass]="showBackward ? 'back-chevron' : 'back-chevron hide'" (click)="toggleContacts('back')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                 stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
            </span> {{ contactCounter }} of {{ numOfContacts }}
            <span [ngClass]="showForward ? 'forward-chevron' : 'forward-chevron hide'"
                  (click)="toggleContacts('forward')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                 stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
            </span>
        </div>
    </div>

    <div class="loading-shade" *ngIf="isLoadingResults">
        <svg class="loading-gif" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"
             fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-loader">
            <line x1="12" y1="2" x2="12" y2="6"></line>
            <line x1="12" y1="18" x2="12" y2="22"></line>
            <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
            <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
            <line x1="2" y1="12" x2="6" y2="12"></line>
            <line x1="18" y1="12" x2="22" y2="12"></line>
            <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
            <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
        </svg>
    </div>

    <app-loading-error [apiError]="loadingError" (onRetry)="getContacts()" [showShade]="true"></app-loading-error>

    <div *ngIf="contactInformation && contactInformation.length == 0 && !isLoadingResults" class="empty-state">
        <p>Give us a call at 888-823-2017</p>
    </div>

    <div *ngIf="contactInformation && contactInformation.length > 0">
        <div *ngFor="let contact of contactInformation; index as i">
            <div class="contact" [ngClass]="i == 0 ? 'active' : 'not-active'">
                <div class="contact-name">
                    <p class="name">{{ contact.contactFirstName }} {{ contact.contactLastName }}</p>
                    <p class="title">{{ contact.title }} - {{ contact.area }}</p>
                </div>
                <div class="contact-details">
                    <a href="tel:{{contact.callNumber}}" title="{{contact.callNumber}}" target="_blank" rel="noopener" (click)="contactClick('CALL')">
                        <div class="detail">
                            <div class="action-type-container">
                                <div class="action-text">
                                    <p>CALL</p>
                                </div>
                            </div>
                            <div class="text">
                                <p>{{formatPhoneNumber(contact.callNumber)}}</p>
                            </div>
                        </div>
                    </a>

                    <a [href]="contact.textNumberSafe" target="_blank" rel="noopener" title="{{contact.textNumber}}" (click)="contactClick('TEXT')">
                        <div class="detail" *ngIf=contact.textNumber>
                            <div class="action-type-container">
                                <div class="action-text">
                                    <p>TEXT</p>
                                </div>
                            </div>
                            <div class="text">
                                <p>{{formatPhoneNumber(contact.textNumber)}}</p>
                            </div>
                        </div>
                    </a>

                    <a href="mailto:{{contact.email}}" title="{{contact.email}}" target="_blank" rel="noopener" (click)="contactClick('EMAIL')">
                        <div class="detail">
                            <div class="action-type-container">
                                <div class="action-text">
                                    <p>EMAIL</p>
                                </div>
                            </div>
                            <div class="text">
                                <p>{{contact.email}}</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
