import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators, Form, UntypedFormGroup, FormControl } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { JobService } from "../../services/job-orders.service";
import { ApiError } from "../../models/api-error.model";
import { switchMap } from "rxjs/operators";
import { CLOSE_SUCCESS } from "../perf-review-dialog/perf-review-dialog.component";
import { UserService } from '../../services';
import { NetworkErrorSnackbarService } from "../../../shared/services/network-error-snackbar.service";

@Component({
    selector: "app-keep-in-the-running",
    templateUrl: "./keep-in-the-running.component.html",
    styleUrls: ["./keep-in-the-running.component.scss"]
})
export class KeepInTheRunningComponent {
    public form: UntypedFormGroup;
    public isSubmitting: boolean = false;
    public formError: string;
    public addToNotes: boolean = true;
    public isLimitedImpersonation: boolean = false;
    public countMax: number = 4000;
    public typingLength = 0;
    public advantages: string = '';
    public disadvantages: string = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: UntypedFormBuilder,
        private dialogRef: MatDialogRef<KeepInTheRunningComponent>,
        private jobOrderService: JobService,
        private userService: UserService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
    ) {
        this.form = this.formBuilder.group({
            advantages: [''],
            disadvantages: [''],
            officeId: [
                this.data.officeId,
                Validators.compose([Validators.required])
            ],
            jobId: [this.data.jobId, Validators.compose([Validators.required])],
            jobCandidateId: [
                this.data.jobCandidateId,
                Validators.compose([Validators.required])
            ],
            candidateId: [
                this.data.candidateId,
                Validators.compose([Validators.required])
            ],
            statusId: [
                this.data.statusId,
                Validators.compose([Validators.required])
            ],
            addToNotes: new FormControl({
                value: true,
                disabled: true
            }),
            sharedCC: [false],
            sharedHiringManagers: new FormControl({
                value: false,
                disabled: true
            })
        });

        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });

        this.form.controls['advantages'].valueChanges.subscribe(() => {
            this.lengthCounter();
        });

        this.form.controls['disadvantages'].valueChanges.subscribe(() => {
            this.lengthCounter();
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    lengthCounter() {
        this.typingLength = this.form.controls['advantages'].value.length + this.form.controls['disadvantages'].value.length;
        this.countMax = 4000 - this.typingLength;
    }

    submit() {
        let detailsSubmitted = false;
        let details = "No details provided.";
        let lastComment = "No details provided."

        if (
            this.form.value["advantages"] !== "" ||
            this.form.value["disadvantages"] !== ""
        ) {
            detailsSubmitted = true;
            details =
                "<strong>Things I Like:</strong> " +
                this.form.value["advantages"] +
                "<br /><strong>Hesitations:</strong> " +
                this.form.value["disadvantages"];
            lastComment =
                "Things I Like:" +
                this.form.value["advantages"] +
                "<br />Hesitations:" +
                this.form.value["disadvantages"];
        }

        if (this.form.valid && !this.isSubmitting && this.typingLength <= 4000) {
            this.isSubmitting = true;
            const params = {
                statusId: this.form.value["statusId"],
                details: lastComment
            };

            let patchJobCandidate = this.jobOrderService.patchJobCandidate(
                this.form.value["officeId"],
                this.form.value["jobId"],
                this.form.value["jobCandidateId"],
                params
            );

            if (this.addToNotes === true && detailsSubmitted === true) {
                const addNotes = this.jobOrderService.postJobCandidateNotes(
                    this.form.value["officeId"],
                    this.form.value["jobId"],
                    this.form.value["candidateId"],
                    this.form.value["jobCandidateId"],
                    {
                        note: details,
                        private: false,
                        sharedCC: true,
                        sharedHiringManagers: this.form.get("sharedHiringManagers").value,
                        sendAlert: false
                    }
                );

                patchJobCandidate = patchJobCandidate.pipe(
                    switchMap(() => addNotes)
                );
            }

            patchJobCandidate.subscribe(/* TODO handle timeout */
                _ => this.dialogRef.close(CLOSE_SUCCESS),
                (err: ApiError) => {
                    this.isSubmitting = false;
                    if (err.statusCode === 400) {
                        this.formError =
                            "Error! Please close the dialog and try again.";
                    } else {
                        this.networkErrorSnackbarService.networkError(err,'')
                    }
                }
            );
        }
    }

    determineInput(event) {
        const value = event.target.value;
        const checked = event.target.checked;

        if (value === "addToNotes" && checked && (this.advantages !== "" || this.disadvantages !== "")) {
            this.addToNotes = true;
            this.form.controls["sharedCC"].setValue(true);
        } else if (value === "addToNotes" && !checked) {
            this.addToNotes = false;
            this.form.controls["sharedCC"].setValue(false);
            this.form.controls["sharedHiringManagers"].setValue(false);
        }

        if (value === "creative-circle" && this.addToNotes === true) {
            return false;
        }
    }
}
