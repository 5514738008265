<div class="work-section" [class.block]="workHistory && workHistory.length == 0">
    <div class="work-row">
        <div class="name">
            <p>Work History</p>
        </div>
        <div *ngIf="workHistory && workHistory.length == 0" class="no-work-history">
            <p>This candidate hasn't worked for your company yet.</p>
        </div>
        <div *ngIf="workHistory && workHistory.length > 0">
            <div class="new-company" *ngFor="let work of workHistory">
                <div>
                    <p class="company">FOR {{ work[0].companyName }}</p>
                    <div class="table head">
                        <div class="row one">
                            <span>STATUS</span>
                        </div>
                        <div class="row two">
                            <span>JOB TITLE</span>
                        </div>
                        <div class="row three">
                            <span>START DATE</span>
                        </div>
                        <div class="row four">
                            <span>END DATE</span>
                        </div>
                        <div class="row five">
                            <span>REPORT TO CONTACT</span>
                        </div>
                        <div class="row six">
                            <span>AVG RATING</span>
                        </div>
                    </div>
                    <div *ngFor="let entry of work">
                        <div class="table">
                            <div class="row one">
                                <span>{{ entry.jobStatus }}</span>
                            </div>
                            <div class="row two">
                                <span>{{ entry.jobTitle }}</span>
                            </div>
                            <div class="row three">
                                <span *ngIf="entry.startDate">{{ entry.startDate | date:'MMM d, yyyy' }}</span>
                                <span *ngIf="!entry.startDate">—</span>
                            </div>
                            <div class="row four" [class.center]="entry.fulltimeFlag">
                                <span *ngIf="entry.endDate && !entry.fulltimeFlag">{{ entry.endDate | date:'MMM d, yyyy' }}</span>
                                <span *ngIf="entry.fulltimeFlag">—</span>
                            </div>
                            <div class="row five">
                                <span>{{ entry.reportToFirstName }} {{ entry.reportToLastName }}</span>
                            </div>
                            <div class="row six">
                                <span *ngIf="entry.averageRating === 0">NOT RATED</span>
                                <div *ngIf="entry.averageRating !== 0">
                                    <span [ngClass]="{'checked': entry.averageRating > 0}">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#f1f1f1"
                                         stroke-width="`"
                                         stroke-linecap="round" stroke-linejoin="round" class="feather feather-star">
                                    <polygon
                                            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                    </svg>
                                </span>
                                    <span [ngClass]="{'checked': entry.averageRating > 1}">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#f1f1f1"
                                         stroke-width="`"
                                         stroke-linecap="round" stroke-linejoin="round" class="feather feather-star">
                                    <polygon
                                            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                    </svg>
                                </span>
                                    <span [ngClass]="{'checked': entry.averageRating > 2}">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#f1f1f1"
                                         stroke-width="`"
                                         stroke-linecap="round" stroke-linejoin="round" class="feather feather-star">
                                    <polygon
                                            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                    </svg>
                                </span>
                                    <span [ngClass]="{'checked': entry.averageRating > 3}">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#f1f1f1"
                                         stroke-width="`"
                                         stroke-linecap="round" stroke-linejoin="round" class="feather feather-star">
                                    <polygon
                                            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                    </svg>
                                </span>
                                    <span [ngClass]="{'checked': entry.averageRating > 4}">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#f1f1f1"
                                         stroke-width="`"
                                         stroke-linecap="round" stroke-linejoin="round" class="feather feather-star">
                                    <polygon
                                            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                    </svg>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
