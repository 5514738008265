import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { JobOrderCandidate, JobService } from '../../../../shared';
import { Subscribing } from '../../../../shared/components/subscribing.component';
import { candidateColorFromStatusId } from '../../../../shared/helpers/status-color';
import { JobDetails } from '../../../../shared/models/job-order-details.model';
import { AnalyticsService } from '../../../../shared/services/analytics.service';

const COMPLETE_JOB_STATUSES = ['ACTIVE', 'CLOSED', 'CANCELLED', 'FT_PLACEMENT'];
const CANDIDATE_STATUS_BY_ID = {
    1: 'Not Reviewed',
    2: 'Interview Scheduled',
    3: 'Interview Requested',
    5: 'Passed',
    6: 'Considering',
    7: 'Hire Pending',
    8: 'Hired',
    9: 'Candidate Declined',
    12: 'No Longer Available',
    17: 'Not Selected'
};

@Component({
    selector: 'app-job-order-details-candidates-list',
    templateUrl: './job-order-details-candidates-list.component.html',
    styleUrls: ['./job-order-details-candidates-list.component.scss']
})
export class JobOrderDetailsCandidatesListComponent extends Subscribing implements OnInit {
    @ViewChild('input_fields', { static: true })
    input_fields: ElementRef;
    @ViewChild('candidates_container', { static: true })
    candidateContainer: ElementRef;

    public filterActive = false;
    public sortActive = false;
    public customFilter = false;
    public activeSort: string = '';
    public showCompleteJobHeadlines: boolean = false;
    public jobDetails: JobDetails;
    public _clientId: number;
    public _officeId: number;

    public _candidatesUnderConsideration: JobOrderCandidate[];
    public _candidatesNewSubmittals: JobOrderCandidate[];
    public _candidatesPassedOn: JobOrderCandidate[];
    public _candidatesHired: JobOrderCandidate[];
    public _candidatesNotSelected: JobOrderCandidate[];
    public _allCandidates: JobOrderCandidate[];
    public clickedJobOrderStatus: string;
    public searchControl: UntypedFormControl = new UntypedFormControl();
    public _jobCandidateIdParam: number;
    public isWindows: boolean = false;
    public clickedCandidate: any;
    public noCandidates: boolean = false;
    public totalFiltered: number = 0;
    public section: string = '';
    public cardClicked: boolean = false;

    constructor(
        private analyticsService: AnalyticsService,
        private router: Router,
        private jobService: JobService,
        private route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit() {
        if (
            navigator.userAgent.indexOf('Chrome') !== -1 &&
            navigator.userAgent.indexOf('Windows') !== -1
        ) {
            this.isWindows = true;
        }

        this.route.params.subscribe(routeParams => {
            if (routeParams['section']) {
                this.section = routeParams['section'];
            }
        })

        this.subscriptions.push(
            this.jobService.jobDetails.subscribe(jobDetails => {
                this.customFilter = false;
                if (jobDetails) {
                    this.clearAllFilters();
                    if (this.cardClicked !== true) {
                        this.scrollTopList();
                    }
                    this._clientId = jobDetails['clientId'];
                    this._officeId = jobDetails['officeId'];
                    this.jobDetails = jobDetails;
                    this._allCandidates = jobDetails.candidates.sort(
                        this.defaultSortJobOrderCandidates
                    );

                    if (jobDetails.candidates.length == 0) {
                        this.noCandidates = true;
                    } else {
                        this.noCandidates = false;
                    }

                    // Gets the status of the currently selected job, then based on that it sets the flag that determines whether to display
                    // the different job status headlines
                    this.clickedJobOrderStatus = jobDetails.status;
                    this.showCompleteJobHeadlines = COMPLETE_JOB_STATUSES.includes(
                        this.clickedJobOrderStatus
                    );

                    this._candidatesUnderConsideration = jobDetails.candidates
                        .filter(candidate =>
                            [2, 3, 6, 7, 8].includes(candidate.statusId)
                        )
                        .reduce((candidateList, candidate) => {
                            // Makes sure that 'hired' candidates are always on top of the list
                            if (candidate.statusId === 8) {
                                return [candidate, ...candidateList];
                            }
                            return [...candidateList, candidate];
                        }, []);
                    this._candidatesNewSubmittals = jobDetails.candidates.filter(
                        candidate => [1].includes(candidate.statusId)
                    );
                    this._candidatesPassedOn = jobDetails.candidates.filter(
                        candidate => [5, 9, 12, 14].includes(candidate.statusId)
                    );
                    this._candidatesHired = jobDetails.candidates.filter(
                        candidate => candidate.candidateId == jobDetails.candId
                    );
                    this._candidatesNotSelected = jobDetails.candidates.filter(
                        candidate =>
                            candidate.statusId != 8 &&
                            candidate.candidateId != jobDetails.candId
                    );
                } else {
                    this._allCandidates = [];
                    this._candidatesUnderConsideration = [];
                    this._candidatesNewSubmittals = [];
                    this._candidatesPassedOn = [];
                    this._candidatesHired = [];
                    this._candidatesNotSelected = [];
                }

                this.setClickedCandidate();
            })
        );

        this.subscriptions.push(
            this.jobService.candidateDetails.subscribe(candDetails => {
                this.clickedCandidate = candDetails;
            })
        );

        this.subscriptions.push(
            this.route.params.subscribe(params => {
                this._jobCandidateIdParam = params.jobCandidateId;
                this.setClickedCandidate();
            })
        );

        this.subscriptions.push(
            this.searchControl.valueChanges
                .pipe(
                    debounceTime(400)
                )
                .subscribe(value => {
                    this.onSearchChange(value);
                })
        );
    }

    ngAfterViewChecked(): void {
        if (this.clickedCandidate && Object.keys(this.clickedCandidate).length && !this.cardClicked) {
            const jobElement =
                this.candidateContainer.nativeElement.querySelector('div[data-candidate-id="' + this.clickedCandidate.jobCandidateId + '"]');
            if (jobElement != null) {
                jobElement.scrollIntoView({ block: "start", inline: "nearest" });
            }
        }
    }

    private setClickedCandidate() {
        if (this._allCandidates) {
            this.clickedCandidate = this._allCandidates.find(
                jobCandidate =>
                    jobCandidate.jobCandidateId == this._jobCandidateIdParam
            );
        }
    }

    scrollTopList() {
        setTimeout(() => {
            this.candidateContainer.nativeElement.scrollTop = 0;
        }, 500);
    }

    expandedCandidateDetails(candidate: JobOrderCandidate) {
        this.cardClicked = true;
        this.clickedCandidate = candidate;
        this.analyticsService.fireEvent(
            'Job Candidates',
            'Job Candidate Click',
            candidate.statusId.toString(),
            {
                dimension6: candidate.jobCandidateId,
                dimension5:
                    candidate.jobId.toString() +
                    '.' +
                    candidate.officeId.toString(),
                dimension3:
                    this._clientId.toString() + '.' + this._officeId.toString()
            }
        );

        if (this.section === 'active-placements') {
            this.router.navigate(["jobs", { officeId: candidate.officeId, jobId: candidate.jobId, jobCandidateId: candidate.jobCandidateId, "section": "active-placements"}]);
        } else if (this.section === 'current-openings') {
            this.router.navigate(["jobs", { officeId: candidate.officeId, jobId: candidate.jobId, jobCandidateId: candidate.jobCandidateId, "section": "current-openings"}]);
        } else {
            this.router.navigate([
                '/jobs',
                {
                    officeId: candidate.officeId,
                    jobId: candidate.jobId,
                    jobCandidateId: candidate.jobCandidateId,
                }
            ]);
        }
    }

    refreshCandidates() {
        this._allCandidates =
            typeof this.jobDetails != 'undefined'
                ? this.jobDetails.candidates
                : [];
    }

    /**
     * Convenience method for activating the filter popover
     */
    handleFilter() {
        if (this.noCandidates) {
            return false;
        }

        if (this.sortActive) this.sortActive = false;
        this.filterActive = !this.filterActive;
    }

    /**
     * Convenience method for activating the sort popover
     */
    handleSort() {
        if (this.noCandidates) {
            return false;
        }

        if (this.filterActive) this.filterActive = false;
        this.sortActive = !this.sortActive;
    }

    filterBy() {
        this.refreshCandidates();
        this.customFilter = true;
        const allFilterInputs = this.input_fields.nativeElement.getElementsByClassName(
            'filter-check'
        );
        let selectedCheckboxes = [];
        let filteredCandidates = [];

        for (let i = 0; i < allFilterInputs.length; i++) {
            if (allFilterInputs[i].checked) {
                selectedCheckboxes.push(allFilterInputs[i].value);
            }
        }

        if (selectedCheckboxes.length) {
            this.analyticsService.fireEvent(
                'Job Candidates',
                'Filtered',
                selectedCheckboxes.join(',')
            );
        }

        if (selectedCheckboxes.length === 0) {
            this.customFilter = false;
        }

        const favoritesChecked = selectedCheckboxes.includes('favorited');
        const blockChecked = selectedCheckboxes.includes('blocked');
        const notSelectedChecked = selectedCheckboxes.includes('not-selected');
        const interviewedChecked = selectedCheckboxes.includes('interviewed');

        for (let c = 0; c < this._allCandidates.length; c++) {
            if (
                notSelectedChecked &&
                !['8'].includes(this._allCandidates[c].statusId.toString())
            ) {
                filteredCandidates.push(this._allCandidates[c]);
            } else if (
                // this cand's status is selected
                selectedCheckboxes.includes(
                    this._allCandidates[c].statusId.toString()
                )
            ) {
                if (
                    (blockChecked && this._allCandidates[c].blocked === true) ||
                    !blockChecked
                ) {
                    if (filteredCandidates.indexOf(this._allCandidates[c]) === -1) {
                        filteredCandidates.push(this._allCandidates[c]);
                    }
                }
            } else if (
                blockChecked &&
                (selectedCheckboxes.length === 1 || !favoritesChecked) &&
                this._allCandidates[c].blocked === true
            ) {
                // the only thing checked is 'blocked'
                if (filteredCandidates.indexOf(this._allCandidates[c]) === -1) {
                    filteredCandidates.push(this._allCandidates[c]);
                }
            } else if (
                // Only interviewed
                interviewedChecked && this._allCandidates[c].interviewed === true
            ) {
                if (filteredCandidates.indexOf(this._allCandidates[c]) === -1) {
                    filteredCandidates.push(this._allCandidates[c]);
                }
            }

            if (this._allCandidates[c].statusId === 3 && selectedCheckboxes.includes("3")) {
                if (filteredCandidates.indexOf(this._allCandidates[c]) === -1) {
                    filteredCandidates.push(this._allCandidates[c]);
                }
            }
        }

        if (this.input_fields) {
            let totalChecked = [];
            let inputFields = this.input_fields.nativeElement.querySelectorAll('input');
            for (let i = 0; i < inputFields.length; i++) {
                if (inputFields[i].checked) {
                    totalChecked.push(inputFields[i]);
                }
            }

            this.totalFiltered = totalChecked.length;

        }

        if (favoritesChecked) {
            if (filteredCandidates.length === 0) {
                filteredCandidates = this._allCandidates;
            }

            for (let c = 0; c < filteredCandidates.length; c++) {
                filteredCandidates = filteredCandidates.filter(candidate => candidate.favorited === true && candidate.sunsetFlag === false);
            }
        }

        this._allCandidates = filteredCandidates;
    }

    closeSort() {
        this.sortActive = false;
    }

    sortBy(event) {
        let checkedInput = event.target.closest('div').classList[0];
        this.analyticsService.fireEvent(
            'Job Candidates',
            'Sorted',
            checkedInput
        );
        this.activeSort = checkedInput;
        this.customFilter = true;
        this.sortActive = false;

        if (checkedInput == 'status') {
            return (this.customFilter = false);
        }

        switch (checkedInput) {
            case 'first-name-desc':
                return this._allCandidates.sort(
                    (a: any, b: any) =>
                        +(a.firstName > b.firstName) ||
                        -(a.firstName < b.firstName)
                );
            case 'first-name-asc':
                return this._allCandidates.sort(
                    (a: any, b: any) =>
                        +(b.firstName > a.firstName) ||
                        -(b.firstName < a.firstName)
                );
            case 'submitted-date-asc':
                return this._allCandidates.sort(
                    (a: any, b: any) =>
                        +new Date(a.submittedDate) - +new Date(b.submittedDate)
                );
            case 'submitted-date-desc':
                return this._allCandidates.sort(
                    (a: any, b: any) =>
                        +new Date(b.submittedDate) - +new Date(a.submittedDate)
                );
        }
    }

    closeFilter() {
        this.filterActive = false;
        this.sortActive = false;
    }

    clearAllFiltersOnMobile() {
        this.refreshCandidates();
        this.customFilter = false;


        if (this.input_fields) {
            const inputFields = this.input_fields.nativeElement.querySelectorAll('input');
            for (let i = 0; i < inputFields.length; i++) {
                inputFields[i].checked = false;
            }
        }

        this.searchControl.setValue(null);
        this.totalFiltered = 0;
    }

    clearAllFilters() {
        this.refreshCandidates();
        this.customFilter = false;
        let counter = 0;

        if (this.input_fields) {
            const inputFields = this.input_fields.nativeElement.querySelectorAll('input');
            for (let i = 0; i < inputFields.length; i++) {
                if (!inputFields[i].classList.contains('favorites-check') && !inputFields[i].classList.contains('interview-check')) {
                    inputFields[i].checked = false;
                } else {
                    if (inputFields[i].checked) {
                        counter += 1;
                    }
                }
            }
        }

        this.searchControl.setValue(null);
        this.totalFiltered = counter;
    }

    determineColor(statusId) {
        return 'status ' + candidateColorFromStatusId(statusId);
    }

    determineStatus(candidateId) {
        return CANDIDATE_STATUS_BY_ID[candidateId] || 'Not Reviewed';
    }

    onSearchChange(searchValue: string) {
        this.refreshCandidates();
        let search = '';
        if (searchValue !== null) {
            search = searchValue.toLowerCase();
        }

        if (search === '') {
            this.customFilter = false;
        } else {
            this.customFilter = true;
        }

        this.analyticsService.fireEvent('Job Candidates', 'Search');

        let filteredCandidates = this._allCandidates.filter(candidate =>
            candidate.firstName.toLowerCase().startsWith(search)
        );

        this._allCandidates = filteredCandidates.sort(
            this.defaultSortJobOrderCandidates
        );
    }

    /**
     * The default sort method for job order candidates. Sorting order is:
     * 1. Job order candidate's first name (ASC)
     * 2. Job order candidate's last name (ASC)
     * 3. Job order candidate's submitted date (ASC)
     *
     * @param a JobOrderCandidate First job to compare
     * @param b JobOrderCandidate Second job to compare
     *
     * @return int Result of comparison
     */
    public defaultSortJobOrderCandidates(
        a: JobOrderCandidate,
        b: JobOrderCandidate
    ) {
        if (a.firstName < b.firstName) {
            // Active candidate's first name (ascending)
            return -1;
        } else if (a.firstName > b.firstName) {
            return 1;
        } else if (a.lastName < b.lastName) {
            // Active candidate's last name (ascending)
            return -1;
        } else if (a.lastName > b.lastName) {
            return 1;
        } else if (Date.parse(a.submittedDate) < Date.parse(b.submittedDate)) {
            // End date (ascending)
            return -1;
        } else if (Date.parse(a.submittedDate) > Date.parse(b.submittedDate)) {
            return 1;
        }

        return 0;
    }

    parseNumber(number) {
        return Number(number);
    }
}
