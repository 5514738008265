<div class="recent-activity-container dashboard-section activity" [class.active-section]="activeSection">
    <div class="headline-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity">
            <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
        </svg>
        <p class="category-title">Your Recent Activity</p>
    </div>

    <div class="loading-shade" *ngIf="isLoadingResults">
        <svg class="loading-gif" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
            <line x1="12" y1="2" x2="12" y2="6"></line>
            <line x1="12" y1="18" x2="12" y2="22"></line>
            <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
            <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
            <line x1="2" y1="12" x2="6" y2="12"></line>
            <line x1="18" y1="12" x2="22" y2="12"></line>
            <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
            <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
        </svg>
    </div>

    <app-loading-error [apiError]="loadingError" (onRetry)="loadRecentActivity()" [showShade]="true"></app-loading-error>

    <div *ngIf="!loadingError && !isLoadingResults && recentActivity && recentActivity.length == 0" class="recent-activity no-activity">
        <p>As you use Lightbox, you can find your recent activity here</p>
    </div>

    <div class="activity-background">
        <div class="timeline" *ngIf="recentActivity">
            <div class="recent-activity" *ngFor="let activity of recentActivity; let i = index">
                <div class="timeline__group">
                    <div class="timeline__box">
                        <div class="timeline__date">
                            <span class="timeline__day"></span>
                        </div>
                        <div class="timeline__post">
                            <div class="timeline__content">
                                <div *ngIf="!activity.grouped" class="timeline-item not-grouped" (click)="activityClicked(activity)">
                                    <p class="date-completed">
                                        {{ adjustForLocalTime(activity.dateCreated) | dateago }}
                                    </p>
                                    <p>{{ determineActivityType(activity) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
