import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Candidate, CandidateDetail, DashboardService } from '../../../../shared';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { RescheduleInterviewDialogComponent } from "../../../../shared/components/reschedule-interview-dialog/reschedule-interview-dialog.component";
import { AlertDialogComponent } from "../../../../shared/components/alert-dialog/alert-dialog.component";
import { ApiError } from '../../../../shared/models/api-error.model';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { NetworkErrorSnackbarService } from '../../../../shared/services/network-error-snackbar.service';
import { DatePipe } from '@angular/common';
import { StartInterviewDialogComponent } from '../../../../shared/components/start-interview-dialog/start-interview-dialog.component';


@Component({
    selector: 'app-candidate-details-tab-interviews',
    templateUrl: './candidate-details-tab-interviews.component.html',
    styleUrls: ['./candidate-details-tab-interviews.component.scss']
})
export class CandidateDetailsTabInterviewsComponent implements OnInit {

    @Input()
    candidate: CandidateDetail;

    @Input()
    candidateObject: Candidate;

    public interviewType: string = '';

    public futureInterview: Boolean = false;
    public today: Date = new Date();

    constructor(
        private dialog: MatDialog,
        private dashboardService: DashboardService,
        private analyticsService: AnalyticsService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
        private datePipe: DatePipe
    ) {}

    openRescheduleInterviewDialog() {
        if (this.candidateObject.blocked === true) {
            this.blockedWarning();
            return;
        }

        this.dialog.open(RescheduleInterviewDialogComponent, {
            width: "600px",
            panelClass: "candidate_details_page",
            data: {
                candidateName: this.candidate.firstName + " " + this.candidate.lastName,
                date: this.candidate.nextInterview,
                timeZone: this.candidate.nextInterviewTimeZone,
                jobId: this.candidate.jobId,
                officeId: this.candidate.officeId,
                jobCandidateId: this.candidate.jobCandidateId,
                interviewType: this.interviewType,
                // Interview Scheduled statusId
                statusId: 2
            }
        });
    }

    ngOnInit() {
        if (this.candidate.interviewLocation) {
            this.determineInterviewType();
        }

        if (this.candidate.nextInterview && this.candidate.nextInterview > (new Date()).toISOString()) {
            this.futureInterview = true;
        }

        this.candidate.interviewIsToday = this.datePipe.transform(this.today, 'yMMdd') ===
                 this.datePipe.transform(this.candidate.nextInterview, 'yMMdd');
    }


    openRescheduledAlert() {
        if (this.candidateObject.blocked === true) {
            this.blockedWarning();
            return;
        }

        this.dialog.open(AlertDialogComponent, {
            width: "500px",
            data: {
                rescheduleConfirmMessage: true,
                buttonText: 'Got It'
            }
        });
    }
    openInterviewDialog(candidate) {
        event.stopPropagation();

        if (candidate.interviewUrl) {
            this.dialog.open(StartInterviewDialogComponent, {
                width: "600px",
                data: {
                    interviewWith: candidate.interviewWith,
                    interviewUrl: candidate.interviewUrl
                }
            });
        }
    }

    determineInterviewType() {
        let interviewLocation = this.candidate.interviewLocation.toLowerCase();
        if (interviewLocation.includes('office')) {
            this.interviewType = 'person';
        } else if (interviewLocation.includes('phone') || interviewLocation.includes('call')) {
            this.interviewType = 'phone';
        } else if (interviewLocation.includes('video')) {
            this.interviewType = 'video';
        } else {
            // show nothing
            this.interviewType = '';
        }
    }

    private blockedWarning() {
        const dialog = this.dialog.open(AlertDialogComponent, {
            panelClass: 'block_candidate_panel',
            data: {
                blockedNonPassedMessage: true,
                buttonText: 'Unblock',
                candidateFirstName: this.candidate.firstName
            }
        });

        dialog.afterClosed().subscribe(result => {
            if (result) {
                const params = {'blocked': false};
                this.analyticsService.fireEvent(
                    'Job Candidates',
                    'Unblocked',
                    '',
                    {
                        'dimension6': this.candidate.jobCandidateId,
                        'dimension3': this.candidate.clientId.toString() + '.' + this.candidate.officeId.toString(),
                        'dimension5': this.candidate.jobId.toString() + '.' + this.candidate.officeId.toString()
                    });
                this.dashboardService.updateJobCandidatePreference(
                    this.candidate.officeId,
                    this.candidate.jobId,
                    this.candidateObject.candidateId, params
                )
                    .subscribe(data => {
                            this.candidate.blocked = true;
                        },
                        (err: ApiError) => {
                            return this.networkErrorSnackbarService.networkError(err, '');
                        }
                    );
                this.candidateObject.blocked = false;
            }
        });
    }

}
