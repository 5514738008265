import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import {UserService, ROLE_CLIENT, ROLE_DEMO} from './user.service';
import { MessageService } from './message.service';
import { take, map } from 'rxjs/operators';
import { Message, MESSAGE_TYPE_ERROR } from '../models/message.model';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private userService: UserService, private messageService: MessageService) {
    }

    canActivate = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> =>
        this.getObservable(state);

    canActivateChild = (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> =>
        this.getObservable(state);

    private getObservable(state: RouterStateSnapshot): Observable<boolean> {
        return this.userService.currentUserType.pipe(take(1), map(userType => {
            if (userType && (userType.toLowerCase() === ROLE_CLIENT || userType && userType.toLowerCase() === ROLE_DEMO)) {
                return true;
            }

            this.messageService.addMessage(
                new Message(MESSAGE_TYPE_ERROR, 'Please log in to continue.')
            );

            this.router.navigate(['home'], {queryParams: {returnUrl: state.url}});

            return false;
        }));
    }
}
