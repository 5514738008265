<div class="background-container">
    <div class="small-container">
        <mat-card>
            <div class="logo-container">
                <img src="../assets/images/circle-logo.png" alt="Creative Circle Lightbox" />
            </div>
            <mat-card-title>
                Create an Account
            </mat-card-title>
            <mat-card-content>
                <app-messages></app-messages>
                <p class="help">Please provide your email address to receive registration instructions.</p>
                <form [formGroup]="registerForm" (ngSubmit)="submitForm()">
                    <mat-form-field>
                        <input matInput type="text" formControlName="email" placeholder="Email Address" />
                        <mat-error *ngIf="email.invalid && email.touched && !formTimelySubmitted">
                            A valid email address is required.
                        </mat-error>
                        <mat-error *ngIf="email.invalid && email.touched && formTimelySubmitted">
                            The previous process is still running, and the email should arrive within 10 minutes. If you don’t receive it by that time please try again.
                        </mat-error>
                    </mat-form-field>
                    <button aria-label="Submit" mat-raised-button class="btn" [class.invalid-btn]="registerForm.invalid" [class.is-submitting]="isSubmitting" color="primary" type="submit" [disabled]="!registerForm.valid || isSubmitting">
                        <span class="not-submitting">Submit</span>
                        <span class="submitting">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                                <line x1="12" y1="2" x2="12" y2="6"></line>
                                <line x1="12" y1="18" x2="12" y2="22"></line>
                                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                                <line x1="2" y1="12" x2="6" y2="12"></line>
                                <line x1="18" y1="12" x2="22" y2="12"></line>
                                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                            </svg>
                        </span>
                    </button>
                </form>
                <a routerLink="/" class="home-link">RETURN TO LOGIN</a>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="show-on-mobile">
        <div class="container">
            <div class="link">
                <a class="nav-link" href="https://www.creativecircle.com/roles-we-place/" target="_blank" rel="noopener">
                    ROLES WE PLACE
                </a>
            </div>
            <div class="link">
                <a class="nav-link" [href]="timecardLoginUrl" target="_blank" rel="noopener">
                    TIMECARD LOGIN
                </a>
            </div>
            <div class="link">
                <a class="nav-link" [href]="requestTalentUrl" target="_blank" rel="noopener">
                    REQUEST TALENT
                </a>
            </div>
        </div>
    </div>
</div>
