<div class="fixed-nav-mobile">
    <div #mobile_icons class="mobile-icons">
        <div class="left-shadow"></div>
        <div class="icon action-items" (click)="makeActive('action-items')" [class.active]="activeSection === 'action-items' || !activeSection">
            <div class="icon-content">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#ffffff"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell">
                    <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
                </svg>
                <p>ACTION ITEMS</p>
            </div>
            <span class="blue-bar"></span>
        </div>
        <div class="icon active-candidates" (click)="makeActive('active-candidates')" [class.active]="activeSection === 'active-candidates'">
            <div class="icon-content">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#ffffff"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users">
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>
                <p>ACTIVE PLACEMENTS</p>
            </div>
            <span class="blue-bar"></span>
        </div>
        <div class="icon open-searches" (click)="makeActive('open-searches')" [class.active]="activeSection === 'open-searches'">
            <div class="icon-content">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#ffffff"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase">
                    <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                    <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                </svg>
                <p>CURRENT OPENINGS</p>
            </div>
            <span class="blue-bar"></span>
        </div>
        <div class="icon interviews" (click)="makeActive('interviews')" [class.active]="activeSection === 'interviews'">
            <div class="icon-content">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#ffffff"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle">
                    <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
                </svg>
                <p>SCHEDULED INTERVIEWS</p>
            </div>
            <span class="blue-bar"></span>
        </div>
        <div class="icon activity" (click)="makeActive('activity')" [class.active]="activeSection === 'activity'">
            <div class="icon-content">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#ffffff"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity">
                    <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                </svg>
                <p>YOUR RECENT ACTIVITY</p>
            </div>
            <span class="blue-bar"></span>
        </div>
        <div class="icon your-team" (click)="makeActive('your-team')" [class.active]="activeSection === 'your-team'">
            <div class="icon-content">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#ffffff"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard">
                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                </svg>
                <p>YOUR CREATIVE CIRCLE TEAM</p>
            </div>
            <span class="blue-bar"></span>
        </div>
        <div class="right-shadow"></div>
    </div>

    <div class="dashboard-container" [class.overlay]="showOverlay">
        <div class="background-container">
            <div class="inner-container">
                <div class="content-body" #content_body>
                    <div class="left-column scrollbar">
                        <dashboard-action-items [activeSection]="activeSection === 'action-items' || !activeSection"></dashboard-action-items>
                        <app-dashboard-job-orders [activeSection]="activeSection === 'open-searches'"></app-dashboard-job-orders>
                        <app-layout-footer class="dashboard-footer"></app-layout-footer>
                    </div>
                    <div class="column-divider"></div>
                    <div class="right-column scrollbar">
                        <dashboard-contact-information [activeSection]="activeSection === 'your-team'"></dashboard-contact-information>
                        <dashboard-interviews [activeSection]="activeSection === 'interviews'"></dashboard-interviews>
                        <dashboard-active-candidates [activeSection]="activeSection === 'active-candidates'"></dashboard-active-candidates>
                        <dashboard-recent-activity [activeSection]="activeSection === 'activity'"></dashboard-recent-activity>
                    </div>
                </div>
            </div>
        </div>
    </div>