import { Injectable, OnDestroy } from "@angular/core";
import { ApiService } from "./api.service";
import { interval, Observable, Subscription } from "rxjs";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";

const APP_VERSION_CHECK_INTERVAL = 1000 * 60 * 60 * 4;

interface ApiVersionResponse {
    version: string;
}

@Injectable()
export class AppVersionService implements OnDestroy {
    private intervalSubscription: Subscription;
    private currentVersion: string;

    constructor(
        private apiService: ApiService,
        private snackBar: MatSnackBar
    ) {}

    public resetAppVersionChecking() {
        if (this.intervalSubscription) this.intervalSubscription.unsubscribe();

        this.tick();

        this.intervalSubscription = interval(
            APP_VERSION_CHECK_INTERVAL
        ).subscribe(this.tick.bind(this));
    }

    ngOnDestroy(): void {
        if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
    }

    private tick() {
        this.apiService.get("/version").subscribe((res: ApiVersionResponse) => {
            if (!this.currentVersion) this.currentVersion = res.version;
            if (this.currentVersion != res.version) this.updateFound();
        });
    }

    private updateFound() {
        this.intervalSubscription.unsubscribe();

        this.snackBar
            .open("A new version of Lightbox is available", "Refresh", {
                duration: 0
            })
            .onAction()
            .subscribe(() => {
                window.location.reload();
            });
    }
}
