<div class="interviews-container dashboard-section interviews" [class.active-section]="activeSection">
    <div *ngIf="upcomingInterviews && upcomingInterviews.length == 0 && !isLoadingResults" class="empty-state">
        <p>You currently have no interviews scheduled</p>
    </div>
    <div *ngIf="upcomingInterviews && upcomingInterviews.length > 0">
        <div class="headline-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none"
                 stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-users">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
            <p class="category-title">Scheduled Interviews</p>
        </div>
        <div class="upcoming-interview" *ngFor="let interview of upcomingInterviews">
            <div *ngIf="interview.timeZone" class="single-interview" (click)=routeJob(interview)>
                <div class="content">
                    <div class="candidate-details">
                        <p class="name">{{ interview.candidateFirstName }} {{ interview.candidateLastName }}</p>
                        <p class="jobTitle">{{ interview.jobTitle }}</p>
                        <p class="area">{{ interview.area }}</p>
                    </div>
                    <div class="interview-date" *ngIf="!interview.interviewRescheduledFlag">
                        <p class="date">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none"
                                stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-clock">
                                <circle cx="12" cy="12" r="10"></circle>
                                <polyline points="12 6 12 12 16 14"></polyline>
                            </svg>
                            <span>{{interview.date | date:'MMM d, yyyy'}} @ {{interview.date | date:'h:mm a'}} {{interview.timeZone}}</span>
                        </p>

                        <p class="location" *ngIf="interview.interviewType === 'person'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                <circle cx="12" cy="12" r="3"></circle>
                            </svg>
                            <span>In person</span>
                        </p>

                        <p class="location" *ngIf="interview.interviewType === 'phone'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone">
                                <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                            </svg>
                            <span>By Phone</span>
                        </p>

                        <p class="location" *ngIf="interview.interviewType === 'video'" (click)="interview.interviewUrl && openInterviewDialog(interview)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-video">
                                <polygon points="23 7 16 12 23 17 23 7"></polygon><rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
                            </svg>
                            <span [ngClass]="{underlined: interview.interviewUrl}">Video Conference {{interview.interviewIsToday ? '(Today)' : ''}}</span>
                        </p>
                    </div>
                    <div class="interview-date" *ngIf="interview.interviewWith">
                        <p class="date">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none"
                                stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-users">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                            <span>Interviewer: {{ interview.interviewWith }}</span>
                        </p>
                    </div>
                </div>
                <span class="chevron right"></span>
            </div>
            <div class="reschedule-container" *ngIf="!interview.interviewRescheduledFlag">
                <p (click)="openRescheduleDialog(interview)">RESCHEDULE</p>
            </div>
            <div class="reschedule-container" *ngIf="interview.interviewRescheduledFlag">
                <p (click)="openRescheduledAlert()">RESCHEDULED <span>(PENDING)</span></p>
            </div>
        </div>
        <div class="show-more-container">
            <button *ngIf="currentPage < pages" [ngClass]="{'button': true, 'disabled invalid-btn': currentPage >= pages}" (click)="showMoreItems()" [disabled]="currentPage >= pages">Show More</button>
            <p *ngIf="currentPage >= pages">Showing All Upcoming Interviews</p>
        </div>
    </div>
</div>
