import { Component, OnInit } from '@angular/core';
import { Subscribing } from '../shared/components/subscribing.component';
import { MaintenanceService, UserService } from './../shared/services';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.scss']
})
export class ClientComponent extends Subscribing implements OnInit {
    constructor(
        private userService: UserService,
        private maintenanceService: MaintenanceService,
        private router: Router,
        private dialog: MatDialog,
    ) {
        super();
    }

    public loggedIn: boolean = false;
    public inMaintenanceMode: boolean = false;

    ngOnInit() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.dialog.closeAll();
            window.scrollTo(0, 0);
        });
        this.subscriptions.push(
            this.userService.currentUserType.subscribe(userType => {
                if (userType) {
                    this.loggedIn = true;
                } else {
                    this.loggedIn = false;
                }
            }),
            this.maintenanceService.update$.subscribe(bool => {
                this.inMaintenanceMode = bool;
            })
        );
    }
}
