import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../shared/services';
import { Router, NavigationEnd } from '@angular/router';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-layout-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public requestTalentUrl: string = environment.request_talent_url;
    public timecardLoginUrl: string = environment.timecard_login_url;

    constructor(
        private router: Router,
        private analyticsService: AnalyticsService
    ) {
    }

    ngOnInit() {
    }

    linkOutClick(text, url) {
        this.analyticsService.fireEvent(
            'Outbound Link Click',
            'Header : ' + text,
            url
        );
    }
}
