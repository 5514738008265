<nav class="navbar">
    <div class="container">
        <a class="navbar-brand" aria-label="Logo" routerLink="/"><img alt="Creative Circle Logo" src="../assets/images/logo-updated.svg"/></a>
        <ul class="nav-link-container">
            <li class="nav-item">
                <a class="nav-link" aria-label="Roles We Place" href="https://www.creativecircle.com/roles-we-place/" target="_blank" rel="noopener" (click)="linkOutClick('ROLES WE PLACE', 'https://www.creativecircle.com/roles-we-place/')">
                    ROLES WE PLACE
                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link" aria-label="Timecard Login" [href]="timecardLoginUrl" target="_blank" rel="noopener" (click)="linkOutClick('TIMECARD LOGIN', timecardLoginUrl)">
                    TIMECARD LOGIN
                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link" aria-label="Request Talent" [href]="requestTalentUrl" target="_blank" rel="noopener" (click)="linkOutClick('TALENT REQUEST', requestTalentUrl)">
                    REQUEST TALENT
                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link" aria-label="Login" routerLink="/home">
                    LOG IN
                </a>
            </li>
        </ul>
    </div>
</nav>
