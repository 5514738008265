import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Job, JobDetails, JobOrderCandidate } from "../models";
import { ApiService } from "./api.service";
import { ApiError } from "../models/api-error.model";

export const JOB_STATUS_OPEN = "OPEN";
export const JOB_STATUS_ACTIVE = "ACTIVE";
export const JOB_STATUS_FT_PLACEMENT = "FT_PLACEMENT";
export const JOB_STATUS_PAST = "PAST";
export const JOB_STATUS_CLOSED = "CLOSED";
export const JOB_STATUS_CANCELLED = "CANCELLED";

@Injectable()
export class JobService {
    constructor(private apiService: ApiService) {}

    public jobsSubject: BehaviorSubject<Job[]> = new BehaviorSubject<Job[]>([]);
    public jobs: Observable<Job[]> = this.jobsSubject.asObservable();

    private jobsLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<
        boolean
    >(false);
    public jobsLoading: Observable<
        boolean
    > = this.jobsLoadingSubject.asObservable();

    public jobDetailsSubject: BehaviorSubject<JobDetails> = new BehaviorSubject<
        JobDetails
    >(null);
    public jobDetails: Observable<
        JobDetails
    > = this.jobDetailsSubject.asObservable();

    private jobDetailsLoadingSubject: BehaviorSubject<
        boolean
    > = new BehaviorSubject<boolean>(false);
    public jobDetailsLoading: Observable<
        boolean
    > = this.jobDetailsLoadingSubject.asObservable();

    private candidateDetailsSubject: BehaviorSubject<
        JobDetails
    > = new BehaviorSubject<JobDetails>(null);
    public candidateDetails: Observable<
        JobDetails
    > = this.candidateDetailsSubject.asObservable();

    private candidateDetailsLoadingSubject: BehaviorSubject<
        boolean
    > = new BehaviorSubject<boolean>(false);
    public candidateDetailsLoading: Observable<
        boolean
    > = this.candidateDetailsLoadingSubject.asObservable();

    public formChangedSubject: BehaviorSubject<boolean> = new BehaviorSubject<
        boolean
    >(false);
    public formChanged: Observable<
        boolean
    > = this.formChangedSubject.asObservable();

    getJobs(): Observable<any> {
        this.jobsLoadingSubject.next(true);

        return this.apiService
            .get("/job", { limit: "10000" })
            .pipe(map(data => data.results))
            .pipe(
                map(data => {
                    this.jobsSubject.next(data);
                    this.jobsLoadingSubject.next(false);

                    return data;
                }),
                catchError((apiError: ApiError) => {
                    this.jobsLoadingSubject.next(false);
                    return throwError(apiError);
                })
            );
    }

    getJobOrderDetails(officeId, jobId): Observable<JobDetails> {
        return this.apiService.get(`/job/${officeId}/${jobId}`).pipe(
            map(
                data => ({
                    ...data,
                    candidates: data.candidates,
                    get candidatesNewSubmittals(): JobOrderCandidate[] {
                        return this.candidates.filter(candidate =>
                            [1].includes(candidate.statusId)
                        );
                    },
                    get candidatesUnderConsideration(): JobOrderCandidate[] {
                        return this.candidates.filter(candidate =>
                            [2, 3, 6, 7, 8].includes(candidate.statusId)
                        );
                    },
                    get candidatesPassedOn(): JobOrderCandidate[] {
                        return this.candidates.filter(candidate =>
                            [5, 9, 12, 14].includes(candidate.statusId)
                        );
                    }
                }),
                err => console.error(err)
            )
        );
    }

    getJobCandidate(
        officeId,
        jobId,
        candidateId
    ): Observable<JobOrderCandidate> {
        return this.apiService
            .get(`/job/${officeId}/${jobId}/candidate/${candidateId}`)
            .pipe(map(data => data, err => console.error(err)));
    }

    patchJobCandidate(
        officeId,
        jobId,
        jobCandidateId,
        params
    ): Observable<JobOrderCandidate> {
        return this.apiService
            .patch(
                "/job/" +
                    officeId +
                    "/" +
                    jobId +
                    "/candidate/" +
                    jobCandidateId,
                params
            )
            .pipe(map(data => data, err => console.error(err)));
    }

    postJobCandidateNotes(
        officeId,
        jobId,
        candidateId,
        jobCandidateId,
        params
    ): Observable<JobOrderCandidate> {
        return this.apiService
            .post(
                `/job/${officeId}/${jobId}/candidate/${officeId}/${candidateId}/${jobCandidateId}/note`,
                params
            )
            .pipe(map(data => data, err => console.error(err)));
    }

    patchJobCandidateNotes(noteId, params): Observable<JobOrderCandidate> {
        return this.apiService
            .patch("/note/" + noteId, {
                sharedCC: params["sharedCC"],
                sharedHiringManagers: params["sharedHiringManagers"],
                note: params["note"]
            })
            .pipe(map(data => data, err => console.error(err)));
    }

    postContactEmail(officeId, jobId, candidateId, jobCandidateId, params) {
        return this.apiService
            .post(
                "/job/" +
                    officeId +
                    "/" +
                    jobId +
                    "/candidate/" +
                    officeId +
                    "/" +
                    candidateId +
                    "/" +
                    jobCandidateId +
                    "/contact",
                {
                    type: params["type"],
                    details: params["details"]
                }
            )
            .pipe(map(data => data, err => console.error(err)));
    }

    postContactCandidateEmail(officeId, candidateId, params) {
        return this.apiService
            .post("/candidate/" + officeId + "/" + candidateId + "/contact", {
                type: params["type"],
                details: params["details"]
            })
            .pipe(map(data => data, err => console.error(err)));
    }

    patchJobTitle(officeId, jobId, JobTitle): Observable<JobDetails> {
        return this.apiService
            .patch(`/job/${officeId}/${jobId}`, { JobTitle })
            .pipe(map(data => data, err => console.error(err)));
    }

    postShareJobCandidate(
        officeId,
        candidateId,
        jobId,
        jobCandidateId,
        params
    ) {
        return this.apiService
            .post(
                "/share/" +
                    officeId +
                    "/" +
                    candidateId +
                    "/" +
                    jobId +
                    "/" +
                    jobCandidateId,
                params
            )
            .pipe(map(data => data, err => console.error(err)));
    }

    getSharedCandidate(tokenParam): Observable<JobOrderCandidate> {
        return this.apiService
            .get("/share/", tokenParam)
            .pipe(map(data => data, err => console.error(err)));
    }

    getJobDetails(officeId, jobId): Observable<JobDetails> {
        this.jobDetailsLoadingSubject.next(true);
        return this.apiService.get("/job/" + officeId + "/" + jobId).pipe(
            map(
                data => {
                    this.jobDetailsSubject.next(data);
                    this.jobDetailsLoadingSubject.next(false);
                    return data;
                },
                err => {
                    this.jobDetailsLoadingSubject.next(false);
                    console.error(err);
                }
            )
        );
    }
}
