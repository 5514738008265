import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class EditModeService {

    private editEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    update$: Observable<boolean> = this.editEnabled.asObservable();

    updateBoolean(edited: boolean) {
        this.editEnabled.next(edited);
    }
}
