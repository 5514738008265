import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SortActiveService {

    private sortActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    update$: Observable<boolean> = this.sortActive.asObservable();

    updateBoolean(active: boolean) {
        this.sortActive.next(active);
    }
}
