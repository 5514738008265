import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {
    CandidateDetail,
} from './../../../shared';
import { DomSanitizer } from '@angular/platform-browser';
import { LookingForDialogComponent } from './../../../shared/components/looking-for-dialog/looking-for-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-candidate-details-about',
    templateUrl: './candidate-details-about.component.html',
    styleUrls: ['./candidate-details-about.component.scss']
})
export class CandidateDetailsAboutComponent implements OnInit {

    @Input()
    candidate: CandidateDetail;

    @Input()
    showSalaryDetails: boolean = true;

    @Input()
    lookingFor: boolean = true;

    constructor(
        private sanitizer: DomSanitizer,
        private dialog: MatDialog
    ) {}

    toggleToolTip() {
        if (window.innerWidth < 824) {
            let dialog = this.dialog.open(LookingForDialogComponent, {
                width: '90%',
                maxHeight: '90%',
                autoFocus: false,
            });
        }
    }

    ngOnInit() {
        this.candidate["safeBioBody"] = this.sanitizer.bypassSecurityTrustHtml(this.candidate.bioBody);
        this.candidate["safeJobCandBioBody"] = this.sanitizer.bypassSecurityTrustHtml(this.candidate.jobCandBioBody);
        this.candidate["safeBioIntro"] = this.sanitizer.bypassSecurityTrustHtml(this.candidate.bioIntro);
    }

    ngOnChanges() {
        this.candidate["safeBioBody"] = this.sanitizer.bypassSecurityTrustHtml(this.candidate.bioBody);
        this.candidate["safeJobCandBioBody"] = this.sanitizer.bypassSecurityTrustHtml(this.candidate.jobCandBioBody);
        this.candidate["safeBioIntro"] = this.sanitizer.bypassSecurityTrustHtml(this.candidate.bioIntro);
    }

}
