import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../../shared";
import { BRCDialogComponent } from "../../shared/components/brc-dialog/brc-dialog.component";
import { FilterCloseDirective } from "../../shared/directives/filter-close.directive";
import { PhoneFormatPipe } from "../../shared/pipes/phonenumber.pipe";
import { JobService } from "../../shared/services/job-orders.service";
import { LoadingErrorModule } from "../loading-error/loading-error.module";
import { SearchBarModule } from "../search-bar/search-bar.module";
import { CandidateDetailsModule } from "./../candidate-details/candidate-details.module";
import { FooterJobsComponent } from "./../layout/footer-jobs/footer-jobs.component";
import { JobOrderCandidateDetailsComponent } from "./job-order-details/job-order-candidate-details/job-order-candidate-details.component";
import { JobOrderDetailsAdditionalDetailsComponent } from "./job-order-details/job-order-details-additional-details/job-order-details-additional-details.component";
import { JobOrderDetailsCandidatesListComponent } from "./job-order-details/job-order-details-candidates-list/job-order-details-candidates-list.component";
import { JobOrderDetailsJobOverviewComponent } from "./job-order-details/job-order-details-job-overview/job-order-details-job-overview.component";
import { JobOrderDetailsTeamComponent } from "./job-order-details/job-order-details-team/job-order-details-team.component";
import { TeamMemberMobileComponent } from "./job-order-details/job-order-details-team/team-member-mobile/team-member-mobile.component";
import { TeamMemberComponent } from "./job-order-details/job-order-details-team/team-member/team-member.component";
import { JobOrderDetailsComponent } from "./job-order-details/job-order-details.component";
import { JobOrderCardComponent } from "./job-order-list/job-order-card/job-order-card.component";
import { JobOrderListComponent } from "./job-order-list/job-order-list.component";
import { JobOrdersComponent } from "./job-orders.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SearchBarModule,
        SharedModule,
        CandidateDetailsModule,
        LoadingErrorModule
    ],
    exports: [JobOrderCardComponent],
    declarations: [
        JobOrdersComponent,
        JobOrderListComponent,
        JobOrderDetailsComponent,
        JobOrderCardComponent,
        JobOrderDetailsCandidatesListComponent,
        JobOrderCandidateDetailsComponent,
        JobOrderDetailsJobOverviewComponent,
        JobOrderDetailsTeamComponent,
        TeamMemberMobileComponent,
        JobOrderDetailsAdditionalDetailsComponent,
        PhoneFormatPipe,
        TeamMemberComponent,
        FilterCloseDirective,
        FooterJobsComponent
    ],
    providers: [JobService]
})
export class JobOrdersModule {}
