<h2 class="title">Contact Info</h2>
<div class="team">
    <app-team-member class="recruiter" [details]=jobOrderDetails [title]='"Recruiter"' [name]='jobOrderDetails.recruiterName'
                     [phone]='jobOrderDetails.recruiterPhoneNumber'
                     [mobile]='jobOrderDetails.recruiterMobilePhoneNumber' [email]='jobOrderDetails.recruiterEmail'
                     [phoneExtension]='jobOrderDetails.recruiterPhoneNumberExtension'></app-team-member>
    <hr>
    <app-team-member class="account-executive" [details]=jobOrderDetails [title]='"Account Executive"'
                     [name]='jobOrderDetails.accountExecutiveName' [phone]='jobOrderDetails.accountExecutivePhoneNumber'
                     [mobile]='jobOrderDetails.accountExecutiveMobilePhoneNumber'
                     [email]='jobOrderDetails.accountExecutiveEmail'
                     [phoneExtension]='jobOrderDetails.accountExecutivePhoneNumberExtension'></app-team-member>
    <app-team-member-mobile class="account-executive-mobile" [details]=jobOrderDetails [title]='"Recruiter"'
                            [name]='jobOrderDetails.recruiterName' [phone]='jobOrderDetails.recruiterPhoneNumber'
                            [mobile]='jobOrderDetails.recruiterMobilePhoneNumber'
                            [email]='jobOrderDetails.recruiterEmail'
                            [phoneExtension]='jobOrderDetails.recruiterPhoneNumberExtension'></app-team-member-mobile>
    <app-team-member-mobile class="account-executive-mobile" [title]='"Account Executive"'
                            [name]='jobOrderDetails.accountExecutiveName'
                            [phone]='jobOrderDetails.accountExecutivePhoneNumber'
                            [mobile]='jobOrderDetails.accountExecutiveMobilePhoneNumber'
                            [email]='jobOrderDetails.accountExecutiveEmail'
                            [phoneExtension]='jobOrderDetails.accountExecutivePhoneNumberExtension'></app-team-member-mobile>
</div>