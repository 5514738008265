<div class="filter-container">
    <app-search-bar [formControl]="searchFormControl" forCandidates="true" placeHolder="Search" ngDefaultControl></app-search-bar>
    <hr>
    <div class="filters" #filter_container>
        <h2>Pipeline</h2>
        <div>
            <div>
                <input type="checkbox" id="favorited" [(ngModel)]="appliedFilters.favorited" (change)="filterCandidateList()" />
                <label for="favorited">
                    Favorited
                </label>
            </div>
            <div>
                <input type="checkbox" id="interviewed" [(ngModel)]="appliedFilters.interviewed" (change)="filterCandidateList()" />
                <label for="interviewed">
                    Interviewed
                </label>
            </div>
            <div>
                <input type="checkbox" id="active" [(ngModel)]="appliedFilters.active" (change)="filterCandidateList()" />
                <label for="active">
                    Active Placement
                </label>
            </div>
            <div>
                <input type="checkbox" id="inTheRunning" [(ngModel)]="appliedFilters.inTheRunning" (change)="filterCandidateList()" />
                <label for="inTheRunning">
                    In the Running
                </label>
            </div>
            <div>
                <input type="checkbox" id="placedBefore" [(ngModel)]="appliedFilters.placedBefore" (change)="filterCandidateList()" />
                <label for="placedBefore">
                    Hired Before
                </label>
            </div>
            <div>
                <input type="checkbox" id="blocked" [(ngModel)]="appliedFilters.blocked" (change)="filterCandidateList()" />
                <label for="blocked">
                    Blocked
                </label>
            </div>
            <div>
                <input type="checkbox" id="notSelected" [(ngModel)]="appliedFilters.notSelected" (change)="filterCandidateList()" />
                <label for="notSelected">
                    Not Selected
                </label>
            </div>
        </div>
        <hr *ngIf="territories?.length > 0">
        <div class="area column" *ngIf="territories?.length > 0">
            <h2>Areas</h2>
            <div *ngFor="let territory of territories">
                <input type="checkbox" [id]="filterNameFromTerritoryId(territory.territoryId)" [name]="filterNameFromTerritoryId(territory.territoryId)" [(ngModel)]="appliedFilters[filterNameFromTerritoryId(territory.territoryId)]" (change)="filterCandidateList()" />
                <label [for]="filterNameFromTerritoryId(territory.territoryId)">
                    {{ territory.territoryName }}
                </label>
            </div>
        </div>
        <hr *ngIf="jobCategories && jobCategories.length">
        <div class="area column" *ngIf="jobCategories && jobCategories.length">
            <h2>Looking For</h2>
            <div *ngFor="let jobCategory of jobCategories">
                <input type="checkbox" [id]="filterNameFromJobCategoryId(jobCategory.jobCategoryId)" [name]="filterNameFromJobCategoryId(jobCategory.jobCategoryId)" [(ngModel)]="appliedFilters[filterNameFromJobCategoryId(jobCategory.jobCategoryId)]" (change)="filterCandidateList()" />
                <label [for]="filterNameFromJobCategoryId(jobCategory.jobCategoryId)">
                    {{ jobCategory.jobCategory }}
                </label>
            </div>
        </div>
    </div>
    <div class="filters-mobile">
        <div class="filter-and-sort-container-mobile">
            <button class="filters" [class.active]="mobileFilterDropdownActive" [class.inactive]="noResults" (click)="toggleMobileFilterDropdown()">
                <span>
                    <svg width="20" height="11" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#131e27" fill-rule="evenodd" opacity=".6">
                            <rect width="20" height="2.2" rx="1.1" />
                            <rect x="3.571" y="4.4" width="12.857" height="2.2" rx="1.1" />
                            <rect x="6.429" y="8.8" width="7.143" height="2.2" rx="1.1" />
                        </g>
                    </svg>
                </span> FILTER BY {{ numFiltersApplied? "( " + numFiltersApplied + " )": "" }}
            </button>
            <button class="sorts" (clickOutside)="closeSort()" [class.active]="mobileSortDropdownActive" [class.inactive]="noResults" (click)="toggleMobileSortDropdown()">
                <span>
                    <svg width="12" height="14" viewBox="0 0 15 17" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="#131e27" stroke-width="1.8" fill="none" fill-rule="evenodd" opacity=".6" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M9.667 5.286L5.333 1 1 5.286M5.333 6.357V1" />
                            <g>
                                <path d="M5.333 11.714L9.667 16 14 11.714M9.667 10.643V16" />
                            </g>
                        </g>
                    </svg>
                </span> SORT BY
            </button>
            <div *ngIf="!noResults" class="active" [class.active]="mobileFilterDropdownActive" class="filter-container">
                <div class="row header-row">
                    <span>FILTER</span>
                    <button class="close-btn clear-all-btn" (click)="clearFilters('all')">
                        CLEAR ALL FILTERS
                    </button>
                </div>
                <hr>
                <div class="filter-by-dropdown-container">
                    <div class="filter-dropdown">
                        <div class="input-fields">
                            <div>
                                <button aria-label="Clear" class="clear-btn" (click)="clearFilters('pipeline')">Clear</button>
                                <h2 class="headline">Pipeline</h2>
                                <div class="input">
                                    <input type="checkbox" class="candidate-filter-mobile" name="favorited-mobile" id="favorited-mobile" [(ngModel)]="appliedFilters.favorited" (change)="filterCandidateList()">
                                    <label for="favorited-mobile">
                                        Favorited
                                    </label>
                                </div>
                                <div class="input">
                                    <input type="checkbox" class="candidate-filter-mobile" name="interviewed-mobile" id="interviewed-mobile" [(ngModel)]="appliedFilters.interviewed" (change)="filterCandidateList()">
                                    <label for="interviewed-mobile">
                                        Interviewed
                                    </label>
                                </div>
                                <div class="input">
                                    <input type="checkbox" class="candidate-filter-mobile" name="active-mobile" id="active-mobile" [(ngModel)]="appliedFilters.active" (change)="filterCandidateList()">
                                    <label for="active-mobile">
                                        Active Placement
                                    </label>
                                </div>
                                <div class="input">
                                    <input type="checkbox" class="candidate-filter-mobile" name="inTheRunning-mobile" id="inTheRunning-mobile" [(ngModel)]="appliedFilters.inTheRunning" (change)="filterCandidateList()">
                                    <label for="inTheRunning-mobile">
                                        In the Running
                                    </label>
                                </div>
                                <div class="input">
                                    <input type="checkbox" class="candidate-filter-mobile" name="placedBefore-mobile" id="placedBefore-mobile" [(ngModel)]="appliedFilters.placedBefore" (change)="filterCandidateList()">
                                    <label for="placedBefore-mobile">
                                        Hired Before
                                    </label>
                                </div>
                                <div class="input">
                                    <input type="checkbox" class="candidate-filter-mobile" name="blocked-mobile" id="blocked-mobile" [(ngModel)]="appliedFilters.blocked" (change)="filterCandidateList()">
                                    <label for="blocked-mobile">
                                        Blocked
                                    </label>
                                </div>
                                <div class="input">
                                    <input type="checkbox" class="candidate-filter-mobile" name="notSelected-mobile" id="notSelected-mobile" [(ngModel)]="appliedFilters.notSelected" (change)="filterCandidateList()">
                                    <label for="notSelected-mobile">
                                        Not Selected
                                    </label>
                                </div>
                            </div>
                            <hr *ngIf="territories?.length > 0">
                            <div *ngIf="territories?.length > 0">
                                <button aria-label="Clear" class="clear-btn" (click)="clearFilters('area')">Clear</button>
                                <h2 class="headline">Area</h2>
                                <div *ngFor="let territory of territories">
                                    <div class="input">
                                        <input type="checkbox" [id]="'mobile-' + filterNameFromTerritoryId(territory.territoryId)" [name]="'mobile-' + filterNameFromTerritoryId(territory.territoryId)" [(ngModel)]="appliedFilters[filterNameFromTerritoryId(territory.territoryId)]" (change)="filterCandidateList()" />
                                        <label [for]="'mobile-' + filterNameFromTerritoryId(territory.territoryId)">
                                            {{ territory.territoryName }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <hr *ngIf="territories?.length > 0">
                            <div *ngIf="jobCategories && jobCategories.length > 0">
                                <button aria-label="Clear" class="clear-btn" (click)="clearFilters('jobcategories')">Clear</button>
                                <h2 class="headline">Looking For</h2>
                                <div class="checkbox-container" *ngFor="let category of jobCategories">
                                    <div class="input">
                                        <input type="checkbox" [id]="'mobile-' + filterNameFromJobCategoryId(category.jobCategoryId)" [name]="'mobile-' + filterNameFromJobCategoryId(category.jobCategoryId)" [(ngModel)]="appliedFilters[filterNameFromJobCategoryId(category.jobCategoryId)]" (change)="filterCandidateList()" />
                                        <label [for]="'mobile-' + filterNameFromJobCategoryId(category.jobCategoryId)">
                                            {{ category.jobCategory }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="view-all-results">
                                <button (click)="closeFilter()">VIEW RESULTS</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [class.active]="mobileSortDropdownActive" class="sort-container popover">
                <div class="sort-by-dropdown-container">
                    <div class="sort-by-dropdown">
                        <div class="row row-header">
                            <span>SORT</span>
                            <button class="close-btn" (click)="closeSort()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                            </button>
                        </div>
                        <div class="clearfix"></div>
                        <div class="radio-btn-container filter-inputs">
                            <div class="name-asc area" (click)="changeSort('CandidateFirstName', 'ASC')">
                                <div class="check-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check" [class.active-sort]="sortBy === 'CandidateFirstName-ASC'">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </div>
                                <p>Name - A to Z</p>
                            </div>
                            <div class="name-desc area" (click)="changeSort('CandidateFirstName', 'DESC')">
                                <div class="check-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check" [class.active-sort]="sortBy === 'CandidateFirstName-DESC'">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </div>
                                <p>Name - Z to A</p>
                            </div>
                            <div class="title-asc area" (click)="changeSort('BioHeadline', 'ASC')">
                                <div class="check-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check" [class.active-sort]="sortBy === 'BioHeadline-ASC'">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </div>
                                <p>Headline - A to Z</p>
                            </div>
                            <div class="title-desc area" (click)="changeSort('BioHeadline', 'DESC')">
                                <div class="check-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check" [class.active-sort]="sortBy === 'BioHeadline-DESC'">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </div>
                                <p>Headline - Z to A</p>
                            </div>
                            <div class="area-asc area" (click)="changeSort('TrtyName', 'ASC')">
                                <div class="check-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check" [class.active-sort]="sortBy === 'TrtyName-ASC'">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </div>
                                <p>Area - A to Z</p>
                            </div>
                            <div class="area-desc area" (click)="changeSort('TrtyName', 'DESC')">
                                <div class="check-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check" [class.active-sort]="sortBy === 'TrtyName-DESC'">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </div>
                                <p>Area - Z to A</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
