<div class="action-items-container dashboard-section action-items" [class.active-section]="activeSection">
    <div class="headline-container-mobile">
        <button aria-label="View All Actions" class="view-all-btn">VIEW ALL ACTIONS</button>
    </div>
    <div class="headline-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell">
            <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
        </svg>
        <p class="category-title">Action Items ({{actionItemCount}})</p>
    </div>
    <div class="action-items-background">
        <div class="loading-shade" *ngIf="isLoadingResults">
            <svg class="loading-gif" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
                stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        </div>

        <app-loading-error [apiError]="loadingError" (onRetry)="gatherMessages()"></app-loading-error>

        <div *ngIf="actionItems && actionItems.length == 0 && !isLoadingResults && !loadingError" class="empty-state">
            <p>You currently have no pending action items.</p>
        </div>
        <div *ngIf="actionItems && actionItems.length > 0 && !loadingError">
            <div class="action-item" *ngFor="let item of actionItems"
                (click)="(item.type === 'BillRateConfirmation' && isLimitedImpersonation) || clickHandler(item)">
                <div class="action-container">
                    <div class="action-content">
                        <div class="left-container">
                            <div class="icon-container">
                                <svg class="icon" height="18" width="18" viewBox="0 0 18 18" *ngIf="item.type === 'Message'">
                                    <use xlink:href="../assets/sprites/sprite.svg#message-square"></use>
                                </svg>

                                <svg class="icon" height="18" width="18" viewBox="0 0 18 18" *ngIf="item.type === 'NewCandidates'">
                                    <use xlink:href="../assets/sprites/sprite.svg#user-plus" />
                                </svg>

                                <svg class="icon" height="18" width="18" viewBox="0 0 18 18" *ngIf="item.type === 'BillRateConfirmation'">
                                    <use xlink:href="../assets/sprites/sprite.svg#dollar-sign" />
                                </svg>

                                <svg class="icon" height="18" width="18" viewBox="0 0 18 18" *ngIf="item.type === 'PerfReviewNeeded'">
                                    <use xlink:href="../assets/sprites/sprite.svg#user-check" />
                                </svg>
                            </div>
                            <div>
                                <p class="title" *ngIf="item.type === 'Message'">
                                    {{ item.messageTitle }}
                                </p>
                                <p class="title" *ngIf="item.type === 'NewCandidates'">
                                    New Candidate Submittal{{ item.noCandidates > 1 ? "s" : "" }} To Review for {{
                                    item.jobTitle }}
                                </p>
                                <p class="title" *ngIf="item.type === 'BillRateConfirmation'">
                                    Bill Rate Approval Required for {{ item.candFirstName }} {{ item.candLastName }}
                                </p>
                                <p class="title" *ngIf="item.type === 'PerfReviewNeeded'">
                                    Performance Review Requested for {{ item.candFirstName }} {{ item.candLastName }}
                                </p>

                                <div class="message-container">
                                    <p class="message" *ngIf="item.type === 'Message'" [innerHTML]="item.messageBody.length > 130 ? (item.messageBody | slice:0:130) + '...' : item.messageBody"></p>
                                    <p class="message" *ngIf="item.type === 'NewCandidates'">
                                        Check &apos;em out and let us know next steps.
                                    </p>
                                    <p class="message" *ngIf="item.type === 'BillRateConfirmation'">
                                        Let&apos;s make this placement official: Check the {{ item.jobFulltimeFlag ?
                                        "placement
                                        fee" : "billing rates" }} and confirm
                                        if we've captured it correctly.
                                    </p>
                                    <p class="message" *ngIf="item.type === 'PerfReviewNeeded'">
                                        How&apos;d it go? Help us to improve our service to you.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="user-action">
                        <p class="action" *ngIf="item.type === 'Message'">
                            Read
                            <span class="chevron right"></span>
                        </p>
                        <p class="action" *ngIf="item.type === 'NewCandidates'">
                            Review
                            <span class="chevron right"></span>
                        </p>
                        <p class="action" *ngIf="item.type === 'BillRateConfirmation'">
                            Confirm
                            <span class="chevron right"></span>
                        </p>
                        <p class="action" *ngIf="item.type === 'PerfReviewNeeded'">
                            Review
                            <span class="chevron right"></span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="show-more-container">
                <button *ngIf="currentPage < pages" [ngClass]="{'button': true, 'disabled invalid-btn': currentPage >= pages}" (click)="showMoreItems()" [disabled]="currentPage >= pages">Show More</button>
                <p *ngIf="currentPage >= pages">Showing All Action Items</p>
            </div>
        </div>
    </div>
</div>
