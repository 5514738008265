import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, Form, UntypedFormGroup, FormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from '../../services/api.service';
import { JobService } from '../../services/job-orders.service';
import { JobOrderCandidate } from '../../models/job-order-candidate.model';
import { ApiError } from '../../models/api-error.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UserService } from '../../services';
import { NetworkErrorSnackbarService } from "../../../shared/services/network-error-snackbar.service";
import { switchMap } from "rxjs/operators";

export const CLOSE_SUCCESS = 'SUCCESS';


@Component({
    selector: 'app-hired-dialog',
    templateUrl: './hired-dialog.component.html',
    styleUrls: ['./hired-dialog.component.scss']
})
export class HiredDialogComponent {

    public form: UntypedFormGroup;
    public isSubmitting: boolean = false;
    public formError: string;
    public isLimitedImpersonation: boolean = false;
    public countMax: number = 4000;
    public addToNotes: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<HiredDialogComponent>,
        private jobOrdersService: JobService,
        private dialog: MatDialog,
        private userService: UserService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
    ) {
        this.form = this.fb.group({
            details: [null, Validators.compose([
                Validators.required,
                Validators.maxLength(4000),
            ])],
            jobCandidateId: [this.data.jobCandidateId, Validators.compose([
                Validators.required,
            ])],
            officeId: [this.data.officeId, Validators.compose([
                Validators.required,
            ])],
            jobId: [this.data.jobId, Validators.compose([
                Validators.required,
            ])],
            statusId: [this.data.statusId, Validators.compose([
                Validators.required,
            ])],
            candidateId: [
                this.data.candidateId,
                Validators.compose([Validators.required])
            ],
            addToNotes: new FormControl({
                value: true,
                disabled: true
            }),
            sharedCC: [false],
            sharedHiringManagers: new FormControl({
                value: false,
                disabled: true
            })
        });

        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });

        this.form.controls['details'].valueChanges.subscribe(content => {
            this.countMax = 4000 - content.length;
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    submit() {
        if (this.form.valid && !this.isSubmitting) {
            this.isSubmitting = true;
            let details = "Hired Details: " + this.form.value["details"];
            let params = {'statusId': this.form.value['statusId'], 'details': details};
            let patchJobCandidate = this.jobOrdersService.patchJobCandidate(
                this.form.value['officeId'],
                this.form.value['jobId'],
                this.form.value['jobCandidateId'],
                params
            );

            if (this.addToNotes === true) {
                const addNotes = this.jobOrdersService.postJobCandidateNotes(
                    this.form.value["officeId"],
                    this.form.value["jobId"],
                    this.form.value["candidateId"],
                    this.form.value["jobCandidateId"],
                    {
                        note: details,
                        private: false,
                        sharedCC: true,
                        sharedHiringManagers: this.form.get("sharedHiringManagers").value,
                        sendAlert: false
                    }
                );
    
                patchJobCandidate = patchJobCandidate.pipe(
                    switchMap(() => addNotes)
                );
            }

            patchJobCandidate.subscribe(/* TODO handle timeout */
                _ => this.dialogRef.close(CLOSE_SUCCESS),
                (err: ApiError) => {
                    this.isSubmitting = false;
                    if (err.statusCode === 400) {
                        this.formError = 'Error! Please close the dialog and try again.';
                    } else {
                        this.networkErrorSnackbarService.networkError(err,'')
                    }
                }
            );
        }
    }

    determineInput(event) {
        const value = event.target.value;
        const checked = event.target.checked;

        if (value === 'addToNotes' && checked) {
            this.addToNotes = true;
            this.form.controls['sharedCC'].setValue(true);
        } else if (value === 'addToNotes' && !checked) {
            this.addToNotes = false;
            this.form.controls['sharedCC'].setValue(false);
            this.form.controls['sharedHiringManagers'].setValue(false);
        }
    }
}
