import { ModuleWithProviders, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppComponent } from "./app.component";
import {
    ApiService,
    AuthGuard,
    JwtService,
    UserService,
    HttpTokenInterceptor,
    HttpErrorInterceptor,
    MessageService,
    DashboardService,
    JobService,
    NavigationScrollService,
    HamburgerService,
    CandidatesService,
    MaintenanceService,
    CandidateModalDirectionService,
    CandidateModalCandidateService,
    EditModeService,
    SearchNotesModeService,
    CandidateSearchService,
    SortActiveService,
    UpdateCandidateListService
} from "./shared";

import { AdminModule } from "./admin/admin.module";
import { ClientModule } from "./client/client.module";
import { LbTitle } from "./shared/services/title.service";
import { AnalyticsService } from "./shared/services/analytics.service";
import { AppVersionService } from "./shared/services/app-version.service";
import { RescheduledInterviewService } from "./shared/services/rescheduled-interview.service";
import { ViewSDKClient } from "./shared/services/view-sdk.service";
import { DatePipe } from "@angular/common";

const rootRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot([], {});

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, AdminModule, ClientModule, rootRouting],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpTokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        ApiService,
        AuthGuard,
        JwtService,
        UserService,
        MessageService,
        DashboardService,
        JobService,
        NavigationScrollService,
        HamburgerService,
        CandidatesService,
        LbTitle,
        AnalyticsService,
        MaintenanceService,
        CandidateModalDirectionService,
        CandidateModalCandidateService,
        EditModeService,
        SearchNotesModeService,
        CandidateSearchService,
        UpdateCandidateListService,
        SortActiveService,
        AppVersionService,
        RescheduledInterviewService,
        ViewSDKClient,
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
