import { Job } from "../models/job.model";
import {
    JOB_STATUS_PAST,
    JOB_STATUS_CANCELLED,
    JOB_STATUS_ACTIVE,
    JOB_STATUS_CLOSED,
    JOB_STATUS_FT_PLACEMENT
} from "../services/job-orders.service";
import { JobOrderListComponent } from "../../client/job-orders/job-order-list/job-order-list.component";

export const jobColorFromJob = (job: Job) => {
    const greyStatuses = [
        JOB_STATUS_PAST,
        JOB_STATUS_CANCELLED,
        JOB_STATUS_CLOSED,
        JOB_STATUS_FT_PLACEMENT
    ];

    if (
        job.status == JOB_STATUS_ACTIVE ||
        JobOrderListComponent.isJobActiveFullTime(job)
    )
        return "green";
    if (greyStatuses.indexOf(job.status) !== -1) return "grey";
    if (job.numNewSubmittals > 0) return "red";
    if (job.numUnderConsideration > 0) return "orange";

    return "blue";
};

export const candidateColorFromStatusId = (statusId: number) => {
    const map = {
        1: "red",
        2: "orange",
        3: "orange",
        5: "grey",
        6: "orange",
        7: "orange",
        8: "green",
        9: "grey",
        12: "grey",
        14: "grey"
    };

    if (map[statusId]) return map[statusId];

    return "grey";
};
