import { Component, OnInit, Input } from '@angular/core';
import { JobDetails } from '../../../../../shared';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-team-member',
    templateUrl: './team-member.component.html',
    styleUrls: ['./team-member.component.scss']
})
export class TeamMemberComponent implements OnInit {

    @Input() title: string;
    @Input() name: string;
    @Input() phone: string;
    @Input() mobile: string;
    @Input() email: string;
    @Input() phoneExtension: string;
    @Input() details: any;
    safeMobileNumber: SafeUrl;

    constructor(
        private sanitizer: DomSanitizer,
        private analyticsService: AnalyticsService
        ) {
    }

    formatPhoneNumber(number) {
        let s2 = ('' + number).replace(/\D/g, '');
        let m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return (!m) ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3];
    }

    contactRecruiter(type) {
        if (this.title === 'Account Executive') {
            this.analyticsService.fireEvent(
                'Creative Circle Team',
                'Contact Team Member Click',
                type
            );

        } else {
            this.analyticsService.fireEvent(
                'Jobs',
                'Recruiter Contact Click',
                type,
                {'dimension5': this.details.jobId.toString() + "." + this.details.officeId.toString()}
            );
        }
    }

    ngOnInit() {
        this.safeMobileNumber = this.sanitizer.bypassSecurityTrustUrl('sms:' + this.mobile);
    }

}
