import { Component, Input, OnInit } from '@angular/core';
import {JobService, Candidate, UserService, JobOrderCandidate, Job, DashboardService} from './../../../shared';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PassedOnDialogComponent } from './../../../shared/components/passed-on-dialog/passed-on-dialog.component';
import { AlertDialogComponent } from './../../../shared/components/alert-dialog/alert-dialog.component';
import { RequestInterviewDialogComponent } from './../../../shared/components/request-interview-dialog/request-interview-dialog.component';
import { HiredDialogComponent } from './../../../shared/components/hired-dialog/hired-dialog.component';
import { AnalyticsService } from './../../../shared/services/analytics.service';
import { KeepInTheRunningComponent } from '../../../shared/components/keep-in-the-running/keep-in-the-running.component';
import { ApiError } from '../../../shared/models/api-error.model';
import { NetworkErrorSnackbarService } from '../../../shared/services/network-error-snackbar.service';

@Component({
    selector: 'app-candidate-details-action',
    templateUrl: './candidate-details-action.component.html',
    styleUrls: ['./candidate-details-action.component.scss']
})
export class CandidateDetailsActionComponent {
    @Input()
    candidate: Candidate;

    @Input()
    job: Job;

    @Input()
    jobOrderCandidate: JobOrderCandidate;

    @Input()
    candidateObject: Candidate;

    public sendMore: boolean = false;

    constructor(
        private dialog: MatDialog,
        private jobOrdersService: JobService,
        private analyticsService: AnalyticsService,
        private userService: UserService,
        private dashboardService: DashboardService,
        private jobsService: JobService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService
    ) {}

    ngOnInit() {
    }

    updateJobList() {
        this.jobOrdersService.getJobs().subscribe({
            error: (apiError: ApiError) => {
                // TODO: show error alert
            }
        });
        this.jobOrdersService
            .getJobDetails(
                this.jobOrderCandidate.officeId,
                this.jobOrderCandidate.jobId
            )
            .subscribe({
                error: (apiError: ApiError) => {
                    // TODO: show error alert
                }
            });
    }

    passOnAction(jobCandidate, statusId) {
        if (this.candidateObject.blocked === true) {
            this.blockedWarning();
            return;
        }

        this.analyticsService.fireEvent(
            "Job Candidates",
            "Job Candidate Status Update Click",
            jobCandidate.statusId,
            {
                dimension6: jobCandidate.jobCandidateId,
                dimension5:
                    jobCandidate.jobId.toString() +
                    "." +
                    jobCandidate.officeId.toString() +
                    "." +
                    jobCandidate.officeId.toString(),
                dimension3:
                    jobCandidate.clientId.toString() +
                    "." +
                    jobCandidate.officeId.toString()
            }
        );
        const dialog = this.dialog.open(PassedOnDialogComponent, {
            width: "600px",
            maxWidth: "90%",
            maxHeight: "90%",
            panelClass: "mobileContainer",
            autoFocus: false,
            data: {
                candidateFirstName: jobCandidate.firstName,
                officeId: this.jobOrderCandidate.officeId,
                jobId: this.jobOrderCandidate.jobId,
                jobCandidateId: this.jobOrderCandidate.jobCandidateId,
                candidateId: this.jobOrderCandidate.candidateId,
                statusId: statusId
            }
        });

        dialog.afterClosed().subscribe(result => {
            if (result === "SUCCESS") {
                this.analyticsService.fireEvent(
                    "Job Candidates",
                    "Job Candidate Status Updated",
                    statusId,
                    {
                        dimension6: jobCandidate.jobCandidateId,
                        dimension3:
                            jobCandidate.clientId.toString() +
                            "." +
                            jobCandidate.officeId.toString(),
                        dimension5:
                            jobCandidate.jobId.toString() +
                            "." +
                            jobCandidate.officeId.toString()
                    }
                );
                this.updateJobList();
            }
        });
    }

    keepInTheRunning(jobCandidate, statusId) {
        if (this.candidateObject.blocked === true) {
            this.blockedWarning();
            return;
        }

        this.analyticsService.fireEvent(
            "Job Candidates",
            "Job Candidate Status Update Click",
            jobCandidate.statusId,
            {
                dimension6: jobCandidate.jobCandidateId,
                dimension3:
                    jobCandidate.clientId.toString() +
                    "." +
                    jobCandidate.officeId.toString(),
                dimension5:
                    jobCandidate.jobId.toString() +
                    "." +
                    jobCandidate.officeId.toString()
            }
        );

        const dialog = this.dialog.open(KeepInTheRunningComponent, {
            width: "600px",
            maxWidth: "90%",
            maxHeight: "90%",
            autoFocus: false,
            data: {
                candidateFirstName: jobCandidate.firstName,
                officeId: this.jobOrderCandidate.officeId,
                jobId: this.jobOrderCandidate.jobId,
                jobCandidateId: this.jobOrderCandidate.jobCandidateId,
                candidateId: this.jobOrderCandidate.candidateId,
                statusId: statusId
            }
        });

        dialog.afterClosed().subscribe(result => {
            if (result === "SUCCESS") {
                this.updateJobList();

                this.analyticsService.fireEvent(
                    "Job Candidates",
                    "Job Candidate Status Updated",
                    statusId,
                    {
                        dimension6: jobCandidate.jobCandidateId,
                        dimension3:
                            jobCandidate.clientId.toString() +
                            "." +
                            jobCandidate.officeId.toString(),
                        dimension5:
                            jobCandidate.jobId.toString() +
                            "." +
                            jobCandidate.officeId.toString()
                    }
                );
            }
        });
    }

    openHiredDialog(jobCandidate, statusId) {
        if (this.candidateObject.blocked === true) {
            this.blockedWarning();
            return;
        }

        this.analyticsService.fireEvent(
            "Job Candidates",
            "Job Candidate Status Update Click",
            jobCandidate.statusId,
            {
                dimension6: jobCandidate.jobCandidateId,
                dimension3:
                    jobCandidate.clientId.toString() +
                    "." +
                    jobCandidate.officeId.toString(),
                dimension5:
                    jobCandidate.jobId.toString() +
                    "." +
                    jobCandidate.officeId.toString()
            }
        );
        let dialog = this.dialog.open(HiredDialogComponent, {
            width: "600px",
            maxWidth: "90%",
            maxHeight: "90%",
            autoFocus: false,
            data: {
                candidateFirstName: jobCandidate.firstName,
                candidateLastName: jobCandidate.lastName,
                officeId: jobCandidate.officeId,
                jobId: jobCandidate.jobId,
                jobCandidateId: jobCandidate.jobCandidateId,
                statusId: statusId,
                candidateId: this.jobOrderCandidate.candidateId
            }
        });

        dialog.afterClosed().subscribe(result => {
            if (result == "SUCCESS") {
                this.dialog.open(AlertDialogComponent, {
                    width: "350px",
                    data: {
                        title: "Got it!",
                        message:
                            "We'll follow up with the candidate and get back in touch with you as soon as we have a confirmation."
                    }
                });
                this.analyticsService.fireEvent(
                    "Job Candidates",
                    "Job Candidate Status Updated",
                    statusId,
                    {
                        dimension6: jobCandidate.jobCandidateId,
                        dimension3:
                            jobCandidate.clientId.toString() +
                            "." +
                            jobCandidate.officeId.toString(),
                        dimension5:
                            jobCandidate.jobId.toString() +
                            "." +
                            jobCandidate.officeId.toString()
                    }
                );

                this.jobOrderCandidate.statusId = statusId;
                this.updateJobList();
            }
        });
    }

    openRequestInterviewDialog(jobCandidate, statusId) {
        if (this.candidateObject.blocked === true) {
            this.blockedWarning();
            return;
        }

        this.analyticsService.fireEvent(
            "Job Candidates",
            "Job Candidate Status Update Click",
            jobCandidate.statusId,
            {
                dimension6: jobCandidate.jobCandidateId,
                dimension3:
                    jobCandidate.clientId.toString() +
                    "." +
                    jobCandidate.officeId.toString(),
                dimension5:
                    jobCandidate.jobId.toString() +
                    "." +
                    jobCandidate.officeId.toString()
            }
        );
        let dialog = this.dialog.open(RequestInterviewDialogComponent, {
            width: "600px",
            maxWidth: "90%",
            maxHeight: "90%",
            autoFocus: false,
            data: {
                title: "SCHEDULE INTERVIEW WITH ",
                officeId: jobCandidate.officeId,
                candidateName:
                    jobCandidate.firstName + " " + jobCandidate.lastName,
                jobId: jobCandidate.jobId,
                jobCandidateId: jobCandidate.jobCandidateId,
                statusId: statusId,
                candidateId: this.jobOrderCandidate.candidateId
            }
        });

        dialog.afterClosed().subscribe(result => {
            if (result == "SUCCESS") {
                this.jobOrderCandidate.statusId = statusId;

                this.dialog.open(AlertDialogComponent, {
                    width: "350px",
                    data: {
                        title: "Thanks!",
                        message:
                            "We will follow up to provide you with a confirmation as soon as possible."
                    }
                });
                this.analyticsService.fireEvent(
                    "Job Candidates",
                    "Job Candidate Status Updated",
                    statusId,
                    {
                        dimension6: jobCandidate.jobCandidateId,
                        dimension5:
                            jobCandidate.jobId.toString() +
                            "." +
                            jobCandidate.officeId.toString()
                    }
                );
                this.updateJobList();
            }
        });
    }

    private blockedWarning() {
        const dialog = this.dialog.open(AlertDialogComponent, {
            panelClass: 'block_candidate_panel',
            data: {
                blockedNonPassedMessage: true,
                buttonText: 'Unblock',
                candidateFirstName: this.candidate.firstName
            }
        });

        dialog.afterClosed().subscribe(result => {
            if (result) {
                const params = {'blocked': false};
                this.analyticsService.fireEvent(
                    'Job Candidates',
                    'Unblocked',
                    '',
                    {
                        'dimension6': this.jobOrderCandidate.jobCandidateId,
                        'dimension3': this.jobOrderCandidate.clientId.toString() + '.' + this.jobOrderCandidate.officeId.toString(),
                        'dimension5': this.jobOrderCandidate.jobId.toString() + '.' + this.jobOrderCandidate.officeId.toString()
                    });
                this.dashboardService.updateJobCandidatePreference(
                    this.jobOrderCandidate.officeId,
                    this.jobOrderCandidate.jobId,
                    this.candidate.candidateId, params
                )
                .subscribe(data => {
                        this.candidate.blocked = true;
                        this.jobOrderCandidate.blocked = true;
                    },
                    (err: ApiError) => {
                        return this.networkErrorSnackbarService.networkError(err, '');
                    }
                );
                this.candidateObject.blocked = false;
            }
        });
    }
}
