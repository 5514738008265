import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BRCDialogComponent } from './components/brc-dialog/brc-dialog.component';
import { MessagesComponent } from './components/messages/messages.component';
import { PerfReviewDialogComponent } from './components/perf-review-dialog/perf-review-dialog.component';
import { ShowAuthedDirective } from './directives/show-authed.directive';
import { CheckComponent } from './components/icon/check/check.component';
import { FilterComponent } from './components/icon/filter/filter.component';
import { SortComponent } from './components/icon/sort/sort.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule
    ],
    declarations: [
        ShowAuthedDirective,
        MessagesComponent,
        BRCDialogComponent,
        PerfReviewDialogComponent,
        CheckComponent,
        FilterComponent,
        SortComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        ShowAuthedDirective,
        MessagesComponent,
        CheckComponent,
        FilterComponent,
        SortComponent
    ]
})
export class SharedModule {
}
