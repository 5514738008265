import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import {
    JobDetails,
    Job,
    JobOrderCandidate,
    JobService,
    CandidateDetail,
    CandidatesService
} from "../../../../shared";
import { Router } from "@angular/router";
import { ApiError } from "../../../../shared/models/api-error.model";
import { Subscribing } from "../../../../shared/components/subscribing.component";
import { RescheduledInterviewService } from "../../../../shared/services/rescheduled-interview.service";

@Component({
    selector: "app-job-order-candidate-details",
    templateUrl: "./job-order-candidate-details.component.html",
    styleUrls: ["./job-order-candidate-details.component.scss"]
})
export class JobOrderCandidateDetailsComponent extends Subscribing
    implements OnInit {
    public candidateDetails: JobOrderCandidate;
    public isLoadingResults: boolean = true;
    public loadingError: ApiError;
    public candidate: CandidateDetail;
    public hiredCandId: number = 0;
    public doNotUse: boolean = false;

    @Input()
    jobOrderCandidate: JobOrderCandidate;

    @Input()
    jobOrderDetails: JobDetails;

    @Input()
    jobOrder: Job;

    @Input()
    mobile: boolean = false;

    constructor(
        private sanitizer: DomSanitizer,
        private jobOrdersService: JobService,
        private candidatesService: CandidatesService,
        private router: Router,
        private rescheduledInterviewService: RescheduledInterviewService
    ) {
        super();
    }

    ngOnInit() {
        this.callSequence();

        this.rescheduledInterviewService.update$.subscribe(bool => {
            if (bool) {
                this.callSequence();
            }
        });
    }

    isDoNotUse() {
        if (!this.candidate.activeFlag && !this.candidate.deleteFlag) {
            return true; 
        }
        return false;
    }

    refreshJobCandidate() {
        if (!this.jobOrderCandidate || !this.jobOrderCandidate.officeId) {
            return;
        }

        this.jobOrderCandidate["clientId"] = this.jobOrderDetails["clientId"];

        this.loadingError = null;

        this.jobOrdersService
            .getJobCandidate(
                this.jobOrderCandidate.officeId,
                this.jobOrderCandidate.jobId,
                this.jobOrderCandidate.jobCandidateId
            )
            .subscribe(
                jobCandidate => {
                    jobCandidate.safeBioBody = this.sanitizer.bypassSecurityTrustHtml(
                        jobCandidate.bioBody
                    );
                    this.candidateDetails = jobCandidate;
                },
                (error: ApiError) => {
                    this.candidateDetails = null;

                    if (error.statusCode === 404) {
                        this.router.navigateByUrl("/not-found");
                    } else {
                        this.loadingError = error;
                    }
                }
            )
            .add(() => (this.isLoadingResults = false));

        this.candidatesService
            .getCandidatesDetails(
                this.jobOrderCandidate.officeId,
                this.jobOrderCandidate.candidateId
            )
            // TODO handle timeout
            .subscribe(
                (candidate: CandidateDetail) => {
                    this.candidate = candidate
                    if (!candidate.activeFlag && !candidate.deleteFlag) {
                        this.doNotUse = true;
                    }
                },
                (err: ApiError) => {
                    if (err.statusCode === 404) {
                        this.router.navigateByUrl("/not-found");
                    }
                }
            );
    }

    checkIfHired() {
        if (this.jobOrderDetails && this.jobOrderDetails.candId) {
            this.hiredCandId = this.jobOrderDetails.candId;
        }
    }

    callSequence() {
        this.refreshJobCandidate();
        this.checkIfHired();
    }

    ngOnChanges() {
        this.isLoadingResults = true;
        this.doNotUse = false;
        this.callSequence();
    }
}
