<div class="line-container">
    <div class="line" [ngClass]="lineClass"></div>
</div>

<div class="info-container" [class.dashboard]="dashboardView">
    <div class="title-container">
        <h2>
            <span *ngIf="job.status === 'CANCELLED' || job.status === 'OPEN'" [class.prox]="job.status === 'OPEN' && job.numNewSubmittals > 0">
                {{ job.jobTitle }}
            </span>
            <span *ngIf="job.status !== 'CANCELLED' && job.status !== 'OPEN' && job.sunsetFlag == 0">
                {{job.activeCandidateFirstName}} {{job.activeCandidateLastName}}
            </span>

            <span *ngIf="job.status !== 'CANCELLED' && job.status !== 'OPEN' && job.sunsetFlag == 1">
                {{job.activeCandidateFirstName}} {{job.activeCandidateLastName[0]}}.
            </span>
        </h2>
    </div>

    <div class="meta-container" [class.not-open]="job.status !== 'OPEN'">
        <div class="data-container">
            <div *ngIf="job.status !== 'CANCELLED' && job.status !== 'OPEN'">
                <svg class="feather feather-briefcase" fill="none" height="12" stroke="#7E868C" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="12" xmlns="http://www.w3.org/2000/svg">
                    <rect height="14" rx="2" ry="2" width="20" x="2" y="7" />
                    <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" /></svg>
                <span>{{ job.jobTitle }}</span>
            </div>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#7E868C"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe">
                    <circle cx="12" cy="12" r="10" />
                    <path d="M2 12h20M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" /></svg>
                <span>{{ job.area }}</span>
            </div>
            <div>
                <div class="svg-container" *ngIf="!isFullTime">
                    <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
                        <g transform="translate(1 1)" stroke="#7E868C" stroke-width=".877" fill="none"
                           fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <rect y="1" width="9" height="9" rx="1"/>
                            <path d="M6.5 0v2M2.5 0v2M0 4h9"/>
                        </g>
                    </svg>
                </div>
                <span *ngIf="sortedBy === 'curr-openings-asc' || sortedBy === 'curr-openings-desc'">Opened {{ job.dateOpened | date:'MMM d, y' }}
                </span>
                <span *ngIf="!(sortedBy === 'curr-openings-asc' || sortedBy === 'curr-openings-desc') && job.status !== 'ACTIVE' && job.status !== 'PAST' && job.status !== 'CLOSED' && job.status !== 'FT_PLACEMENT'">{{ job.dateOpened | date:'MMM d, y' }}
                </span>
                <span *ngIf="!(sortedBy === 'curr-openings-asc' || sortedBy === 'curr-openings-desc') && job.status === 'ACTIVE'">Ends {{ job.dateEnd | date:'MMM d, y' }}
                </span>
                <span *ngIf="job.employmentType === 'Freelance' && job.status === 'CLOSED'">Ended {{ job.dateEnd | date:'MMM d, y' }}
                </span>
                <span *ngIf="job.employmentType === 'Full Time' && job.status != 'OPEN'">Full-Time Placement
                </span>
            </div>
        </div>
        <div class="status-container">
            <div class="status-container-item" [class.hidden]="job.numNewSubmittals === 0 || job.status !== 'OPEN'">
                <div>
                    <span *ngIf="job.numNewSubmittals <= 9" class="circle red" [class.pill]="job.numUnderConsideration > 9">{{ job.numNewSubmittals }}</span>
                    <span *ngIf="job.numNewSubmittals > 9" class="circle red pill">9+</span>
                </div>
                <div class="label bold">New Submittals</div>
            </div>
            <div class="status-container-item" [class.hidden]="job.numUnderConsideration === 0 || job.status !== 'OPEN'">
                <div>
                    <span class="circle orange" *ngIf="job.numUnderConsideration <= 9" [class.pill]="job.numNewSubmittals > 9">{{ job.numUnderConsideration }}</span>
                    <span class="circle orange pill" *ngIf="job.numUnderConsideration > 9">9+</span>
                </div>
                <div class="label">In the Running</div>
            </div>
        </div>
        <div class="action-container">
            <a (click)="routeToJobClick()" [routerLink]="['/jobs', { officeId: job.officeId, jobId: job.jobId }]">
                Review
                <svg width="6" height="10" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 10l4-5-4-5" stroke="#FFF" fill="none" fill-rule="evenodd" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </a>
        </div>
    </div>
</div>
