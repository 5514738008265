<div class="background-container" [class.loggedIn]="loggedIn">
    <div class="candidate-details-container" *ngIf="jobOrderCandidate">
        <div class="candidate-row">
            <app-candidate-details-name [public]="'true'" [candidate]="jobOrderCandidate"></app-candidate-details-name>
            <div *ngIf="jobOrderCandidate.sunsetFlag === 0" class="row-divider"></div>
            <app-candidate-details-links-and-docs *ngIf="jobOrderCandidate.sunsetFlag === 0" [candidate]="jobOrderCandidate"></app-candidate-details-links-and-docs>
            <div class="row-divider" *ngIf="jobOrderCandidate.sunsetFlag === 0"></div>
            <app-candidate-details-about *ngIf="jobOrderCandidate.sunsetFlag === 0" [candidate]="jobOrderCandidate" [showSalaryDetails]="false"></app-candidate-details-about>
            <div class="grey-background" *ngIf="jobOrderCandidate.sunsetFlag === 1">
                <div>
                    <div class="disclaimer">
                        <p class="header">It’s been a while since we sent this candidate your way.</p>
                        <p class="subheader">Please contact your Creative Circle team for updated information.</p>
                    </div>
                </div>
            </div>
            <div class="row-divider"></div>
        </div>
        <app-candidate-details-footer></app-candidate-details-footer>
    </div>
</div>