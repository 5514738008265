import { Component, OnInit } from '@angular/core';
import { LbTitle } from '../../../shared/services/title.service';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
    constructor(private titleService: LbTitle) {
        this.titleService.setTitleFromKeywords('Maintenance In Progress');
    }

    ngOnInit() {
    }
}
