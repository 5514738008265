<div class="medium-container">
    <mat-card>
        <mat-card-title> {{ action }} Message </mat-card-title>
        <mat-card-content>
            <form [formGroup]="createForm" (ngSubmit)="submitForm()">
                <mat-form-field>
                    <input type="text" matInput placeholder="Title" formControlName="title" />
                    <mat-error>
                        This field is required and must be 100 characters or less.
                    </mat-error>
                </mat-form-field>

                <div class="quill-container">
                    <label for="message">Message</label>
                    <quill-editor formControlName="message" [modules]="quillModules"></quill-editor>
                </div>

                <mat-form-field>
                    <input matInput [matDatepicker]="picker" placeholder="End Date" formControlName="endDate" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error>
                        This field is required and must be in standard date format.
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input #timepicker atp-time-picker matInput formControlName="endTime" type="time" arrowStyle="{'background': '#3C9E87', 'color': '#ffffff'}" placeholder="End Time" />
                    <mat-error>
                        Please select a time.
                    </mat-error>
                </mat-form-field>

                <div class="button-container">
                    <button aria-label="Cancel" mat-raised-button color="warn" type="button" (click)="cancel()" [disabled]="isSubmitting">
                        Cancel
                    </button>

                    <button aria-label="Save" mat-raised-button color="primary" type="submit" [disabled]="!createForm.valid || isSubmitting">
                        Save
                    </button>
                </div>

                <p class="error-message" *ngIf="createForm.hasError('serverError')">
                    A server error has occurred. Please try again later.
                </p>
                <p class="error-message" *ngIf="createForm.hasError('timeout')">
                    The server did not respond in time. Please verify the message was not created, check your connection, and then try again.
                </p>
            </form>
        </mat-card-content>
    </mat-card>
</div>
