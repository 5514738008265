import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import {
    ActionItem,
    ActiveCandidate,
    ContactInformation,
    Interview,
    RecentActivity
} from "../models";
import { ClientMessage } from "../models/client-message.model";
import { ApiService } from "./api.service";
import { JwtService } from "./jwt.service";
import { MessageService } from "./message.service";
import { ApiError } from "../models/api-error.model";
import { NetworkErrorSnackbarService } from "./../../shared/services/network-error-snackbar.service";

@Injectable()
export class DashboardService {
    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private jwtService: JwtService,
        private router: Router,
        private messageService: MessageService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
    ) {}

    // cache action items
    private actionItems: ActionItem[] = null;
    // last time actionItems was updated with API result
    // not used not, but can be used to introduce local caching
    private actionItemsLastUpdated: number = null;

    // Recent Activity list subject and observable
    public recentActivitySubject: BehaviorSubject<
        RecentActivity[]
    > = new BehaviorSubject<RecentActivity[]>([]);
    public recentActivity: Observable<
        RecentActivity[]
    > = this.recentActivitySubject.asObservable();

    // Recent Activity loading indicator (boolean) subject and observable
    private recentActivityLoadingSubject: BehaviorSubject<
        boolean
    > = new BehaviorSubject<boolean>(false);
    public recentActivityLoading: Observable<
        boolean
    > = this.recentActivityLoadingSubject.asObservable();

    public actionItemsSubject: BehaviorSubject<number> = new BehaviorSubject<
        number
    >(0);
    public actionItemsCount: Observable<
        number
    > = this.actionItemsSubject.asObservable();

    // added useCache option to allow getting action items from cache if available
    // cache is populated on first successful api fetch
    // if useful, this can be easily epanded to all other services
    getActionItems(
        limit = 10,
        offset = 0,
        useCache = false
    ): Observable<ActionItem[]> {
        if (useCache && this.actionItems !== null) {
            return Observable.create(observer => {
                observer.next(this.actionItems);
                observer.complete();

                // Any cleanup logic might go here
                return function() {
                    // nothing to do here at this point as this is just a simple cache hit
                };
            });
        }
        return this.apiService
            .get("/action-item", {
                limit: limit.toString(),
                offset: offset.toString()
            })
            .pipe(
                map(
                    data => {
                        this.actionItems = data["results"];
                        this.actionItemsLastUpdated = new Date().getTime();
                        this.actionItemsSubject.next(data["results"].length);
                        return data["results"];
                    },
                    err => {
                        console.error(err);
                    }
                )
            );
    }

    getActiveCandidates(
        limit = 1000,
        offset = 0
    ): Observable<ActiveCandidate[]> {
        return this.apiService
            .get("/active-candidate", {
                limit: limit.toString(),
                offset: offset.toString()
            })
            .pipe(
                map(
                    data => {
                        return data["results"];
                    },
                    err => {
                        console.error(err);
                    }
                )
            );
    }

    getUpcomingInterviews(limit = 1000, offset = 0): Observable<Interview[]> {
        return this.apiService
            .get("/interview", {
                limit: limit.toString(),
                offset: offset.toString()
            })
            .pipe(
                map(
                    data => {
                        return data["results"];
                    },
                    err => {
                        console.error(err);
                    }
                )
            );
    }

    getContactInformation(
        limit = 10,
        offset = 0
    ): Observable<ContactInformation[]> {
        return this.apiService
            .get("/contact-information", {
                limit: limit.toString(),
                offset: offset.toString()
            })
            .pipe(
                map(
                    data => {
                        return data;
                    },
                    err => {
                        console.error(err);
                    }
                )
            );
    }

    /**
     * Returns an observable with a list of recent activity items
     *
     * @param limit
     * @param offset
     *
     * @return Observable
     */
    getRecentActivity(limit = 10, offset = 0): Observable<RecentActivity[]> {
        // Indicates that recent activity is loading
        this.recentActivityLoadingSubject.next(true);

        // Fetches the recent activity items
        return this.apiService
            .get("/activity-log", {
                limit: limit.toString(),
                offset: offset.toString()
            })
            .pipe(
                map(data => {
                    this.recentActivitySubject.next(data["results"]);
                    this.recentActivityLoadingSubject.next(false);

                    return data["results"];
                }),
                catchError((apiError: ApiError) => {
                    this.recentActivityLoadingSubject.next(false);

                    return throwError(apiError);
                })
            );
    }

    updateCandidatePreference(officeId, candidateId, action): Observable<any> {
        return this.apiService
            .patch("/candidate/" + officeId + "/" + candidateId, action)
            .pipe(
                map(
                    data => {
                        return data;
                    },
                    (err: ApiError) => {
                        this.networkErrorSnackbarService.networkError(err,'')
                    }
                )
            );
    }

    updateJobCandidatePreference(
        officeId,
        jobId,
        candidateId,
        action
    ): Observable<any> {
        return this.apiService
            .patch(
                `/job/${officeId}/${jobId}/candidate/${candidateId}/preference`,
                action
            )
            .pipe(
                map(
                    data => {
                        return data;
                    },
                    (err: ApiError) => {
                        this.networkErrorSnackbarService.networkError(err,'')
                    }
                )
            );
    }

    adjustActiveCandidateEndDate(officeId, jobId, params) {
        return this.apiService
            .post(
                "/active-candidate/" +
                    officeId +
                    "/" +
                    jobId +
                    "/change-request",
                params
            )
            .pipe(map(data => data, err => console.error(err)));
    }

    markMessageAsRead(messageId) {
        const params = { read: true };
        return this.apiService
            .patch("/message/" + messageId, params)
            .pipe(map(data => data, err => console.error(err)));
    }

    getMessage(messageId): Observable<ClientMessage> {
        return this.apiService.get("/message/" + messageId).pipe(
            map(
                data => {
                    return data;
                },
                err => {
                    console.error(err);
                }
            )
        );
    }
}
