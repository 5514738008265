<div class="header" [class.forgot-password]="data.lineOne">
	<svg (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
	     stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x on-white">
	    <line x1="18" y1="6" x2="6" y2="18"></line>
	    <line x1="6" y1="6" x2="18" y2="18"></line>
	</svg>
	<div *ngIf="data.favoritedMessage" class="warning">
		<h1>FAVORITE CANDIDATE</h1>
		<p>Favoriting a candidate saves the candidate to your personal 'favorites' list, like a bookmark.</p>
		<p>To let Creative Circle know your reaction to a candidate or request a next step with the candidate, please use one of the action buttons on the candidate's details.</p>
	</div>
	<div *ngIf="data.rescheduleConfirmMessage" class="warning reschedule">
		<h1>INTERVIEW REQUEST PENDING</h1>
		<p>Your request to reschedule has been sent to the recruiter. Please contact your Creative Circle team for updated information.</p>
	</div>
	<div *ngIf="data.blockedAfterMessage" class="warning">
		<p class="after-header">YOU'VE SUCCESSFULLY BLOCKED <span class="bold">{{data.candidateName}}</span></p>
		<p>If you have specific feedback on why a candidate is not a good fit for you, please let us know through one of the action buttons on the candidate's details - or give your Creative Circle team a call.</p>
	</div>
	<div *ngIf="data.blockedMessage" class="warning">
		<h1>BLOCK CANDIDATE</h1>
		<p class="confirm-text">ARE YOU SURE YOU WANT TO BLOCK <span class="bold">{{data.candidateName}}</span>?</p>
		<p>Blocking a candidate stops Creative Circle from re-submitting the candidate to you for future roles. If {{data.candidateFirstName}} is in the running for current roles, you will not be able to take further action with {{data.candidateFirstName}} unless you unblock them.</p>
		<p *ngIf="data.currentlyFavorited" class="warning-text"><span class="bold">Please Note:</span> Blocking a favorited candidate will remove their favorite status.</p>
	</div>
	<div *ngIf="!data.favoritedMessage">
		<h1 *ngIf="data.title && !data.favorited" mat-dialog-title>
		    {{data.title}}<span>{{data.candidateName}}</span>{{data.endingText}}
		</h1>
		<h1 *ngIf="data.lineOne" mat-dialog-title>
			{{data.lineOne}}
		</h1>
		<h1 *ngIf="data.title && data.favorited == true" mat-dialog-title>
		    {{data.messageText}}<span>{{data.candidateName}}</span>{{data.endingTextFavorite}}
		</h1>
		<div *ngIf="data.message" mat-dialog-content>
		    <p>{{ data.message }}</p>
		</div>
		<div class="forget-password-message" *ngIf="!data.message && data.lineOne">
			<p>{{data.lineTwo}}</p>
			<p>{{data.lineThree}}</p>
			<p>{{data.lineFour}}</p>
		</div>
	</div>
    <div *ngIf="data.blockedNonPassedMessage" class="warning">
        <h1>CANDIDATE IS BLOCKED</h1>
        <p class="confirm-text">You have blocked this candidate.</p>
        <p>It looks like you blocked this candidate earlier. If you’d like to take an action with {{data.candidateFirstName}} now, please unblock them first.</p>
    </div>
</div>
<div *ngIf="data.showCheckbox" class="checkbox-container input">
	<input type="checkbox" id="dont-show" (change)="updatePref()">
	<label for="dont-show">
		Do not show me this message again.
	</label>
</div>
<div mat-dialog-actions *ngIf="data.showCheckbox">
    <button aria-label="Ok" (click)="submitData()">{{ data.buttonText || "OK" }}</button>
</div>
<div mat-dialog-actions *ngIf="!data.showCheckbox">
    <button aria-label="Ok" [mat-dialog-close]="true">{{ data.buttonText || "OK" }}</button>
    <button *ngIf="data.blockedNonPassedMessage" aria-label="Cancel" [mat-dialog-close]="false">Cancel</button>
</div>
