import { Component, OnInit, Input } from '@angular/core';
import { Interview } from '../../shared/models';
import { DashboardService } from '../../shared';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { RescheduledInterviewService } from '../../shared/services/rescheduled-interview.service';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { RescheduleInterviewDialogComponent } from "../../shared/components/reschedule-interview-dialog/reschedule-interview-dialog.component";
import { AlertDialogComponent } from "../../shared/components/alert-dialog/alert-dialog.component";
import { StartInterviewDialogComponent } from '../../shared/components/start-interview-dialog/start-interview-dialog.component';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'dashboard-interviews',
    templateUrl: './interviews.component.html',
    styleUrls: ['./interviews.component.scss']
})
export class InterviewsComponent implements OnInit {
    @Input()
    public activeSection: boolean;
    public interviewType: string = '';

    upcomingInterviews: Interview[];
    public allUpcomingInterviews: Interview[];
    public upcomingInterviewCount: number;
    public pages: number;
    public currentPage: number;
    isLoadingResults: boolean = true;
    public today: Date = new Date();

    constructor(
        private dashboardService: DashboardService,
        private analyticsService: AnalyticsService,
        private router: Router,
        private dialog: MatDialog,
        private rescheduledInterviewService: RescheduledInterviewService,
        private datePipe: DatePipe
    ) {
    }

    routeJob(jobOrder) {
        this.analyticsService.fireEvent(
            'Job Candidates',
            'Job Candidate Click',
            'Scheduled Interview'
        );
        const officeId = jobOrder.officeId;
        const jobId = jobOrder.jobId;
        const jobCandidateId = jobOrder.jobCandidateId;

        this.router.navigate(['jobs', {officeId, jobId, jobCandidateId, "section": "interviews"}]);
    }

    openInterviewDialog(interview) {
        event.stopPropagation();

        if (interview.interviewUrl) {
            this.dialog.open(StartInterviewDialogComponent, {
                width: "600px",
                data: {
                    interviewWith: interview.interviewWith,
                    interviewUrl: interview.interviewUrl
                }
            });
        }
    }

    sameDateAsToday(interviewDate): boolean {
        return this.datePipe.transform(this.today, 'yMMdd') === 
                 this.datePipe.transform(interviewDate, 'yMMdd');
     }

    openRescheduledAlert() {
        this.dialog.open(AlertDialogComponent, {
            width: "500px",
            data: {
                rescheduleConfirmMessage: true,
                buttonText: 'Got It'
            }
        });
    }

    openRescheduleDialog(interview) {
        this.dialog.open(RescheduleInterviewDialogComponent, {
            width: "600px",
            panelClass: "candidate_details_page",
            data: {
                candidateName: interview.candidateFirstName + " " + interview.candidateLastName,
                date: interview.date,
                timeZone: interview.timeZone,
                jobId: interview.jobId,
                officeId: interview.officeId,
                jobCandidateId: interview.jobCandidateId,
                interviewType: interview.interviewType,
                // Interview Scheduled statusId
                statusId: 2
            }
        });
    }

    loadUpcomingInterviews() {
        // TODO handle timeout
        this.dashboardService.getUpcomingInterviews().subscribe(interviews => {
            // this.upcomingInterviews = interviews;
            this.allUpcomingInterviews = interviews.filter(scheduledInterview => scheduledInterview.jobCandidateStatusId === 2);

            this.upcomingInterviewCount = this.allUpcomingInterviews.length;
            this.upcomingInterviews = this.allUpcomingInterviews.slice(0, 10);
            this.pages = (this.upcomingInterviewCount / 10) % 1 === 0 ? (this.upcomingInterviewCount / 10) : Math.floor((this.upcomingInterviewCount / 10) + 1);
            this.currentPage = 1;
            
            this.isLoadingResults = false;
            this.determineInterviewTypes();
        });
    }

    ngOnInit() {
        this.loadUpcomingInterviews();

        this.rescheduledInterviewService.update$.subscribe(bool => {
            if (bool) {
                this.loadUpcomingInterviews();
            }
        });
    }

    public showMoreItems() {
        ++this.currentPage;
        if (this.currentPage <= this.pages) {
            if ((this.currentPage * 10) < this.upcomingInterviewCount) {
                this.upcomingInterviews = this.allUpcomingInterviews.slice(0, this.currentPage * 10);
            } else {
                this.upcomingInterviews = this.allUpcomingInterviews;
            }
        }

        this.determineInterviewTypes();
    }

    determineInterviewTypes() {
        for (let i = 0; i < this.upcomingInterviews.length; i++) {
            if (this.upcomingInterviews[i].interviewLocation) {
                let location = this.upcomingInterviews[i].interviewLocation.toLowerCase();
                if (location.includes('office')) {
                    this.upcomingInterviews[i].interviewType = 'person';
                } else if (location.includes('phone') || location.includes('call')) {
                    this.upcomingInterviews[i].interviewType = 'phone';
                } else if (location.includes('video')) {
                    this.upcomingInterviews[i].interviewIsToday = false;
                    if (this.sameDateAsToday(this.upcomingInterviews[i].date)) {
                        this.upcomingInterviews[i].interviewIsToday = true;
                    }
                    this.upcomingInterviews[i].interviewType = 'video';
                } else {
                    // show nothing
                    this.upcomingInterviews[i].interviewType = '';
                }
            }
        }
    }
}
