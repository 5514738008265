<div class="modal-header" [ngClass]="{'with-subtitle': submittedFirstPart}">
    <h3 mat-dialog-title *ngIf="!submittedFirstPart">
        This will remove <span>{{ data.candidateFirstName }}</span> from consideration
    </h3>

    <h3 mat-dialog-title *ngIf="submittedFirstPart">
        BEFORE YOU LEAVE...
    </h3>
    <svg *ngIf="!submittedFirstPart" (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x on-white">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
</div>
<div mat-dialog-content>
    <h6 *ngIf="submittedFirstPart">
        Some quick feedback on <span>{{ data.candidateFirstName }}</span> would really help:
    </h6>

    <form [formGroup]="form" (submit)="submit()">
        <input type="hidden" formControlName="jobId">
        <input type="hidden" formControlName="officeId">
        <input type="hidden" formControlName="jobCandidateId">
        <input type="hidden" formControlName="statusId">
        <div *ngIf="!submittedFirstPart">
            <div class="text-area-favorite-wrapper passed-input">
                <label>Where does this candidate come up short?</label>
                <textarea [(ngModel)]="advantages" formControlName="disadvantages" placeholder="Write any hesitations here..."></textarea>
            </div>
            <div class="text-area-favorite-wrapper passed-input">
                <label>Things you liked about this candidate?</label>
                <textarea [(ngModel)]="disadvantages" formControlName="advantages" placeholder="Write anything you like about the candidate here...">
                </textarea>
            </div>
        </div>

        <div formGroupName="rangesGroup" *ngIf="submittedFirstPart">

            <div class="range-input" [ngClass]="{'middle' : experienceLevel == 50 && !experienceLevelTouched}">
                <label>Experience Level</label>
                <mat-slider min="1" max="100" step="1" [(value)]="experienceLevel" (change)="experienceLevelTouched = true;" color="blue" formControlName="experienceLevel"></mat-slider>
            </div>

            <div class="range-input" [ngClass]="{'middle' : softSkills == 50 && !softSkillsTouched}">
                <label>Soft Skills</label>
                <mat-slider min="1" max="100" step="1" [(value)]="softSkills" (change)="softSkillsTouched = true;" color="blue" formControlName="softSkills"></mat-slider>
            </div>

            <div class="range-input portfolio" [ngClass]="{'middle' : portfolio == 50 && !portfolioTouched}">
                <label>Portfolio</label>
                <mat-slider min="1" max="100" step="1" [disabled]="noPortfolio" [(value)]="portfolio" (change)="portfolioTouched = true;" color="blue" formControlName="portfolio"></mat-slider>
            </div>

            <div class="access-control">
                <div class="control">
                    <input type="checkbox" formControlName='noPortfolio' value="0" id="noPortfolio" [(ngModel)]="noPortfolio" (change)="portfolioTouched = true;">
                    <label for="noPortfolio">Portfolio N/A</label>
                </div>
            </div>

            <div class="text-area-favorite-wrapper passed-input short">
                <textarea formControlName="comments" placeholder="{{data.candidateFirstName}}..." [(ngModel)]="comments" (input)="lengthCounter()">
                </textarea>
            </div>
        </div>

        <div class="access-control">
            <div class="control">
                <input type="checkbox" formControlName='addToNotes' value="addToNotes" id="addToNotes" (click)="determineInput($event)" [attr.disabled]="(!submittedFirstPart && advantages == '' && disadvantages == '') || (submittedFirstPart && comments == '' && !experienceLevelTouched && !softSkillsTouched && !portfolioTouched) ? '' : null">
                <label [class]="(!submittedFirstPart && advantages == '' && disadvantages == '') || (submittedFirstPart && comments == '' && !experienceLevelTouched && !softSkillsTouched && !portfolioTouched) ? 'disabled' : ''" for="addToNotes">Save to your Notes</label>
            </div>
            <div class="control">
                <input type="checkbox" formControlName='sharedHiringManagers' value="hiring-managers" id="sharedHiringManagers" (click)="determineInput($event)" [attr.disabled]="(!submittedFirstPart && advantages == '' && disadvantages == '') || (submittedFirstPart && comments == '' && !experienceLevelTouched && !softSkillsTouched && !portfolioTouched) ? '' : null">
                <label [class]="(!submittedFirstPart && advantages == '' && disadvantages == '' && addToNotes) || (submittedFirstPart && comments == '' && !experienceLevelTouched && !softSkillsTouched && !portfolioTouched) ? 'disabled' : ''" for="sharedHiringManagers">Save to your team's Notes</label>
            </div>
        </div>
        <div class="counter">
            <span [class.error]="countMax < 0">Characters remaining: {{countMax}}</span>
        </div>
    </form>
    <div class="error">
        <p *ngIf="formError" class="form-error">{{ formError }}</p>
    </div>
</div>
<div mat-dialog-actions>
    <button aria-label="Submit" [ngClass]="{'is-submitting': isSubmitting}" [disabled]="isLimitedImpersonation" (click)="submit()">
        <span class="not-submitting">Submit</span>
        <span class="spinner" *ngIf="isSubmitting">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        </span>
    </button>
</div>
