import { Component, OnInit } from '@angular/core';
import { JobService } from '../../shared';
import { LbTitle } from '../../shared/services/title.service';

@Component({
    selector: 'app-job-orders',
    templateUrl: './job-orders.component.html',
    styleUrls: ['./job-orders.component.scss']
})
export class JobOrdersComponent implements OnInit {
    public formChanged: boolean = false;

    constructor(
        private jobOrdersService: JobService,
        private titleService: LbTitle
    ) {
        this.titleService.setTitleFromKeywords('Jobs', 'Jobs');
    }

    ngOnInit() {
        this.jobOrdersService.formChanged.subscribe(formChanged => {
            this.formChanged = formChanged;
        });

        this.jobOrdersService.getJobs(); 
    }
}
