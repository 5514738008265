import { AbstractControl } from '@angular/forms';

export default (control: AbstractControl) => {
    // cloning date object so we don't change the value selected
    const selectedDate = new Date(control.value.getTime());
    const currentDate = new Date();

    // reseting to midnight so time is not a factor
    currentDate.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    if (selectedDate < currentDate) {
        return {currentOrFutureOnly: false};
    }

    return null;
};