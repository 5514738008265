<div class="mobile-sub-navigation" *ngIf='jobParams?.jobId && !jobCandParams?.jobCandidateId'>
    <div *ngIf="routeParam === ''" class="back-navigation">
        <svg class="arrow-back" width="11" height="22" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 2l-7 9 7 9" stroke="#13ADC2" stroke-width="2.8" fill="none" fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
        <a routerLink="/jobs" replaceUrl="true">
            <h2 class="label">BACK TO JOBS</h2>
        </a>
    </div>
    <div *ngIf="routeParam !== ''" class="back-navigation">
        <svg class="arrow-back" width="11" height="22" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 2l-7 9 7 9" stroke="#13ADC2" stroke-width="2.8" fill="none" fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"/>
        </svg>
        <h2 (click)="routeChange()" class="label">BACK TO {{paramToText()}}</h2>
    </div>
    <div class="job-order-details-button" (click)="showDetailsModal = true">
        <span>JOB DETAILS</span>
        <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(1 1)" fill="none" fill-rule="evenodd">
                <circle stroke="#131e27" cx="12" cy="12" r="12"/>
                <text font-family="Baskerville-Bold, Baskerville" font-size="18"
                      font-weight="bold" fill="#131e27">
                    <tspan x="9" y="18">i</tspan>
                </text>
            </g>
        </svg>
    </div>
</div>

<div class="mobile-sub-navigation" *ngIf="jobCandParams?.jobCandidateId && routeParam !== 'interviews'">
    <div class="back-navigation" (click)="backToCandidates()">
        <svg class="arrow-back" width="11" height="22" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 2l-7 9 7 9" stroke="#13ADC2" stroke-width="2.8" fill="none" fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
        <h2 class="label">BACK TO CANDIDATES</h2>
    </div>
</div>

<div class="mobile-sub-navigation" *ngIf="jobCandParams?.jobCandidateId && routeParam === 'interviews'">
    <div class="back-navigation" (click)="backToCandidates()">
        <svg class="arrow-back" width="11" height="22" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 2l-7 9 7 9" stroke="#13ADC2" stroke-width="2.8" fill="none" fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
        <h2 class="label">BACK TO INTERVIEWS</h2>
    </div>
</div>

<div class="no-job-order-clicked" *ngIf='!jobParams?.officeId'>
    <div class="default-state">
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea"
             stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase">
            <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
        </svg>
        <h2 *ngIf="!noJobs">Select a Job to view the related Candidates</h2>
        <h2 *ngIf="noJobs">You have no current openings listed with us.</h2>
    </div>
    <app-layout-footer-jobs class="jobs-footer"></app-layout-footer-jobs>
</div>

<div class="no-job-order-clicked-loader" *ngIf='!jobParams?.officeId && jobDetailsLoading'>
  <span class="submitting">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
         stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
      <line x1="12" y1="2" x2="12" y2="6"></line>
      <line x1="12" y1="18" x2="12" y2="22"></line>
      <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
      <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
      <line x1="2" y1="12" x2="6" y2="12"></line>
      <line x1="18" y1="12" x2="22" y2="12"></line>
      <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
      <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
    </svg>
  </span>
</div>

<div class="job-order-details job-order-details-desktop" *ngIf='jobParams.jobId'>
    <div>
        <div class="back-navigation-candidate-list" (click)="backToJobs()">
            <svg class="arrow-back" width="11" height="22" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 2l-7 9 7 9" stroke="#13ADC2" stroke-width="2.8" fill="none" fill-rule="evenodd"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
            </svg>
            <h2 class="label">BACK TO JOBS</h2>
        </div>
        <app-job-order-details-candidates-list></app-job-order-details-candidates-list>
    </div>
    <div #details_container class="details scrollbar">
        <app-job-order-details-job-overview *ngIf="jobDetails"
                                            [jobDetails]='jobDetails'></app-job-order-details-job-overview>
        <div *ngIf='!jobCandParams.jobCandidateId && jobDetails?.candidates.length'
             class="default-state candidate-default-state">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none"
                 stroke="#b3e1ea"
                 stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/>
                <circle cx="9" cy="7" r="4"/>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75"/>
            </svg>
            <h2>Select a Candidate on the left to view Candidate Details</h2>
        </div>
        <div class="team-and-additional-details" *ngIf='jobDetails && !jobCandParams.jobCandidateId'>
            <app-job-order-details-team [jobOrderDetails]='jobDetails'></app-job-order-details-team>
            <app-job-order-details-additional-details
                    [jobOrderDetails]='jobDetails'></app-job-order-details-additional-details>
        </div>
        <app-layout-footer-jobs *ngIf='!jobCandParams.jobCandidateId' class="jobs-footer"></app-layout-footer-jobs>
        <div class="candidate-details" *ngIf='jobCandParams.jobCandidateId'>
            <app-job-order-candidate-details [mobile]="false" [jobOrder]="job" [jobOrderDetails]="jobDetails"
                                             [jobOrderCandidate]="jobCand"></app-job-order-candidate-details>
        </div>
    </div>
</div>

<div class="job-order-details job-order-details-mobile" *ngIf='jobParams?.officeId'>
    <app-job-order-details-candidates-list
            *ngIf="!jobCandParams?.jobCandidateId"></app-job-order-details-candidates-list>
    <div class="details" *ngIf="jobCandParams?.jobCandidateId">
        <div class="candidate-details">
            <app-job-order-candidate-details [mobile]="true" [jobOrder]="job" [jobOrderCandidate]="jobCand"
                                             [jobOrderDetails]="jobDetails">
            </app-job-order-candidate-details>
        </div>
    </div>
</div>

<div #modal class="details-modal" *ngIf="jobDetails && showDetailsModal">
    <div class="details">
        <div class="text-right">
            <p class="title">JOB ORDER DETAILS</p>
            <svg (click)="closeCandidateDetailsModal()" xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                 viewBox="0 0 24 24"
                 fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
        </div>
        <hr>
        <app-job-order-details-job-overview *ngIf="jobDetails"
                                            [jobDetails]='jobDetails'></app-job-order-details-job-overview>
        <hr>
        <div class="team-and-additional-details">
            <app-job-order-details-team [jobOrderDetails]='jobDetails'></app-job-order-details-team>
            <hr>
            <app-job-order-details-additional-details
                    [jobOrderDetails]='jobDetails'></app-job-order-details-additional-details>
        </div>
    </div>
</div>
