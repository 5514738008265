import { Component, OnInit } from '@angular/core';
import { LbTitle } from '../../../shared/services/title.service';

@Component({
    selector: 'app-not-found',
    templateUrl: './not_found.component.html',
    styleUrls: ['./not_found.component.scss']
})
export class NotFoundComponent implements OnInit {
    constructor(private titleService: LbTitle) {
        this.titleService.setTitleFromKeywords('Page Not Found');
    }

    ngOnInit() {
    }
}
