import { Component, Inject, ViewChild, ElementRef, DoCheck } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import futureDate from '../../../shared/validators/current-future-date';
import {DashboardService, JobService, UserService} from '../../../shared';
import { ApiError } from '../../../shared/models/api-error.model';
import { AnalyticsService } from '../../services/analytics.service';
import {switchMap} from 'rxjs/operators';

export const CLOSE_SUCCESS = 'SUCCESS';

@Component({
    selector: 'app-extension-dialog',
    templateUrl: './extension-dialog.component.html',
    styleUrls: ['./extension-dialog.component.scss']
})
export class ExtensionDialogComponent implements DoCheck {
    requestForm: UntypedFormGroup;
    todaysDate: Date = new Date();
    formError: string;
    isSubmitting: boolean;
    sameDateError: boolean = false;
    public isLimitedImpersonation: boolean = false;
    public countMax: number = 4000;

    @ViewChild('calendar', { read: ElementRef, static: true }) private matCalendar: ElementRef;

    public get dateControl(): AbstractControl {
        // Setting the display to 'none' then to 'block' (hiding and showing) to fix a specific bug in IE11 where when selecting a date, the
        // selected date's styles get out of shape and causes the other dates to shift sometimes. Doing the below solves this issue. This is
        // most likely an issue with the browser itself or its rendering engine.
        this.matCalendar.nativeElement.style.display = 'none';
        this.matCalendar.nativeElement.style.display = 'block';

        return this.requestForm.get('date');
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ExtensionDialogComponent>,
        private fb: UntypedFormBuilder,
        private dashboardService: DashboardService,
        private analyticsService: AnalyticsService,
        private userService: UserService,
        private jobOrdersService: JobService
    ) {
        this.requestForm = this.fb.group({
            officeId: [this.data.officeId, Validators.compose([Validators.required])],
            jobId: [this.data.jobId, Validators.compose([Validators.required])],
            date: [
                this.data.currentDate,
                Validators.compose([Validators.required, futureDate])
            ],
            note: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(4000)
                ])
            ]
        });

        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });

        this.requestForm.controls['note'].valueChanges.subscribe(content => {
            this.countMax = 4000 - content.length;
        });
    }

    dateValueChange(val) {
        this.dateControl.setValue(val);
    }

    close() {
        this.dialogRef.close();
    }

    ngDoCheck() {
        // Checks if the selected date is same as the old date, and if it is, it renders that field as invalid.
        if (this.sameAsCurrentDate()) {
            this.requestForm.controls['date'].setErrors({'incorrect': true});
        }
    }

    submit() {
        if (this.requestForm.valid && !this.isSubmitting) {
            this.isSubmitting = true;

            const params = {
                date: this.requestForm.value.date,
                message: this.requestForm.value.note
            };

            let adjustActiveCandidateEndDate = this.dashboardService
                .adjustActiveCandidateEndDate(
                    this.data.officeId,
                    this.data.jobId,
                    params
                );

            const patchJobCandidate = this.jobOrdersService.patchJobCandidate(
                this.data.officeId,
                this.data.jobId,
                this.data.candidate.jobCandidateId,
                {
                    statusId: 8,
                    details: this.requestForm.value.note,
                    addingLastClientComment: true
                }
            );

            adjustActiveCandidateEndDate = adjustActiveCandidateEndDate.pipe(
                switchMap(() => patchJobCandidate)
            );

            adjustActiveCandidateEndDate.subscribe(/* TODO handle timeout */
                _ => {
                    let verb = '';
                    if (this.data.currentDate > this.requestForm.value.date) {
                        verb = 'Shortened';
                    } else {
                        verb = 'Extended';
                    }
                    this.analyticsService.fireEvent(
                        'Job Candidates',
                        'Adjust End Date',
                        'End Date ' + verb,
                        {
                            'dimension6' : this.data.candidate.jobCandidateId,
                            'dimension5': this.data.jobId.toString() + "." + this.data.officeId.toString(),
                            'dimension3': this.data.clientId.toString() + "." + this.data.officeId
                        }
                    );

                    this.dialogRef.close(CLOSE_SUCCESS);
                },
                (err: ApiError) => {
                    this.isSubmitting = false;
                    if (err.statusCode === 400) {
                        this.formError = 'Error! Please close the dialog and try again.';
                    } else if (err.statusCode === 409) {
                        this.formError =
                            'Candidate does not exist! Please refresh the page.';
                    } else {
                        this.formError = 'Server Error! Please try again later.';
                    }
                }
            );
        }
    }

    /**
     * Checks if the selected date is same as the old date
     *
     * @return boolean Result of comparison
     */
    private sameAsCurrentDate() {
        const selectedDate = new Date(this.data.currentDate);
        const originalDate = new Date(this.requestForm.value.date);

        selectedDate.setHours(0, 0, 0, 0);
        originalDate.setHours(0, 0, 0, 0);

        return selectedDate.getTime() === originalDate.getTime();
    }

    public checkChangedValue() {
        // On date change, checks if the selected date is same as the old date, and if it is, it renders that field as invalid.
        if (this.sameAsCurrentDate() && this.matCalendar.nativeElement.getElementsByClassName('mat-calendar-body-selected').length) {
            this.sameDateError = true;
            this.requestForm.controls['date'].setErrors({'incorrect': true});
            this.matCalendar.nativeElement.getElementsByClassName('mat-calendar-body-selected')[0].classList.add('error');
        } else if (this.matCalendar.nativeElement.getElementsByClassName('mat-calendar-body-selected').length) {
            this.sameDateError = false;
            this.requestForm.controls['date'].setErrors(null);
            this.matCalendar.nativeElement.getElementsByClassName('mat-calendar-body-selected')[0].classList.remove('error');
        }
    }
}
