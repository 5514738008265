<h2 class="title">Job Details</h2>
<div class="details" *ngIf='jobOrderDetails'>
    <div class="col">
        <div class="data dates" *ngIf="jobOrderDetails.dateStart || jobOrderDetails.dateEnd">
            <div class="data date-part" *ngIf="jobOrderDetails.dateStart">
                <span class="label">Start Date</span>
                <span class="text">{{jobOrderDetails.dateStart | date:'MMM d, yyyy'}}</span>
            </div>
            <div class="data date-part" *ngIf="jobOrderDetails.dateEnd && !isFullTime(jobOrderDetails)">
                <span class="label">End Date</span>
                <span class="text">{{jobOrderDetails.dateEnd | date:'MMM d, yyyy'}}</span>
                <button aria-label="End Date" *ngIf="jobOrderDetails.status === 'ACTIVE'" class="adjust" (click)="adjust()">Adjust End Date</button>
            </div>
        </div>
        <hr *ngIf="jobOrderDetails.dateStart || jobOrderDetails.dateEnd" />
        <div class="data brc-view" *ngIf="(jobOrderDetails.rateBillViewer || jobOrderDetails.brcApprover) && (jobOrderDetails.brcStatus === 'Approved' || jobOrderDetails.brcStatus === 'Closed') && jobOrderDetails.placementType === 'Freelance' && jobOrderDetails.brcResponse ==='Accepted'">
            <div class="stats">
                <span class="label">Bill Rate</span>
                <span class="text">{{jobOrderDetails.rateBillReg | currency: 'USD'}}/hour</span>
            </div>
            <div class="view-confirm">
                <span class="text brc-confirm" (click)="isLimitedImpersonation || openBRCDialog()">VIEW CONFIRMATION</span>
            </div>
        </div>

        <div class="data brc-confirm-bill-rate-fee" *ngIf="jobOrderDetails.brcApprover && (jobOrderDetails.brcStatus === 'Pending' || jobOrderDetails.brcStatus === 'Open') && jobOrderDetails.placementType === 'Freelance'">
            <div class="stats">
                <span class="label">Bill Rate</span>
                <span class="text" (click)="isLimitedImpersonation || openBRCDialog()">CONFIRM BILL RATE</span>
            </div>
        </div>

        <div class="data brc-confirm-fee" *ngIf="jobOrderDetails.brcApprover && jobOrderDetails.brcStatus === 'Pending' && jobOrderDetails.placementType === 'Full Time'">
            <div class="stats">
                <span class="label">Placement Salary</span>
                <span class="text" (click)="isLimitedImpersonation || openBRCDialog()">CONFIRM FEE</span>
            </div>
        </div>

        <div class="data brc-view" *ngIf="(jobOrderDetails.rateBillViewer || jobOrderDetails.brcApprover) && (jobOrderDetails.brcStatus === 'Approved' || jobOrderDetails.brcStatus === 'Closed') && jobOrderDetails.placementType === 'Full Time'">
            <div class="stats">
                <span class="label">Placement Salary</span>
                <span class="text">{{jobOrderDetails.placementSalary | currency: 'USD'}}</span>
            </div>
            <div class="view-confirm">
                <span class="text brc-confirm" (click)="isLimitedImpersonation || openBRCDialog()">VIEW CONFIRMATION</span>
            </div>
        </div>

        <div class="data brc-pending" *ngIf="jobOrderDetails.rateBillViewer && !jobOrderDetails.brcApprover && (jobOrderDetails.brcStatus === 'Open' || jobOrderDetails.brcStatus === 'Pending') && jobOrderDetails.placementType === 'Freelance'">
            <div class="stats">
                <span class="label">Bill Rate</span>
                <span class="text">Pending with {{jobOrderDetails.clientBrcFirstName}} {{jobOrderDetails.clientBrcLastName}}</span>
            </div>
        </div>

        <div class="data brc-pending" *ngIf="jobOrderDetails.rateBillViewer && (jobOrderDetails.brcStatus === 'Closed' || jobOrderDetails.brcStatus === 'Cancelled') && jobOrderDetails.placementType === 'Freelance' && (jobOrderDetails.brcResponse === 'Declined' || !jobOrderDetails.brcResponse)">
            <div class="stats">
                <span class="label">Bill Rate</span>
                <span class="text">Pending with {{jobOrderDetails.clientBrcFirstName}} {{jobOrderDetails.clientBrcLastName}}</span>
            </div>
        </div>

        <div class="data brc-pending" *ngIf="jobOrderDetails.rateBillViewer && !jobOrderDetails.brcApprover && (jobOrderDetails.brcStatus === 'Open' || jobOrderDetails.brcStatus === 'Pending') && jobOrderDetails.placementType === 'Full Time'">
            <div class="stats">
                <span class="label">Placement Salary</span>
                <span class="text">Pending with {{jobOrderDetails.clientBrcFirstName}} {{jobOrderDetails.clientBrcLastName}}</span>
            </div>
        </div>

        <div class="data brc-pending" *ngIf="jobOrderDetails.rateBillViewer && (jobOrderDetails.brcStatus === 'Closed' || jobOrderDetails.brcStatus === 'Cancelled') && jobOrderDetails.placementType === 'Full Time' && jobOrderDetails.brcResponse === 'Declined'">
            <div class="stats">
                <span class="label">Placement Salary</span>
                <span class="text">Pending with {{jobOrderDetails.clientBrcFirstName}} {{jobOrderDetails.clientBrcLastName}}</span>
            </div>
        </div>
        <div *ngIf="jobOrderDetails.placementType" class="data placement-type">
            <span class="label">Placement Type</span>
            <span class="text">{{jobOrderDetails.placementType}}</span>
        </div>
        <div *ngIf="jobOrderDetails.billingContact.trim()" class="data billing-contact">
            <span class="label">Billing Contact</span>
            <span class="text">{{jobOrderDetails.billingContact}}</span>
        </div>
        <hr *ngIf="activeCandidate" />
        <div *ngIf="activeCandidate" class="data">
            <span class="label">Selected Candidate</span>
            <div class="star selected-candidate" *ngIf="!activeCandidate.blocked" (click)="isLimitedImpersonation || favoritedCandidate(activeCandidate.candidateId, activeCandidate.officeId, activeCandidate.favorited)">
                <svg width="19" height="20" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg"><path d="M9 2.314C13.438-2.248 24.534 5.735 9 16-6.534 5.736 4.562-2.248 9 2.314Z" fill-rule="nonzero" stroke="#979797" fill="none" [ngClass]="{ favored: activeCandidate.favorited }"/></svg>
                <span class="text"> {{activeCandidate.firstName}} {{activeCandidate.lastName}}</span>
            </div>
            <button aria-label="Write Review" *ngIf="jobOrderDetails.status === 'ACTIVE'" class="adjust" (click)="openPerformanceReviewDialog(activeCandidate)">Write Review</button>
        </div>
        <hr *ngIf="jobOrderDetails.placementType || jobOrderDetails.billingContact.trim() !== '' " />
        <div class="data opened">
            <span class="label">Opened</span>
            <span class="text">{{jobOrderDetails.dateOpened | date:'MMM d, yyyy'}}</span>
        </div>
        <div class="data total">
            <span class="label">Total Submittals</span>
            <span class="text">{{jobOrderDetails.candidates.length}} Candidates</span>
        </div>
        <div *ngIf="jobOrderDetails.poNumber" class="data billing-contact">
            <span class="label">PO Number</span>
            <span class="text">PO #{{jobOrderDetails.poNumber}}</span>
        </div>
        <hr *ngIf="jobOrderDetails.placementType || jobOrderDetails.poNumber" />
        <div *ngIf="jobOrderDetails.primaryContact" class="data primary-contact">
            <span class="label">Primary Contact</span>
            <span class="text">{{jobOrderDetails.primaryContact}}</span>
        </div>
        <div class="data reports-to">
            <span class="label">Reports To Contact</span>
            <span class="text">{{jobOrderDetails.reportsTo}}</span>
        </div>
        <div class="data work-location" *ngIf="jobOrderDetails.workLine1 || jobOrderDetails.workLocationType">
            <span class="label">Work Location</span>
            <span class="text address-text" *ngIf="jobOrderDetails.workLine1">
                {{jobOrderDetails.workLine1}} <br>
                {{jobOrderDetails.workCity}}, {{jobOrderDetails.workStateProvince}} {{jobOrderDetails.workPostCode}}
            </span>
            <span class="text address-text" *ngIf="!jobOrderDetails.workLine1">
                {{jobOrderDetails.workLocationType}}
            </span>
        </div>
    </div>
</div>
