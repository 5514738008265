<div class="large-container">
    <mat-toolbar color="primary" role="header">
    <span>
      Lightbox Admin
      <small>[ {{ username }} ]</small>
    </span>
        <span class="spacer"></span>
        <a mat-button routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
           routerLink="/admin/clients">Clients</a>
        <a *ngIf="isAdmin()" mat-button routerLinkActive="active"
           [routerLinkActiveOptions]="{exact:true}" routerLink="/admin/messages">Messages</a>
        <a *ngIf="isAdmin()" mat-button routerLinkActive="active"
           [routerLinkActiveOptions]="{exact:true}" routerLink="/admin/templates">Templates</a>
         <a mat-button routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/admin/demo-regions">Demo Users</a>
        <a mat-button (click)="logOut()">Log Out</a>
    </mat-toolbar>
</div>
