<div class="tab-information-container">
    <div class="grey-background">
        <div class="categories">
            <div class="contain" [class.together]="candidate.sunsetFlag === 1 || doNotUse">
                <div class="single-tab">
                    <div class="tabs" (click)="makeActive(1)" >
                        <p [class.active-tab]="activeTab === 1">Submission Details</p>
                        <p class="icon">
                            <svg *ngIf="activeTab != 1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                            <svg *ngIf="activeTab === 1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg>
                        </p>
                    </div>
                    <div *ngIf="activeTab === 1" class="single-content">
                        <app-candidate-details-tab-submitted [candidate]="candidate" [doNotUse]="doNotUse" [job]="job"></app-candidate-details-tab-submitted>
                    </div>
                </div>
                <div class="single-tab" *ngIf="candidate.sunsetFlag === 0 && !doNotUse">
                    <div class="tabs" (click)="makeActive(2)" >
                        <p [class.active-tab]="activeTab === 2">Interviews</p>
                        <p class="icon">
                            <svg *ngIf="activeTab != 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                            <svg *ngIf="activeTab === 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg>
                        </p>
                    </div>
                    <div *ngIf="activeTab === 2" class="single-content">
                        <app-candidate-details-tab-interviews [candidate]="candidate" [candidateObject]="candidateObject"></app-candidate-details-tab-interviews>
                    </div>
                </div>
                <div class="single-tab">
                    <div class="tabs" (click)="makeActive(3)" >
                        <p [class.active-tab]="activeTab === 3">Request Call About Candidate</p>
                        <p class="icon">
                            <svg *ngIf="activeTab != 3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                            <svg *ngIf="activeTab === 3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg>
                        </p>
                    </div>
                    <div *ngIf="activeTab === 3" class="single-content">
                        <app-candidate-details-tab-email [candidate]="candidate"></app-candidate-details-tab-email>
                    </div>
                </div>
                <div class="single-tab">
                    <div class="tabs" (click)="makeActive(4)" >
                        <p [class.active-tab]="activeTab === 4">Connect with Recruiter</p>
                        <p class="icon">
                            <svg *ngIf="activeTab != 4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                            <svg *ngIf="activeTab === 4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg>
                        </p>
                    </div>
                    <div *ngIf="activeTab === 4" class="single-content">
                        <app-candidate-details-tab-contact class="not-mobile" [details]="details"></app-candidate-details-tab-contact>
                    </div>
                </div>
            </div>
            <hr>
        </div>
        <div class="content">
            <div *ngIf="activeTab === 1">
                <app-candidate-details-tab-submitted [candidate]="candidate" [doNotUse]="doNotUse" [job]="job"></app-candidate-details-tab-submitted>
            </div>
            <div *ngIf="activeTab === 2 && candidate.sunsetFlag === 0 && !doNotUse">
               <app-candidate-details-tab-interviews [candidate]="candidate" [candidateObject]="candidateObject"></app-candidate-details-tab-interviews>
            </div>

            <div *ngIf="activeTab === 3">
            	<app-candidate-details-tab-email [candidate]="candidate"></app-candidate-details-tab-email>
            </div>

            <div *ngIf="activeTab === 4">
    			<app-candidate-details-tab-contact class="not-mobile" [details]="details"></app-candidate-details-tab-contact>
            </div>
        </div>
    </div>
</div>
