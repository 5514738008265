<div *ngIf="!forCandidates" class="search-bar-container">
    <svg width="16" class="search-icon" height="16" xmlns="http://www.w3.org/2000/svg">
        <g stroke="#1C2B39" stroke-width="1.673" fill="none" fill-rule="evenodd" stroke-linecap="round"
           stroke-linejoin="round">
            <path d="M7.14 12.667c3.392 0 6.14-2.612 6.14-5.834C13.28 3.612 10.533 1 7.14 1 3.75 1 1 3.612 1 6.833c0 3.222 2.75 5.834 6.14 5.834zM15 15l-3.74-3.553"/>
        </g>
    </svg>
    <input class="search-bar" type="text" [formControl]="formControl" [placeholder]="placeHolder"/>
</div>

<div *ngIf="forCandidates" class="search-bar-container">
    <svg width="16" class="search-icon" height="16" xmlns="http://www.w3.org/2000/svg">
        <g stroke="#1C2B39" stroke-width="1.673" fill="none" fill-rule="evenodd" stroke-linecap="round"
           stroke-linejoin="round">
            <path d="M7.14 12.667c3.392 0 6.14-2.612 6.14-5.834C13.28 3.612 10.533 1 7.14 1 3.75 1 1 3.612 1 6.833c0 3.222 2.75 5.834 6.14 5.834zM15 15l-3.74-3.553"/>
        </g>
    </svg>
    <div>
    	<div class="input-container">
    		<input #search_input_field class="search-bar" (click)="expandOptions()" type="text" [formControl]="formControl" [placeholder]="placeHolder"/>
    		<svg *ngIf="showDropDown" (click)="closeOptions()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#1C2B39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
    	</div>
    	<div *ngIf="showDropDown" class="dropdown-container">
    		<div (click)="searchBy('name')">
    			<svg xmlns="http://www.w3.org/2000/svg" class="check" [class.selected]="filterSelected === 'name'" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
    			<p>SEARCH BY NAME</p>
    		</div>
    		<div (click)="searchBy('notes')">
    			<svg xmlns="http://www.w3.org/2000/svg" class="check" [class.selected]="filterSelected === 'notes'" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
    			<p>SEARCH NOTES</p>
    		</div>
    	</div>
	</div>
</div>
