import { Component, OnInit } from "@angular/core";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../shared";
import { Subscribing } from "../../shared/components/subscribing.component";
import { AdminDemoUser } from "../../shared/models/admin-demo-user.model";

@Component({
    selector: "app-admin-demo-users",
    templateUrl: "./demo-users.component.html",
    styleUrls: ["./demo-users.component.scss"]
})
export class DemoUsersComponent extends Subscribing implements OnInit {
    public displayedColumns = [
        "RegionName",
        "Username"
    ];
    public dataSource = new MatTableDataSource<AdminDemoUser>();
    public resultsLength: number = 0;
    public isLoadingResults: boolean = true;
    public loadingError: string = null;
    public todaysPassword: string = null;

    constructor(
        private userService: UserService,
        private route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit() {
        const regionId = parseInt(this.route.snapshot.paramMap.get('regionId'));
        this.isLoadingResults = true;
        this.subscriptions.push(
            this.userService.getDemoUsers(regionId).subscribe(
                (results: any) => {
                    this.dataSource.data = results.item3.result
                    this.todaysPassword = results.item3.result1[0].password
                    this.isLoadingResults = false;
                }
            ),
        );
    }
}
