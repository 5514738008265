import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, Form, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from '../../services/api.service';
import { JobService } from '../../services/job-orders.service';
import { JobOrderCandidate } from '../../models/job-order-candidate.model';
import { ApiError } from '../../models/api-error.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UserService } from '../../services';

export const CLOSE_SUCCESS = 'SUCCESS';


@Component({
    selector: 'app-email-contact-candidate-dialog',
    templateUrl: './email-contact-candidate-dialog.component.html',
    styleUrls: ['./email-contact-candidate-dialog.component.scss']
})
export class EmailContactCandidateDialogComponent {

    public form: UntypedFormGroup;
    public isSubmitting: boolean = false;
    public formError: string;
    public isLimitedImpersonation: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<EmailContactCandidateDialogComponent>,
        private jobOrdersService: JobService,
        private dialog: MatDialog,
        private userService: UserService
    ) {
        this.form = this.fb.group({
            details: [null, Validators.compose([
                Validators.required,
                Validators.maxLength(1000),
            ])],
            candidateId: [this.data.candidateId, Validators.compose([
                Validators.required,
            ])],
            officeId: [this.data.officeId, Validators.compose([
                Validators.required,
            ])],
            type: [this.data.type, Validators.compose([
                Validators.required,
            ])],
        });

        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    submit() {
        if (this.form.valid && !this.isSubmitting) {
            this.isSubmitting = true;
            let params = {'type': this.form.value['type'], 'details': this.form.value['details']};
            this.jobOrdersService.postContactCandidateEmail(
                this.form.value['officeId'],
                this.form.value['candidateId'],
                params)
                .subscribe(// TODO handle timeout
                    _ => this.dialogRef.close(CLOSE_SUCCESS),
                    (err: ApiError) => {
                        this.isSubmitting = false;
                        if (err.statusCode === 400) {
                            this.formError = 'Error! Please close the dialog and try again.';
                        } else {
                            this.formError = 'Server Error! Please try again later.';
                        }
                    }
                );
        }
    }
}
