<div class="team-member">
    <h3 class="recruiter-name">{{name}}</h3>
    <p class="location">{{title}}</p>
    <div class="contact-detail" *ngIf="phone">
        <div class="action-type-container">
            <div class="action-text">
                <p>CALL</p>
            </div>
        </div>
        <div class="text">
            <p>
                <a href="tel:{{phone | phone}}" (click)="contactRecruiter('Call')">{{formatPhoneNumber(phone)}}</a>
                <span *ngIf="phoneExtension">x<a href="tel:{{phone | phone}}">{{formatPhoneNumber(phone)}}</a></span>
            </p>
        </div>
    </div>
    <div class="contact-detail" *ngIf="mobile">
        <div class="action-type-container">
            <div class="action-text">
                <p>TEXT</p>
            </div>
        </div>
        <div class="text">
            <p>
                <a [href]="safeMobileNumber" (click)="contactRecruiter('Text')">{{formatPhoneNumber(mobile)}}</a>
            </p>
        </div>
    </div>
    <div class="contact-detail" *ngIf="email">
        <div class="action-type-container">
            <div class="action-text">
                <p>EMAIL</p>
            </div>
        </div>
        <div class="text">
            <p>
                <a href="mailto:{{email}}" (click)="contactRecruiter('Email')">{{ email }}</a>
            </p>
        </div>
    </div>
</div>