import { Component, OnInit, OnChanges, Input, ElementRef, ViewChild, HostListener } from '@angular/core';
import { CandidateDetail } from './../../../shared';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AnalyticsService } from './../../../shared/services/analytics.service';
import { HorizontalScrollHelper } from './../../../shared/helpers/horizontal-scroll-helper';
import { DocumentLinkPreviewDialogComponent } from './../../../shared/components/document-link-preview-dialog/document-link-preview-dialog.component';
import { DocumentPreviewDialogComponent } from './../../../shared/components/document-preview-dialog/document-preview-dialog.component';

@Component({
    selector: 'app-candidate-details-links-and-docs',
    templateUrl: './candidate-details-links-and-docs.component.html',
    styleUrls: ['./candidate-details-links-and-docs.component.scss']
})
export class CandidateDetailsLinksAndDocsComponent implements OnInit {
    @ViewChild('document_container', { static: true })
    document_container: ElementRef;

    @Input()
    candidate: CandidateDetail;

    public invalidBack: boolean = true;
    public invalidForward: boolean = false;
    public enableArrows: boolean = false;
    public sortedFiles: any[];

    @HostListener('window:resize') onResize() {
        this.enableArrows = this.document_container.nativeElement.offsetWidth < 600;
    }

    constructor(
        private dialog: MatDialog,
        private analyticsService: AnalyticsService
    ) {}

    determineType(type) {
        if (type == 'R' || type == 'Resume_CC' || type == 'Resume_orig') {
            return 'Resume';
        } else if (type == 'P' || type == 'Portfolio') {
            return 'Portfolio';
        } else if (type == 'Sample') {
            return 'Sample';
        } else if (type == 'Other') {
            return 'Other';
        } else if (type == 'Online App') {
            return 'Document';
        } else {
            return 'Link';
        }
    }

    previewDocument(document) {
        let urlFile = document['url'];
        let extensionPattern = /\.([0-9a-z]+)(?:[\?#]|$)/i;
        let extension = '';
        let previewExtensions = [
            '.xls',
            '.doc',
            '.pdf',
            '.ppt',
            '.pptx',
            '.docx',
            '.xlsx',
            '.ppsx',
            '.jpg',
            '.jpeg',
            '.png'
        ];

        if (urlFile.match(extensionPattern)) {
            extension = urlFile.match(extensionPattern)[0];
        }

        if (previewExtensions.includes(extension)) {
            return true;
        } else {
            return false;
        }
    }

    openLinkPreview(document) {
        this.dialog.open(DocumentLinkPreviewDialogComponent, {
            width: '600px',
            maxWidth: '90%',
            maxHeight: '90%',
            autoFocus: false,
            data: {
                document: document
            }
        });
    }

    openDocumentPreviewDialog(document) {
        this.dialog.open(DocumentPreviewDialogComponent, {
            width: '600px',
            maxWidth: '90%',
            maxHeight: '85%',
            panelClass: 'previewDialog',
            autoFocus: false,
            data: {
                document: document,
                name: this.candidate.firstName + " " + this.candidate.lastName
            }
        });
    }

    scrollContainer(direction) {
        const container = this.document_container.nativeElement.getElementsByClassName(
            'scroll-container'
        );

        const maxScrollLeft = container[0].scrollWidth - container[0].clientWidth;
        const horizontalScrollHelper = new HorizontalScrollHelper();
        if (direction == 'forward') {
            const scrollLeft = container[0].scrollLeft + 310;

            if (scrollLeft >= maxScrollLeft) {
                this.invalidForward = true;
            } else {
                this.invalidForward = false;
            }
            this.invalidBack = false;
            horizontalScrollHelper.smoothScrollTo(container[0], scrollLeft, 750);
        } else {
            const scrollRight = container[0].scrollLeft - 310;
            if (scrollRight <= 0) {
                this.invalidBack = true;
                this.invalidForward = false;
            } else {
                this.invalidBack = false;
                this.invalidForward = true;
            }
            horizontalScrollHelper.smoothScrollTo(container[0], scrollRight, 750);
        }
    }

    registerClick(document) {
        if (this.candidate.jobCandidateId) {
            this.analyticsService.fireEvent('Job Candidates', 'Document Viewed', document.fileType, {
                'dimension6': this.candidate.jobCandidateId,
                'dimension5': this.candidate.jobId.toString() + "." + this.candidate.officeId.toString(),
                'dimension3': this.candidate.clientId.toString() + '.' + this.candidate.officeId.toString()
            });
        } else {
            this.analyticsService.fireEvent('Candidates', 'Document Viewed', document.fileType, {'dimension7': this.candidate.candidateId});
        }
    }

    sortFiles() {
        this.sortedFiles = this.candidate.files.sort((fileOne, fileTwo) => fileOne.fileType.localeCompare(fileTwo.fileType));
    }

    ngOnChanges() {
        this.sortFiles();
    }

    ngOnInit() {
        this.enableArrows = this.document_container.nativeElement.offsetWidth < 600;
        this.sortFiles();
    }
}
