import {
    Component,
    OnInit,
    Inject
} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
    CandidatesService,
} from '../../../shared';
import { Candidate, CandidateDetail } from '../../models';
import { ApiError } from "../../../shared/models/api-error.model";
import { NetworkErrorSnackbarService } from "../../../shared/services/network-error-snackbar.service";

@Component({
    selector: 'app-candidate-dialog',
    templateUrl: './candidate-dialog.component.html',
    styleUrls: ['./candidate-dialog.component.scss']
})
export class CandidateDialogComponent implements OnInit {
    public candidateDetail: CandidateDetail;
    public candidate: Candidate;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private candidatesService: CandidatesService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
        private dialogRef: MatDialogRef<void>,
    ) {
        this.candidate = data['jobCandidate'];
    }

    ngOnInit() {
        this.refreshCandidate();
    }

    refreshCandidate() {
        let officeId = this.candidate.officeId;
        let candidateId = this.candidate.candidateId;

        this.candidatesService
            .getCandidatesDetails(officeId, candidateId)
            .subscribe(data => {
                this.candidateDetail = data;
            },
            (err: ApiError) => {
                this.dialogRef.close();
                this.networkErrorSnackbarService.networkError(err,'')
            }
        );
    }
}
