import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from '../../services/message.service';
import { Message } from '../../models/message.model';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy {

    messages: Array<Message> = [];

    constructor(private messageService: MessageService) {
    }

    ngOnInit() {
        this.messageService.update$
            .subscribe((messages: Array<Message>) => this.messages = messages);
    }

    closeAlert(index: number) {
        this.messageService.removeMessage(index);
    }

    ngOnDestroy() {
        this.messageService.clearMessages();
    }
}
