import { Component, OnInit } from '@angular/core';
import { UserService, User } from '../../shared';
import { Router } from '@angular/router';
import { Subscribing } from '../../shared/components/subscribing.component';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
    selector: 'admin-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends Subscribing implements OnInit {
    public username: string;
    private userType: string;

    constructor(
        private userService: UserService,
        private router: Router,
        private analyticsServices: AnalyticsService
    ) {
        super();

        this.subscriptions.push(
            this.userService.currentUser.subscribe((user: User) => {
                this.username = user.username;
                this.userType = user.userType;
            })
        );
    }

    public isCollapsed = true;

    ngOnInit() {
    }

    isAdmin() {
        return this.userType === "admin";
    }

    logOut() {
        // when the user gets wiped, navigate home
        this.subscriptions.push(
            this.userService.currentUser.subscribe(user => {
                if (!user.userId) this.router.navigateByUrl('/home');
            })
        );
        this.userService.purgeAuth();
    }
}
