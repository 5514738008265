<div mat-dialog-title>
    &nbsp;
    <div class="close" (click)="close()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea"
             stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
    </div>
</div>
<iframe [src]='data.brcLink' frameborder="0" width="100%" height="100%"></iframe>
