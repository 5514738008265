import { Component, OnInit } from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    AbstractControl
} from '@angular/forms';
import { UserService, MessageService, ApiService } from '../../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiError } from '../../shared/models/api-error.model';
import match from '../../shared/validators/match';
import {
    Message,
    MESSAGE_TYPE_SUCCESS
} from '../../shared/models/message.model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AlertDialogComponent } from '../../shared/components/alert-dialog/alert-dialog.component';
import { LbTitle } from '../../shared/services/title.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
    passwordResetForm: UntypedFormGroup;
    invalidToken: boolean = false;
    isSubmitting: boolean = false;
    hasEightCharacters: boolean = false;
    hasOneUpperCase: boolean = false;
    hasOneLowerCase: boolean = false;
    hasNumeric: boolean = false;
    hasActivePopover: boolean = false;
    public requestTalentUrl: string = environment.request_talent_url;
    public timecardLoginUrl: string = environment.timecard_login_url;

    public get password(): AbstractControl {
        return this.passwordResetForm.get('password');
    }

    public get passwordConfirmation(): AbstractControl {
        return this.passwordResetForm.get('passwordConfirmation');
    }

    private get token(): AbstractControl {
        return this.passwordResetForm.get('token');
    }

    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private dialog: MatDialog,
        private titleService: LbTitle
    ) {
        this.titleService.setTitleFromKeywords('Reset Password');

        this.passwordResetForm = this.fb.group(
            {
                password: [
                    null,
                    Validators.compose([
                        Validators.required,
                        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)
                    ])
                ],
                passwordConfirmation: [null, Validators.compose([Validators.required])],
                token: [null, Validators.compose([Validators.required])],
                minimumChar: [false],
                upperCaseChar: [false],
                lowerCaseChar: [false],
                numericChar: [false],
                specialChar: [false]
            },
            {
                validator: match('password', 'passwordConfirmation')
            }
        );

        this.passwordResetForm.get('password').valueChanges.subscribe(value => {
            let flag = false;

            // minimum 8 characters
            if (!this.updatePasswordCriteria('minimumChar', value.length >= 8)) {
                flag = true;
            }

            // at least 1 uppercase character
            if (!this.updatePasswordCriteria('upperCaseChar', value.match(/[A-Z]/))) {
                flag = true;
            }

            // at least 1 lowercase character
            if (!this.updatePasswordCriteria('lowerCaseChar', value.match(/[a-z]/))) {
                flag = true;
            }

            // at least 1 numeral
            if (!this.updatePasswordCriteria('numericChar', value.match(/\d/))) {
                flag = true;
            }

            // an optional special character
            if (
                !this.updatePasswordCriteria(
                    'specialChar',
                    value.match(/[^a-zA-Z\d\s]/)
                )
            ) {
                flag = true;
            }
            this.hasActivePopover = flag;
        });
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.token.setValue(params.resetToken);
            this.apiService
                .get('/user/password-reset?token=' + params.resetToken)
                        // TODO handle timeout
                        .subscribe(_ => _, e => (this.invalidToken = true));
        });

        this.passwordResetForm.valueChanges.subscribe(data => {
            if (/^(?=.{8,})/.test(data['password'])) {
                this.hasEightCharacters = true;
            } else {
                this.hasEightCharacters = false;
            }

            if (/^(?=.*[A-Z])/.test(data['password'])) {
                this.hasOneUpperCase = true;
            } else {
                this.hasOneUpperCase = false;
            }

            if (/^(?=.*[a-z])/.test(data['password'])) {
                this.hasOneLowerCase = true;
            } else {
                this.hasOneLowerCase = false;
            }

            if (/^(?=.*[0-9])/.test(data['password'])) {
                this.hasNumeric = true;
            } else {
                this.hasNumeric = false;
            }
        });
    }

    submitForm() {
        this.isSubmitting = true;
                        // TODO handle timeout
                        this.userService.updatePassword(this.passwordResetForm.value).subscribe(
            data => {
                this.isSubmitting = false;
                const dialog = this.dialog.open(AlertDialogComponent, {
                    width: '400px',
                    data: {
                        title: 'SUCCESS!',
                        message: 'Your new password has been set.',
                        buttonText: 'Log In'
                    }
                });

                dialog.afterClosed().subscribe(_ => this.router.navigateByUrl('/'));

                return data;
            },
            (err: ApiError) => {
                this.isSubmitting = false;
                if (err.statusCode === 401) {
                    this.passwordResetForm.setErrors({unauthorized: true});
                } else if (err.statusCode === 400) {
                    this.passwordResetForm.setErrors({invalid: true});
                } else if (err.statusCode === 500) {
                    this.passwordResetForm.setErrors({server: true});
                }
            }
        );
    }

    /**
     * Update checkboxes for the various password criteria
     * @param {string} field
     * @param {boolean} value
     * @return {boolean}
     */
    private updatePasswordCriteria(field: string, value: boolean): boolean {
        this.passwordResetForm.get(field).setValue(value);

        return value;
    }
}
