<div class="background-container">
    <div class="small-container">
        <mat-card>
            <div class="logo-container">
                <img src="../assets/images/circle-logo.png" alt="Creative Circle Lightbox" />
            </div>
            <mat-card-title>
                Log in to Your Account
            </mat-card-title>
            <mat-card-content>
                <app-messages> </app-messages>
                <p *ngIf="routeError">
                    <span class="danger">{{ routeError }}</span>
                </p>
                <form [formGroup]="authForm" (ngSubmit)="submitForm()">
                    <mat-form-field>
                        <input matInput type="text" formControlName="username" placeholder="Username / Email Address" autocomplete="username" />
                    </mat-form-field>

                    <mat-form-field>
                        <input matInput type="password" formControlName="password" placeholder="Password" autocomplete="current-password" />
                    </mat-form-field>

                    <a routerLink="/login/forgot" class="register register-left">
                        FORGOT PASSWORD?
                    </a>

                    <p class="error" *ngIf="authForm.hasError('unauthorized')">
                        Invalid username or password. <br class="mobile-only">Please try again.
                    </p>

                    <p class="error" *ngIf="authForm.hasError('server')">
                        An error occurred while trying to log you in.
                        <span *ngIf="authForm.getError('server') !== true">Reference {{ authForm.getError("server") }}.</span>
                    </p>

                    <p class="error" *ngIf="authForm.hasError('timeout')">
                        It took to long to submit your credentials. Please check your network connection and try again.
                    </p>

                    <p class="error" *ngIf="authForm.hasError('deactivated')">
                        We're sorry. We can't log you in at this time.
                    </p>

                    <button aria-label="Login" mat-raised-button class="btn" [class.invalid-btn]="authForm.invalid" [class.is-submitting]="isSubmitting" color="primary" type="submit" [disabled]="!authForm.valid || isSubmitting">
                        <span class="not-submitting">Log In</span>
                        <span class="submitting">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                                <line x1="12" y1="2" x2="12" y2="6"></line>
                                <line x1="12" y1="18" x2="12" y2="22"></line>
                                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                                <line x1="2" y1="12" x2="6" y2="12"></line>
                                <line x1="18" y1="12" x2="22" y2="12"></line>
                                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                            </svg>
                        </span>
                    </button>

                    <a routerLink="/register" class="register">CREATE AN ACCOUNT</a>

                    <p class="legal" [innerHTML]="legalText"></p>
                </form>
            </mat-card-content>
        </mat-card>

        <p class="questions">
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(1 1)" fill="none" fill-rule="evenodd">
                    <circle stroke="#FFF" cx="7" cy="7.225" r="7" />
                    <text font-family="Montserrat" font-size="10.8" font-weight="bold" fill="#FFF"><tspan x="4.5" y="11">?</tspan></text>
                </g>
            </svg>
            QUESTIONS?
            <a href="https://www.creativecircle.com/contact-us/" (click)="fireContactEvent()" target="_blank" rel="noopener" class="register">
                CONTACT US
            </a>
        </p>
    </div>

    <div class="show-on-mobile">
        <div class="container">
            <div class="link">
                <a class="nav-link" href="https://www.creativecircle.com/roles-we-place/" target="_blank" rel="noopener">
                    ROLES WE PLACE
                </a>
            </div>
            <div class="link">
                <a class="nav-link" [href]="timecardLoginUrl" target="_blank" rel="noopener">
                    TIMECARD LOGIN
                </a>
            </div>
            <div class="link">
                <a class="nav-link" [href]="requestTalentUrl" target="_blank" rel="noopener">
                    REQUEST TALENT
                </a>
            </div>
        </div>
    </div>
</div>
