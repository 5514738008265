<div class="modal-header">
    <h3 mat-dialog-title>
        <span>{{ data.candidateFirstName }}</span> will be noted as a Maybe
    </h3>
    <svg (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x on-white">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
</div>

<div mat-dialog-content>
    <form [formGroup]="form" (submit)="submit()">
        <input type="hidden" formControlName="jobId">
        <input type="hidden" formControlName="officeId">
        <input type="hidden" formControlName="jobCandidateId">
        <input type="hidden" formControlName="statusId">

        <div class="text-area-favorite-wrapper passed-input">
            <label>Things you like about this candidate?</label>
            <textarea [(ngModel)]="advantages" formControlName="advantages"
                      placeholder="Write what you like about the candidate here...">
            </textarea>
        </div>

        <div class="text-area-favorite-wrapper passed-input">
            <label>Any hesitations?</label>
            <textarea [(ngModel)]="disadvantages" formControlName="disadvantages" placeholder="Write any hesitations here..."></textarea>
        </div>

        <div class="access-control">
            <div class="control">
                <input type="checkbox" formControlName='addToNotes' value="addToNotes" id="addToNotes"
                       (click)="determineInput($event)"
                       [attr.disabled]="advantages.length == 0 && disadvantages.length == 0 ? '' : null">
                <label [class]="advantages == '' && disadvantages == '' ? 'disabled' : ''" for="addToNotes">Save to your Notes</label>
            </div>
            <div class="control">
                <input type="checkbox" formControlName='sharedHiringManagers' value="hiring-managers"
                       id="sharedHiringManagers" (click)="determineInput($event)"
                       [attr.disabled]="addToNotes && (advantages != '' || disadvantages != '') ? null : ''">
                <label [class]="addToNotes && (advantages != '' || disadvantages != '') ? '' : 'disabled'" for="sharedHiringManagers">Save to your team's Notes</label>
            </div>
        </div>
        <div class="counter">
            <span [class.error]="countMax < 0">Characters remaining: {{countMax}}</span>
        </div>
    </form>
    <div class="error">
        <p *ngIf="formError" class="form-error">{{ formError }}</p>
    </div>
</div>
<div mat-dialog-actions>
    <button aria-label="Submit" [ngClass]="{'is-submitting': isSubmitting}" [disabled]="isLimitedImpersonation || !form.valid || this.typingLength > 4000" (click)="submit()">
        <span class="not-submitting">Submit</span>
        <span class="spinner" *ngIf="isSubmitting">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        </span>
    </button>
</div>
