import { Component, OnInit, Input, Output } from '@angular/core';
import { Job } from '../../../../shared';
import { jobColorFromJob } from '../../../../shared/helpers/status-color';
import { JobOrderListComponent } from '../job-order-list.component';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-job-order-card',
    templateUrl: './job-order-card.component.html',
    styleUrls: ['./job-order-card.component.scss']
})
export class JobOrderCardComponent implements OnInit {
    @Input()
    job: Job;

    @Input()
    dashboardView: boolean = false;

    @Input()
    sortedBy: string;

    lineClass: string = '';
    public isFullTime: boolean = false;

    constructor(private router: Router, private analyticsService: AnalyticsService) {}
    ngOnInit() {
        this.lineClass = jobColorFromJob(this.job);
        this.isFullTime = JobOrderListComponent.isFullTime(this.job);
    }

    routeToJobClick() {
        this.analyticsService.fireEvent(
            'Jobs',
            'Job Clicked',
            'OPEN'
        );
    }
}
