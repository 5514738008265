import { Component, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { DomSanitizer } from "@angular/platform-browser";
import { UserService } from "../../shared";
import { Subscribing } from "../../shared/components/subscribing.component";
import { AdminDemoRegion } from "../../shared/models/admin-regions-user.model";

@Component({
    selector: "app-admin-regions-users",
    templateUrl: "./demo-regions.component.html",
    styleUrls: ["./demo-regions.component.scss"]
})
export class DemoRegionsComponent extends Subscribing implements OnInit {
    public displayedColumns = [
        "name",
        "numberofusers",
        "action"
    ];
    public dataSource = new MatTableDataSource<AdminDemoRegion>();
    public resultsLength: number = 0;
    public isLoadingResults: boolean = true;
    public loadingError: string = null;

    constructor(
        private userService: UserService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        super();

        iconRegistry.addSvgIcon(
            "users",
            sanitizer.bypassSecurityTrustResourceUrl("assets/icons/log-in.svg")
        );
    }

    ngOnInit() {
        this.isLoadingResults = true;
        this.subscriptions.push(
            this.userService.getDemoRegions().subscribe(
                (results: any) => {
                    this.dataSource.data = results.item3.result.filter(region => {
                        return region.active === true;
                    })
                    this.isLoadingResults = false;
                }
            ),
        );
    }
}
