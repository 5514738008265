<div class="not-found-page">
    <div class="background-container">
        <div class="form-card">
            <img src="assets/images/logo-email-2.png" alt="Maintenance Logo">
            <p>Sorry, this page is not found.</p>
            <a routerLink="/">
                <button aria-label="Ok">OK</button>
            </a>
        </div>
    </div>
</div>
