import { AbstractControl } from '@angular/forms';

export default function () {
    return (control: AbstractControl) => {
        if (typeof control.value === 'string') {
            if (!control.value.trim()) {
                return {required: true};
            }
        }

        return null;
    };
}
