import { AbstractControl } from '@angular/forms';

export default function (firstControlName, secondControlName) {
    return (AC: AbstractControl) => {
        if (AC.get(firstControlName).value !== AC.get(secondControlName).value) {
            AC.get(secondControlName).setErrors({match: true});
        } else {
            return null;
        }
    };
};