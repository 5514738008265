<svg (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
     stroke="#b3e1ea"
     stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x on-white">
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 15 15" fill="none" stroke="currentColor"
     stroke-width="2"
     stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle message-svg">
    <path
        d="M12.4 9.2a5.667 5.667 0 0 1-5.067 3.133 5.587 5.587 0 0 1-2.533-.6L1 13l1.267-3.8a5.587 5.587 0 0 1-.6-2.533A5.667 5.667 0 0 1 4.8 1.6 5.587 5.587 0 0 1 7.333 1h.334A5.653 5.653 0 0 1 13 6.333v.334a5.587 5.587 0 0 1-.6 2.533z"
        stroke="#b3e1ea" stroke-width="1.2" fill="none" fill-rule="evenodd" stroke-linecap="round"
        stroke-linejoin="round"/>
</svg>
<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
    <p *ngIf="message" [innerHTML]="message"></p>
    <div *ngIf="!message" class="loader">
        <svg class="loading-gif" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"
             fill="none" stroke="#ffffff"
             stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
            <line x1="12" y1="2" x2="12" y2="6"></line>
            <line x1="12" y1="18" x2="12" y2="22"></line>
            <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
            <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
            <line x1="2" y1="12" x2="6" y2="12"></line>
            <line x1="18" y1="12" x2="22" y2="12"></line>
            <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
            <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
        </svg>
    </div>
</div>
<div mat-dialog-actions>
    <button aria-label="Dismiss" [mat-dialog-close]="true">Dismiss</button>
</div>
