import { AfterContentChecked, AfterViewChecked, Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Job, User, UserService } from "../../../shared";
import { RequestTalentDialogComponent } from "../../../shared/components/request-talent-dialog/request-talent-dialog.component";
import { Subscribing } from "../../../shared/components/subscribing.component";
import { ApiError } from "../../../shared/models/api-error.model";
import { AnalyticsService } from "../../../shared/services/analytics.service";
import {
    JobService,
    JOB_STATUS_ACTIVE,
    JOB_STATUS_CANCELLED,
    JOB_STATUS_CLOSED,
    JOB_STATUS_OPEN,
    JOB_STATUS_PAST
} from "../../../shared/services/job-orders.service";
import { NetworkErrorSnackbarService } from "../../../shared/services/network-error-snackbar.service";
import { debounceTime } from 'rxjs/operators';

class ActiveJobIds {
    public officeId: number;
    public jobId: number;
}

class JobTypes {
    public "Full Time": boolean = false;
    public Freelance: boolean = false;
}

class JobStatuses {
    public open: boolean = false;
    public closed: boolean = false;
    public active: boolean = false;
    public cancelled: boolean = false;
    public past: boolean = false;
}

class JobListFilters {
    areas: string[] = [];
    jobTypes: JobTypes = new JobTypes();
    jobStatuses: JobStatuses = new JobStatuses();
    onlyShowNew: boolean = false;
    jobGroup: string = "ALL";
    poNumber: string = "";
    jobId: string = "";
    candidateName: string = "";
    search: string = "";
}

@Component({
    selector: "app-job-order-list",
    templateUrl: "./job-order-list.component.html",
    styleUrls: ["./job-order-list.component.scss"]
})
export class JobOrderListComponent extends Subscribing implements OnInit, AfterViewChecked, AfterContentChecked {
    @ViewChild("area_filters") area_filters: ElementRef;
    @ViewChild("filter_dropdown_container") filter_dropdown_container: ElementRef;
    @ViewChild("scroll_jobs", { static: true }) scroll_jobs: ElementRef;

    private defaultFilters: JobListFilters = new JobListFilters();
    public activeFilters: JobListFilters = Object.assign(
        {},
        this.defaultFilters
    );
    public searchFormControl: UntypedFormControl = new UntypedFormControl();
    public activeJob: ActiveJobIds;
    public isLoadingResults: boolean = true;
    loadingError: ApiError;
    public allJobs: Job[] = [];
    public filteredJobs: Job[] = [];
    public filteredJobsType: string = null;
    public filteredJobsGroup: string = null;
    public openRequests: Job[];
    public activePlacements: Job[];
    public pastPlacements: Job[];
    public cancelledRequests: Job[];
    public sortDropdownActive = false;
    public activeSort: string = "status";
    public filterDropdownActive = false;
    public firstColumnAreas: any[];
    public secondColumnAreas: any[];
    public user: User;
    public areaFiltered: boolean = false;
    public isWindows: boolean = false;
    public hideOtherJobs: boolean = false;
    public totalFiltered: number = 0;
    public cardClicked: boolean = false;

    @HostBinding("class.hide-tablet") hideTablet: boolean = false;

    constructor(
        private analyticsService: AnalyticsService,
        private route: ActivatedRoute,
        private router: Router,
        private jobService: JobService,
        private dialog: MatDialog,
        private userService: UserService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
    ) {
        super();
    }

    ngAfterContentChecked() {
        this.route.queryParams.subscribe(queryParams => {
            const active = queryParams['active'];

            if (active === '1') {
                this.activeFilters.jobStatuses['active'] = true;
                this.applyJobFilters(true);
            }
        });
    }

    ngAfterViewChecked(): void {
        if (Object.keys(this.activeJob).length && !this.cardClicked) {
            const jobElement =
                this.scroll_jobs.nativeElement.querySelector('app-job-order-card[data-job-id="' + this.activeJob.jobId + '"]');
            if (jobElement != null) {
                jobElement.scrollIntoView();
            }
        }
    }

    ngOnInit() {
        this.clearAllFilters('');

        if (
            navigator.userAgent.indexOf("Chrome") !== -1 &&
            navigator.userAgent.indexOf("Windows") !== -1
        ) {
            this.isWindows = true;
        }

        this.subscriptions.push(
            this.jobService.jobsLoading.subscribe(isLoading => {
                this.isLoadingResults = isLoading;
            })
        );

        this.route.params.subscribe(params => {
            if (params.officeId && params.jobId) {
                this.activeJob = new ActiveJobIds();
                this.activeJob.jobId = params.jobId;
                this.activeJob.officeId = params.officeId;
                this.hideTablet = true;
            } else {
                this.activeJob = new ActiveJobIds();
                this.hideTablet = false;
            }
        });

        this.jobService.jobs.subscribe(jobs => {
            this.allJobs = jobs.slice();
            this.filteredJobs = jobs.slice();
            this.activeSort = "status";

            this.populateFilteredJobCategories();
            this.createAreaInputFields();
        });

        this.refreshJobs();

        this.searchFormControl.valueChanges
            .pipe(
                debounceTime(400)
            )
            .subscribe(value => {
                this.activeFilters.search = value;
                this.analyticsService.fireEvent("Jobs", "Search");
                this.applyJobFilters();
            });

        this.userService.currentUser.subscribe(userData => {
            this.user = userData;
        });
    }

    refreshJobs() {
        this.loadingError = null;
        this.jobService.getJobs()
            .subscribe({
                error: (err: ApiError) => (this.networkErrorSnackbarService.networkError(err,''))
        });
    }

    handleCardClick(job: Job) {
        this.cardClicked = true;
        this.router.navigate([
            "jobs",
            { officeId: job.officeId, jobId: job.jobId }
        ]);

        this.analyticsService.fireEvent("Jobs", "Job Clicked", job.status, {
            dimension5: job.jobId.toString() + "." + job.officeId.toString(),
            dimension3: job.clientId.toString() + "." + job.officeId.toString()
        });
    }

    createAreaInputFields() {
        let areas = [];

        for (let i = 0; i < this.allJobs.length; i++) {
            const area = this.allJobs[i]["area"];
            if (areas.indexOf(area) === -1) areas.push(area);
        }

        let sortedAreas = areas.sort();

        if (sortedAreas.length > 5) {
            if (sortedAreas.length % 2) {
                // odd number
                this.firstColumnAreas = sortedAreas.slice(
                    0,
                    sortedAreas.length / 2 + 1
                );
                this.secondColumnAreas = sortedAreas.slice(
                    sortedAreas.length / 2 + 1,
                    sortedAreas.length
                );
            } else {
                // even number
                this.firstColumnAreas = sortedAreas.slice(
                    0,
                    sortedAreas.length / 2
                );
                this.secondColumnAreas = sortedAreas.slice(
                    sortedAreas.length / 2,
                    sortedAreas.length
                );
            }
        } else {
            this.firstColumnAreas = sortedAreas;
        }
    }

    toggleFilterDropdown() {
        this.sortDropdownActive = false;
        this.filterDropdownActive = !this.filterDropdownActive;
    }

    closeFilterDropdown() {
        this.filterDropdownActive = false;
        this.sortDropdownActive = false;
    }

    applyJobFilters(filterMenuAction = false) {
        if (filterMenuAction) {
            this.activeSort = "status";
            this.filteredJobsType = null;
            this.filteredJobsGroup = null;
        }

        let jobs = this.allJobs.slice();

        this.populateAreaFilters();
        if (this.activeFilters.areas.length) {
            jobs = jobs.filter(job => {
                return this.activeFilters.areas.indexOf(job.area) !== -1;
            });
        }

        if (this.activeFilters.candidateName) {
            const candNameQueries = this.activeFilters.candidateName
                .trim()
                .toLowerCase()
                .split(" ")
                .filter(term => !!term);

            jobs = jobs.filter(
                JobOrderListComponent.jobMatchesCandNameQueries(candNameQueries)
            );
        }

        const jobTypes = Object.keys(this.activeFilters.jobTypes).filter(
            type => this.activeFilters.jobTypes[type]
        );
        if (jobTypes.length) {
            jobs = jobs.filter(
                job => jobTypes.indexOf(job.employmentType) !== -1
            );
        }

        const jobStatuses = Object.keys(this.activeFilters.jobStatuses).filter(
            status => this.activeFilters.jobStatuses[status]
        );
        if (jobStatuses.length) {
            jobs = this.filterJobStatuses(jobs, jobStatuses);
        }

        if (this.activeFilters.onlyShowNew) {
            jobs = jobs.filter(
                job =>
                    job.status === JOB_STATUS_OPEN && job.numNewSubmittals > 0
            );
        }

        if (this.activeFilters.poNumber) {
            jobs = jobs.filter(job =>
                JobOrderListComponent.jobMatchesPoNumberQuery(
                    job,
                    this.activeFilters.poNumber
                )
            );
        }

        if (this.activeFilters.jobId) {
            jobs = jobs.filter(job =>
                JobOrderListComponent.jobMatchesJobIdQuery(
                    job,
                    this.activeFilters.jobId
                )
            );
        }

        if (this.activeFilters.search) {
            let searchTerm = this.activeFilters.search.toLowerCase();
            jobs = jobs.filter(
                job =>
                    job.jobTitle &&
                    job.jobTitle.toLowerCase().search(searchTerm) !== -1
            );
        }

        let filtersSelected: string[] = [];

        const status = this.activeFilters.jobStatuses;
        for (let statusKey in status)
            if (status[statusKey]) filtersSelected.push(statusKey);

        if (this.activeFilters.candidateName.length) {
            filtersSelected.push("candidateName");
        }

        if (this.activeFilters.poNumber.length) {
            filtersSelected.push("poNumber");
        }

        if (this.activeFilters.jobId.length) {
            filtersSelected.push("jobId");
        }

        if (this.activeFilters.areas.length) {
            filtersSelected.push("area");
        }

        if (this.activeFilters.jobTypes["Freelance"]) {
            filtersSelected.push("Freelance");
        }

        if (this.activeFilters.jobTypes["Full-Time"]) {
            filtersSelected.push("Full-Time");
        }

        if (this.activeFilters.onlyShowNew) {
            filtersSelected.push("onlyShowNew");
        }

        const filtersSelectedString: string = filtersSelected.join(",");

        if (filtersSelectedString) {
            this.analyticsService.fireEvent(
                "Jobs",
                "Filtered",
                filtersSelectedString
            );
        }

        if (this.filter_dropdown_container) {
            let totalChecked = [];
            let inputFields = this.filter_dropdown_container.nativeElement.querySelectorAll('input');

            for (let i = 0; i < inputFields.length; i++) {
                if (inputFields[i].checked) {
                    totalChecked.push(inputFields[i]);
                }
            }

            if (this.activeFilters.poNumber.length) {
               totalChecked.length += 1;
            }

            if (this.activeFilters.candidateName.length) {
                totalChecked.length += 1;
            }

            if (this.activeFilters.jobId.length) {
                totalChecked.length += 1;
            }

            this.totalFiltered = totalChecked.length;
        }


        this.filteredJobs = jobs;

        this.populateFilteredJobCategories();
    }

    private static jobMatchesCandNameQueries(
        candNameQueries: string[]
    ): (job: Job) => any {
        return job => {
            const candFirstName =
                    job.activeCandidateFirstName &&
                    job.activeCandidateFirstName.toLowerCase(),
                candLastName =
                    job.activeCandidateLastName &&
                    job.activeCandidateLastName.toLowerCase();

            if (!candFirstName && !candLastName) return false;

            for (let i = 0, len = candNameQueries.length; i < len; i++) {
                if (
                    (candFirstName &&
                        candFirstName.search(candNameQueries[i]) !== -1) ||
                    (candLastName &&
                        candLastName.search(candNameQueries[i]) !== -1)
                ) {
                    return true;
                }
            }

            return false;
        };
    }

    clearAllFilters(selector) {
        if (this.filter_dropdown_container) {
            let allFilterInputs = this.filter_dropdown_container.nativeElement.querySelectorAll(
                ".filter-check"
            );

            if (selector === 'ALL') {
                for (let i = 0; i < allFilterInputs.length; i++) {
                    allFilterInputs[i].checked = false;
                }
                this.totalFiltered = 0;
                return true;
            }

            this.activeFilters.areas = [];
            for (let i = 0; i < allFilterInputs.length; i++) {
                if (allFilterInputs[i].checked && allFilterInputs[i].classList.contains(selector)) {
                    allFilterInputs[i].checked = false;
                    this.totalFiltered -= 1;
                }
            }
        }

        if (selector === 'placement-select') {
            this.activeFilters.jobTypes['Full Time'] = false;
            this.activeFilters.jobTypes['Freelance'] = false;
        }

        if (selector === 'job-status-select') {
            this.activeFilters.jobStatuses['open'] = false;
            this.activeFilters.jobStatuses['active'] = false;
            this.activeFilters.jobStatuses['past'] = false;
            this.activeFilters.jobStatuses['canceled'] = false;
            this.activeFilters.jobStatuses['closed'] = false;
        }

        if (selector === 'area-select') {
            this.activeFilters.areas = [];
        }

        this.applyJobFilters(true);

    }

    toggleSortDropdown() {
        if (this.filterDropdownActive) {
            this.filterDropdownActive = false;
        }
        this.sortDropdownActive = !this.sortDropdownActive;
    }

    closeSortDropdown() {
        this.sortDropdownActive = false;
    }

    sortBy(sort = "status"): void {
        this.activeSort = sort;
        this.sortDropdownActive = false;

        this.filteredJobs = this.allJobs;
        this.applyJobFilters();

        if (sort === "status") {
            return this.populateFilteredJobCategories();
        }

        let sortedList = this.filteredJobs
            .slice()
            .sort(JobOrderListComponent.defaultSortJob);

        switch (sort) {
            case "curr-openings-asc":
                sortedList = sortedList.sort(
                    (a: Job, b: Job): number => {
                        // OPEN jobs first
                        if (
                            a.status != JOB_STATUS_OPEN &&
                            b.status == JOB_STATUS_OPEN
                        ) {
                            return 1;
                        } else if (
                            b.status != JOB_STATUS_OPEN &&
                            a.status == JOB_STATUS_OPEN
                        ) {
                            return -1;
                        }

                        if (
                            a.status === JOB_STATUS_OPEN &&
                            b.status === JOB_STATUS_OPEN
                        ) {
                            return (
                                +new Date(a.dateOpened) -
                                +new Date(b.dateOpened)
                            );
                        }
                    }
                );

                this.filteredJobsGroup = "Current Openings";
                this.filteredJobsType = JOB_STATUS_OPEN;
                break;
            case "curr-openings-desc":
                sortedList = sortedList.sort((a: Job, b: Job) => {
                    // OPEN jobs first
                    if (
                        a.status != JOB_STATUS_OPEN &&
                        b.status == JOB_STATUS_OPEN
                    ) {
                        return 1;
                    } else if (
                        a.status == JOB_STATUS_OPEN &&
                        b.status != JOB_STATUS_OPEN
                    ) {
                        return -1;
                    }

                    if (
                        a.status === JOB_STATUS_OPEN &&
                        b.status === JOB_STATUS_OPEN
                    ) {
                        return (
                            +new Date(b.dateOpened) - +new Date(a.dateOpened)
                        );
                    }
                });

                this.filteredJobsGroup = "Current Openings";
                this.filteredJobsType = JOB_STATUS_OPEN;
                break;
            case "num-candidates-asc":
                sortedList = sortedList.sort((a: Job, b: Job) => {
                    // OPEN jobs come first
                    if (
                        a.status != JOB_STATUS_OPEN &&
                        b.status == JOB_STATUS_OPEN
                    ) {
                        return 1;
                    } else if (
                        b.status != JOB_STATUS_OPEN &&
                        a.status == JOB_STATUS_OPEN
                    ) {
                        return -1;
                    }

                    if (
                        a.status === JOB_STATUS_OPEN &&
                        b.status === JOB_STATUS_OPEN
                    ) {
                        // highest num new submittals come first if different
                        if (a.numNewSubmittals > b.numNewSubmittals) {
                            return 1;
                        } else if (a.numNewSubmittals < b.numNewSubmittals) {
                            return -1;
                        }
                    }

                    // date opened desc as fallback
                    return +new Date(a.dateOpened) - +new Date(b.dateOpened);
                });

                // Items with new submittals first (groups red and orange)
                sortedList = sortedList.reduce((comp, element) => {
                    if (element.numNewSubmittals >= 0 && element.numUnderConsideration === 0) {
                        return [element, ...comp];
                    }
                    return [...comp, element];
                }, []);

                this.filteredJobsGroup = "Current Openings";
                this.filteredJobsType = JOB_STATUS_OPEN;
                break;
            case "num-candidates-desc":
                sortedList = sortedList.sort((a: Job, b: Job) => {
                    // OPEN jobs come first
                    if (
                        a.status != JOB_STATUS_OPEN &&
                        b.status == JOB_STATUS_OPEN
                    ) {
                        return 1;
                    } else if (
                        b.status != JOB_STATUS_OPEN &&
                        a.status == JOB_STATUS_OPEN
                    ) {
                        return -1;
                    }

                    if (
                        a.status === JOB_STATUS_OPEN &&
                        b.status === JOB_STATUS_OPEN
                    ) {
                        // highest num new submittals come first if different
                        if (a.numNewSubmittals > b.numNewSubmittals) {
                            return -1;
                        } else if (a.numNewSubmittals < b.numNewSubmittals) {
                            return 1;
                        }
                    }

                    // date opened desc as fallback
                    return +new Date(a.dateOpened) - +new Date(b.dateOpened);
                });

                // Items with new submittals first (groups red and orange)
                sortedList = sortedList.reduce((comp, element) => {
                    if (element.numNewSubmittals >= 0 && element.numUnderConsideration === 0) {
                        return [element, ...comp];
                    }
                    return [...comp, element];
                }, []);

                this.filteredJobsGroup = "Current Openings";
                this.filteredJobsType = JOB_STATUS_OPEN;
                break;
            case "end-date-asc":
                sortedList = sortedList.sort((a: Job, b: Job) => {
                    // ACTIVE jobs come first
                    if (
                        a.status != JOB_STATUS_ACTIVE &&
                        b.status == JOB_STATUS_ACTIVE
                    ) {
                        return 1;
                    } else if (
                        b.status != JOB_STATUS_ACTIVE &&
                        a.status == JOB_STATUS_ACTIVE
                    ) {
                        return -1;
                    }

                    if (
                        a.status === JOB_STATUS_ACTIVE &&
                        b.status === JOB_STATUS_ACTIVE
                    ) {
                        return +new Date(a.dateEnd) - +new Date(b.dateEnd);
                    }
                });

                // Also, sort the past and closed plasements/requests
                this.cancelledRequests.sort((a: Job, b: Job) => {
                    return +new Date(a.dateEnd) - +new Date(b.dateEnd);
                });
                this.pastPlacements.sort((a: Job, b: Job) => {
                    return +new Date(a.dateEnd) - +new Date(b.dateEnd);
                });

                this.filteredJobsGroup = "Active Placements";
                this.filteredJobsType = JOB_STATUS_ACTIVE;
                break;
            case "end-date-desc":
                sortedList = sortedList.sort((a: Job, b: Job) => {
                    // ACTIVE jobs come first
                    if (
                        a.status != JOB_STATUS_ACTIVE &&
                        b.status == JOB_STATUS_ACTIVE
                    ) {
                        return 1;
                    } else if (
                        b.status != JOB_STATUS_ACTIVE &&
                        a.status == JOB_STATUS_ACTIVE
                    ) {
                        return -1;
                    }

                    if (
                        a.status === JOB_STATUS_ACTIVE &&
                        b.status === JOB_STATUS_ACTIVE
                    ) {
                        return +new Date(b.dateEnd) - +new Date(a.dateEnd);
                    }
                });

                // Also, sort the past and closed plasements/requests
                this.cancelledRequests.sort((a: Job, b: Job) => {
                    return +new Date(b.dateEnd) - +new Date(a.dateEnd);
                });
                this.pastPlacements.sort((a: Job, b: Job) => {
                    return +new Date(b.dateEnd) - +new Date(a.dateEnd);
                });

                this.filteredJobsGroup = "Active Placements";
                this.filteredJobsType = JOB_STATUS_ACTIVE;

                let openJobs = [];
                for (let i = 0; i < this.filteredJobs.length; i++) {
                    if (this.filteredJobs[i].status === "OPEN") {
                        openJobs.push(this.filteredJobs[i]);
                    }
                }

                if (openJobs.length === 0) {
                    this.hideOtherJobs = true;
                }
                break;
        }

        this.analyticsService.fireEvent("Jobs", "Sorted", sort);
        this.filteredJobs = sortedList;
    }

    openRequestTalentDialog() {
        const dialog = this.dialog.open(RequestTalentDialogComponent, {
            width: "600px",
            maxWidth: "90%",
            maxHeight: "90%",
            autoFocus: false,
            data: { user: this.user, analyticsLabel: 'Job Orders' }
        });

        this.analyticsService.fireEvent(
            'Talent Request',
            'Request Talent Click',
            'Job Orders'
        );

        dialog.afterClosed().subscribe();
    }

    static isJobActiveFullTime(job: Job): boolean {
        return (
            JobOrderListComponent.isFullTime(job) &&
            JobOrderListComponent.isJobStartInFuture(job)
        );
    }

    static isFullTime(job: Job): boolean {
        return job.fullTime && job.status === JOB_STATUS_CLOSED;
    }

    private static jobMatchesJobIdQuery(job: Job, query: string): boolean {
        if (!query || !query.trim()) return true;
        if (!job.jobId) return false;

        const jobId: string = job.jobId.toString();
        const queries: string[] = query
            .trim()
            .replace(/[^0-9 ]/g, "")
            .split(" ")
            .filter(q => !!q);

        for (let i = 0, len = queries.length; i < len; i++)
            if (jobId === queries[i]) return true;

        return false;
    }

    private static jobMatchesPoNumberQuery(job: Job, query: string): boolean {
        if (!query || !query.trim()) return true;
        if (!job.poNumber || !job.poNumber.trim()) return false;

        const poNumber: string = job.poNumber
            .trim()
            .toLowerCase()
            .replace(/[^a-z0-9 ]/g, "");

        const queries: string[] = query
            .trim()
            .toLowerCase()
            .replace(/[^a-z0-9 ]/g, "")
            .split(" ")
            .filter(q => !!q);

        for (let i = 0, len = queries.length; i < len; i++)
            if (poNumber.search(queries[i]) !== -1) return true;

        return false;
    }

    private populateAreaFilters() {
        this.activeFilters.areas = [];
        if (!this.area_filters) return;

        let allFilterInputs = this.area_filters.nativeElement.querySelectorAll(
            ".filter-check"
        );

        for (let i = 0, len = allFilterInputs.length; i < len; i++)
            if (allFilterInputs[i].checked)
                this.activeFilters.areas.push(allFilterInputs[i].dataset.value);
    }

    private populateFilteredJobCategories() {
        this.openRequests = this.filteredJobs
            .filter(JobOrderListComponent.isJobOpenRequest)
            .sort(JobOrderListComponent.defaultSortJob);
        this.activePlacements = this.filteredJobs
            .filter(JobOrderListComponent.isJobActivePlacement)
            .sort(JobOrderListComponent.defaultSortCandidate);
        this.pastPlacements = this.filteredJobs
            .filter(JobOrderListComponent.isJobPastPlacement)
            .sort(JobOrderListComponent.defaultSortCandidate);
        this.cancelledRequests = this.filteredJobs
            .filter(JobOrderListComponent.isJobCancelledPlacement)
            .sort(JobOrderListComponent.defaultSortJob);
    }

    private static isJobOpenRequest(j: Job): boolean {
        return j.status === JOB_STATUS_OPEN;
    }

    private static isJobActivePlacement(job: Job): boolean {
        return (
            job.status === JOB_STATUS_ACTIVE ||
            JobOrderListComponent.isJobActiveFullTime(job)
        );
    }

    private static isJobPastPlacement(job: Job): boolean {
        return (
            job.status === JOB_STATUS_PAST ||
            (job.status === JOB_STATUS_CLOSED &&
                !JobOrderListComponent.isJobActiveFullTime(job))
        );
    }

    private static isJobCancelledPlacement(job: Job): boolean {
        return job.status === JOB_STATUS_CANCELLED;
    }

    private static isJobStartInFuture(job: Job) {
        if (!job.dateStart) return false;

        let jobDate: number = Number(job.dateStart.replace(/-/g, "")),
            currD: Date = new Date(),
            currDate: number = Number(
                String(currD.getFullYear()).padStart(4, "0") +
                    String(currD.getMonth() + 1).padStart(2, "0") +
                    String(currD.getDate()).padStart(2, "0")
            );

        // e.g. 20190102 > 20190101
        return jobDate > currDate;
    }

    /**
     * The default sort method for current openings and canceled placements. Sorting order is:
     * 1. Job title (ASC)
     * 2. Number of new submissions (DESC)
     * 3. Number of under consideration or running candidates (DESC)
     *
     * @param a Job First job to compare
     * @param b Job Second job to compare
     *
     * @return int Result of comparison
     */
    private static defaultSortJob(a: Job, b: Job) {
        if (a.jobTitle < b.jobTitle) {
            // Job title (ascending)
            return -1;
        } else if (a.jobTitle > b.jobTitle) {
            return 1;
        } else if (a.numNewSubmittals > b.numNewSubmittals) {
            // New submissions (descending)
            return -1;
        } else if (a.numNewSubmittals < b.numNewSubmittals) {
            return 1;
        } else if (a.numUnderConsideration > b.numUnderConsideration) {
            // Running candidates (descending)
            return -1;
        } else if (a.numUnderConsideration < b.numUnderConsideration) {
            return 1;
        }

        return 0;
    }

    /**
     * The default sort method for active and past placements. Sorting order is:
     * 1. Active candidate's first name (ASC)
     * 2. Active candidate's last name (ASC)
     * 3. Job's end date (ASC)
     *
     * @param a Job First job to compare
     * @param b Job Second job to compare
     *
     * @return int Result of comparison
     */
    private static defaultSortCandidate(a: Job, b: Job) {
        if (a.activeCandidateFirstName < b.activeCandidateFirstName) {
            // Active candidate's first name (ascending)
            return -1;
        } else if (a.activeCandidateFirstName > b.activeCandidateFirstName) {
            return 1;
        } else if (a.activeCandidateLastName < b.activeCandidateLastName) {
            // Active candidate's last name (ascending)
            return -1;
        } else if (a.activeCandidateLastName > b.activeCandidateLastName) {
            return 1;
        } else if (Date.parse(a.dateEnd) < Date.parse(b.dateEnd)) {
            // End date (ascending)
            return -1;
        } else if (Date.parse(a.dateEnd) > Date.parse(b.dateEnd)) {
            return 1;
        }

        return 0;
    }

    /**
     * Filters jobs based on the selected status(es) (the statuses 'active' and 'past' for full-time jobs are treated differently in that
     * they are selected if the job's start date is the future or in the past, respectively. Using the 'status' job property for those types
     * of jobs is incorrect).
     *
     * @param jobs Job[] First job to compare
     * @param jobStatuses [] Job Second job to compare
     *
     * @return Job[] jobs Filtered job list
     */
    private filterJobStatuses(jobs: Job[], jobStatuses) {
        jobs = jobs.filter(function(job: Job) {
            if (
                jobStatuses.indexOf(JOB_STATUS_ACTIVE.toLowerCase()) !== -1 &&
                JobOrderListComponent.isJobActiveFullTime(job)
            ) {
                return true;
            } else if (
                JobOrderListComponent.isFullTime(job) &&
                jobStatuses.indexOf(JOB_STATUS_PAST.toLowerCase()) !== -1 &&
                JobOrderListComponent.isJobPastPlacement(job)
            ) {
                return true;
            } else {
                return jobStatuses.indexOf(job.status.toLowerCase()) !== -1;
            }
        });

        return jobs;
    }
}
