<div *ngIf="formSubmitted">
    <p class="info">Thanks! Your recruiter will reach out shortly.</p>
</div>
<div class="form" *ngIf="!formSubmitted">
    <p class="info" *ngIf="type === 'CallMe' || !type">We'll be happy to give you a call about
        <span class="bold">{{candidate.firstName}}</span>. What information should we be aware of when we call?
    </p>
    <p class="info" *ngIf="type === 'MoreLikeThis'">
        Request More Candidates like {{candidate.firstName}}
    </p>
    <form [formGroup]="form" (submit)="submit()">
        <div class="text-area-favorite-wrapper">
            <textarea formControlName="details" placeholder="Write some additional details here to share with your recruiter."></textarea>
        </div>
        <div class="counter">
            <span [class.error]="countMax < 0">Characters remaining: {{countMax}}</span>
        </div>
        <div class="error">
            <p *ngIf="formError" class="form-error">{{ formError }}</p>
        </div>
        <button aria-label="Submit" [ngClass]="{'is-submitting': isSubmitting}" [disabled]="isLimitedImpersonation || !form.valid" (click)="submit()">
            <span class="not-submitting">Submit</span>
            <span class="submitting">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                    <line x1="12" y1="2" x2="12" y2="6"></line>
                    <line x1="12" y1="18" x2="12" y2="22"></line>
                    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                    <line x1="2" y1="12" x2="6" y2="12"></line>
                    <line x1="18" y1="12" x2="22" y2="12"></line>
                    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                </svg>
            </span>
        </button>
    </form>
</div>
