import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SearchNotesModeService {

    private notesEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    update$: Observable<boolean> = this.notesEnabled.asObservable();

    updateBoolean(edited: boolean) {
        this.notesEnabled.next(edited);
    }
}
