import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Message } from '../models/message.model';

@Injectable()
export class MessageService {
    private messages = [];
    private messages$: BehaviorSubject<Array<Message>> = new BehaviorSubject<Array<Message>>(this.messages);

    update$: Observable<Array<Message>> = this.messages$.asObservable();

    addMessage(message: Message) {
        // Adds a new message if the list is empty or it's empty and the message doesn't exist in it
        if (
            this.messages.length === 0 ||
            (this.messages.length > 0 && this.messages.find(msg => msg.message === message.message) === undefined)
        ) {
            this.messages.push(message);
        }

        this.messages$.next(this.messages);
    }

    removeMessage(index: number) {
        this.messages.splice(index, 1);
        this.messages$.next(this.messages);
    }

    clearMessages() {
        this.messages = [];
        this.messages$.next(this.messages);
    }
}
