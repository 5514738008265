<div class="contact-row" *ngIf="details.recruiterEmail">
  <div class="grey-background">
    <div class="contact">
      <p class="name">{{ details.recruiterName }}</p>
      <p class="title">Recruiter</p>
    </div>
    <div class="contact-details">
      <a href="tel:{{details.recruiterPhoneNumber}}" title="{{details.recruiterPhoneNumber}}" target="_blank" rel="noopener">
        <div class="detail" *ngIf="details.recruiterPhoneNumber">
          <div class="row">
            <div class="title">
              <p>CALL</p>
            </div>
            <div class="text" *ngIf="!details.recruiterPhoneNumberExtension">
              <p>{{formatPhoneNumber(details.recruiterPhoneNumber) }}</p>
            </div>
            <div class="text" *ngIf="details.recruiterPhoneNumberExtension">
              <p>{{formatPhoneNumber(details.recruiterPhoneNumber) }} x
              {{details.recruiterPhoneNumberExtension}}</p>
            </div>
          </div>
        </div>
      </a>
      <a *ngIf="details.recruiterMobilePhoneNumber" [href]="details.textNumberSafe" title="{{details.textNumberSafe}}" target="_blank" rel="noopener">
        <div class="detail row">
          <div class="title">
            <p>TEXT</p>
          </div>
          <div class="text">
            <p>{{formatPhoneNumber(details.recruiterMobilePhoneNumber) }}</p>
          </div>
        </div>
      </a>
      <a *ngIf="details.recruiterEmail" href="mailto:{{details.recruiterEmail}}">
        <div class="detail row">
          <div class="title">
            <p>EMAIL</p>
          </div>
          <div class="text">
            <p>
              {{ details.recruiterEmail }}
            </p>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>