import { Component, OnInit, Input } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { CandidatesService, DashboardService } from "../../shared";
import { MessageDialogComponent } from "../../shared/components/message-dialog/message-dialog.component";
import { RecentActivity } from "../../shared/models";
import { AnalyticsService } from "../../shared/services/analytics.service";
import { CandidateDialogComponent } from "./../../shared/components/candidate-dialog/candidate-dialog.component";
import { Subscribing } from "../../shared/components/subscribing.component";
import { ApiError } from "../../shared/models/api-error.model";

@Component({
    selector: "dashboard-recent-activity",
    templateUrl: "./recent-activity.component.html",
    styleUrls: ["./recent-activity.component.scss"]
})
export class RecentActivityComponent extends Subscribing implements OnInit {
    @Input()
    public activeSection: boolean;

    recentActivity: RecentActivity[];
    isLoadingResults: boolean = true;
    loadingError: ApiError;

    constructor(
        private dashboardService: DashboardService,
        private analyticsService: AnalyticsService,
        private candidatesService: CandidatesService,
        private router: Router,
        private dialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(
            this.dashboardService.recentActivityLoading.subscribe(isLoading => {
                this.isLoadingResults = isLoading;
            }),
            this.dashboardService.recentActivity.subscribe(recentActivity => {
                for (let i = 0; i < recentActivity.length; i++) {
                    recentActivity[i]["svgSrc"] =
                        "../assets/sprites/sprite.svg#" +
                        recentActivity[i]["icon"];
                }
                this.recentActivity = recentActivity;
            })
        );

        this.loadRecentActivity();
    }

    loadRecentActivity() {
        this.loadingError = null;
        this.dashboardService.getRecentActivity().subscribe({
            error: (apiError: ApiError) => {
                this.recentActivity = null;
                this.loadingError = apiError;
            }
        });
    }

    perfReviewSubmitted(activity) {
        const candFullName =
            activity.candFirstName + " " + activity.candLastName;
        const jobTitle = activity.jobTitle;

        return (
            "Submitted performance review for " +
            candFullName +
            " for " +
            jobTitle
        );
    }

    jobEndDateExtension(activity) {
        const candFullName =
            activity.candFirstName + " " + activity.candLastName;
        const jobTitle = activity.jobTitle;

        return "Extended end date for " + candFullName + " for " + jobTitle;
    }

    jobEndDateContraction(activity) {
        const candFullName =
            activity.candFirstName + " " + activity.candLastName;
        const jobTitle = activity.jobTitle;

        return (
            "Requested end date change for " + candFullName + " for " + jobTitle
        );
    }

    jobStatusChange(activity) {
        const originalStatus = Number(activity.jobCandStatusIDFrm);
        const changedStatus = Number(activity.jobCandStatusIDTo);
        const candFullName =
            activity.candFirstName + " " + activity.candLastName;
        const jobTitle = activity.jobTitle;

        // hired
        if (changedStatus === 8) {
            return "Selected " + candFullName + " for " + jobTitle;
        }

        // hire pending
        if (changedStatus === 7) {
            return "Hire Pending for " + candFullName + " for " + jobTitle;
        }

        // interview requested
        if (changedStatus === 3) {
            return "Requested Interview with " + candFullName + " for " + jobTitle;
        }

        // passed on
        if (changedStatus === 5) {
            return "Passed on " + candFullName + " for " + jobTitle;
        }

        // Considering
        if (changedStatus === 6) {
            return "Considering " + candFullName + " for " + jobTitle;
        }

        // Rescheduled interview (status stays the same jobCandStatusIDFrom and jobCandStatusIDTo)
        if (originalStatus === 2 && changedStatus === 2) {
            return "Requested interview reschedule for " + candFullName + " for " + jobTitle;
        }

        // Scheduled interview
        if (changedStatus === 2) {
            return "Interview scheduled for " + candFullName + " for " + jobTitle;
        }
    }

    jobCandShared(activity) {
        const candFullName =
            activity.candFirstName + " " + activity.candLastName;
        const recipientFullName =
            activity.recipientFName + " " + activity.recipientLName;

        return "Shared " + candFullName + " with " + recipientFullName;
    }

    candShared(activity) {
        const candFullName =
            activity.candFirstName + " " + activity.candLastName;
        const recipientFullName =
            activity.recipientFName + " " + activity.recipientLName;

        return "Shared " + candFullName + " with " + recipientFullName;
    }

    messageRead(activity) {
        return "Read message from Creative Circle";
    }

    /**
     * Returns the local date/time object for the provided date/time string
     *
     * @param date string (e.g. "2018-10-02T21:21:17.83")
     *
     * @return Date
     */
    adjustForLocalTime(date) {
        // Splits the UTC/GMT formatted date/time string from the server
        const dateParts = date.split(/[^0-9]/);

        // Instantiates a new Date object from the split parts so that it renders the same date/time in all browsers
        const newDate = new Date(
            dateParts[0],
            dateParts[1] - 1,
            dateParts[2],
            dateParts[3],
            dateParts[4],
            dateParts[5]
        );

        // Gets the timezone difference and offsets it
        const timeOffsetInMS: number = newDate.getTimezoneOffset() * 60000;
        newDate.setTime(newDate.getTime() - timeOffsetInMS);

        return newDate;
    }

    determineActivityType(activity) {
        const activityId = activity.activityLogTypeID;
        switch (activityId) {
            case 1:
                return this.perfReviewSubmitted(activity);
            case 2:
                return this.jobEndDateExtension(activity);
            case 3:
                return this.jobEndDateContraction(activity);
            case 4:
                return this.jobStatusChange(activity);
            case 5:
                return this.jobCandShared(activity);
            case 6:
                return this.candShared(activity);
            case 7:
                return this.messageRead(activity);
        }
    }

    displayMessage(messageId) {
        this.dialog.open(MessageDialogComponent, {
            width: "600px",
            maxWidth: "90%",
            maxHeight: "90%",
            data: {
                messageID: messageId
            }
        });
    }

    openCandidateDetailsModal(officeId, candidateId) {
        this.candidatesService
            .getCandidatesDetails(officeId, candidateId)
            // TODO handle timeout
            .subscribe(data => {
                const dialog = this.dialog.open(CandidateDialogComponent, {
                    width: "800px",
                    panelClass: "candidate_details_page",
                    autoFocus: false,
                    data: {
                        jobCandidate: data,
                        recentAcivity: true
                    }
                });
            });
    }

    activityClicked(activity) {
        this.analyticsService.fireEvent(
            "Recent Activity",
            "Activity Log Item Clicked",
            activity.dateCreated.toString()
        );

        const activityId = activity.activityLogTypeID;
        const officeId = activity.ofcID;
        const jobId = activity.jobID;
        const jobCandidateId = activity.jobCandID;
        const candidateId = activity.candID;
        const messageId = activity.messageID;

        const jobCandidateDetailsRoute = [1, 4, 5];
        const candidateDetailsRoute = [6];
        const jobDetails = [2, 3];
        const message = [7];

        if (jobCandidateDetailsRoute.includes(activityId)) {
            return this.router.navigate([
                "jobs",
                { officeId, jobId, jobCandidateId }
            ]);
        } else if (candidateDetailsRoute.includes(activityId)) {
            return this.router.navigate(["candidates", { candidateId }]);
        } else if (jobDetails.includes(activityId)) {
            return this.router.navigate(["jobs", { officeId, jobId }]);
        } else if (message.includes(activityId)) {
            this.displayMessage(messageId);
        } else {
            return false;
        }
    }
}
