import { Component, OnInit, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { TemplatesService } from "../templates.service";
import { SendTestEmailDialogData } from "./send-test-email-dialog-data";
import { UserService } from "../../../shared/services/user.service";
import { Subscribing } from "../../../shared/components/subscribing.component";
import { ApiError } from '../../../shared/models/api-error.model';

@Component({
    selector: "app-send-test-email-dialog",
    templateUrl: "./send-test-email-dialog.component.html",
    styleUrls: ["./send-test-email-dialog.component.scss"]
})
export class SendTestEmailDialogComponent extends Subscribing
    implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SendTestEmailDialogData,
        private dialogRef: MatDialogRef<SendTestEmailDialogComponent>,
        private userService: UserService,
        private templateService: TemplatesService
    ) {
        super();
    }

    public toAddress: string;

    ngOnInit() {
        this.userService.currentUser.subscribe(
            user => (this.toAddress = user.email)
        );
    }

    cancel() {
        this.dialogRef.close(null);
    }

    submit() {
        this.subscriptions.push(
            this.templateService
                .sendTestEmail(
                    this.data.templateKeyGroup,
                    this.toAddress,
                    this.data.subjectTemplate,
                    this.data.bodyTemplate,
                    this.data.linkTextTemplateKey,
                    this.data.linkUrlTemplate
                )
                .subscribe({
                    next: () => this.dialogRef.close(true),
                    error: (err: ApiError) => this.dialogRef.close(err)
                })
        );
    }
}
