import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UntypedFormBuilder, Validators, Form, UntypedFormGroup } from '@angular/forms';
import { JobService } from '../../../../shared/services/job-orders.service';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import {
    Candidate, CandidateDetail, UserService
} from '../../../../shared';
import { NetworkErrorSnackbarService } from "../../../../shared/services/network-error-snackbar.service";
import {switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-candidate-details-tab-email',
    templateUrl: './candidate-details-tab-email.component.html',
    styleUrls: ['./candidate-details-tab-email.component.scss']
})
export class CandidateDetailsTabEmailComponent implements OnChanges {

    public form: UntypedFormGroup;
    public isSubmitting: boolean = false;
    public formError: string;
    public formSubmitted: boolean = false;
    public isLimitedImpersonation: boolean = false;
    public countMax: number = 4000;

    @Input()
    candidate: CandidateDetail;

    @Input()
    details: any;

    @Input()
    type: string;

    constructor(
       private fb: UntypedFormBuilder,
       private jobOrdersService: JobService,
       private analyticsService: AnalyticsService,
       private userService: UserService,
       private networkErrorSnackbarService: NetworkErrorSnackbarService,
    ) {
        this.form = this.fb.group({
            details: [null, Validators.compose([
                Validators.required,
                Validators.maxLength(4000),
            ])],
        });

        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });

        this.form.controls['details'].valueChanges.subscribe(content => {
            this.countMax = 4000 - content.length;
        });
    }

    ngOnChanges() {
        this.formSubmitted = false;
        this.form.controls['details'].setValue('');
    }

    submitCandidateModalForm() {
        if (this.form.valid && !this.isSubmitting) {
            this.isSubmitting = true;
            let params = {'type': this.type, 'details': this.form.value['details']};
            this.jobOrdersService.postContactCandidateEmail(
                this.candidate.officeId,
                this.candidate.candidateId,
                params)
                        // TODO handle timeout
                        .subscribe(
                data => {
                    this.formSubmitted = true;

                    if (this.type === 'CallMe') {
                        this.analyticsService.fireEvent(
                            'Candidates',
                            'Request Call About Candidate Submitted',
                            '',
                            {'dimension7': this.candidate.candidateId, 'dimension5': this.candidate.jobId.toString() + "." + this.candidate.officeId.toString()}
                        );
                    } else {
                        this.analyticsService.fireEvent(
                            'Candidates',
                            'Send More Like This Submitted',
                            '',
                            {'dimension7': this.candidate.candidateId, 'dimension5': this.candidate.jobId.toString() + "." + this.candidate.officeId.toString()}
                        );
                    }
                },
                err => {
                    if (err.statusCode === 400) {
                        this.formError = 'Error! Please close and try again.';
                    } else {
                        this.networkErrorSnackbarService.networkError(err,'')
                    }
                }
                );
        }
    }

    submitJobCandidate() {
        if (this.form.valid && !this.isSubmitting) {
            this.isSubmitting = true;

            let params = {'type': 'CallMe', 'details': this.form.value['details']};
            let postContactEmail = this.jobOrdersService.postContactEmail(
                this.candidate.officeId,
                this.candidate.jobId,
                this.candidate.candidateId,
                this.candidate.jobCandidateId,
                params
            );

            const patchJobCandidate = this.jobOrdersService.patchJobCandidate(
                this.candidate.officeId,
                this.candidate.jobId,
                this.candidate.jobCandidateId,
                {
                    statusId: this.candidate.statusId,
                    details: this.form.value['details'],
                    addingLastClientComment: true
                }
            );

            postContactEmail = postContactEmail.pipe(
                switchMap(() => patchJobCandidate)
            );

            postContactEmail.subscribe(
                // TODO handle timeout
                data => {
                    this.formSubmitted = true;
                    this.analyticsService.fireEvent(
                        'Job Candidates',
                        'Request Call About Candidate Submitted',
                        this.candidate.statusId.toString(),
                        {'dimension6': this.candidate.jobCandidateId, 'dimension3': this.candidate.clientId.toString() + "." + this.candidate.officeId.toString(), 'dimension5': this.candidate.jobId.toString() + "." + this.candidate.officeId.toString()}
                    );
                },
                err => {
                    if (err.statusCode === 400) {
                        this.formError = 'Error! Please close and try again.';
                    } else {
                        this.isSubmitting = false;
                        this.networkErrorSnackbarService.networkError(err,'')
                    }
                }
            );
        }
    }

    submit() {
      if (this.type) {
          this.submitCandidateModalForm();
      } else {
          this.submitJobCandidate();
      }
    }

}
