import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SearchNotesModeService, CandidateSearchService } from './../../shared';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
    @ViewChild('search_input_field') search_input_field: ElementRef;

    @Input()
    public formControl: UntypedFormControl;

    @Input()
    public placeHolder: String;

    @Input()
    public forCandidates: Boolean = false;

    public filterSelected: String = 'name';

    public showDropDown: Boolean = false;

    constructor(
        private searchNotesModeService: SearchNotesModeService,
        private candidateSearchService: CandidateSearchService,
        ) {
    }

    expandOptions() {
        this.showDropDown = !this.showDropDown;
    }

    closeOptions() {
        this.showDropDown = false;
        this.formControl.setValue('');
    }

    searchBy(type) {
        this.filterSelected = type;
        this.formControl.setValue('');

        if (type === 'name') {
            this.placeHolder = "SEARCH BY NAME";
            this.searchNotesModeService.updateBoolean(false);
        } else {
            this.placeHolder = "SEARCH NOTES";
            this.searchNotesModeService.updateBoolean(true);
        }

        this.search_input_field.nativeElement.value = '';
        this.search_input_field.nativeElement.focus();
        this.showDropDown = false;
    }

    ngOnInit() {
        this.searchNotesModeService.updateBoolean(false);
        this.formControl.valueChanges.subscribe(value => {
            if (value === '') {
                this.candidateSearchService.updateBoolean(true);
            } else {
                this.candidateSearchService.updateBoolean(false);
            }
        })
    }

}
