import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from "@angular/forms";
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserService } from '../../shared';
import { RequestTalentDialogComponent } from '../../shared/components/request-talent-dialog/request-talent-dialog.component';
import { Subscribing } from '../../shared/components/subscribing.component';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { HamburgerService } from '../../shared/services/hamburger.service';
import { NavigationScrollService } from '../../shared/services/navigation-scroll.service';
import { User } from './../../shared/models';
import { environment } from "../../../environments/environment";

@Component({
    selector: "app-fixed-navigation-bar",
    templateUrl: "./fixed-navigation-bar.component.html",
    styleUrls: ["./fixed-navigation-bar.component.scss"]
})
export class FixedNavigationBarComponent extends Subscribing implements OnInit {
    @ViewChild("mobile_icons")
    mobile_icons: ElementRef;
    public requestTalentUrl: string = environment.request_talent_url;
    public newForm: UntypedFormGroup;
    public showDropdown: boolean = false;
    public expandedHamburger: boolean = false;
    public currentUser: User;
    public fixedNav: boolean = false;
    public onSharePage: boolean = false;
    public isImpersonation: boolean;
    public currentRoute: string = "";
    public helpUrl: string = "";
    public timecardLoginUrl: string = "";
    public get email(): AbstractControl {
        return this.newForm.get("email");
    }
    public get token(): AbstractControl {
        return this.newForm.get("token");
    }

    constructor(
        private router: Router,
        private userService: UserService,
        private navigationScrollService: NavigationScrollService,
        private hamburgerService: HamburgerService,
        private analyticsServices: AnalyticsService,
        private dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        private analyticsService: AnalyticsService
    ) {
        super();
        this.subscriptions.push(router.events.pipe(
            filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                this.currentRoute = event.url;
            }));
        
        this.newForm = this.formBuilder.group({
            email: ["",
                Validators.compose([Validators.required])
            ],
            token: ["",
                Validators.compose([Validators.required])
            ]
        })
    }

    logout() {
        this.subscriptions.push(
            this.userService.currentUser.subscribe(
                user =>
                    user.active != true && this.router.navigateByUrl("/home")
            )
        );
        this.userService.purgeAuth();
        this.analyticsServices.fireEvent("Logout", "Logout Complete");
    }

    backToAdmin() {
        this.subscriptions.push(
            this.userService
                .endImpersonation()
                        // TODO handle timeout
                        .subscribe(
                    _ => this.router.navigateByUrl("/admin/clients"),
                    _ => (<any>window).reload()
                )
        );
    }

    closeMyAccount() {
        this.showDropdown = false;
    }

    openMyAccount() {
        this.showDropdown = true;
    }

    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
    }

    toggleHamburger() {
        this.expandedHamburger = !this.expandedHamburger;
        this.hamburgerService.updateBoolean(this.expandedHamburger);
    }

    ngOnInit() {
        this.currentRoute = this.router.url;
        this.helpUrl = environment.help_url;
        this.timecardLoginUrl = environment.timecard_login_url;

        let share = this.router.url.split("/")[1];
        if (share == "share") {
            this.onSharePage = true;
        }

        this.subscriptions.push(
            this.router.events.subscribe(_ => {
                if (_ instanceof NavigationEnd) {
                    this.expandedHamburger = false;
                    this.hamburgerService.updateBoolean(this.expandedHamburger);
                }
            }),

            this.userService.getGiantToken().subscribe(data => {
                this.newForm.get("token").setValue(data["token"]);
            }),
            
            this.userService.currentUser.subscribe(userData => {
                this.currentUser = userData;
                this.newForm.get("email").setValue(userData.email);
            }),
            this.navigationScrollService.update$.subscribe(bool => {
                this.fixedNav = bool;
            }),
        
            this.userService.isImpersonation.subscribe(isImp => {
                this.isImpersonation = isImp;
            })
        );
    }

    linkOutClick(text, url) {
        this.analyticsServices.fireEvent(
            'Outbound Link Click',
            'Header : ' + text,
            url
        );
    }

    openRequestTalentDialog() {
        const dialog = this.dialog.open(RequestTalentDialogComponent, {
            width: '600px',
            maxWidth: '90%',
            maxHeight: '90%',
            autoFocus: false,
            data: { user: this.currentUser, analyticsLabel: 'Header' }
        });

        this.analyticsService.fireEvent(
            'Talent Request',
            'Request Talent Click',
            'Header'
        );

        dialog.afterClosed().subscribe();
    }
}
