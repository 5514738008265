<div class="header">
    <svg (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x on-white">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
    <h1>
        {{ data.title }} <span>{{data.candidateName}}</span>
    </h1>
</div>
<div mat-dialog-content>
    <form [formGroup]="form" (submit)="submit()">
        <input type="hidden" formControlName="jobCandidateId">
        <input type="hidden" formControlName="jobId">
        <input type="hidden" formControlName="officeId">
        <input type="hidden" formControlName="statusId">

        <div class="text-area-favorite-wrapper availability">
            <label>What is your availability?</label>
            <input formControlName="availability"
                   placeholder="Write available dates & times here..."/>
        </div>

        <div class="text-area-favorite-wrapper meet-options">
            <label>How would you like to meet?</label>
            <div class="selection-container">
                <div class="selection">
                    <input formControlName="contactMethod" value="phone" type="radio" name="contactMethod" id="select-phone"/>
                    <label for="select-phone">By Phone</label>
                </div>
                <div class="selection">
                    <input formControlName="contactMethod" value="person" type="radio" name="contactMethod" id="select-person"/>
                    <label for="select-person">In Person</label>
                </div>
                <div class="selection">
                    <input formControlName="contactMethod" value="video" type="radio" name="contactMethod" id="select-video"/>
                    <label for="select-video">Video Conference</label>
                </div>
            </div>
        </div>

        <div class="text-area-favorite-wrapper">
            <label>Do you have any other details?</label>
            <textarea formControlName="details"
                      placeholder="Write additional details here..."></textarea>
        </div>

        <div class="access-control">
            <div class="control">
                <input type="checkbox" formControlName='addToNotes' value="addToNotes" id="addToNotes"
                       (click)="determineInput($event)"
                       [attr.disabled]="!form.valid ? '' : null">
                <label [class]="!form.valid ? 'disabled' : ''" for="addToNotes">Save to your Notes</label>
            </div>
            <div class="control">
                <input type="checkbox" formControlName='sharedHiringManagers' value="hiring-managers"
                       id="sharedHiringManagers" (click)="determineInput($event)"
                       [attr.disabled]="addToNotes && form.valid ? null : ''">
                <label [class]="addToNotes && form.valid ? '' : 'disabled'" for="sharedHiringManagers">Save to your team's Notes</label>
            </div>
        </div>

        <div class="counter">
            <span [class.error]="countMax < 0">Characters remaining: {{countMax}}</span>
        </div>
    </form>
</div>
<div mat-dialog-content>
    <div class="error">
        <p *ngIf="formError" class="form-error">{{ formError }}</p>
    </div>
</div>
<div mat-dialog-actions>
    <button aria-label="Submit" [ngClass]="{'is-submitting': isSubmitting}" [disabled]="isLimitedImpersonation || !form.valid || this.typingLength > 4000" (click)="submit()">
        <span class="not-submitting">Submit</span>
        <span class="spinner" *ngIf="isSubmitting">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        </span>
    </button>
</div>
