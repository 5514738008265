import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import {
    Candidate,
    CandidateModalCandidateService,
    CandidateModalDirectionService,
    CandidateSearchService,
    CandidatesService,
    DashboardService,
    SearchNotesModeService,
    UpdateCandidateListService,
    User,
    UserService,
    SortActiveService
} from "../../../shared";
import { CandidateDialogComponent } from "../../../shared/components/candidate-dialog/candidate-dialog.component";
import { RequestTalentDialogComponent } from "../../../shared/components/request-talent-dialog/request-talent-dialog.component";
import { Subscribing } from "../../../shared/components/subscribing.component";
import { ApiError } from "../../../shared/models/api-error.model";
import { Territory } from "../../../shared/models/territory.model";
import { AnalyticsService } from "../../../shared/services/analytics.service";
import { AlertDialogComponent } from "../../../shared/components/alert-dialog/alert-dialog.component";
import { NetworkErrorSnackbarService } from "../../../shared/services/network-error-snackbar.service";



@Component({
    selector: "app-candidate-table",
    templateUrl: "./table.component.html",
    styleUrls: ["./table.component.scss"]
})
export class TableComponent extends Subscribing implements OnInit {
    public isLoadingResults: boolean = true;
    public count: number = 0;
    public noteCount: number = 0;
    public candidatesList: Candidate[];
    public candidateNoteList: any = [];
    public forwardClicks: number = 0;

    public candidateModalDirection: string = "";
    public isLimitedImpersonation: boolean = false;
    public isImpersonating: boolean = false;
    public user: User;
    public userData: User;
    public isNoResultsToFilter: boolean = false;
    public sortByField;
    public sortByDirection;
    public showNotesView: boolean = false;
    public loadingError: ApiError;
    public showSortOverlay: boolean = false;
    public perPage: number = 10;

    // Default sorting to be checked if changed in ngOnChanges
    private lastSortBy: string = "CandidateFirstName-ASC";
    private filtersChanged: boolean = false;

    private defaultParams = {
        limit: this.perPage,
        offset: 0,
        areaId: [],
        jobClassCategoryId: [],
        searchName: "",
        searchNote: "",
        sort: "",
        sortDirection: ""
    };
    private params = JSON.parse(JSON.stringify(this.defaultParams));
    private sortByValue;

    @Input()
    selectedFilters = [];
    @Input()
    searchTerm;

    @Output()
    sortByChange = new EventEmitter();

    @Input()
    get sortBy() {
        return this.sortByValue;
    }

    set sortBy(val) {
        this.sortByValue = val;

        [this.sortByField, this.sortByDirection] = val.split("-");

        this.sortByChange.emit(this.sortByValue);
    }

    @Output()
    noResultsToFilter = new EventEmitter();
    @Input()
    territories: Territory[] = [];

    constructor(
        private candidatesService: CandidatesService,
        private dialog: MatDialog,
        private dashboardService: DashboardService,
        private candidateModalDirectionService: CandidateModalDirectionService,
        private candidateModalCandidateService: CandidateModalCandidateService,
        private userService: UserService,
        private searchNotesModeService: SearchNotesModeService,
        private updateCandidateListService: UpdateCandidateListService,
        private candidateSearchService: CandidateSearchService,
        private analyticsService: AnalyticsService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
        private sortActiveSerice: SortActiveService
    ) {
        super();
    }

    ngOnInit() {
        this.populateCandidateList();
        this.checkRoute();

        this.updateCandidateListService.update$.subscribe(bool => {
            if (bool === true) {
                this.populateCandidateList();
            }
        })

        this.sortActiveSerice.update$.subscribe(bool => {
            this.showSortOverlay = bool;
        })

        this.searchNotesModeService.update$.subscribe(bool => {
            this.showNotesView = bool;

            if (bool === false) {
                this.noteCount = 0;
                this.candidateNoteList = [];
            }
        });

        this.candidateSearchService.update$.subscribe(bool => {
            if (bool === true && this.showNotesView) {
                this.noteCount = 0;
                this.candidateNoteList = [];
                this.populateCandidateList();
            }
        });

        this.subscriptions.push(
            this.userService.isLimitedImpersonation.subscribe(isLimited => {
                this.isLimitedImpersonation = isLimited;
            })
        );

        this.subscriptions.push(
            this.candidateModalDirectionService.update$.subscribe(direction => {
                this.candidateModalDirection = direction;
            })
        );

        this.subscriptions.push(
            this.candidateModalCandidateService.update$.subscribe(candidate => {
                if (this.candidatesList && candidate) {
                    let direction = this.candidateModalDirection;
                    let indexOfCurrentCandidate = 0;
                    let nextCandidate = {};
                    let candidateId = candidate.candidateId;

                    if (this.showNotesView) {
                        for (let i = 0; i < this.candidateNoteList.length; i++) {
                            if (this.candidateNoteList[i].candidateId == candidateId) {
                                indexOfCurrentCandidate = i;
                            }
                        }
                    } else {
                        for (let i = 0; i < this.candidatesList.length; i++) {
                            if (this.candidatesList[i].candidateId == candidateId) {
                                indexOfCurrentCandidate = i;
                            }
                        }
                    }

                    if (direction == "right") {
                        if (this.showNotesView) {
                            if (
                                this.candidateNoteList[indexOfCurrentCandidate + 1] ==
                                undefined
                            ) {
                                // go to first candidate on list
                                nextCandidate = this.candidateNoteList[0];
                            } else {
                                nextCandidate = this.candidateNoteList[
                                    indexOfCurrentCandidate + 1
                                ];
                            }
                        } else {
                            if (
                                this.candidatesList[indexOfCurrentCandidate + 1] ==
                                undefined
                            ) {
                                // go to first candidate on list
                                nextCandidate = this.candidatesList[0];
                            } else {
                                nextCandidate = this.candidatesList[
                                    indexOfCurrentCandidate + 1
                                ];
                            }
                        }
                        // assumes left button is clicked
                    } else {
                        if (this.showNotesView) {
                            if (
                                this.candidateNoteList[indexOfCurrentCandidate - 1] ==
                                undefined
                            ) {
                                // go to last candidate on list
                                nextCandidate = this.candidateNoteList[
                                    this.candidateNoteList.length - 1
                                ];
                            } else {
                                nextCandidate = this.candidateNoteList[
                                    indexOfCurrentCandidate - 1
                                ];
                            }
                        } else {
                            if (
                                this.candidatesList[indexOfCurrentCandidate - 1] ==
                                undefined
                            ) {
                                // go to last candidate on list
                                nextCandidate = this.candidatesList[
                                    this.candidatesList.length - 1
                                ];
                            } else {
                                nextCandidate = this.candidatesList[
                                    indexOfCurrentCandidate - 1
                                ];
                            }
                        }
                    }
                    this.openCandidateDetailsDialog(nextCandidate);
                }
            })
        );

        this.userService.currentUser.subscribe(userData => {
            this.user = userData;
        });
        // TODO handle timeout
        this.userService.getUserCompanies().subscribe();
        this.getUser();
    }

    getUser() {
        this.userService.getUser().subscribe(data => {
            this.userData = data;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        // Reset the forward click counter
        this.forwardClicks = 0;
        this.params = JSON.parse(JSON.stringify(this.defaultParams));

        if (this.selectedFilters.length > 0) {
            const booleanFilters = [
                "favorited",
                "interviewed",
                "active",
                "inTheRunning",
                "placedBefore",
                "blocked",
                "notSelected"
            ];

            for (let i = 0; i < this.selectedFilters.length; i++) {
                // Default was false, will change to true once filters are applied for the first time
                this.filtersChanged = true;
                const currFilter = this.selectedFilters[i];

                if (booleanFilters.includes(this.selectedFilters[i])) {
                    // static filters
                    this.params[currFilter] = true;
                } else {
                    // dynamic filters for territory and jobCategories
                    const filterNameSplit = currFilter.split("-");

                    if (filterNameSplit[0] === "territoryId") {
                        // filter by territory
                        this.params.areaId.push(filterNameSplit[1]);
                    } else if (filterNameSplit[0] === "jobCategoryId") {
                        // filter by jobClassCategory
                        this.params.jobClassCategoryId.push(filterNameSplit[1]);
                    }
                }
            }
        }

        if (this.sortBy) {
            const sortBySplit = this.sortBy.split("-");
            this.params.sort = sortBySplit[0];
            this.params.sortDirection = sortBySplit[1];
        }

        if (this.searchTerm) {
            if (this.showNotesView) {
                this.params.searchNote = this.searchTerm;
                this.analyticsService.fireEvent(
                    "Candidates",
                    "Search",
                    "Search Notes"
                );
            } else {
                this.params.searchName = this.searchTerm;
                this.analyticsService.fireEvent(
                    "Candidates",
                    "Search",
                    "Search By Name"
                );
            }
        }

        if (this.showNotesView) {
            this.populateNotesList();
        } else if (
            (changes.searchTerm && !changes.searchTerm.isFirstChange()) ||
            this.filtersChanged ||
            this.sortBy != this.lastSortBy
        ) {
            // Those checks in the else-if above make sure that fetching candidates
            // only happens once per change and when the page loads
            this.populateCandidateList();
        }

        this.lastSortBy = this.sortBy;
    }

    searchNote(stringToSearch, phrase) {
        let regExp = eval(
            "/(\\S+\\s){0,5}\\S*" + phrase + "\\S*(\\s\\S+){0,5}/g"
        );
        return stringToSearch.match(regExp)[0];
    }

    createPreview(note, term) {
        let currentTerm = note.match(term)[0];
        let newNote = this.searchNote(note, currentTerm);
        let finalNote = newNote.replace(
            term,
            "<span style='font-family:Montserrat, sans-serif;font-weight: bold;'>" +
                currentTerm +
                "</span>"
        );

        if (newNote.indexOf(currentTerm) >= 15 && note.length >= 50) {
            finalNote = "... " + finalNote + " ...";
        } else if (newNote.indexOf(currentTerm) >= 15) {
            finalNote = "... " + finalNote;
        } else if (newNote.indexOf(currentTerm) == 0) {
            finalNote = finalNote + " ...";
        }

        return this.sanitizer.bypassSecurityTrustHtml(finalNote);
    }

    boldSearchTerm(note, term) {
        let newNote = note.replace(
            term,
            "<span style='font-family:Montserrat, sans-serif;font-weight: bold;'>" +
                note.match(term)[0] +
                "</span>"
        );
        let searchedNote = this.sanitizer.bypassSecurityTrustHtml(newNote);
        return searchedNote;
    }

    parseText(note) {
        if (this.searchTerm && note) {
            let term = new RegExp(this.searchTerm, "gi");
            if (note.match(term) !== null) {
                if (note.length <= 100) {
                    return this.boldSearchTerm(note, term);
                } else {
                    return this.createPreview(note, term);
                }
            }
        }
        return note;
    }

    openCandidateDetailsDialog(candidate) {
        this.analyticsService.fireEvent("Candidates", "Candidate Click", "");
        let dialog = this.dialog.open(CandidateDialogComponent, {
            width: "800px",
            panelClass: "candidate_details_page",
            autoFocus: false,
            data: {
                jobCandidate: candidate
            }
        });
    }

    onRowPerPageChange(event) {
        this.perPage = +event.target.value;
        this.params["limit"] = this.perPage;
        this.params["offset"] = 0;
        this.forwardClicks = 0;

        if (this.showNotesView) {
            this.populateNotesList();
        } else {
            this.populateCandidateList();
        }
    }

    updatePrefs(e, officeId, candidateId, alreadyFavorited) {
        let favoritedAction = alreadyFavorited ? false : true;
        let params = { favorited: favoritedAction };
        this.subscriptions.push(
            this.dashboardService
                .updateCandidatePreference(officeId, candidateId, params)
                .subscribe({
                    next: () => {
                        if (this.showNotesView) {
                            this.candidateNoteList.filter(candidate => {
                                if (candidate.candidateId == candidateId) {
                                    candidate.favorited = favoritedAction;

                                    if (favoritedAction) {
                                        this.analyticsService.fireEvent(
                                            "Candidates",
                                            "Favorited",
                                            ""
                                        );
                                    } else {
                                        this.analyticsService.fireEvent(
                                            "Candidates",
                                            "Unfavorited",
                                            ""
                                        );
                                    }
                                }
                            });
                        } else {
                            this.candidatesList.filter(candidate => {
                                if (candidate.candidateId == candidateId) {
                                    candidate.favorited = favoritedAction;

                                    if (favoritedAction) {
                                        this.analyticsService.fireEvent(
                                            "Candidates",
                                            "Favorited",
                                            ""
                                        );
                                    } else {
                                        this.analyticsService.fireEvent(
                                            "Candidates",
                                            "Unfavorited",
                                            ""
                                        );
                                    }
                                }
                            });
                        }
                    },
                    error: (err: ApiError) => {
                        const favActionText = !favoritedAction
                            ? "unfavoriting"
                            : "favoriting";

                        this.networkErrorSnackbarService
                            .networkError(
                                err,
                                favActionText + " this candidate."
                            )
                            .subscribe(() => {
                                this.updateFavorited(
                                    e,
                                    officeId,
                                    candidateId,
                                    alreadyFavorited
                                );
                            });
                    }
                })
        );
    }

    updateFavorited(e, officeId, candidateId, alreadyFavorited) {
        e.stopPropagation();

        this.getUser();
        this.userService.getUser().subscribe(() => {
            if (!this.userData.hideFavMessageFlag && !alreadyFavorited) {
                let dialog = this.dialog.open(AlertDialogComponent, {
                    panelClass: 'confirm_status_change_alert',
                    data: {
                        buttonText: "Favorite",
                        showCheckbox: true,
                        favoritedMessage: true
                    }
                });

                dialog.afterClosed().subscribe(result => {
                    if (result) {
                        if (result.isChecked) {
                            const params = {
                                hideFavMessageFlag: true
                            }

                            this.userService.patchUserPreferences(params)
                                .subscribe(() =>
                                    this.getUser()
                                );

                            this.user.hideFavMessageFlag = true;
                        }
                        this.updatePrefs(e, officeId, candidateId, alreadyFavorited);
                    }
                });
            } else {
                this.updatePrefs(e, officeId, candidateId, alreadyFavorited);
            }
        });
    }

    moreCandidatesLeft() {
        if (this.forwardClicks > 0 || this.params["offset"] !== 0) {
            return true;
        } else {
            return false;
        }
    }

    moreCandidatesRight() {
        let count = 0;

        if (this.showNotesView) {
            count = this.noteCount;
        } else {
            count = this.count;
        }

        if (this.forwardClicks * this.perPage + this.perPage > count) {
            return false;
        } else {
            return true;
        }
    }

    dictatePaginationView() {
        let count = 0;
        if (this.showNotesView) {
            count = this.noteCount;
        } else {
            count = this.count;
        }

        if (this.forwardClicks == 0) {
            return (
                (count < this.perPage ? count : this.perPage).toString() +
                " of " +
                count.toString()
            );
        } else {
            let start = this.forwardClicks * this.perPage;
            let end = start + this.perPage;
            return (
                (start + 1).toString() +
                " - " +
                (count < end ? count : end).toString() +
                " of " +
                count.toString()
            );
        }
    }

    paginateNext() {
        this.params["offset"] += this.perPage;
        this.forwardClicks += 1;

        if (this.showNotesView) {
            this.populateNotesList();
        } else {
            this.populateCandidateList();
        }
    }

    paginatePrevious() {
        this.params["offset"] -= this.perPage;
        this.forwardClicks -= 1;

        if (this.showNotesView) {
            this.populateNotesList();
        } else {
            this.populateCandidateList();
        }
    }

    changeSort(field) {
        let direction = "ASC";

        // if the field isn't changing, toggle asc/desc
        if (field === this.sortByField) {
            direction = this.sortByDirection === "ASC" ? "DESC" : "ASC";
        }

        this.sortBy = field + "-" + direction;

        this.analyticsService.fireEvent(
            "Candidates",
            "Sorted",
            field + "-" + direction
        );
    }

    private getRelevantTerritory(territories) {
        let selectedTerritories =
            this.selectedFilters &&
            this.selectedFilters.length &&
            this.selectedFilters.filter(
                f => f.search && f.search("territory") == 0
            );

        if (!selectedTerritories || !selectedTerritories.length) {
            return territories.length ? territories[0] : "";
        }

        let t = this.territories.find(
            t => t.territoryId == selectedTerritories[0].substring(12)
        );

        if (t) return t.territoryName;
        return territories[0];
    }

    private populateNotesList() {
        if (this.showNotesView && this.params.searchNote) {
            this.subscriptions.push(
                // TODO handle timeout
                this.candidatesService
                    .getCandidatesList(this.params)
                    .subscribe({
                        next: data => {
                            if (data['searchNote'] === null) {
                                this.noteCount = 0;
                                this.candidateNoteList = [];
                            } else {
                                this.noteCount = data.count;
                                this.candidateNoteList = data.results;
                            }
                        },
                        error: (err: ApiError) => {
                            this.networkErrorSnackbarService.networkError(err,'')
                        }
                    })
            );
        }
    }

    populateCandidateList() {
        this.subscriptions.push(
            // TODO handle timeout
            this.candidatesService
                .getCandidatesList(this.params)
                .subscribe({
                    next: data => {
                        this.candidatesList = data.results.map(c =>
                            Object.assign(c, {
                                relevantTrtyName: this.getRelevantTerritory(
                                    c.trtyName
                                )
                            })
                        );
                        this.count = data.count;
                        this.isLoadingResults = false;
                        this.isNoResultsToFilter =
                            !data.count && !this.selectedFilters.length;
                        this.noResultsToFilter.emit(this.isNoResultsToFilter);
                    },
                    error: (err: ApiError) => {
                        this.networkErrorSnackbarService.networkError(err,'')
                    }
                })
        );
    }

    checkRoute() {
        this.route.params.subscribe(params => {
            if (params.candidateId) {
                const newParams = JSON.parse(
                    JSON.stringify(this.defaultParams)
                );
                const candidateId = parseInt(params.candidateId);
                newParams["limit"] = 1000;
                // TODO handle timeout
                this.candidatesService
                    .getCandidatesList(newParams)
                    .subscribe(data => {
                        const candidate = data.results.filter(
                            candidate => candidate.candidateId === candidateId
                        )[0];
                        let dialog = this.dialog.open(
                            CandidateDialogComponent,
                            {
                                width: "800px",
                                panelClass: "candidate_details_page",
                                autoFocus: false,
                                data: {
                                    jobCandidate: candidate,
                                    hideArrow: true
                                }
                            }
                        );
                    });
            }
        });
    }

    openRequestTalentDialog() {
        this.dialog.open(RequestTalentDialogComponent, {
            width: "600px",
            maxWidth: "90%",
            maxHeight: "90%",
            autoFocus: false,
            data: { user: this.user, analyticsLabel: 'Candidates' }
        });

        this.analyticsService.fireEvent(
            'Talent Request',
            'Request Talent Click',
            'Candidates'
        );
    }
}
