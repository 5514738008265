import { Component, OnInit } from "@angular/core";
import {
    BehaviorSubject as BSubject,
    Observable as Obs,
    Subscription
} from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { HamburgerService, SortActiveService } from "../../shared";
import { Subscribing } from "../../shared/components/subscribing.component";
import filterToString from "../../shared/helpers/filter-to-string";
import { Candidate as Cand } from "../../shared/models/candidate.model";
import { Territory } from "../../shared/models/territory.model";
import { AnalyticsService } from "../../shared/services/analytics.service";
import { CandidatesService } from "../../shared/services/candidates.service";
import { LbTitle } from "../../shared/services/title.service";

@Component({
    selector: "app-candidates-component",
    templateUrl: "./candidates.component.html",
    styleUrls: ["./candidates.component.scss"]
})
export class CandidatesComponent extends Subscribing implements OnInit {
    resultsSubject: BSubject<Array<Cand>> = new BSubject<Array<Cand>>([]);
    results: Obs<Array<Cand>> = this.resultsSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    isLoading: boolean = true;
    selectedFilters: any[] = [];
    searchTerm: any;
    sortBy: string = "CandidateFirstName-ASC";
    showOverlay: boolean = false;
    subscription: Subscription;
    noResults: boolean = false;
    territories: Territory[] = [];
    public showSortOverlay: boolean = false;

    constructor(
        private analyticsService: AnalyticsService,
        private candidatesService: CandidatesService,
        private hamburgerService: HamburgerService,
        private sortActiveSerice: SortActiveService,
        titleService: LbTitle
    ) {
        super();

        titleService.setTitleFromKeywords("Candidates", "Candidates");
    }

    filterChange(filters) {
        this.analyticsService.fireEvent(
            "Candidates",
            "Filtered",
            filterToString(filters)
        );
        this.selectedFilters = filters;
    }

    searchChange(string) {
        this.searchTerm = string;
    }

    mobileSortFilterChange(value) {
        this.analyticsService.fireEvent("Candidates", "Sorted", value);
        this.sortBy = value;
    }

    noResultsToFilter(bool) {
        this.noResults = bool;
    }

    ngOnInit() {
        this.subscription = this.hamburgerService.update$.subscribe(bool => {
            this.showOverlay = bool;
        });

        this.sortActiveSerice.update$.subscribe(bool => {
            this.showSortOverlay = bool;
        })

        this.candidatesService.getCandidatesTerritory()
        // TODO handle timeout
        .subscribe(territories => {
            this.territories = territories.sort(
                (a: Territory, b: Territory) =>
                    a.territoryName.localeCompare(b.territoryName)
            );
        });
    }
}
