import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { JobDetails, JobService, UserService, EditModeService } from '../../../../shared';
import { BRCDialogComponent } from '../../../../shared/components/brc-dialog/brc-dialog.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-job-order-details-job-overview',
    templateUrl: './job-order-details-job-overview.component.html',
    styleUrls: ['./job-order-details-job-overview.component.scss']
})
export class JobOrderDetailsJobOverviewComponent implements OnInit {

    @Input() jobDetails: JobDetails;
    public isLimitedImpersonation: boolean = false;

    _editMode = false;
    _initialJobTitle = '';
    subscription: Subscription;

    constructor(
        private jobOrdersService: JobService,
        private dialog: MatDialog,
        private sanitizationService: DomSanitizer,
        private userService: UserService,
        private editModeService: EditModeService,
        private analyticsService: AnalyticsService
    ) {
    }

    ngOnInit() {
        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });
        this._initialJobTitle = this.jobDetails.jobTitle;
    }

    ngOnChanges() {
        this._editMode = false;
        this._initialJobTitle = this.jobDetails.jobTitle;
    }

    enableEditMode() {
        this._editMode = true;
    }

    cancelEditMode() {
        this.jobDetails.jobTitle = this._initialJobTitle;
        this._editMode = false;
    }

    saveJobTitle() {
        this.jobOrdersService.patchJobTitle(
            this.jobDetails.officeId,
            this.jobDetails.jobId,
            this.jobDetails.jobTitle
        )
                        // TODO handle timeout
                        .subscribe((r) => {
                this._editMode = false;
                this.editModeService.updateBoolean(true);

                // Updates the new job title across the board (jobs array and jobDetails)
                this.jobOrdersService.jobDetailsSubject.next(this.jobDetails);

                // Deep clones the jobs array
                const updatedJobList = JSON.parse(JSON.stringify(this.jobOrdersService.jobsSubject.value));

                // Injects the new title in the cloned jobs array
                updatedJobList.map(jobObject => {
                    if (jobObject.jobId === this.jobDetails.jobId) {
                        jobObject.jobTitle = this.jobDetails.jobTitle;
                    }

                    return jobObject;
                });

                // Pushes the updated jobs array through the stream
                this.jobOrdersService.jobsSubject.next(updatedJobList);

                // Updates the displayed job title
                this._initialJobTitle = this.jobDetails.jobTitle;
            });
    }

    openBRCDialog() {
        let result = false;
        if (this.jobDetails.brcStatus === 'Open') {
            result = true;
        }

        this.dialog.open(BRCDialogComponent, {
            width: '90vw',
            height: '90vh',
            maxWidth: '90vw',
            maxHeight: '90vh',
            data: {
                showAlert: result,
                brcLink: this.sanitizationService.bypassSecurityTrustResourceUrl(this.jobDetails.brcLink)
            }
        });
        this.analyticsService.fireEvent('Jobs', 'View Bill Rate Click', this.jobDetails.status, {'dimension5': this.jobDetails.jobId.toString() + "." + this.jobDetails.officeId.toString(), 'dimension3' : this.jobDetails.clientId.toString() + "." + this.jobDetails.officeId.toString()});
    }

}
