import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CandidateModalDirectionService {

    private arrowClicked: BehaviorSubject<string> = new BehaviorSubject<string>('');

    update$: Observable<string> = this.arrowClicked.asObservable();

    updateDirection(clicked: string) {
        this.arrowClicked.next(clicked);
    }
}
