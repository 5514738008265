import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class MaintenanceService {

    private maintenancePageUp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    update$: Observable<boolean> = this.maintenancePageUp.asObservable();

    updateBoolean(pageUp: boolean) {
        this.maintenancePageUp.next(pageUp);
    }
}