<div class="large-container">
    <div class="search">
        <div class="title-container">
            <h2>Search</h2>
            <button aria-label="New Search" mat-button color="primary" (click)="clearSearch()">[ New Search ]</button>
        </div>
        <form *ngIf="!loadingError">
            <mat-form-field>
                <input #firstNameElm matInput type="search" placeholder="First Name" [formControl]="firstNameControl" autocomplete="off" autofocus />
            </mat-form-field>
            <mat-form-field>
                <input matInput type="search" placeholder="Last Name" [formControl]="lastNameControl" autocomplete="off" />
            </mat-form-field>
            <mat-form-field>
                <input matInput type="search" placeholder="Email Address" [formControl]="emailControl" autocomplete="off" />
            </mat-form-field>
        </form>
        <div class="clearfix"></div>

        <div class="loading-error" *ngIf="loadingError">
            <p>{{ loadingError }}</p>
        </div>
        <div class="clearfix"></div>
    </div>

    <div class="loading-shade" *ngIf="isLoadingResults">
        <svg class="loading-gif" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#131e27" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
            <line x1="12" y1="2" x2="12" y2="6"></line>
            <line x1="12" y1="18" x2="12" y2="22"></line>
            <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
            <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
            <line x1="2" y1="12" x2="6" y2="12"></line>
            <line x1="18" y1="12" x2="22" y2="12"></line>
            <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
            <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
        </svg>
    </div>

    <mat-table #table [dataSource]="dataSource" matSort matSortDisableClear [ngClass]="{ hidden: isLoadingResults || loadingError }">
        <ng-container matColumnDef="Username">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Username</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.username }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.email }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="FirstName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>First</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.firstName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="LastName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Last</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.lastName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <span *ngIf="!row.active && (!row.username || !row.username.trim()) && isAdmin()"></span>

                <button aria-label="Deactivate" *ngIf="row.active && isAdmin()" mat-button (click)="deactivate(row)" title="Deactivate a User" class="mat-button">
                    <mat-icon svgIcon="deactivate" aria-label="Deactivate"></mat-icon>
                    Deactivate
                </button>

                <button aria-label="Activate" *ngIf="!row.active && row.username && row.username.trim() && isAdmin()" mat-button (click)="activate(row)" title="Activate a User" class="mat-button">
                    <mat-icon svgIcon="activate" aria-label="Activate"></mat-icon>
                    Activate
                </button>

                <button aria-label="Impersonate" mat-button (click)="impersonate(row, $event.currentTarget)" title="Impersonate a User" class="mat-button impersonate-button">
                    <mat-icon svgIcon="impersonate" aria-label="Impersonate"></mat-icon>
                    <span class="is-not-loading">
                        Impersonate
                    </span>
                    <span class="is-loading">
                        Loading...
                    </span>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator [length]="resultsLength" [pageSize]="limit" [ngClass]="{ hidden: isLoadingResults || loadingError }"> </mat-paginator>
</div>
