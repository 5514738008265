import { Component, OnInit } from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    FormControl,
    Validators
} from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {HamburgerService, User, UserService, ROLE_CLIENT, ROLE_DEMO} from '../../shared';
import { UsernameChangeDialogComponent } from "../../shared/components/username-change-dialog/username-change-dialog.component";
import { ApiError } from "../../shared/models/api-error.model";
import { LbTitle } from "../../shared/services/title.service";
import { ConfirmDialogComponent } from "../../shared/components/confirm-dialog/confirm-dialog.component";
import { Subscribing } from "../../shared/components/subscribing.component";
import { NetworkErrorSnackbarService } from "../../shared/services/network-error-snackbar.service";
import { environment } from "../../../environments/environment";
import {map, take} from 'rxjs/operators';
import {Message, MESSAGE_TYPE_ERROR} from '../../shared/models/message.model';


@Component({
    selector: "app-settings-component",
    templateUrl: "./settings.component.html",
    styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent extends Subscribing implements OnInit {
    mask: any[] = [
        /[1-9]/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ];

    public form: UntypedFormGroup;
    public passwordForm: UntypedFormGroup;
    public activePanel = "account";
    public editForm = false;
    public editPassword = false;
    public eightChar = false;
    public oneUpperCase = false;
    public oneLowerCase = false;
    public numeral = false;
    public oneSpecialChar = false;
    public currentUser: User;
    public successUpdate = false;
    public successPasswordUpdate = false;
    public userConfirmedUsernameChange = false;
    public passwordMatch: boolean = false;
    public validCurrentPassword: boolean = true;
    public isLimitedImpersonation: boolean;
    public isImpersonation: boolean;
    public showExtension: boolean = false;
    public showOverlay: boolean = false;
    public formChanged: boolean = false;
    public phonePrimary: string = 'us';
    public phoneMobile: string = 'us';
    public versionNumber: string = '';
    public clientRole = ROLE_CLIENT;
    public currentUserRole = null;

    public get username(): AbstractControl {
        return this.form.get("username");
    }

    public get firstName(): AbstractControl {
        return this.form.get("firstName");
    }

    public get lastName(): AbstractControl {
        return this.form.get("lastName");
    }

    public get phone(): AbstractControl {
        return this.form.get("phone");
    }

    public get phoneInternational(): AbstractControl {
        return this.form.get("phoneInternational");
    }

    public get phoneInternationalMobile(): AbstractControl {
        return this.form.get("phoneInternationalMobile");
    }

    public get phoneExt(): AbstractControl {
        return this.form.get("phoneExt");
    }

    public get mobile(): AbstractControl {
        return this.form.get("mobile");
    }

    public get password(): AbstractControl {
        return this.passwordForm.get("password");
    }

    public get currentPassword(): AbstractControl {
        return this.passwordForm.get("currentPassword");
    }

    constructor(
        private router: Router,
        private userService: UserService,
        private fb: UntypedFormBuilder,
        private dialog: MatDialog,
        private titleService: LbTitle,
        private hamburgerService: HamburgerService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
    ) {
        super();

        this.titleService.setTitleFromKeywords("Settings", "Settings");

        this.form = this.fb.group({
            username: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(20)
                ])
            ],
            firstName: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(1),
                    Validators.pattern(/\D/)
                ])
            ],
            lastName: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(1)
                ])
            ],
            phone: [
                "",
                Validators.compose([
                    Validators.pattern(/^$|^([0-9]+-)*[0-9]+$/)
                ])
            ],
            phoneInternational: [
                "",
                Validators.compose([
                    Validators.pattern(/[+]?[0-9]+/),
                ])
            ],
            phoneExt: [
                "",
                Validators.compose([
                    Validators.minLength(1),
                    Validators.maxLength(10),
                    Validators.pattern(/^[0-9]+$/)
                ])
            ],

            phoneExtUS: [
                "",
                Validators.compose([
                    Validators.minLength(1),
                    Validators.maxLength(10),
                    Validators.pattern(/^[0-9]+$/)
                ])
            ],
            phoneExtInternational: [
                "",
                Validators.compose([
                    Validators.minLength(1),
                    Validators.maxLength(10),
                    Validators.pattern(/^[0-9]+$/)
                ])
            ],
            mobile: [
                "",
                Validators.compose([
                    Validators.pattern(/^$|^([0-9]+-)*[0-9]+$/)
                ])
            ],
            phoneInternationalMobile: [
                "",
                Validators.compose([
                    Validators.pattern(/[+]?[0-9]+/)
                ])
            ],
        });

        this.form.valueChanges.subscribe(_ => {
            this.formChanged = true;
        });

        this.form.setErrors({});

        this.passwordForm = this.fb.group({
            currentPassword: [null, Validators.compose([Validators.required])],
            password: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
                    )
                ])
            ],
            passwordConfirmation: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
                    )
                ])
            ]
        });

        this.subscriptions.push();
    }

    _keydown(event: any, mobile=false) {
        const validChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', '(', '-', ')']
        let inputChar = String.fromCharCode(event.key);
        let currentValue = '';

        if (mobile) {
          currentValue = this.phoneInternationalMobile['value'];
        } else {
          currentValue = this.phoneInternational['value'];
        }

        if (currentValue === '+' && event.key === 'Backspace') {
          event.preventDefault();
        }

        if (!validChars.includes(event.key) && event.keyCode != 37 && event.keyCode != 39) {
          event.preventDefault();
        }
    }

    ngOnInit() {
        this.refreshUser();

        this.versionNumber = environment.version_number

        this.subscriptions.push(
            this.userService.currentUser.subscribe(userData => {
                this.currentUser = userData;
            }),

            this.userService.isImpersonation.subscribe(isImpersonation => {
                this.isImpersonation = isImpersonation;
            }),

            this.userService.isLimitedImpersonation.subscribe(isLimited => {
                this.isLimitedImpersonation = isLimited;
            }),

            this.hamburgerService.update$.subscribe(bool => {
                this.showOverlay = bool;
            }),

            this.passwordForm.valueChanges.subscribe(value => {
                this.passwordMatch =
                    value["password"] &&
                    value["passwordConfirmation"] &&
                    value["password"] === value["passwordConfirmation"];
            }),

            this.password.valueChanges.subscribe(value => {
                // minimum 8 characters
                if (value.length >= 8) {
                    this.eightChar = true;
                } else {
                    this.eightChar = false;
                }

                // 1 uppercase letter
                if (value.match(/[A-Z]/)) {
                    this.oneUpperCase = true;
                } else {
                    this.oneUpperCase = false;
                }

                // 1 lowercase letter
                if (value.match(/[a-z]/)) {
                    this.oneLowerCase = true;
                } else {
                    this.oneLowerCase = false;
                }

                // 1 numeral
                if (value.match(/\d/)) {
                    this.numeral = true;
                } else {
                    this.numeral = false;
                }

                // optional special character
                if (value.match(/[^a-zA-Z\d\s]/)) {
                    this.oneSpecialChar = true;
                } else {
                    this.oneSpecialChar = false;
                }
            })
        );
    }

    editFormChange() {
        // Setting the office phone's validation to 'required' if the value is not already empty
        if (this.currentUser.phoneOffice !== null && this.phonePrimary === 'us') {
            this.form.controls["phone"].setValidators([
                Validators.required,
                Validators.pattern(/^$|^([0-9]+-)*[0-9]+$/)
            ]);
        }

        if (this.editForm && this.formChanged) {
            const confirm = this.dialog.open(ConfirmDialogComponent, {
                width: "400px",
                data: {
                    title: "Are you sure?",
                    message: "If you cancel, your changes will be lost.",
                    cancelText: "Nevermind"
                }
            });

            return confirm.afterClosed().subscribe(confirmed => {
                if (confirmed) {
                    this.form.markAsPristine();
                    this.form.controls["username"].setValue(
                        this.currentUser.username
                    );
                    this.form.controls["firstName"].setValue(
                        this.currentUser.firstName
                    );
                    this.form.controls["lastName"].setValue(
                        this.currentUser.lastName
                    );
                    this.form.controls["phone"].setValue(
                        this.currentUser.phoneOffice
                    );
                    this.form.controls["mobile"].setValue(
                        this.currentUser.phoneCell
                    );
                    this.editForm = false;
                    this.formChanged = false;
                }
            });
        }
        this.editForm = !this.editForm;
    }

    passwordChange() {
        this.passwordForm.controls["currentPassword"].setValue("");
        this.passwordForm.controls["password"].setValue("");
        this.passwordForm.controls["passwordConfirmation"].setValue("");
        this.passwordForm.markAsUntouched();
        this.editPassword = !this.editPassword;
    }

    phoneSelect(event, type) {

        if (type === 'international') {
            this.form.get('phone').clearValidators();
            this.form.get('phone').updateValueAndValidity();
            this.form.controls["phone"].setValidators([
                Validators.pattern(/^$|^([0-9]+-)*[0-9]+$/)
            ]);

            this.form.controls["phoneInternational"].setValidators([
                 Validators.required,
                 Validators.minLength(6),
            ]);
        }

        if (type === 'us') {
            this.form.get('phoneInternational').clearValidators();
            this.form.get('phoneInternational').updateValueAndValidity();
            this.form.controls["phoneInternational"].setValidators([
                 Validators.minLength(2),
            ]);
            this.form.controls["phone"].setValidators([
                Validators.required,
                Validators.pattern(/^$|^([0-9]+-)*[0-9]+$/)
            ]);
        }

        this.phonePrimary = type;
    }

    phoneSelectMobile(event, type) {
        this.phoneMobile = type;
    }

    formatPhoneNumber(number) {
        if (
            number === "NULL" ||
            number === "" ||
            number === undefined ||
            number == null
        ) {
            return "Not listed";
        }

        const numberPattern = /\d+/g;
        const sanitizedNumber = number
            ? number.match(numberPattern).join("")
            : "";
        const areaCode = sanitizedNumber.substring(0, 3);
        const firstSet = sanitizedNumber.substring(3, 6);
        const finalFour = sanitizedNumber.substring(6, 10);

        return areaCode + "-" + firstSet + "-" + finalFour;
    }

    refreshUser() {
        this.subscriptions.push(
            // TODO handle timeout
            this.userService.getUser().subscribe(userData => {
                this.currentUser = userData;
                let phoneOffice = "";
                let currentExtension = "";
                let phoneCell = "";

                if (userData.phoneOffice && userData.phoneOffice !== "NULL") {
                    phoneOffice = userData.phoneOffice;
                }

                if (userData.phoneExt && userData.phoneExt !== "NULL") {
                    currentExtension = userData.phoneExt;
                    this.showExtension = true;
                } else {
                    this.showExtension =  false;
                }

                if (userData.phoneCell && userData.phoneCell !== "NULL") {
                    phoneCell = userData.phoneCell;
                }

                this.form.controls["username"].setValue(
                    this.currentUser.username
                );
                this.form.controls["firstName"].setValue(
                    this.currentUser.firstName
                );
                this.form.controls["lastName"].setValue(
                    this.currentUser.lastName
                );
                this.form.controls["mobile"].setValue(phoneCell);
                this.form.controls["phoneExt"].setValue(currentExtension);

                if (phoneOffice.includes("+")) {
                    this.phonePrimary = 'international';
                    this.form.controls["phoneInternational"].setValue(phoneOffice);

                    this.form.controls["phone"].setValue('');

                    if (this.currentUser.phoneExt) {
                        this.form.get('phoneExtInternational').setValue(this.currentUser.phoneExt)
                    } else {
                        this.form.get('phoneExtInternational').setValue('');
                    }
                } else {
                    this.phonePrimary = 'us';
                    this.form.controls["phone"].setValue(phoneOffice);
                    this.form.controls["phoneInternational"].setValue('+0');

                    if (this.currentUser.phoneExt) {
                        this.form.controls['phoneExtUS'].setValue(this.currentUser.phoneExt)
                        this.form.get('phoneExtInternational').setValue('')
                    }
                }

                if (phoneCell.includes("+")) {
                    this.phoneMobile = 'international';
                    this.form.controls["phoneInternationalMobile"].setValue(phoneCell);
                    this.form.controls["mobile"].setValue('');
                } else {
                    this.phoneMobile = 'us';
                    this.form.controls["mobile"].setValue(phoneCell);
                    this.form.controls["phoneInternationalMobile"].setValue('+0');
                }
                this.formChanged = false;
            }),
            this.userService.currentUserType.subscribe(userType => {
                this.currentUserRole = userType;
            })
        );
    }

    openUsernameChangeDialog() {
        const dialog = this.dialog.open(UsernameChangeDialogComponent, {
            width: "600px",
            maxWidth: "90%",
            maxHeight: "90%",
            data: {
                newUsername: this.form.value["username"]
            }
        });

        dialog.afterClosed().subscribe(confirmedChange => {
            if (confirmedChange === true) {
                this.userConfirmedUsernameChange = true;
                this.submitPreferences();
            }
        });
    }

    submitPreferences() {
        if (!this.form.valid) {
            return false;
        }

        // changing username will log user out so we need to show them a popup confirming username change
        if (
            this.currentUser.username.localeCompare(
                this.form.value["username"]
            ) !== 0 &&
            !this.userConfirmedUsernameChange
        ) {
            this.openUsernameChangeDialog();
            return false;
        }

        let phone = '';
        let mobile = '';

        if (this.phonePrimary === 'us') {
            phone = this.form.value["phone"].replace(/-/g, "");
        } else {
            phone = this.form.value["phoneInternational"].replace(/-/g, "");
        }

        if (this.phoneMobile === 'us') {
            mobile = this.form.value["mobile"].replace(/-/g, "");
        } else {
            mobile = this.form.value["phoneInternationalMobile"].replace(/-/g, "");
            if (mobile === '+0') {
                mobile = '';
            }
        }

        if (
            this.form.value["phoneExt"] != "NULL" &&
            this.form.value["phoneExt"].length != 0
        ) {
            this.showExtension = true;
        } else {
            this.showExtension = false;
        }

        let phoneExtTrue = '';

        if (this.form.value["phoneExtInternational"] && this.phonePrimary === 'international') {
            phoneExtTrue = this.form.value["phoneExtInternational"]
        } else {
            phoneExtTrue = this.form.value["phoneExtUS"];
        }

        const params = {
            username: this.form.value["username"],
            firstName: this.form.value["firstName"],
            lastName: this.form.value["lastName"],
            phone: phone,
            phoneExt: phoneExtTrue,
            mobile: mobile
        };

        // TODO handle timeout
        this.userService.patchUserPreferences(params).subscribe(
            data => {
                this.refreshUser();
                this.editForm = false;
                this.successUpdate = true;
                setInterval(
                    a => {
                        this.successUpdate = false;
                    },
                    5000,
                    []
                );
                if (this.userConfirmedUsernameChange) {
                    this.userService.purgeAuth();
                    this.router.navigateByUrl("/home");
                    this.userConfirmedUsernameChange = true;
                }

                // Stops tracking form changes after the form gets successfully submitted
                this.formChanged = false;
            },
            (err: ApiError) => {
                if (err.statusCode === 400) {
                    Object.keys(err.errors).forEach(key => {
                        const input = this.form.get(key);
                        if (input) {
                            input.setErrors({
                                serverError: err.errors[key][0]
                            });
                        }
                    });
                } else if (err.statusCode === 409) {
                    this.form.get("username").setErrors({
                        serverError: "Username is taken. Please choose another."
                    });
                } else {
                    this.networkErrorSnackbarService.networkError(err,'')
                }
            }
        );
    }

    submitPasswordChange() {
        if (!this.passwordForm.valid || !this.passwordMatch) {
            return false;
        }

        const params = {
            currentPassword: this.passwordForm.value["currentPassword"],
            password: this.passwordForm.value["password"],
            passwordConfirmation: this.passwordForm.value[
                "passwordConfirmation"
            ]
        };

        // TODO handle timeout
        this.userService.patchUserPreferences(params).subscribe(
            data => {
                this.refreshUser();
                this.editPassword = false;
                this.successPasswordUpdate = true;
                this.validCurrentPassword = true;
                this.passwordForm.controls["currentPassword"].setValue("");
                this.passwordForm.controls["password"].setValue("");
                this.passwordForm.controls["passwordConfirmation"].setValue("");

                setInterval(
                    a => {
                        this.successPasswordUpdate = false;
                    },
                    5000,
                    []
                );
            },
            (err: ApiError) => {
                if (err.statusCode === 400) {
                    this.passwordForm.setErrors({ badRequest: true });
                    this.validCurrentPassword = false;
                } else {
                    this.networkErrorSnackbarService.networkError(err,'')
                }
            }
        );
    }
}
