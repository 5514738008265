import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-start-interview-dialog',
    templateUrl: './start-interview-dialog.component.html',
    styleUrls: ['./start-interview-dialog.component.scss']
})
export class StartInterviewDialogComponent implements OnInit {

    public isChecked: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA)
        public data: any,
        private dialogRef: MatDialogRef<StartInterviewDialogComponent>,
    ) {
    }

    cancel() {
        this.dialogRef.close();
    }

    updatePref() {
        this.isChecked = !this.isChecked;
    }

    goToInterviewLink() {
        if (this.data.interviewUrl) {
            window.open(this.data.interviewUrl, "_blank");
        }
    }

    ngOnInit() {
    }

}
