<div class="sidebar-navigation">
    <a routerLink="/dashboard" routerLinkActive #dashboardRoute="routerLinkActive">
        <div class="navigation-icon dashboard" [ngClass]="{'active-icon': dashboardRoute.isActive}">
            <div class="svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="#fafafa"
                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid">
                    <rect x="3" y="3" width="7" height="7"></rect>
                    <rect x="14" y="3" width="7" height="7"></rect>
                    <rect x="14" y="14" width="7" height="7"></rect>
                    <rect x="3" y="14" width="7" height="7"></rect>
                </svg>
            </div>
            <span>DASHBOARD</span>
            <div *ngIf="numActionsItems" class="unread-count-icon">{{ numActionsItems }}</div>
        </div>
    </a>

    <a routerLink="/jobs" routerLinkActive #jobsRoute="routerLinkActive">
        <div class="navigation-icon jobs" [ngClass]="{'active-icon': jobsRoute.isActive}">
            <div class="svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none"
                     stroke="#fafafa"
                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase">
                    <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                    <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                </svg>
            </div>
            <span>JOBS</span>
            <div *ngIf="numberOfNewSubmittals !== null && numberOfNewSubmittals > 0" class="unread-count-icon">
                {{numberOfNewSubmittals}}
            </div>
        </div>
    </a>

    <a routerLink="/candidates" routerLinkActive #candidatesRoute="routerLinkActive">
        <div class="navigation-icon candidates" [ngClass]="{'active-icon': candidatesRoute.isActive}">
            <div class="svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none"
                     stroke="#fafafa"
                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users">
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>
            </div>
            <span>CANDIDATES</span>
        </div>
    </a>

    <div class="no-hover">
        <div class="svg-container">
            <hr>
        </div>
    </div>

    <a routerLink="/settings" routerLinkActive #settingsRoute="routerLinkActive">
        <div class="navigation-icon settings" [ngClass]="{'active-icon': settingsRoute.isActive}">
            <div class="svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="#fafafa"
                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings">
                    <circle cx="12" cy="12" r="3"></circle>
                    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                </svg>
            </div>
            <span>SETTINGS</span>
        </div>
    </a>
</div>