import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { User } from "../../shared/models";
import {
    UserService,
    NavigationScrollService,
    HamburgerService
} from "../../shared";
import { Subscription } from "rxjs";
import { LbTitle } from "../../shared/services/title.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscribing } from "../../shared/components/subscribing.component";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent extends Subscribing implements OnInit {
    @ViewChild("mobile_icons", { static: true })
    mobile_icons: ElementRef;
    @ViewChild("content_body", { static: true })
    content_body: ElementRef;

    activeSection: string = "action-items";

    currentUser: User;
    showOverlay: boolean = false;

    constructor(
        private userService: UserService,
        private navigationScrollService: NavigationScrollService,
        private hamburgerService: HamburgerService,
        private titleService: LbTitle,
        private route: ActivatedRoute,
        private router: Router
    ) {
        super();
        this.titleService.setTitleFromKeywords("Dashboard", "Dashboard");
    }

    ngOnInit() {
        this.fixedNav();

        this.subscriptions.push(
            this.hamburgerService.update$.subscribe(bool => {
                this.showOverlay = bool;
            }),
            this.userService.currentUser.subscribe(userData => {
                this.currentUser = userData;
            }),
            this.route.params.subscribe(params => {
                this.activeSection = params.section;
            })
        );
    }

    fixedNav() {
        let ticking = false;
        const leftColumn = this.content_body.nativeElement.getElementsByClassName(
            "left-column"
        );
        const rightColumn = this.content_body.nativeElement.getElementsByClassName(
            "right-column"
        );
        const leftShadow = this.mobile_icons.nativeElement.getElementsByClassName(
            "left-shadow"
        );
        const rightShadow = this.mobile_icons.nativeElement.getElementsByClassName(
            "right-shadow"
        );
        let leftScrollTop = leftColumn[0].scrollTop;
        let rightScrollTop = rightColumn[0].scrollTop;
        const service = this.navigationScrollService;

        const update = function() {
            if (leftScrollTop === 0 && rightScrollTop === 0) {
                service.updateBoolean(false);
            } else {
                service.updateBoolean(true);
            }
            ticking = false;
        };

        const requestTick = function() {
            if (!ticking) {
                window.requestAnimationFrame(update);
                ticking = true;
            }
        };

        const onScroll = function() {
            leftScrollTop = leftColumn[0].scrollTop;
            rightScrollTop = rightColumn[0].scrollTop;
            requestTick();
        };

        const onHorizontalScroll = () => {
            // Calculates the needed opacity for the left/right shadows, then increases and decreases as needed when the user
            // horizontally scrolls
            const opacity =
                this.mobile_icons.nativeElement.scrollLeft /
                (this.mobile_icons.nativeElement.scrollWidth -
                    this.mobile_icons.nativeElement.offsetWidth);
            leftShadow[0].style.opacity = opacity;
            rightShadow[0].style.opacity = 1 - opacity;
        };

        // Informs the browser to perform an animation for smoother results
        const controlSideShadows = () => {
            window.requestAnimationFrame(onHorizontalScroll);
        };

        leftColumn[0].addEventListener("scroll", onScroll);
        rightColumn[0].addEventListener("scroll", onScroll);

        // Mobile horizontal scrolling indication event
        this.mobile_icons.nativeElement.addEventListener(
            "scroll",
            controlSideShadows
        );
    }

    makeActive(section) {
        this.router.navigate(["dashboard", { section }]);
    }
}
