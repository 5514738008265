import { Component, OnInit, Input, Inject, Output } from "@angular/core";
import {
    CandidateDetail,
    DashboardService,
    CandidateModalCandidateService,
    CandidateModalDirectionService,
    UpdateCandidateListService,
    UserService,
    User
} from "./../../../shared";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { AlertDialogComponent } from "./../../../shared/components/alert-dialog/alert-dialog.component";
import { EmailContactCandidateDialogComponent } from "./../../../shared/components/email-contact-candidate-dialog/email-contact-candidate-dialog.component";
import { ShareCandidateDialogComponent } from "./../../../shared/components/share-candidate-dialog/share-candidate-dialog.component";
import { AnalyticsService } from "../../../shared/services/analytics.service";
import { ApiError } from "../../../shared/models/api-error.model";
import { NetworkErrorSnackbarService } from "../../../shared/services/network-error-snackbar.service";

@Component({
    selector: "app-candidate-details-modal-header",
    templateUrl: "./candidate-details-modal-header.component.html",
    styleUrls: ["./candidate-details-modal-header.component.scss"]
})
export class CandidateDetailsModalHeaderComponent implements OnInit {
    @Input()
    candidate: CandidateDetail;

    @Input()
    hideArrows: boolean = false;

    public isLimitedImpersonation: boolean = false;
    public requestCall: boolean = false;
    public user: User;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<CandidateDetailsModalHeaderComponent>,
        private dashboardService: DashboardService,
        private analyticsService: AnalyticsService,
        private candidateModalDirectionService: CandidateModalDirectionService,
        private candidateModalCandidateService: CandidateModalCandidateService,
        private userService: UserService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
        private updateCandidateListService: UpdateCandidateListService
    ) {}

    patchCandidateStanding(officeId, candidateId, params, favorite) {
        this.dashboardService
            .updateCandidatePreference(officeId, candidateId, params)
            .subscribe(
                data => {
                    this.candidate.blocked = params["blocked"];
                    this.candidate.favorited = params["favorited"];
                    this.updateCandidateListService.updateBoolean(true);
                },
                (err: ApiError) => {
                    return this.networkErrorSnackbarService.networkError(err,'')
                }
            );
    }

    blockCandidate(candidateId, officeId, currentStatus, favorited) {
        let action = currentStatus ? false : true;
        let params = { blocked: action, favorited: favorited };
        if (currentStatus == true) {
            params = { blocked: false, favorited: favorited };
            this.patchCandidateStanding(officeId, candidateId, params, false);
            this.analyticsService.fireEvent("Candidates", "Unblocked", "", {
                dimension7: candidateId
            });
        } else {
            if (!this.user.hideBlockMessageFlag) {
                let dialog = this.dialog.open(AlertDialogComponent, {
                    panelClass: "block_candidate_panel",
                    data: {
                        blockedMessage: true,
                        buttonText: "Block",
                        currentlyFavorited: favorited,
                        candidateName: this.candidate.firstName.toUpperCase() + " " + this.candidate.lastName.toUpperCase(),
                        candidateFirstName: this.candidate.firstName
                    }
                });

                dialog.afterClosed().subscribe(result => {
                    if (result) {
                        this.dialog.open(AlertDialogComponent, {
                            panelClass: "block_candidate_panel",
                            data: {
                                blockedAfterMessage: true,
                                buttonText: "Got it",
                                currentlyFavorited: favorited,
                                candidateName: this.candidate.firstName.toUpperCase() + " " + this.candidate.lastName.toUpperCase()
                            }
                        })

                        this.analyticsService.fireEvent(
                            "Candidates",
                            "Blocked",
                            "",
                            {
                                dimension7: candidateId,
                                dimension5:
                                    this.candidate.jobId +
                                    "." +
                                    this.candidate.officeId
                            }
                        );
                        params = { blocked: true, favorited: false };
                        this.updateCandidateListService.updateBoolean(true);
                        this.patchCandidateStanding(
                            officeId,
                            candidateId,
                            params,
                            false
                        );
                    }
                });
            } else {
                this.analyticsService.fireEvent(
                    "Candidates",
                    "Blocked",
                    "",
                    {
                        dimension7: candidateId,
                        dimension5:
                            this.candidate.jobId +
                            "." +
                            this.candidate.officeId
                    }
                );

                params = { blocked: true, favorited: false };
                this.updateCandidateListService.updateBoolean(true);
                this.patchCandidateStanding(
                    officeId,
                    candidateId,
                    params,
                    false
                );
            }
        }
    }

    updatePrefs(candidateId, officeId, currentStatus) {
        let action = currentStatus ? false : true;
        let params = { favorited: action };

        this.dashboardService
        .updateCandidatePreference(officeId, candidateId, params)
        .subscribe(
            data => {
                this.candidate.favorited = action;
                this.updateCandidateListService.updateBoolean(true);
            },
            (err: ApiError) => {
                this.networkErrorSnackbarService.networkError(
                    err,
                    (action ? "favoriting" : "unfavoriting") +
                        " this candidate."
                );
            }
        );

        if (action) this.analyticsService.fireEvent("Candidates", "Favorited");
        else this.analyticsService.fireEvent("Candidates", "Unfavorited");
    }

    favoritedCandidate(candidateId, officeId, currentStatus) {
        if (!this.user.hideFavMessageFlag && !currentStatus) {
            let dialog = this.dialog.open(AlertDialogComponent, {
                panelClass: 'confirm_status_change_alert',
                data: {
                    buttonText: "Favorite",
                    showCheckbox: true,
                    favoritedMessage: true
                }
            })

            dialog.afterClosed().subscribe(result => {
                if (result) {
                    this.updateCandidateListService.updateBoolean(true);
                    if (result.isChecked) {
                        const params = {
                            hideFavMessageFlag: true
                        }

                        this.userService.patchUserPreferences(params)
                            .subscribe(data =>
                                this.getUser()
                            )

                        this.user.hideFavMessageFlag = true;
                    }
                    this.updatePrefs(candidateId, officeId, currentStatus);
                }
            })
        } else {
            this.updatePrefs(candidateId, officeId, currentStatus);
        }
    }

    close() {
        this.dialogRef.close();
    }

    openShareDialog() {
        let dialog = this.dialog.open(ShareCandidateDialogComponent, {
            width: "600px",
            maxWidth: "90%",
            maxHeight: "90%",
            panelClass: "share-dialog",
            autoFocus: false,
            data: {
                title: "Who would you like to share this candidate with?",
                officeId: this.candidate.officeId,
                candidateId: this.candidate.candidateId
            }
        });

        dialog.afterClosed().subscribe(result => {
            if (result === "SUCCESS") {
                this.analyticsService.fireEvent("Candidates", "Shared", "", {
                    dimension7: this.candidate.candidateId
                });
                this.dialog.open(AlertDialogComponent, {
                    width: "350px",
                    panelClass: "alertDialog",
                    data: {
                        title: "",
                        message: "This candidate has been shared."
                    }
                });
            }
        });
    }

    openEmailContactDialog(type, buttonId) {
        let titleText = "";
        let messageText = "";
        let typeRequest = "";
        if (buttonId === 1) {
            titleText = "We'll be happy to give you a call about ";
            messageText =
                "What information should we be aware of when we call?";
            typeRequest = "CallMe";
            this.analyticsService.fireEvent(
                "Candidates",
                "Request Call About Candidate Click",
                "",
                {
                    dimension7: this.candidate.candidateId.toString()
                }
            );
        } else if (buttonId === 2) {
            titleText = "Request More Candidates like ";
            typeRequest = "MoreLikeThis";
            this.analyticsService.fireEvent(
                "Candidates",
                "Send More Like This Click",
                "",
                {
                    dimension7: this.candidate.candidateId.toString()
                }
            );
        }
        let dialog = this.dialog.open(EmailContactCandidateDialogComponent, {
            width: "600px",
            maxWidth: "90%",
            maxHeight: "90%",
            panelClass: "request_more_panel",
            autoFocus: false,
            data: {
                title: titleText,
                message: messageText,
                candidateName:
                    this.candidate.firstName + " " + this.candidate.lastName,
                officeId: this.candidate.officeId,
                candidateId: this.candidate.candidateId,
                type: typeRequest
            }
        });

        dialog.afterClosed().subscribe(result => {
            if (result === "SUCCESS") {
                if (buttonId === 1) {
                    this.analyticsService.fireEvent(
                        "Candidates",
                        "Request Call About Candidate Submitted",
                        "",
                        {
                            dimension7: this.candidate.candidateId.toString()
                        }
                    );
                } else if (buttonId === 2) {
                    this.analyticsService.fireEvent(
                        "Candidates",
                        "Send More Like This Submitted",
                        "",
                        {
                            dimension7: this.candidate.candidateId.toString()
                        }
                    );
                }
                this.dialog.open(AlertDialogComponent, {
                    panelClass: "alert-dialog",
                    data: {
                        title: "Thanks!",
                        message: "Your recruiter will reach back out shortly."
                    }
                });
            }
        });
    }

    modalArrowClicked(direction) {
        this.dialogRef.close();
        this.candidateModalDirectionService.updateDirection(direction);
        this.candidateModalCandidateService.updateCandidate(this.candidate);
    }

    getUser() {
        this.userService.getUser().subscribe(data => {
            this.user = data;
        })
    }

    ngOnInit() {
        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });

        this.getUser();
    }
}
