<div class="header">
	<svg (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
	     stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x on-white">
	    <line x1="18" y1="6" x2="6" y2="18"></line>
	    <line x1="6" y1="6" x2="18" y2="18"></line>
	</svg>
	<div>
		<h1 mat-dialog-title>
		    LOOKING FOR
		</h1>
	</div>
</div>
<div>
	<div>
		<span>Freelance</span>
		<p>Hourly roles of any numbers of hours per week for any duration.</p>
	</div>
	<div>
		<span>Full-Time</span>
		<p>A direct hire on-staff employee role.</p>
	</div>
	<div>
		<span>Freelance to Full-Time</span>
		<p>An hourly role that transitions into a full-time on-staff employee role (try-before-you-hire).</p>
	</div>
	<div>
		<span>Offsite</span>
		<p>Remote roles that requires and/or flex to a home office.</p>
	</div>
	<div>
		<span>Onsite</span>
		<p>Roles that require an onsite presence.</p>
	</div>
</div>
