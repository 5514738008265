import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { ContactInformation } from "../../shared/models";
import { DashboardService } from "../../shared";
import { AnalyticsService } from "../../shared/services/analytics.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ApiError } from "../../shared/models/api-error.model";

@Component({
    selector: "dashboard-contact-information",
    templateUrl: "./contact-information.component.html",
    styleUrls: ["./contact-information.component.scss"]
})
export class ContactInformationComponent implements OnInit {
    @ViewChild("contact_information", { static: true })
    contact_information: ElementRef;

    @Input()
    public activeSection: boolean;

    contactInformation: ContactInformation[];
    contactCounter: number = 1;
    numOfContacts: number = 0;
    showForward: boolean = true;
    showBackward: boolean = false;
    isLoadingResults: boolean = true;
    loadingError: ApiError;

    private contacts: HTMLCollection;
    private currentIndex: number = 0;

    constructor(
        private dashboardService: DashboardService,
        private analyticsService: AnalyticsService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.getContacts();
    }

    getContacts() {
        this.loadingError = null;

        this.dashboardService
            .getContactInformation()
            .subscribe(
                contactInformation => {
                    contactInformation.map(ci => {
                        ci.textNumberSafe = this.sanitizer.bypassSecurityTrustUrl(
                            "sms:" + ci.textNumber
                        );
                        return ci;
                    });
                    this.contactInformation = contactInformation;
                    this.numOfContacts = contactInformation.length;
                },
                (apiError: ApiError) => (this.loadingError = apiError)
            )
            .add(() => (this.isLoadingResults = false));
    }

    toggleContacts(direction) {
        if (!this.contacts) {
            this.contacts = this.contact_information.nativeElement.getElementsByClassName(
                "contact"
            );
        }

        this.contacts[this.currentIndex].classList.remove("active");
        this.contacts[this.currentIndex].classList.add("not-active");

        if (direction === "back") {
            if (this.currentIndex === 0) {
                this.currentIndex = this.numOfContacts - 1;
            } else {
                this.currentIndex--;
                this.showForward = true;
            }
        }

        if (direction === "forward") {
            if (this.currentIndex === this.numOfContacts - 1) {
                this.currentIndex = 0;
            } else {
                this.currentIndex++;
                this.showBackward = true;
            }
        }

        if (this.currentIndex === 0) {
            this.showForward = true;
            this.showBackward = false;
        } else if (this.currentIndex === this.numOfContacts - 1) {
            this.showForward = false;
            this.showBackward = true;
        }

        this.contactCounter = this.currentIndex + 1;
        this.contacts[this.currentIndex].classList.remove("not-active");
        this.contacts[this.currentIndex].classList.add("active");
    }

    contactClick(option) {
        this.analyticsService.fireEvent(
            "Creative Circle Team",
            "Contact Team Member Click",
            option
        );
    }

    formatPhoneNumber(number) {
        const s2 = ("" + number).replace(/\D/g, "");
        const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return !m ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
    }
}
