import { Component, Input, OnInit, HostListener, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { JobService, User } from "../../shared";
import { jobColorFromJob } from "../../shared/helpers/status-color";
import { Job } from "../../shared/models";
import { JOB_STATUS_OPEN } from "../../shared/services/job-orders.service";
import { RequestTalentDialogComponent } from "../../shared/components/request-talent-dialog/request-talent-dialog.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { UserService } from "../../shared/services";
import { ApiError } from "../../shared/models/api-error.model";
import { AnalyticsService } from '../../shared/services/analytics.service';

class JobOrdersDashboardFilters {
    public areas: object = {};
    public jobType: string = "ALL";
    public onlyShowNew: boolean = false;
    public poNumber: string = "";

    get numApplied(): number {
        let sum = 0;

        if (this.poNumber) {
            sum++;
        }
        if (this.jobType !== "ALL") {
            sum++;
        }
        if (this.onlyShowNew) {
            sum++;
        }
        for (const area in this.areas) {
            if (this.areas.hasOwnProperty(area)) {
                if (this.areas[area]) {
                    sum++;
                }
            }
        }

        return sum;
    }
}

@Component({
    selector: "app-dashboard-job-orders",
    templateUrl: "./job-orders-dashboard.component.html",
    styleUrls: ["./job-orders-dashboard.component.scss"]
})
export class JobOrdersDashboardComponent implements OnInit {
    @ViewChild("filter_dropdown_container") filter_dropdown_container: ElementRef;
    @ViewChild("filter_dropdown_container_mobile", { static: true }) filter_dropdown_container_mobile: ElementRef;

    openJobs: Job[] = [];
    filteredOpenJobs: Job[] = [];
    public allFilteredOpenJobs: Job[] = [];
    public filteredOpenJobCount: number;
    public pages: number;
    public currentPage: number;
    jobAreas: string[] = [];

    filterDropdownActive: boolean = false;
    defaultFilters: JobOrdersDashboardFilters = new JobOrdersDashboardFilters();
    filters: JobOrdersDashboardFilters = JSON.parse(
        JSON.stringify(this.defaultFilters)
    );

    isLoadingResults: boolean = true;
    loadingError: ApiError;

    sortByDropdownActive: boolean = false;
    activeSort: string = "";
    activeSortDirection: string ="desc";

    windowWidth: number = 0;
    public user: User;
    public totalFiltered: number = 0;
    public selectedAreas: any[] = [];

    @Input()
    public activeSection: boolean;

    constructor(
        private jobSerivce: JobService,
        private router: Router,
        private dialog: MatDialog,
        private userService: UserService,
        private analyticsService: AnalyticsService
    ) {
        this.onResize();
    }

    ngOnInit() {
        this.jobSerivce.jobs.subscribe(jobs => {
            jobs = jobs.filter(j => j.status === JOB_STATUS_OPEN);

            this.allFilteredOpenJobs = jobs;
            this.sortBy("date-desc");

            this.filteredOpenJobCount = this.allFilteredOpenJobs.length;
            this.filteredOpenJobs = this.allFilteredOpenJobs.slice(0, 10);
            this.pages = (this.filteredOpenJobCount / 10) % 1 === 0 ? (this.filteredOpenJobCount / 10) : Math.floor((this.filteredOpenJobCount / 10) + 1);
            this.currentPage = 1;

            for (let x = 0; x < jobs.length; x++) {
                jobs[x]["statusColor"] = this.determineColor(jobs[x]);
            }

            this.openJobs = this.allFilteredOpenJobs.slice();
            this.filteredOpenJobs = this.allFilteredOpenJobs.slice(0, 10);

            this.populateJobAreasFromJobs();
        });

        this.loadJobs();

        this.jobSerivce.jobsLoading.subscribe(
            isLoading => (this.isLoadingResults = isLoading)
        );

        this.userService.currentUser.subscribe(userData => {
            this.user = userData;
        });
    }

    loadJobs() {
        this.loadingError = null;

        this.jobSerivce
            .getJobs()
            .subscribe({ error: (err: ApiError) => (this.loadingError = err) })
            .add(() => (this.isLoadingResults = false));
    }

    @HostListener("window:resize", ["$event"])
    onResize() {
        this.windowWidth = window.innerWidth;
    }

    public showMoreItems() {
        ++this.currentPage;
        if (this.currentPage <= this.pages) {
            if ((this.currentPage * 10) < this.filteredOpenJobCount) {
                this.filteredOpenJobs = this.allFilteredOpenJobs.slice(0, this.currentPage * 10);
            } else {
                this.filteredOpenJobs = this.allFilteredOpenJobs;
            }
        }
    }

    toggleFilterDropdown() {
        this.closeSortByDropdown();
        this.filterDropdownActive = !this.filterDropdownActive;
    }

    addArea(e, mobile=false) {
       // clicking label fires twice
       if (e.srcElement.nodeName === 'INPUT') {
        let areas = this.filter_dropdown_container.nativeElement.querySelectorAll('.area-location');

        if (mobile) {
            areas = this.filter_dropdown_container_mobile.nativeElement.querySelectorAll('.area-location-mobile');
        }

        let checked = [];
         for (let i = 0; i < areas.length; i++) {
             if (areas[i].checked) {
                 checked.push(areas[i].id);
             }
         }
         this.selectedAreas = checked;
         this.applyFilters(mobile);
       }
    }

    applyFilters(mobile=false) {
        let jobs = this.openJobs.slice();

        if (this.filter_dropdown_container) {
            let totalChecked = [];
            let inputFields = [];
            if (mobile) {
                inputFields = this.filter_dropdown_container_mobile.nativeElement.querySelectorAll('input');
            } else {
                inputFields = this.filter_dropdown_container.nativeElement.querySelectorAll('input');
            }

            for (let i = 0; i < inputFields.length; i++) {
                if (inputFields[i].checked) {
                    totalChecked.push(inputFields[i]);
                }

                if (inputFields[i].checked && inputFields[i].classList.contains('only-show-new')) {
                    jobs = jobs.filter(job => job.numNewSubmittals > 0);
                }
            }

            if (this.filters.poNumber) {
                this.totalFiltered = totalChecked.length + 1;
            } else {
                this.totalFiltered = totalChecked.length;
            }
        }

        if (this.selectedAreas.length > 0) {
            jobs = jobs.filter(job => this.selectedAreas.includes(job.area));
        } 

        if (this.filters.poNumber) {
            jobs = jobs.filter(job =>
                JobOrdersDashboardComponent.jobMatchesPoNumberQuery(
                    job,
                    this.filters.poNumber
                )
            );
        }

        this.allFilteredOpenJobs = jobs;
        this.filteredOpenJobCount = this.allFilteredOpenJobs.length;
        this.filteredOpenJobs = this.allFilteredOpenJobs.slice(0, 10);
        this.pages = (this.filteredOpenJobCount / 10) % 1 === 0 ? (this.filteredOpenJobCount / 10) : Math.floor((this.filteredOpenJobCount / 10) + 1);
        this.currentPage = 1;
    }

    clearAllFilters() {
        this.filters = JSON.parse(JSON.stringify(this.defaultFilters));
        this.totalFiltered = 0;
        this.clearTextFilter();
        if (this.filter_dropdown_container) {
            let mobileInputFields = this.filter_dropdown_container_mobile.nativeElement.querySelectorAll('input');
            let desktopInputFields = this.filter_dropdown_container.nativeElement.querySelectorAll('input');

            for (let i = 0; i < desktopInputFields.length; i++) {
                desktopInputFields[i].checked = false;
            }

            for (let i = 0; i < mobileInputFields.length; i++) {
                mobileInputFields[i].checked = false;
            }
        }
    }

    clearTextFilter() {
        let poText = this.filter_dropdown_container_mobile.nativeElement.querySelectorAll('#po-mobile');
        poText[0].value = '';
        this.filters.poNumber = '';
        this.totalFiltered -= 1;
        this.filteredOpenJobs = this.openJobs;
    }

    closeFilterDropdown() {
        if (window.innerWidth > 821) {
            this.filterDropdownActive = false;
        }
    }

    closeFilterOnMobile() {
        this.filterDropdownActive = false;
    }

    toggleSortByDropdown() {
        this.closeFilterDropdown();
        this.sortByDropdownActive = !this.sortByDropdownActive;
    }

    sortByDirection(value) {
        this.activeSortDirection = value;
    }

    sortBy(sortValue) {
        this.activeSort = sortValue;

        switch (sortValue) {
            case "date-asc":
                this.allFilteredOpenJobs.sort(
                    (a: Job, b: Job) =>
                        +new Date(a.dateOpened) - +new Date(b.dateOpened)
                );
                break;
            case "date-desc":
                this.allFilteredOpenJobs.sort(
                    (a: Job, b: Job) =>
                        +new Date(b.dateOpened) - +new Date(a.dateOpened)
                );
                break;
            case "candidates-asc":
                this.allFilteredOpenJobs.sort((a: Job, b: Job) => {
                    if (
                        a.status !== JOB_STATUS_OPEN &&
                        b.status !== JOB_STATUS_OPEN
                    ) {
                        return (
                            +new Date(a.dateOpened) - +new Date(b.dateOpened)
                        );
                    } else if (a.status !== JOB_STATUS_OPEN) {
                        return -1;
                    } else if (b.status !== JOB_STATUS_OPEN) {
                        return 1;
                    } else {
                        if (a.numNewSubmittals > b.numNewSubmittals) {
                            return 1;
                        } else if (a.numNewSubmittals < b.numNewSubmittals) {
                            return -1;
                        }
                        return (
                            +new Date(a.dateOpened) - +new Date(b.dateOpened)
                        );
                    }
                });
                break;
            case "candidates-desc":
                this.allFilteredOpenJobs.sort((a: Job, b: Job) => {
                    if (
                        a.status !== JOB_STATUS_OPEN &&
                        b.status !== JOB_STATUS_OPEN
                    ) {
                        return (
                            +new Date(a.dateOpened) - +new Date(b.dateOpened)
                        );
                    } else if (a.status !== JOB_STATUS_OPEN) {
                        return 1;
                    } else if (b.status !== JOB_STATUS_OPEN) {
                        return -1;
                    } else {
                        if (a.numNewSubmittals > b.numNewSubmittals) {
                            return -1;
                        } else if (a.numNewSubmittals < b.numNewSubmittals) {
                            return 1;
                        }
                        return (
                            +new Date(a.dateOpened) - +new Date(b.dateOpened)
                        );
                    }
                });
                break;
        }

        if (this.currentPage <= this.pages) {
            if ((this.currentPage * 10) < this.filteredOpenJobCount) {
                this.filteredOpenJobs = this.allFilteredOpenJobs.slice(0, this.currentPage * 10);
            } else {
                this.filteredOpenJobs = this.allFilteredOpenJobs;
            }
        }
    }

    closeSortByDropdown() {
        this.sortByDropdownActive = false;
    }

    routeToJob(jobOrder) {
        this.analyticsService.fireEvent(
            'Jobs',
            'Job Clicked',
            'OPEN'
        );

        this.router.navigate([
            "jobs",
            { officeId: jobOrder.officeId, jobId: jobOrder.jobId, 'section': 'current-openings' }
        ]);
    }

    private static jobMatchesPoNumberQuery(job: Job, query: string): boolean {
        if (!query || !query.trim()) return true;
        if (!job.poNumber || !job.poNumber.trim()) return false;

        const poNumber: string = job.poNumber
            .trim()
            .toLowerCase()
            .replace(/[^a-z0-9 ]/g, "");

        const queries: string[] = query
            .trim()
            .toLowerCase()
            .replace(/[^a-z0-9 ]/g, "")
            .split(" ")
            .filter(q => !!q);

        for (let i = 0, len = queries.length; i < len; i++)
            if (poNumber.search(queries[i]) !== -1) return true;

        return false;
    }

    private populateJobAreasFromJobs() {
        const jobAreas = [];

        for (let i = 0; i < this.openJobs.length; i++) {
            jobAreas.push(this.openJobs[i].area);
        }

        this.jobAreas = jobAreas.sort().filter(function(el, i, a) {
            return i === a.indexOf(el);
        });
    }

    private determineColor(job) {
        return "color-indicator " + jobColorFromJob(job);
    }

    openRequestTalentDialog() {
        const dialog = this.dialog.open(RequestTalentDialogComponent, {
            width: "600px",
            maxWidth: "90%",
            maxHeight: "90%",
            autoFocus: false,
            data: { user: this.user, analyticsLabel: 'Dashboard' }
        });

        this.analyticsService.fireEvent(
            'Talent Request',
            'Request Talent Click',
            'Dashboard'
        );

        dialog.afterClosed().subscribe();
    }
}
