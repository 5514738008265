import {
    Component,
    OnInit,
    AfterViewInit,
    ViewChild,
    ElementRef
} from "@angular/core";
import { Router } from "@angular/router";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { JobService, UserService } from "../../shared";
import { JobOrderCandidate } from "../../shared";
import { DocumentPreviewDialogComponent } from "./../../shared/components/document-preview-dialog/document-preview-dialog.component";
import { LbTitle } from "../../shared/services/title.service";
import { DomSanitizer } from "@angular/platform-browser";
import { HorizontalScrollHelper } from "../../shared/helpers/horizontal-scroll-helper";

@Component({
    selector: "app-share-candidate",
    templateUrl: "./share-candidate.component.html",
    styleUrls: ["./share-candidate.component.scss"]
})
export class ShareCandidateComponent implements OnInit {
    @ViewChild("document_container")
    document_container: ElementRef;

    public jobOrderCandidate: JobOrderCandidate;
    public loggedIn: boolean = false;

    constructor(
        private sanitizer: DomSanitizer,
        private router: Router,
        private jobOrderSerivce: JobService,
        private userService: UserService,
        private dialog: MatDialog,
        private titleService: LbTitle
    ) {
        this.titleService.setTitleFromKeywords("Shared Candidate");
    }

    scrollContainer(direction) {
        let container = this.document_container.nativeElement.getElementsByClassName(
            "scroll-container"
        );

        const horizontalSCrollHelper = new HorizontalScrollHelper();
        if (direction == "forward") {
            const scrollLeft = container[0].scrollLeft + 310;
            horizontalSCrollHelper.smoothScrollTo(
                container[0],
                scrollLeft,
                750
            );
        } else {
            const scrollLeft = container[0].scrollLeft - 310;
            horizontalSCrollHelper.smoothScrollTo(
                container[0],
                scrollLeft,
                750
            );
        }
    }

    previewDocument(document) {
        let urlFile = document["url"];
        let extensionPattern = /\.([0-9a-z]+)(?:[\?#]|$)/i;
        let extension = "";
        let previewExtensions = [
            ".xls",
            ".doc",
            ".pdf",
            ".ppt",
            ".pptx",
            ".docx",
            ".xlsx",
            ".ppsx"
        ];

        if (urlFile.match(extensionPattern)) {
            extension = urlFile.match(extensionPattern)[0];
        }

        if (previewExtensions.includes(extension)) {
            return true;
        } else {
            return false;
        }
    }

    openDocumentPreviewDialog(document) {
        let dialog = this.dialog.open(DocumentPreviewDialogComponent, {
            width: "90%",
            height: "90vh",
            maxWidth: "60%",
            maxHeight: "90vh",
            data: {
                link: document.url,
                title: document.title
            }
        });
    }

    ngOnInit() {
        let anchor = <HTMLAnchorElement>document.createElement("A");
        anchor.href = this.router.url;

        let token = anchor.pathname.split("/share/")[1];

            // TODO handle timeout
            this.jobOrderSerivce.getSharedCandidate({ token: token }).subscribe(
            candidateDetails => {
                this.titleService.setTitleFromKeywords(
                    [
                        "Shared Candidate",
                        candidateDetails.firstName +
                            " " +
                            candidateDetails.lastName
                    ],
                    "Candidate Share Page"
                );
                candidateDetails.safeBioBody = this.sanitizer.bypassSecurityTrustHtml(
                    candidateDetails.bioBody
                );
                this.jobOrderCandidate = candidateDetails;
            },
            error => {
                console.error(error);
                this.router.navigateByUrl("/not_found");
            }
        );

        this.userService.currentUserType.subscribe(userType => {
            if (userType) {
                this.loggedIn = true;
            } else {
                this.loggedIn = false;
            }
        });
    }
}
