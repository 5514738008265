export default function filterToString(filter: object): string {
    return Object.keys(filter)
        .filter(key => filter[key])
        .map(key => {
            if (typeof filter[key] === 'object')
                return Object.keys(filter[key])
                    .filter(areaKey => filter['area'][areaKey])
                    .join(',');
            if (typeof filter[key] === 'boolean') return key;
            if (typeof filter[key] === 'string') return filter[key];
        })
        .filter(x => x)
        .join(',');
}
