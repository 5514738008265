<div class="action-row" *ngIf="candidate.statusId != 12 && candidate.statusId != 9 && candidate.statusId != 8 && job && job.status == 'OPEN' && candidate.sunsetFlag === 0">
  <div class="action" (click)="(candidate.statusId == 6) || keepInTheRunning(candidate, 6)" [class.active]="candidate.statusId == 6">
    <div class="color-container">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-flag">
        <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
        <line x1="4" y1="22" x2="4" y2="15"></line>
      </svg>
      <p *ngIf="candidate.statusId == 6">Considering</p>
      <p *ngIf="candidate.statusId != 6">Keep in the Running</p>
    </div>
  </div>

  <div class="action" [class.active]="candidate.statusId == 3 || candidate.statusId == 2" (click)="candidate.statusId == 2 || candidate.statusId == 3 || openRequestInterviewDialog(candidate, 3)">
    <div class="color-container">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle">
        <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
      </svg>
      <p *ngIf="candidate.statusId != 3 && candidate.statusId != 2">Request Interview</p>
      <p *ngIf="candidate.statusId == 3">Interview Requested</p>
      <p *ngIf="candidate.statusId == 2">Interview Scheduled</p>
    </div>
  </div>

  <div class="action" [class.active]="candidate.statusId == 5" (click)="(candidate.statusId == 5) || passOnAction(candidate, 5)">
    <div class="color-container">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-minus">
        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
        <circle cx="8.5" cy="7" r="4"></circle>
        <line x1="23" y1="11" x2="17" y2="11"></line>
      </svg>
      <p *ngIf="candidate.statusId != 5">Pass on Candidate</p>
      <p *ngIf="candidate.statusId == 5">Passed on Candidate</p>
    </div>
  </div>
  <div class="action"[class.active]="candidate.statusId == 7" (click)="(candidate.statusId == 7) || openHiredDialog(candidate, 7)">
    <div class="color-container">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase">
        <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
        <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
      </svg>
      <p *ngIf="candidate.statusId != 7">Hire Candidate</p>
      <p *ngIf="candidate.statusId == 7">Hire Pending</p>
    </div>
  </div>
</div>
