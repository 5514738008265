import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    ValidationErrors
} from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { AlertDialogComponent } from "../../../shared/components/alert-dialog/alert-dialog.component";
import { ConfirmDialogComponent } from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import { Subscribing } from "../../../shared/components/subscribing.component";
import { ApiError } from "../../../shared/models/api-error.model";
import { LbTitle } from "../../../shared/services/title.service";
import { MessagesService } from "../messages.service";
import * as QuillNameSpace from "quill";


const DEFAULT_HOURS = 18;
const DEFAULT_MINS = 0;
const TIMEZONE = "UTC";

let Quill: any = QuillNameSpace;

@Component({
    templateUrl: "./form.component.html",
    styleUrls: ["./form.component.scss"]
})
export class CreateComponent extends Subscribing {
    createForm: UntypedFormGroup;
    isSubmitting: boolean = false;



    public get weekFromNow(): Date {
        let d = new Date();
        d.setDate(d.getDate() + 7);

        return d;
    }

    public action = "Create";
    private formChanged: boolean = false;

    quill: QuillNameSpace.Quill;
    quillModules = {
        toolbar: {
            container: [
                ["bold", "italic", "underline"],
                [{ 'align': [] }],
                [{ header: 1 }, { header: 2 }],
                ["link"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ 'color': [] }, { 'background': [] }],
                ["clean"]
            ]
        }
    };

    public get title(): AbstractControl {
        return this.createForm.get("title");
    }

    public get message(): AbstractControl {
        return this.createForm.get("message");
    }

    public get endDate(): AbstractControl {
        return this.createForm.get("endDate");
    }

    public get endTime(): AbstractControl {
        return this.createForm.get("endTime");
    }


    constructor(
        private router: Router,
        private messagesService: MessagesService,
        private fb: UntypedFormBuilder,
        private dialog: MatDialog,
        private location: Location,
        titleService: LbTitle
    ) {
        super();

        titleService.setTitleFromKeywords(["Create Message", "Admin"]);

        this.createForm = this.fb.group({
            title: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(100)
                ])
            ],
            message: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(4000)
                ])
            ],
            endDate: [
                this.weekFromNow,
                Validators.compose([Validators.required])
            ],
            endTime: [
                this.getPaddedTimeString(DEFAULT_HOURS) +
                    ":" +
                    this.getPaddedTimeString(DEFAULT_MINS),
                Validators.compose([Validators.required])
            ]
        });

        this.createForm.valueChanges.subscribe(_ => (this.formChanged = true));
    }

    submitForm() {
        let endDate: Date = this.endDate.value;
        let endTime: String = this.endTime.value;

        let match: RegExpMatchArray = endTime.match(/(\d{2}):(\d{2})/);
        if (match && match.length === 3) {
            endDate.setHours(Number(match[1]), Number(match[2]), 0, 0);
        }

        let data = {
            endDate: endDate.toLocaleString("en-US", {
                timeZone: TIMEZONE
            }),
            message: this.message.value,
            title: this.title.value
        };

        this.isSubmitting = true;
        this.subscriptions.push(
            this.messagesService.createMessage(data).subscribe({
                next: () => {
                    this.isSubmitting = false;

                    let dialog = this.dialog.open(AlertDialogComponent, {
                        width: "300px",
                        data: {
                            title: "SUCCESS!",
                            message: "Message created successfully."
                        }
                    });

                    dialog
                        .afterClosed()
                        .subscribe(_ =>
                            this.router.navigateByUrl("/admin/messages")
                        );
                },
                error: (err: ApiError) => {
                    this.isSubmitting = false;

                    if (err.statusCode === 400) {
                        for (let field in err.errors) {
                            this.createForm
                                .get(field)
                                .setErrors(err.errors[field]);
                        }
                    } else if (err.statusCode === 500) {
                        this.createForm.setErrors({ serverError: true });
                    } else if (err.timeout) {
                        this.createForm.setErrors({ timeout: true });
                    }
                }
            })
        );
    }

    public cancel() {
        if (this.formChanged) {
            let dialog = this.dialog.open(ConfirmDialogComponent, {
                width: "400px",
                data: {
                    title: "Are you sure?",
                    message: "Your changes will be lost",
                    cancelText: "Nevermind"
                }
            });

            dialog.afterClosed().subscribe(res => res && this.location.back());

            return;
        }

        this.location.back();
    }

    private getPaddedTimeString(num: number): string {
        return String("00" + num).substr(-2);
    }
}
