import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';

import { take, map } from 'rxjs/operators';
import { UserService, ROLE_ADMIN, ROLE_INTERNAL, MessageService } from '../shared';
import { Message, MESSAGE_TYPE_ERROR } from '../shared/models/message.model';

@Injectable()
export class AdminAuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private userService: UserService,
        private messageService: MessageService
    ) {
    }

    canActivate = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => this.isAdminObservable();
    canActivateChild = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => this.isAdminObservable();

    private isAdminObservable(): Observable<boolean> {
        return this.userService.currentUserType.pipe(take(1), map(userType => {
            if (userType === ROLE_ADMIN || userType === ROLE_INTERNAL) {
                return true;
            }

            this.messageService.addMessage(
                new Message(MESSAGE_TYPE_ERROR, 'You don\'t have access to that page.')
            );
            this.router.navigateByUrl('/dashboard');

            return false;
        }));
    }
}
