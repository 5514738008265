<div class="details-footer">
  <p class="legal" [innerHTML]="legalText"></p>
  <p class="legal" [innerHTML]="equalOpportunityText"></p>
  <div class="footer-container">
    <div class="links">
        <a href="https://www.creativecircle.com/about-us/" aria-label="About Us" target="_blank" rel="noopener"
           (click)="linkOutClick('About Us', 'https://www.creativecircle.com/about-us/')">About Us</a>
        <span class="divider">|</span>
        <a href="https://www.creativecircle.com/contact-us/" aria-label="Contact Us" target="_blank" rel="noopener"
           (click)="linkOutClick('Contact Us','https://www.creativecircle.com/contact-us/')">Contact Us</a>
        <span class="divider">|</span>
        <a href="https://www.creativecircle.com/privacy-policy/" aria-label="Privacy Policy" target="_blank" rel="noopener"
           (click)="linkOutClick('Privacy Policy','https://www.creativecircle.com/privacy-policy/')">Privacy Policy</a>
        <span class="divider">|</span>
        <a href="https://www.creativecircle.com/terms-of-use/" aria-label="Terms of Use" target="_blank" rel="noopener"
           (click)="linkOutClick('Terms of Use','https://www.creativecircle.com/terms-of-use/')">Terms of Use</a>
    </div>
    <div class="copyright">
      <p>Copyright 2019 Creative Circle, LLC. All Rights Reserved.</p>
    </div>
    <div class="social-links">
      <div class="social">
        <a href="https://www.instagram.com/creative.circle/" aria-label="Instagram" (click)="linkOutClick('Instagram', 'https://www.instagram.com/creative.circle/')" target="_blank" rel="noopener">
          <img src="assets/images/instagram-white.svg" alt="Instagram">
        </a>
      </div>
      <div class="social">
        <a href="https://www.linkedin.com/company/creative-circle/" aria-label="LinkedIn" (click)="linkOutClick('LinkedIn', 'https://www.linkedin.com/company/creative-circle/')" target="_blank" rel="noopener">
          <img src="assets/images/linkedin-white.svg" alt="LinkedIn">
        </a>
      </div>
    </div>
  </div>
</div>
