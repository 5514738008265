import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class LbTitle extends Title {
    setTitleFromKeywords(
        keywords: string | string[] = null,
        analyticsTitle: string = null
    ) {
        if (keywords instanceof Array) keywords = keywords.join(' - ');

        let title = keywords + ' | Creative Circle Lightbox';

        this.setTitle(title);
        (<any>window).ga('set', 'title', analyticsTitle ? analyticsTitle : title);
    }
}
