<div class="header">
	<svg (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
	     stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x on-white">
	    <line x1="18" y1="6" x2="6" y2="18"></line>
	    <line x1="6" y1="6" x2="18" y2="18"></line>
	</svg>
	<div class="warning reschedule">
		<h1>START VIDEO CONFERENCE?</h1>
		<p>Are you sure you want to start your interview with {{data.interviewWith}}?</p>
	</div>

</div>
<div mat-dialog-actions>
    <button aria-label="Start video conference" (click)="goToInterviewLink()">START VIDEO CONFERENCE</button>
</div>
