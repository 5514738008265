import { CommonModule } from "@angular/common";
import { ModuleWithProviders } from "@angular/core";
import { NgModule } from "@angular/core";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacySliderModule as MatSliderModule } from "@angular/material/legacy-slider";
import { MatSortModule } from "@angular/material/sort";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { QuillModule } from "ngx-quill";
import { RouterModule } from "@angular/router";
import { AmazingTimePickerModule } from "ngx-amazing-time-picker";
import { NoAuthGuard } from "../client/auth/no-auth-guard.service";
import { SharedModule } from "../shared";
import { AdminAuthGuard } from "./admin-auth-guard.service";
import { AdminComponent } from "./admin.component";
import { HeaderComponent } from "./header/header.component";
import { CreateComponent as CreateMessageComponent } from "./messages/manage/create.component";
import { EditComponent as EditMessageComponent } from "./messages/manage/edit.component";
import { MessagesComponent } from "./messages/messages.component";
import { MessagesService } from "./messages/messages.service";
import { EditTemplateComponent } from "./templates/edit/edit.component";
import { TemplatesComponent } from "./templates/templates.component";
import { TemplatesService } from "./templates/templates.service";
import { UsersComponent } from "./users/users.component";
import { SendTestEmailDialogComponent } from "./templates/send-test-email-dialog/send-test-email-dialog.component";
import { DemoUsersComponent } from "./demo-users/demo-users.component";
import { DemoRegionsComponent } from "./demo-regions/demo-regions.component";

const adminRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild([
    {
        path: "admin",
        component: AdminComponent,
        canActivate: [AdminAuthGuard],
        canActivateChild: [AdminAuthGuard],
        children: [
            {
                path: "messages",
                component: MessagesComponent,
                children: []
            },
            {
                path: "messages/create",
                component: CreateMessageComponent,
                children: []
            },
            {
                path: "messages/edit/:messageID",
                component: EditMessageComponent,
                children: []
            },
            {
                path: "templates",
                component: TemplatesComponent,
                children: []
            },
            {
                path: "templates/edit/:mainTemplateKey",
                component: EditTemplateComponent,
                children: []
            },
            {
                path: "clients",
                component: UsersComponent,
                children: []
            },
            {
                path: "demo-users/:regionId",
                component: DemoUsersComponent,
                children: []
            },
            {
                path: "demo-regions",
                component: DemoRegionsComponent,
                children: []
            }
        ]
    }
]);

@NgModule({
    imports: [
        SharedModule,
        adminRouting,
        CommonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSliderModule,
        MatRadioModule,
        MatIconModule,
        AmazingTimePickerModule,
        QuillModule
    ],
    declarations: [
        AdminComponent,
        HeaderComponent,
        MessagesComponent,
        DemoUsersComponent,
        DemoRegionsComponent,
        CreateMessageComponent,
        EditMessageComponent,
        UsersComponent,
        TemplatesComponent,
        EditTemplateComponent,
        SendTestEmailDialogComponent
    ],
    providers: [AdminAuthGuard, NoAuthGuard, MessagesService, TemplatesService]
})
export class AdminModule {}
