import { Component, OnInit, Input } from '@angular/core';
import {
    Candidate,
} from './../../../shared';

@Component({
    selector: 'app-candidate-details-work-history',
    templateUrl: './candidate-details-work-history.component.html',
    styleUrls: ['./candidate-details-work-history.component.scss']
})
export class CandidateDetailsWorkHistoryComponent implements OnInit {

    public workHistory: any[];

    @Input()
    candidate: Candidate;

    constructor(
    ) {}

    groupBy(history) {
        let hash = {};
        for (let i = 0; i < history.length; i++) {
            if (hash[history[i].companyName]) {
                hash[history[i].companyName].push(history[i]);
            } else {
                hash[history[i].companyName] = [];
                hash[history[i].companyName].push(history[i]);
            }
        }
        this.workHistory = Object.keys(hash).map(function (key) {
            return hash[key];
        });
    }

    ngOnInit() {
        let filteredHistory = this.candidate.workHistory.filter(work => work.jobCandStatusId === 8)
        this.groupBy(filteredHistory);
    }

}
