import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { AnalyticsService } from "../../../shared/services/analytics.service";
import { CandidatesService } from "./../../../shared";
import { ApiError } from "../../../shared/models/api-error.model";

@Component({
    selector: "app-candidate-details-footer",
    templateUrl: "./candidate-details-footer.component.html",
    styleUrls: ["./candidate-details-footer.component.scss"]
})
export class CandidateDetailsFooterComponent implements OnInit {
    public legalText: SafeHtml = "";
    public equalOpportunityText: SafeHtml = "";

    constructor(
        private candidatesService: CandidatesService,
        private sanitizer: DomSanitizer,
        private analyticsServices: AnalyticsService
    ) {}

    linkOutClick(text, url) {
        this.analyticsServices.fireEvent(
            "Outbound Link Click",
            "Footer : " + text,
            url
        );
    }

    ngOnInit() {
        this.candidatesService.getLegalText().subscribe(
            data => {
                this.legalText = this.sanitizer.bypassSecurityTrustHtml(
                    data["template"]
                );
            },
            (apiError: ApiError) => {
                if (apiError.timeout) {
                    this.legalText =
                        "It took too long to load the candidate agreement. Please check your network connection.";
                } else {
                    this.legalText =
                        "An error has occurred while loading the candidate agreement.";
                }
            }
        );

        this.candidatesService.getEqualOpportunityText().subscribe(
            data => {
                this.equalOpportunityText = this.sanitizer.bypassSecurityTrustHtml(
                    data["template"]
                );
            },
            (apiError: ApiError) => {
                if (apiError.timeout) {
                    this.equalOpportunityText =
                        "It took too long to load our Equal Employment Opportunity Employer statement. Please check your network connection.";
                } else {
                    this.equalOpportunityText =
                        "An error has occurred while loading our Equal Employment Opportunity Employer statement.";
                }
            }
        );
    }
}
