import { Component, OnInit, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { CandidateDetail, Job } from '../../../../shared';
import { ExtensionDialogComponent, CLOSE_SUCCESS } from '../../../../shared/components/extension-dialog/extension-dialog.component';
import { AlertDialogComponent } from '../../../../shared/components/alert-dialog/alert-dialog.component';

@Component({
    selector: 'app-candidate-details-tab-submitted',
    templateUrl: './candidate-details-tab-submitted.component.html',
    styleUrls: ['./candidate-details-tab-submitted.component.scss']
})
export class CandidateDetailsTabSubmittedComponent implements OnInit {

    @Input()
    candidate: CandidateDetail;

    @Input()
    doNotUse: boolean = false;

    @Input()
    job: Job;

    constructor(private dialog: MatDialog, private analyticsService: AnalyticsService) {}

    ngOnInit() {
    }

    adjustForTimezone(date:Date):Date{
        let timeOffsetInMS:number = date.getTimezoneOffset() * 60000;
        date.setTime(date.getTime() + timeOffsetInMS);
        return date
    }

    adjust() {
        this.analyticsService.fireEvent('Job Candidates', 'Adjust End Date', 'Click', {
            'dimension6': this.candidate.jobCandidateId,
            'dimension5': this.candidate.jobId.toString() + "." + this.candidate.officeId.toString(),
            'dimension3': this.candidate.clientId.toString() + '.' + this.candidate.officeId.toString()
        });

        const dialog = this.dialog.open(ExtensionDialogComponent, {
            width: '600px',
            autoFocus: false,
            panelClass: ['mobileContainer', 'extendDialog'],
            data: {
                jobId: this.candidate.jobId,
                officeId: this.candidate.officeId,
                candidate: this.candidate,
                clientId: this.candidate.clientId,
                currentDate: this.adjustForTimezone(new Date(this.job.dateEnd))
            }
        });

        dialog.afterClosed().subscribe(result => {
            if (result === CLOSE_SUCCESS) {
                this.dialog.open(AlertDialogComponent, {
                    width: '400px',
                    data: {
                        title: 'Thanks for the update.',
                        message:
                            'We are working on confirming the End Date change and will be back in touch soon.'
                    }
                });
            }
        });
    }
}
