<div class="medium-container">
    <mat-card *ngIf="templateConfiguration">
        <mat-card-title>
            Edit Template <small>[{{ templateConfiguration.DisplayName }}]</small>
        </mat-card-title>
        <mat-card-content>
            <div *ngIf="isLoading" class="loader-container">
                <svg class="loading-gif" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#131e27" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                   <line x1="12" y1="2" x2="12" y2="6"></line>
                   <line x1="12" y1="18" x2="12" y2="22"></line>
                   <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                   <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                   <line x1="2" y1="12" x2="6" y2="12"></line>
                   <line x1="18" y1="12" x2="22" y2="12"></line>
                   <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                   <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
               </svg>
            </div>

            <ng-container *ngIf="!isLoading">
                <ng-container *ngIf="templateConfiguration.Type === 'Email'">
                    <label>Subject</label>
                    <quill-editor [ngModel]="subjectTemplate.template" (onEditorCreated)="subjectQuill = $event" (onContentChanged)="this.onTextEditorChange('Subject', $event)" [modules]="subjectQuillModules" [formats]="['lbvar']"></quill-editor>
                </ng-container>

                <label>{{ templateConfiguration.Type === "Email"? "Body": "Template" }}</label>
                <quill-editor [(ngModel)]="formData.Main" (onEditorCreated)="mainQuill = $event" (onContentChanged)="this.onTextEditorChange(null, $event)" [modules]="mainQuillModules">
                </quill-editor>

                <ng-container *ngIf="templateConfiguration.Type === 'Email'">
                    <label>Call to Action Text <small>(optional)</small></label>
                    <quill-editor [ngModel]="linkTextTemplate.template" (onEditorCreated)="textQuill = $event" (onContentChanged)="this.onTextEditorChange('Text', $event)" [modules]="textQuillModules" [formats]="['lbvar']">
                    </quill-editor>

                    <label>Call to Action URL <small>(optional)</small></label>
                    <quill-editor [ngModel]="linkUrlTemplate.template" (onEditorCreated)="urlQuill = $event" (onContentChanged)="this.onTextEditorChange('Url', $event)" [modules]="urlQuillModules" [formats]="['lbvar']">
                    </quill-editor>
                </ng-container>

                <div class="button-container">
                    <button aria-label="Cancel" (click)="cancel()" mat-raised-button color="warn" type="button" [disabled]="isSubmitting">
                        Cancel
                    </button>
                    <button aria-label="Send Test" *ngIf="mainTemplate.templateType === 'EMAIL'" (click)="sendTest()" mat-raised-button type="button" [disabled]="!isValid || isSubmitting">
                        Send Test
                    </button>
                    <button aria-label="Save" (click)="submit()" mat-raised-button color="primary" type="button" [disabled]="!isFormChanged || !isValid || isSubmitting">
                        Save
                    </button>
                </div>

                <div class="error-container" *ngIf="submitErrorMessage">
                    <p>{{submitErrorMessage}}</p>
                </div>
            </ng-container>
        </mat-card-content>
    </mat-card>
</div>
