<div class="header">
	<svg (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
	     stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x on-white">
	    <line x1="18" y1="6" x2="6" y2="18"></line>
	    <line x1="6" y1="6" x2="18" y2="18"></line>
	</svg>
	<h1 *ngIf="data.title" mat-dialog-title>{{ data.title }}</h1>
</div>
<div mat-dialog-content>
    <p>{{ data.message }}</p>
</div>
<div mat-dialog-actions>
    <button aria-label="Cancel" [mat-dialog-close]="false" class="cancel">{{ data.cancelText || "CANCEL" }}</button>
    <button aria-label="Ok" [mat-dialog-close]="true">{{ data.okText || "OK" }}</button>
</div>
