import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CandidateSearchService {

    private blankSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    update$: Observable<boolean> = this.blankSearch.asObservable();

    updateBoolean(edited: boolean) {
        this.blankSearch.next(edited);
    }
}
