import { Component, OnInit, Input } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { DashboardService, UserService } from "../../shared";
import { AlertDialogComponent } from "../../shared/components/alert-dialog/alert-dialog.component";
import { BRCDialogComponent } from "../../shared/components/brc-dialog/brc-dialog.component";
import { MessageDialogComponent } from "../../shared/components/message-dialog/message-dialog.component";
import {
    CLOSE_SUCCESS,
    PerfReviewDialogComponent,
    PerfReviewDialogData
} from "../../shared/components/perf-review-dialog/perf-review-dialog.component";
import { ActionItem } from "../../shared/models";
import { AnalyticsService } from "../../shared/services/analytics.service";
import { ApiError } from "../../shared/models/api-error.model";

export const ACTION_ITEM_TYPE_BRC = "BillRateConfirmation";
export const ACTION_ITEM_TYPE_PERFORMANCE_REVIEW = "PerfReviewNeeded";
export const ACTION_ITEM_TYPE_MESSAGE = "Message";
export const ACTION_ITEM_TYPE_NEW_CANDIDATES = "NewCandidates";

@Component({
    selector: "dashboard-action-items",
    templateUrl: "./action-items.component.html",
    styleUrls: ["./action-items.component.scss"]
})
export class ActionItemsComponent implements OnInit {
    public actionItems: ActionItem[];
    public allActionItems: ActionItem[];
    public actionItemCount: number;
    public pages: number;
    public currentPage: number;
    public isLoadingResults: boolean = true;
    public loadingError: ApiError;
    @Input()
    public activeSection: boolean;
    public isLimitedImpersonation: boolean = false;

    constructor(
        private dashboardService: DashboardService,
        private dialog: MatDialog,
        private router: Router,
        private domSanitizer: DomSanitizer,
        private userService: UserService,
        private analyticsService: AnalyticsService
    ) {
        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });
    }

    ngOnInit() {
        this.gatherMessages();
    }

    gatherMessages(cache = false) {
        this.isLoadingResults = true;
        this.loadingError = null;

        this.dashboardService
            .getActionItems(undefined, undefined, cache)
            .subscribe(
                actionItems => {
                    this.allActionItems = actionItems.map(
                        this.removeTagsFromAiMessageBody.bind(this)
                    );
                    this.actionItemCount = this.allActionItems.length;
                    this.actionItems = this.allActionItems.slice(0, 10);
                    this.pages = (this.actionItemCount / 10) % 1 === 0 ? (this.actionItemCount / 10) : Math.floor((this.actionItemCount / 10) + 1);
                    this.currentPage = 1;
                },
                err => (this.loadingError = err)
            )
            .add(() => (this.isLoadingResults = false));
    }

    private removeTagsFromAiMessageBody(ai: ActionItem) {
        if (ai.messageBody)
            ai.messageBody = this.clearTagsFromString(ai.messageBody);
        return ai;
    }

    public showMoreItems() {
        ++this.currentPage;
        if (this.currentPage <= this.pages) {
            if ((this.currentPage * 10) < this.actionItemCount) {
                this.actionItems = this.allActionItems.slice(0, this.currentPage * 10);
            } else {
                this.actionItems = this.allActionItems;
            }
        }
    }

    clickHandler(actionItem: ActionItem) {
        if (actionItem.type === ACTION_ITEM_TYPE_BRC) {
            this.analyticsService.fireEvent(
                "Action Items",
                "View Bill Rate Click"
            );
            this.openBrcDialog(actionItem);
        } else if (actionItem.type === ACTION_ITEM_TYPE_PERFORMANCE_REVIEW) {
            this.analyticsService.fireEvent(
                "Action Items",
                "Performance Review Requested Click"
            );
            this.openPerformanceReviewDialog(actionItem);
        } else if (actionItem.type === ACTION_ITEM_TYPE_MESSAGE) {
            this.analyticsService.fireEvent(
                "Action Items",
                "New Message Click",
                actionItem.messageTitle
            );
            this.openMessageDialog(actionItem);
        } else if (actionItem.type === ACTION_ITEM_TYPE_NEW_CANDIDATES) {
            this.analyticsService.fireEvent(
                "Action Items",
                "New Candidate to Review Click"
            );
            this.router.navigate([
                "/jobs",
                { officeId: actionItem.officeID, jobId: actionItem.jobID }
            ]);
        }
    }

    openMessageDialog(actionItem: ActionItem) {
        const dialog = this.dialog.open(MessageDialogComponent, {
            width: "600px",
            panelClass: "messageContainer",
            data: actionItem
        });

        dialog.afterClosed().subscribe(result => {
            if (result === true) {
                this.dashboardService
                    .markMessageAsRead(actionItem.messageID)
                    .subscribe(() => {
                        this.gatherMessages(false);

                        // Refreshes the recent activity section
                        this.dashboardService.getRecentActivity().subscribe();
                    });
            }
        });
    }

    openPerformanceReviewDialog(actionItem: ActionItem) {
        const dialog = this.dialog.open(PerfReviewDialogComponent, {
            width: "600px",
            maxWidth: "90%",
            autoFocus: false,
            panelClass: "mobileContainer",
            data: new PerfReviewDialogData(
                actionItem.officeID,
                actionItem.jobID,
                actionItem.candID,
                actionItem.candFirstName,
                actionItem.candLastName,
                actionItem.favorited
            )
        });

        dialog.afterClosed().subscribe(result => {
            if (result === CLOSE_SUCCESS) {
                this.dialog.open(AlertDialogComponent, {
                    width: "350px",
                    data: {
                        title: "Thanks!",
                        message: "Your review has been submitted."
                    }
                });
                this.actionItems = this.actionItems.filter(
                    // remove the action item from the list
                    a => actionItem !== a
                );
            }
        });
    }

    private clearTagsFromString(str: string) {
        let tmp: HTMLElement = document.createElement("DIV");
        tmp.innerHTML = str.replace(/<\/[A-Z0-9-]+>/gi, " \n"); // replace end tags with new lines;
        let content: string = tmp.textContent || tmp.innerText || "";
        return content;
    }

    openBrcDialog(actionItem: ActionItem) {
        this.dialog.open(BRCDialogComponent, {
            width: "90vw",
            height: "90vh",
            maxWidth: "90vw",
            maxHeight: "90vh",
            data: {
                showAlert: true,
                brcLink: this.domSanitizer.bypassSecurityTrustResourceUrl(
                    actionItem.brcLink
                )
            }
        });
    }
}
