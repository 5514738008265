import { Component, OnInit, Input } from '@angular/core';
import {
    DashboardService,
    UserService,
    JobOrderCandidate,
    Candidate,
    CandidateDetail,
    JobService,
    User
} from './../../../shared';
import { AnalyticsService } from './../../../shared/services/analytics.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AlertDialogComponent } from './../../../shared/components/alert-dialog/alert-dialog.component';
import { ShareJobCandidateDialogComponent } from './../../../shared/components/share-job-candidate-dialog/share-job-candidate-dialog.component';
import { candidateColorFromStatusId } from './../../../shared/helpers/status-color';
import { Router } from '@angular/router';
import { ApiError } from '../../../shared/models/api-error.model';
import { NetworkErrorSnackbarService } from '../../../shared/services/network-error-snackbar.service';

@Component({
    selector: 'app-candidate-details-status',
    templateUrl: './candidate-details-status.component.html',
    styleUrls: ['./candidate-details-status.component.scss']
})
export class CandidateDetailsStatusComponent implements OnInit {

    @Input()
    candidate: Candidate;

    @Input()
    candidateDetails: CandidateDetail;

    @Input()
    jobOrderCandidate: JobOrderCandidate;

    @Input()
    hiredCandId: number = 0;

    public isLimitedImpersonation: boolean = false;
    public showToolTip: boolean = false;
    public candidateHired: boolean = false;
    public user: User;

    constructor(
      private dashboardService: DashboardService,
      private analyticsService: AnalyticsService,
      private jobsService: JobService,
      private dialog: MatDialog,
      private router: Router,
      private userService: UserService,
      private networkErrorSnackbarService: NetworkErrorSnackbarService,
    ) {}

    ngOnInit() {
        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });

        this.getUser();
        this.checkIfHired();
    }

    ngOnChanges() {
        this.checkIfHired();
    }

    getUser() {
        this.userService.getUser().subscribe(data => {
            this.user = data;
        })
    }

    checkIfHired() {
        if (this.hiredCandId === this.jobOrderCandidate.candidateId) {
            this.candidateHired = true;
        } else {
            this.candidateHired = false;
        }
    }

    favoritedCandidate(candidateId, officeId, currentStatus) {
        let action = currentStatus ? false : true;
        let params = {favorited: action};

        this.getUser();

        if (!this.user.hideFavMessageFlag && params.favorited) {

            let dialog = this.dialog.open(AlertDialogComponent, {
                panelClass: 'confirm_status_change_alert',
                data: {
                    buttonText: "Favorite",
                    showCheckbox: true,
                    favoritedMessage: true
                }
            });

            dialog.afterClosed().subscribe(result => {
                if (result) {
                    // never show favorite modal again
                    if (result.isChecked) {
                        const params = {
                            hideFavMessageFlag: true
                        }

                        this.userService.patchUserPreferences(params)
                            .subscribe(data =>
                                this.getUser()
                            )

                        this.user.hideFavMessageFlag = true;
                    }

                    this.dashboardService
                        .updateJobCandidatePreference(officeId, this.jobOrderCandidate.jobId, candidateId, params)
                                    // TODO handle timeout
                        .subscribe(data => {
                            this.candidate.favorited = action;
                            this.jobOrderCandidate.favorited = action;
                        },
                        (err: ApiError) => {
                            this.networkErrorSnackbarService.networkError(err,'')
                        }
                        );

                        if (params.favorited) {
                            this.analyticsService.fireEvent('Job Candidates', 'Favorited');
                        } else {
                            this.analyticsService.fireEvent('Job Candidates', 'Unfavorited');
                    }
                }
            })

        } else {
            this.dashboardService
            .updateJobCandidatePreference(officeId, this.jobOrderCandidate.jobId, candidateId, params)
                        // TODO handle timeout
            .subscribe(data => {
                this.candidate.favorited = action;
                this.jobOrderCandidate.favorited = action;
            },
            (err: ApiError) => {
                this.networkErrorSnackbarService.networkError(err,'')
            }
            );

            if (params.favorited) {
                this.analyticsService.fireEvent('Job Candidates', 'Favorited');
            } else {
                this.analyticsService.fireEvent('Job Candidates', 'Unfavorited');
            }
        }
    }

    blockCandidate(candidateId, officeId, currentStatus, favorited) {
        if (!this.user.hideBlockMessageFlag && currentStatus != true) {
            let dialog = this.dialog.open(AlertDialogComponent, {
                panelClass: "block_candidate_panel",
                data: {
                    blockedMessage: true,
                    buttonText: "Block",
                    currentlyFavorited: favorited,
                    candidateName: this.candidate.firstName.toUpperCase() + " " + this.candidate.lastName.toUpperCase(),
                    candidateFirstName: this.candidate.firstName
                }
            });

            dialog.afterClosed().subscribe(result => {
                if (result) {
                    this.dialog.open(AlertDialogComponent, {
                        panelClass: "block_candidate_panel",
                        data: {
                            blockedAfterMessage: true,
                            buttonText: "Got it",
                            currentlyFavorited: favorited,
                            candidateName: this.candidate.firstName.toUpperCase() + " " + this.candidate.lastName.toUpperCase()
                        }
                    })
                    let params = {'blocked': true, 'favorited': false};

                    this.analyticsService.fireEvent('Job Candidates', 'Blocked', '', {'dimension6': this.jobOrderCandidate.jobCandidateId, 'dimension3': this.jobOrderCandidate.clientId.toString() + '.' + this.jobOrderCandidate.officeId.toString(), 'dimension5': this.jobOrderCandidate.jobId.toString() + "." + this.jobOrderCandidate.officeId.toString()});
                    this.dashboardService.updateJobCandidatePreference(officeId, this.jobOrderCandidate.jobId, candidateId, params)
                        // TODO handle timeout
                        .subscribe(data => {
                            this.candidate.favorited = false;
                            this.candidate.blocked = true;
                            this.jobOrderCandidate.blocked = true;
                            this.jobOrderCandidate.favorited = false;
                        },
                        (err: ApiError) => {
                            return this.networkErrorSnackbarService.networkError(err,'')
                        }
                        );
                    // refresh candidate list so blocked candidate no longer appears
                    // TODO handle timeout
                    this.jobsService.getJobDetails(officeId, this.jobOrderCandidate.jobId ).subscribe({error: err => console.error(err)})
                }
            });
        } else {
            let params = {};
            if (currentStatus) {
                params = {'blocked': false, 'favorited': false};
            } else {
                params = {'blocked': true, 'favorited': false};
            }

            this.analyticsService.fireEvent('Job Candidates', 'Blocked', '', {'dimension6': this.jobOrderCandidate.jobCandidateId, 'dimension3': this.jobOrderCandidate.clientId.toString() + '.' + this.jobOrderCandidate.officeId.toString(), 'dimension5': this.jobOrderCandidate.jobId.toString() + "." + this.jobOrderCandidate.officeId.toString()});
            this.dashboardService.updateJobCandidatePreference(officeId, this.jobOrderCandidate.jobId, candidateId, params)
            // TODO handle timeout
                .subscribe(data => {
                    if (currentStatus) {
                        this.candidate.favorited = false;
                        this.candidate.blocked = false;
                        this.jobOrderCandidate.blocked = false;
                        this.jobOrderCandidate.favorited = false;
                    } else {
                        this.candidate.favorited = false;
                        this.candidate.blocked = true;
                        this.jobOrderCandidate.blocked = true;
                        this.jobOrderCandidate.favorited = false;
                    }
                },
                (err: ApiError) => {
                    this.networkErrorSnackbarService.networkError(err,'')
                }
                );
            // refresh candidate list so blocked candidate no longer appears
            // TODO handle timeout
            this.jobsService.getJobDetails(officeId, this.jobOrderCandidate.jobId ).subscribe({error: err => console.error(err)})

            if (!currentStatus) {
                this.closeCandidateDetails();
            }
        }
    }

    toggleToolTip() {
        this.showToolTip = !this.showToolTip;
    }

    determineColor(statusId) {
        return 'status ' + candidateColorFromStatusId(statusId);
    }

    passedOn(id) {
        return [5, 9, 12, 14].includes(id);
    }

    openShareDialog() {
        let dialog = this.dialog.open(ShareJobCandidateDialogComponent, {
            width: '600px',
            maxWidth: '90%',
            maxHeight: '90%',
            autoFocus: false,
            panelClass: 'share-dialog',
            data: {
                title:
                    'Who would you like to share this candidate with?',
                officeId: this.jobOrderCandidate.officeId,
                jobId: this.jobOrderCandidate.jobId,
                candidateId: this.jobOrderCandidate.candidateId,
                jobCandidateId: this.jobOrderCandidate.jobCandidateId
            }
        });

        dialog.afterClosed().subscribe(result => {
            if (result === 'SUCCESS') {
                this.analyticsService.fireEvent('Job Candidates', 'Shared', '', {'dimension6': this.jobOrderCandidate.jobCandidateId, 'dimension3': this.jobOrderCandidate.clientId.toString() + '.' + this.jobOrderCandidate.officeId.toString(), 'dimension5': this.jobOrderCandidate.jobId + "." + this.jobOrderCandidate.officeId});

                this.dialog.open(AlertDialogComponent, {
                    width: '350px',
                    data: {
                        title: '',
                        message: 'This candidate has been shared.'
                    }
                });
            }
        });
    }

    closeCandidateDetails() {
        this.router.navigate([
            'jobs',
            {
                officeId: this.jobOrderCandidate.officeId,
                jobId: this.jobOrderCandidate.jobId
            }
        ]);
    }

    determineStatus(candidateId) {
        let candidateStatus = {
            1: 'Not Reviewed',
            2: 'Interview Scheduled',
            3: 'Interview Requested',
            5: 'Passed',
            6: 'Considering',
            7: 'Hire Pending',
            8: 'Hired',
            9: 'Candidate Declined',
            12: 'No Longer Available',
            17: 'Not Selected'
        };

        return candidateStatus[candidateId] || 'Not Reviewed';
    }

}
