import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../shared/services/analytics.service';

@Component({
    selector: 'app-layout-footer-jobs',
    templateUrl: './footer-jobs.component.html',
    styleUrls: ['./footer-jobs.component.scss']
})
export class FooterJobsComponent implements OnInit {
    constructor(
    	private analyticsServices: AnalyticsService
    	) {
    }

    linkOutClick(text, url) {
        this.analyticsServices.fireEvent(
            'Outbound Link Click',
            'Footer : ' + text,
            url
        );
    }

    ngOnInit() {
    }

}
