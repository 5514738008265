<div class="footer-container">
    <div class="links">
        <a href="https://www.creativecircle.com/about-us/"  target="_blank" rel="noopener" (click)="linkOutClick('About Us', 'https://www.creativecircle.com/about-us/')" aria-label="About Us">About Us</a>
        <span class="divider">|</span>
        <a href="https://www.creativecircle.com/contact-us/" target="_blank" rel="noopener" (click)="linkOutClick('Contact Us', 'https://www.creativecircle.com/contact-us/')" aria-label="Contact Us">Contact Us</a>
        <span class="divider">|</span>
        <a href="https://www.creativecircle.com/privacy-policy/" target="_blank" rel="noopener" (click)="linkOutClick('Privacy Policy', 'https://www.creativecircle.com/privacy-policy/')" aria-label="Privacy Policy">Privacy Policy</a>
        <span class="divider">|</span>
        <a href="https://www.creativecircle.com/terms-of-use/" target="_blank" rel="noopener" (click)="linkOutClick('Terms of Use', 'https://www.creativecircle.com/terms-of-use/')" aria-label="Terms of Use">Terms of Use</a>
    </div>

    <div class="copyright">
        <p>Copyright 2019 Creative Circle, LLC. All Rights Reserved.</p>
    </div>

    <div class="social-links">
        <div class="social">
            <a href="https://www.instagram.com/creative.circle/" aria-label="Instagram" target="_blank" rel="noopener" (click)="linkOutClick('instagram', 'https://www.instagram.com/creative.circle/')">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <defs><path id="a" d="M0 .008h23.992v23.99H0z" /></defs>
                    <g fill="#7E868C" class="hover-state" fill-rule="evenodd">
                        <g>
                            <mask id="b" fill="#fff"><use xlink:href="#a" /></mask>
                            <path d="M11.996.008C8.738.008 8.33.02 7.05.08 5.773.138 4.901.34 4.138.637a5.88 5.88 0 0 0-2.125 1.384A5.88 5.88 0 0 0 .63 4.146C.333 4.909.13 5.78.072 7.058.014 8.338 0 8.746 0 12.004s.014 3.666.072 4.946c.058 1.277.261 2.149.558 2.912a5.88 5.88 0 0 0 1.383 2.125 5.88 5.88 0 0 0 2.125 1.383c.763.297 1.635.5 2.912.558 1.28.058 1.688.072 4.946.072s3.667-.014 4.946-.072c1.277-.058 2.15-.261 2.912-.558a5.88 5.88 0 0 0 2.125-1.383 5.88 5.88 0 0 0 1.384-2.125c.296-.763.499-1.635.557-2.912.059-1.28.072-1.688.072-4.946s-.013-3.667-.072-4.946c-.058-1.277-.26-2.15-.557-2.912a5.88 5.88 0 0 0-1.384-2.125A5.88 5.88 0 0 0 19.854.637C19.091.341 18.22.138 16.942.08 15.662.02 15.254.008 11.996.008zm0 2.161c3.203 0 3.583.012 4.848.07 1.17.053 1.805.249 2.227.413.56.218.96.478 1.38.897.42.42.68.82.897 1.38.164.422.36 1.058.413 2.227.058 1.265.07 1.645.07 4.848s-.012 3.582-.07 4.847c-.053 1.17-.249 1.805-.413 2.228-.218.56-.478.96-.897 1.38-.42.419-.82.679-1.38.897-.422.164-1.058.36-2.227.413-1.265.057-1.644.07-4.848.07-3.203 0-3.582-.013-4.847-.07-1.17-.054-1.805-.25-2.228-.413a3.716 3.716 0 0 1-1.38-.898c-.419-.42-.679-.82-.897-1.38-.164-.422-.36-1.057-.413-2.227-.057-1.265-.07-1.644-.07-4.847 0-3.203.013-3.583.07-4.848.054-1.17.25-1.805.413-2.227.218-.56.478-.96.898-1.38.42-.42.82-.68 1.38-.897.422-.164 1.057-.36 2.227-.413 1.265-.058 1.644-.07 4.847-.07z" mask="url(#b)" class="hover-state" />
                        </g>
                        <path d="M11.996 16.003a3.999 3.999 0 1 1 0-7.998 3.999 3.999 0 0 1 0 7.998zm0-10.16a6.16 6.16 0 1 0 0 12.321 6.16 6.16 0 0 0 0-12.32zM19.84 5.6a1.44 1.44 0 1 1-2.88 0 1.44 1.44 0 0 1 2.88 0" class="hover-state" />
                    </g>
                </svg>
            </a>
        </div>

        <div class="social">
            <a href="https://www.linkedin.com/company/creative-circle/" aria-label="LinkedIn" target="_blank" rel="noopener" (click)="linkOutClick('linkedin', 'https://www.linkedin.com/company/creative-circle/')">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" class="hover-state"><path d="M4 0h16a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4zm3.473 8.897a1.491 1.491 0 1 0 0-2.982 1.492 1.492 0 1 0 0 2.982zm-1.29 9.407H8.76V10.03H6.183v8.274zm12.216 0v-4.538c0-2.229-.481-3.943-3.088-3.943-1.252 0-2.092.686-2.434 1.337h-.035v-1.13h-2.467v8.274h2.57v-4.092c0-1.079.204-2.126 1.542-2.126 1.322 0 1.338 1.235 1.338 2.194v4.024H18.4z" class="hover-state" fill="#7E868C" fill-rule="evenodd" /></svg>
            </a>
        </div>
    </div>
</div>
