import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Candidate, CandidateDetail, Job } from './../../../shared';
import { AnalyticsService } from "../../../shared/services/analytics.service";

@Component({
    selector: 'app-candidate-details-tab-information',
    templateUrl: './candidate-details-tab-information.component.html',
    styleUrls: ['./candidate-details-tab-information.component.scss']
})
export class CandidateDetailsTabInformationComponent implements OnInit {

    public activeTab: number = 1;
    public showInterviewTab: boolean = false;

    @Input()
    candidate: CandidateDetail;

    @Input()
    details: any;

    @Input()
    doNotUse: boolean = false;

    @Input()
    job: Job;

    @Input()
    mobile: boolean;

    @Input()
    candidateObject: Candidate;

    constructor(
        private analyticsService: AnalyticsService
    ) {}

    ngOnInit() {
        this.checkJobStatus();
    }

    makeActive(tab){
        if (tab === 3) {
            this.analyticsService.fireEvent(
                "Job Candidates",
                "Request Call About Candidate Click",
                this.candidate.statusId.toString(),
                {
                    dimension7: this.candidate.candidateId.toString()
                }
            );
        }
        this.activeTab = tab;
    }

    checkJobStatus() {
        if (this.job.status === "OPEN") {
            this.showInterviewTab = true;
        }

        if (this.candidate.nextInterview && !this.candidate.sunsetFlag && !this.doNotUse) {
            this.activeTab = 2;
        }
    }

    ngOnChanges() {
        this.checkJobStatus();
    }
}
