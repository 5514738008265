import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CandidateDetail } from '../models/candidate-detail.model';

@Injectable()
export class CandidateModalCandidateService {
    private candidate: CandidateDetail = null;

    private candidateClicked: BehaviorSubject<CandidateDetail> = new BehaviorSubject<CandidateDetail>(this.candidate);

    update$: Observable<CandidateDetail> = this.candidateClicked.asObservable();

    updateCandidate(clicked: CandidateDetail) {
        this.candidateClicked.next(clicked);
    }
}
