<div class="active-candidates-container dashboard-section active-candidates" *ngIf="!isLoadingResults" [class.active-section]="activeSection">
    <div class="headline-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase">
            <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
        </svg>
        <p class="category-title">Active Placements</p>
        <a *ngIf="activeCandidates && activeCandidates.length > 1" [queryParams]="{active: '1'}" routerLink="/jobs">
            <button aria-label="View All" class="view-all-btn">View All</button>
        </a>
        <button aria-label="View All" *ngIf="activeCandidates && activeCandidates.length < 1" class="view-all-btn-inactive">View All</button>
    </div>

    <div class="loading-shade" *ngIf="isLoadingResults">
        <svg class="loading-gif" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
            <line x1="12" y1="2" x2="12" y2="6"></line>
            <line x1="12" y1="18" x2="12" y2="22"></line>
            <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
            <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
            <line x1="2" y1="12" x2="6" y2="12"></line>
            <line x1="18" y1="12" x2="22" y2="12"></line>
            <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
            <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
        </svg>
    </div>

    <app-loading-error [apiError]="loadingError" (onRetry)="getActiveCandidates()" [showShade]="true"></app-loading-error>

    <div *ngIf="activeCandidates && activeCandidates.length == 0 && !isLoadingResults" class="empty-state">
        <p>You currently have no active placements</p>
    </div>

    <div class="active-candidate" *ngFor="let candidate of activeCandidates">
        <div>
            <div class="star-icon" (click)="isLimitedImpersonation || updateFavorited(candidate.candidateId, candidate.officeId, candidate.favorited)" [class.disable]="isLimitedImpersonation">
                <svg width="24" height="23" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg"><path d="M9 2.314C13.438-2.248 24.534 5.735 9 16-6.534 5.736 4.562-2.248 9 2.314Z" fill-rule="nonzero" stroke="#979797" fill="none" [ngClass]="{ favored: candidate.favorited }"/></svg>
            </div>
            <div class="right-side-container">
                <div class="candidate-details" (click)="routeJob(candidate)">
                    <p class="name">{{ candidate.firstName }} {{ candidate.lastName }}</p>
                    <p class="jobOrder">#{{ candidate.jobId }} {{ candidate.jobTitle }}</p>
                </div>
                <hr />
                <div class="under-container">
                    <div class="time" [class.ending-alert]="withinSevenDays(candidate.targetEndDate)" (click)="routeJob(candidate)">
                        <p *ngIf="!candidate.fulltimeFlag || candidate.cvStatus.toLowerCase() !== 'closed'">
                            ENDS: <span class="bold-date">{{ candidate.targetEndDate | date: "MMM d, yyyy" }}</span>
                        </p>
                        <p *ngIf="candidate.fulltimeFlag && candidate.cvStatus.toLowerCase() === 'closed'">
                            STARTS: <span class="bold-date">{{ candidate.startDate | date: "MMM d, yyyy" }}</span>
                        </p>
                    </div>
                    <div class="adjust">
                        <button aria-label="Adjust End Date" *ngIf="!candidate.fulltimeFlag || candidate.cvStatus.toLowerCase() !== 'closed'" (click)="adjust(candidate)">Adjust End Date</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
