<div class="header">
    <svg (click)="cancel()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
        stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x on-white">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
    <h1 mat-dialog-title>
        Reschedule Interview with &nbsp;<span class="bold"> {{ data.candidateName }} </span>
    </h1>
</div>
<div mat-dialog-content>
    <form [formGroup]="form" (submit)="submit()">
        <input type="hidden" formControlName="jobCandidateId">
        <input type="hidden" formControlName="jobId">
        <input type="hidden" formControlName="officeId">
        <input type="hidden" formControlName="statusId">

        <div class="text-area-favorite-wrapper current-schedule">
            <label>Currently Scheduled For:</label>
            <div class="schedule-container">
                <div class="date">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none"
                        stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-clock">
                        <circle cx="12" cy="12" r="10"></circle>
                        <polyline points="12 6 12 12 16 14"></polyline>
                    </svg>
                    <span>{{data.date | date:'MMM d, yyyy'}} @ {{data.date | date:'h:mm a'}} {{data.timeZone}}</span>
                </div>
                <div class="location" *ngIf="data.interviewType === 'phone'">
                    <span class='icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                            fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-phone">
                            <path
                                d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                            </path>
                        </svg>
                    </span>
                    <span class="bold">
                        By Phone
                    </span>
                </div>
                <div class="location" *ngIf="data.interviewType === 'person'">
                    <span class='icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                            fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-eye">
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                    </span>
                    <span class="bold">
                        In Person
                    </span>
                </div>
                <div class="location" *ngIf="data.interviewType === 'video'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                        fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-video margin-right">
                        <polygon points="23 7 16 12 23 17 23 7"></polygon>
                        <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
                    </svg>
                    <span class="bold">
                        Video Conference
                    </span>
                </div>
            </div>
        </div>

        <div class="text-area-favorite-wrapper availability">
            <label>What is your updated availability?</label>
            <input formControlName="availability" placeholder="Write available dates & times here..." />
        </div>

        <div class="text-area-favorite-wrapper meet-options">
            <label>How would you like to meet?</label>
            <div class="selection-container">
                <div class="selection">
                    <input formControlName="contactMethod" value="phone" type="radio" name="contactMethod" id="select-phone" checked="checked" />
                    <label for="select-phone">By Phone</label>
                </div>
                <div class="selection">
                    <input formControlName="contactMethod" value="person" type="radio" name="contactMethod" id="select-person"/>
                    <label for="select-person">In Person</label>
                </div>
                <div class="selection">
                    <input formControlName="contactMethod" value="video" type="radio" name="contactMethod" id="select-video"/>
                    <label for="select-video">Video Conference</label>
                </div>
            </div>
        </div>

        <div class="text-area-favorite-wrapper">
            <label>Reason for the change?</label>
            <textarea formControlName="details" placeholder="Write reason for rescheduling here..."></textarea>
        </div>
        <div class="counter">
            <span [class.error]="countMax < 0">Characters remaining: {{countMax}}</span>
        </div>
    </form>
</div>
<div mat-dialog-content>
    <div class="error">
        <p *ngIf="formError" class="form-error">{{ formError }}</p>
    </div>
</div>
<div mat-dialog-actions>
    <button aria-label="Submit" [ngClass]="{'is-submitting': isSubmitting}"
        [disabled]="isLimitedImpersonation || !form.valid || this.typingLength > 4000" (click)="submit()">
        <span class="not-submitting">Submit</span>
        <span class="spinner" *ngIf="isSubmitting">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-loader">
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        </span>
    </button>
</div>
