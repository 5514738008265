import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Company } from "../../models/company.model";
import { User } from "../../models";
import { UserService } from "../../../shared";
import { IndependentTerritory } from "../../models/independent-territory.model";
import { AlertDialogComponent } from "../alert-dialog/alert-dialog.component";
import { ApiError } from "../../models/api-error.model";
import { Subscribing } from "../subscribing.component";
import { AnalyticsService } from '../../services/analytics.service';
import {throwError} from 'rxjs';

@Component({
    selector: "app-request-talent-dialog",
    templateUrl: "./request-talent-dialog.component.html",
    styleUrls: ["./request-talent-dialog.component.scss"]
})
export class RequestTalentDialogComponent extends Subscribing
    implements OnInit {
    public form: UntypedFormGroup;
    public formError: string;
    public companyList: Array<Company>;
    public territoryList: Array<IndependentTerritory>;
    public isSubmitting: boolean = false;
    public user: User;
    public positionList: Array<string>;
    public isLimitedImpersonation: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<RequestTalentDialogComponent>,
        private dialog: MatDialog,
        private userService: UserService,
        private analyticsService: AnalyticsService
    ) {
        super();

        this.userService.isLimitedImpersonation.subscribe(
            ili => (this.isLimitedImpersonation = ili)
        );

        this.form = this.fb.group({
            companies: [null, Validators.compose([Validators.required])],
            territories: [null, Validators.compose([Validators.required])],
            note: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(10000)
                ])
            ]
        });

        this.user = this.data.user;
    }

    ngOnInit() {
        this.form.controls["companies"].setValue("");
        this.form.controls["territories"].setValue("");
        this.form.controls["note"].setValue("");
        this.positionList = [];

        this.subscriptions.push(
            this.userService.company.subscribe(companies => {
                this.companyList = companies;
            }),
            this.userService.territory.subscribe(territories => {
                this.territoryList = territories;
            }),
            this.userService.getUserCompanies().subscribe(() => {
                if (this.companyList.length === 1) {
                    this.form.controls["companies"].setValue(
                        this.companyList[0]
                    );
                    if (
                        this.territoryList.find(
                            territory =>
                                territory.trtyID === this.companyList[0].trtyID
                        )
                    ) {
                        this.form.controls["territories"].setValue(
                            this.companyList[0].trtyID
                        );
                    }
                }
            }),
            this.userService.getTerritories().subscribe(/* TODO handle timeout */)
        );
    }

    cancel() {
        this.dialogRef.close();
    }

    submit() {
        this.userService
            .requestTalent({
                Ofcid: this.form.get("companies").value.ofcID,
                ClientID: this.form.get("companies").value.clientID,
                TerritoryID: this.form.get("territories").value,
                PositionType: this.positionList.join(", "),
                Message: this.form.get("note").value,
                TargetedBy: this.form.get("companies").value.targetedBy || "",
                LBUserID: this.user.userId
            })
            .subscribe(/* TODO handle timeout */
                data => {
                    if (!('item4' in data) || data.item4 === null || data.item4.Status !== true) {
                        return this.errorDialog();
                    }

                    this.isSubmitting = false;

                    this.dialog.open(AlertDialogComponent, {
                        width: "400px",
                        data: {
                            title: "Thanks!",
                            message:
                                "Your Creative Circle team will be in touch shortly.",
                            buttonText: "OK"
                        }
                    });

                    this.analyticsService.fireEvent(
                        'Talent Request',
                        'Request Talent Submit',
                        this.data.analyticsLabel
                    );

                    this.form.reset();
                    this.dialogRef.close();
                },
                (err: any) => {
                    this.errorDialog();
                },
            );
    }

    onChangePositions(event) {
        const value = event.target.value;
        const checked = event.target.checked;

        if (checked) {
            this.positionList.push(value);
        } else {
            this.positionList.splice(this.positionList.indexOf(value), 1);
        }
    }

    errorDialog() {
        this.dialog.open(AlertDialogComponent, {
            width: "400px",
            data: {
                title: "Cannot Save",
                message:
                    "We're sorry. Something has gone wrong and we cannot save your request. Please contact your " +
                    "Creative Circle team directly for assistance.",
                buttonText: "OK"
            }
        });
    }
}
