import { Component, OnInit } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService, MessageService } from '../../shared';
import { ApiError } from '../../shared/models/api-error.model';
import { CompleteRegistration } from '../../shared/models/complete-registration.model';
import {
    Message,
    MESSAGE_TYPE_ERROR,
    MESSAGE_TYPE_SUCCESS
} from '../../shared/models/message.model';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { LbTitle } from '../../shared/services/title.service';
import match from '../../shared/validators/match';
import requireTrimmed from '../../shared/validators/require-trimmed';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-complete-registration',
    templateUrl: './complete-registration.component.html',
    styleUrls: ['./complete-registration.component.scss']
})
export class CompleteRegistrationComponent implements OnInit {
    public email: string;
    public registerForm: UntypedFormGroup;
    public isSubmitting: boolean = false;
    public hasValidEmail: boolean = false;
    public hasActivePopover: boolean = false;
    public requestTalentUrl: string = environment.request_talent_url;
    public timecardLoginUrl: string = environment.timecard_login_url;

    public get username(): AbstractControl {
        return this.registerForm.get('username');
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private apiService: ApiService,
        private messageService: MessageService,
        private titleService: LbTitle,
        private analyticsService: AnalyticsService
    ) {
        this.titleService.setTitleFromKeywords(
            'Complete Registration',
            'Create Account'
        );
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
                        // TODO handle timeout
                        this.getEmailFromToken(params.inviteToken).subscribe(
                result => {
                    this.email = result.email;
                    this.hasValidEmail = true;
                    this.registerForm.get('inviteToken').setValue(params.inviteToken);
                },
                (err: ApiError) => {
                    this.analyticsService.fireEvent(
                        'Login',
                        'Create Account Error',
                        'Invalid Invitation'
                    );

                    let msg = '';
                    if (err.statusCode === 404) {
                        msg =
                            'This link has expired. There may be a newer one in your Inbox. New submissions of the Registration Form ' +
                            'invalidate the links from all previous requests.';
                    } else {
                        msg =
                            'There was an error retrieving your email address, please try again.';
                    }
                    this.messageService.addMessage(new Message(MESSAGE_TYPE_ERROR, msg));
                    this.router.navigateByUrl('/register');
                }
            );
        });

        this.createForm();
    }

    /**
     * Retrieve the email address using a token
     * @param {string} token
     * @returns {Observable<object>}
     */
    public getEmailFromToken(token: string): Observable<any> {
        const endpoint = '/invite?token=' + token;
        return this.apiService.get(endpoint);
    }

    public createForm(): void {
        // Create the form
        this.registerForm = this.fb.group(
            {
                inviteToken: [null, Validators.required],
                username: [
                    null,
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(8),
                        Validators.maxLength(20),
                        Validators.pattern(/^[a-zA-Z0-9]+\s*$/),
                        requireTrimmed()
                    ])
                ],
                password: [
                    null,
                    Validators.compose([
                        Validators.required,
                        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)
                    ])
                ],
                passwordConfirmation: [
                    null,
                    Validators.compose([
                        Validators.required,
                        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)
                    ])
                ],
                minimumChar: new UntypedFormControl({value: false, disabled: true}),
                upperCaseChar: new UntypedFormControl({value: false, disabled: true}),
                lowerCaseChar: new UntypedFormControl({value: false, disabled: true}),
                numericChar: new UntypedFormControl({value: false, disabled: true}),
                specialChar: new UntypedFormControl({value: false, disabled: true})
            },
            {validator: match('password', 'passwordConfirmation')}
        );

        // Listen for changes to the password value
        this.registerForm.get('password').valueChanges.subscribe(value => {
            // minimum 8 characters
            this.updatePasswordCriteria('minimumChar', value.length >= 8);

            // at least 1 uppercase character
            this.updatePasswordCriteria('upperCaseChar', value.match(/[A-Z]/));

            // at least 1 lowercase character
            this.updatePasswordCriteria('lowerCaseChar', value.match(/[a-z]/));

            // at least 1 numeral
            this.updatePasswordCriteria('numericChar', value.match(/\d/));

            // an optional special character
            this.updatePasswordCriteria('specialChar', value.match(/[^a-zA-Z\d\s]/));
        });
    }

    /**
     * Handle the form submission
     */
    public submitForm(): void {
        this.isSubmitting = true;
        this.registerForm.controls['username'].setValue(this.registerForm.controls['username'].value.trim());
                        // TODO handle timeout
                        this.apiService.patch('/user', this.registerForm.value).subscribe(
            (data: CompleteRegistration) => {
                this.isSubmitting = false;

                this.analyticsService.fireEvent('Login', 'Create Account Complete');

                this.messageService.addMessage(
                    new Message(
                        MESSAGE_TYPE_SUCCESS,
                        'You have successfully registered. Please log in.'
                    )
                );

                this.router.navigateByUrl('/');
            },
            (err: ApiError) => {
                this.isSubmitting = false;

                if (err.statusCode === 400) {
                    this.analyticsService.fireEvent(
                        'Login',
                        'Create Account Error',
                        'Invalid Registration Parameters'
                    );
                    for (let field in err.errors) {
                        this.registerForm.get(field).setErrors(err.errors[field]);
                    }
                    this.registerForm.setErrors({badRequest: true});
                } else if (err.statusCode === 409) {
                    this.analyticsService.fireEvent(
                        'Login',
                        'Create Account Error',
                        'Username Already Exists'
                    );
                    this.username.setErrors({conflict: true});
                } else if (err.statusCode === 500) {
                    this.analyticsService.fireEvent(
                        'Login',
                        'Create Account Error',
                        'Server Error'
                    );
                    this.registerForm.setErrors({serverError: true});
                }
            }
        );
    }

    /**
     * Update checkboxes for the various password criteria
     * @param {string} field
     * @param {boolean} value
     * @return {boolean}
     */
    private updatePasswordCriteria(field: string, value: boolean): boolean {
        const input = this.registerForm.get(field);
        input.setValue(value);
        return value;
    }
}
