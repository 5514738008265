export interface ApiErrorErrors {
    [s: string]: any;
}

export class ApiError {
    public statusCode: number;
    public errors: ApiErrorErrors;
    public requestId: string;
    public timeout: boolean;
    public offline: boolean;

    constructor(
        _statusCode: number,
        _requestId: string = "",
        _timeout: boolean = false,
        _offline: boolean = false,
        _errors: ApiErrorErrors = {}
    ) {
        this.statusCode = _statusCode;
        this.requestId = _requestId;
        this.timeout = _timeout;
        this.offline = _offline;
        this.errors = _errors;
    }
}
