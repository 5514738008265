import { CommonModule } from "@angular/common";
import { ModuleWithProviders } from "@angular/core";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySliderModule as MatSliderModule } from "@angular/material/legacy-slider";
import { MatLegacySnackBarModule as MatSnackBarModule, MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/legacy-snack-bar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { IMaskModule } from "angular-imask";
import { DateAgoPipe } from "../shared/pipes/dateago.pipe";
import { SharedModule } from "../shared";
import { AlertDialogComponent } from "../shared/components/alert-dialog/alert-dialog.component";
import { BRCDialogComponent } from "../shared/components/brc-dialog/brc-dialog.component";
import { CandidateDialogComponent } from "../shared/components/candidate-dialog/candidate-dialog.component";
import { ConfirmDialogComponent } from "../shared/components/confirm-dialog/confirm-dialog.component";
import { DocumentLinkPreviewDialogComponent } from "../shared/components/document-link-preview-dialog/document-link-preview-dialog.component";
import { DocumentPreviewDialogComponent } from "../shared/components/document-preview-dialog/document-preview-dialog.component";
import { EmailContactCandidateDialogComponent } from "../shared/components/email-contact-candidate-dialog/email-contact-candidate-dialog.component";
import { EmailContactDialogComponent } from "../shared/components/email-contact-dialog/email-contact-dialog.component";
import { ExtensionDialogComponent } from "../shared/components/extension-dialog/extension-dialog.component";
import { HiredDialogComponent } from "../shared/components/hired-dialog/hired-dialog.component";
import { KeepInTheRunningComponent } from "../shared/components/keep-in-the-running/keep-in-the-running.component";
import { LookingForDialogComponent } from "../shared/components/looking-for-dialog/looking-for-dialog.component";
import { MessageDialogComponent } from "../shared/components/message-dialog/message-dialog.component";
import { PassedOnDialogComponent } from "../shared/components/passed-on-dialog/passed-on-dialog.component";
import { PerfReviewDialogComponent } from "../shared/components/perf-review-dialog/perf-review-dialog.component";
import { RequestInterviewDialogComponent } from "../shared/components/request-interview-dialog/request-interview-dialog.component";
import { RequestTalentDialogComponent } from "../shared/components/request-talent-dialog/request-talent-dialog.component";
import { ShareCandidateDialogComponent } from "../shared/components/share-candidate-dialog/share-candidate-dialog.component";
import { ShareJobCandidateDialogComponent } from "../shared/components/share-job-candidate-dialog/share-job-candidate-dialog.component";
import { UsernameChangeDialogComponent } from "../shared/components/username-change-dialog/username-change-dialog.component";
import { ClickOutsideDirective } from "../shared/directives/click-outside.directive";
import { AuthGuard } from "../shared/services/auth-guard.service";
import { ActionItemsComponent } from "./action-items/action-items.component";
import { ActiveCandidatesComponent } from "./active-candidates/active-candidates.component";
import { NoAuthGuard } from "./auth/no-auth-guard.service";
import { CandidateDetailsModule } from "./candidate-details/candidate-details.module";
import { CandidatesComponent } from "./candidates/candidates.component";
import { FiltersComponent } from "./candidates/filters/filters.component";
import { TableComponent } from "./candidates/table/table.component";
import { ClientComponent } from "./client.component";
import { CompleteRegistrationComponent } from "./complete-registration/complete-registration.component";
import { ContactInformationComponent } from "./contact-information/contact-information.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { MaintenanceComponent } from "./error_pages/maintenance/maintenance.component";
import { NotFoundComponent } from "./error_pages/not_found/not_found.component";
import { FixedNavigationBarComponent } from "./fixed-navigation-bar/fixed-navigation-bar.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { HomeComponent } from "./home/home.component";
import { InterviewsComponent } from "./interviews/interviews.component";
import { JobOrdersDashboardComponent } from "./job-orders-dashboard/job-orders-dashboard.component";
import { JobOrdersComponent } from "./job-orders/job-orders.component";
import { JobOrdersModule } from "./job-orders/job-orders.module";
import { FooterComponent } from "./layout/footer/footer.component";
import { HeaderComponent } from "./layout/header/header.component";
import { LoadingErrorModule } from "./loading-error/loading-error.module";
import { PasswordResetComponent } from "./password-reset/password-reset.component";
import { RecentActivityComponent } from "./recent-activity/recent-activity.component";
import { RegisterComponent } from "./register/register.component";
import { ReportsComponent } from "./reports/reports.component";
import { SearchBarModule } from "./search-bar/search-bar.module";
import { CanDeactivateGuard } from "./settings/can-deactivate.guard";
import { SettingsComponent } from "./settings/settings.component";
import { ShareCandidateComponent } from "./share-candidate/share-candidate.component";
import { SideNavigationBarComponent } from "./side-navigation-bar/side-navigation-bar.component";
import { ConnectionServiceModule } from "ng-connection-service";
import { RescheduleInterviewDialogComponent } from '../shared/components/reschedule-interview-dialog/reschedule-interview-dialog.component';

const clientRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild([
    {
        path: "",
        component: ClientComponent,
        children: [
            {
                path: "",
                component: HomeComponent,
                canActivate: [NoAuthGuard]
            },
            {
                path: "home",
                redirectTo: ""
            },
            {
                path: "login/forgot",
                component: ForgotPasswordComponent,
                canActivate: [NoAuthGuard]
            },
            {
                path: "login/reset/:resetToken",
                component: PasswordResetComponent
            },
            {
                path: "share/:candidateToken",
                component: ShareCandidateComponent
            },
            {
                path: "register",
                component: RegisterComponent
            },
            {
                path: "register/complete/:inviteToken",
                component: CompleteRegistrationComponent
            },
            {
                path: "dashboard",
                component: DashboardComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "candidates",
                component: CandidatesComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "reports",
                component: ReportsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "settings",
                component: SettingsComponent,
                canActivate: [AuthGuard],
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: "jobs",
                component: JobOrdersComponent,
                canActivate: [AuthGuard],
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: "**",
                component: NotFoundComponent
            }
        ]
    }
]);

@NgModule({
    imports: [
        clientRouting,
        CommonModule,
        SearchBarModule,
        SharedModule,
        ReactiveFormsModule,
        JobOrdersModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatSnackBarModule,
        MatCardModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        IMaskModule,
        CandidateDetailsModule,
        LoadingErrorModule,
        ConnectionServiceModule,
        MatSliderModule,
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        SideNavigationBarComponent,
        FixedNavigationBarComponent,
        ClientComponent,
        RegisterComponent,
        CompleteRegistrationComponent,
        ForgotPasswordComponent,
        PasswordResetComponent,
        NotFoundComponent,
        MaintenanceComponent,
        DashboardComponent,
        HomeComponent,
        DateAgoPipe,
        ClickOutsideDirective,
        CandidatesComponent,
        ReportsComponent,
        SettingsComponent,
        ActionItemsComponent,
        JobOrdersDashboardComponent,
        ContactInformationComponent,
        InterviewsComponent,
        ActiveCandidatesComponent,
        RecentActivityComponent,
        AlertDialogComponent,
        ExtensionDialogComponent,
        ConfirmDialogComponent,
        HiredDialogComponent,
        RequestInterviewDialogComponent,
        RescheduleInterviewDialogComponent,
        FiltersComponent,
        TableComponent,
        EmailContactDialogComponent,
        EmailContactCandidateDialogComponent,
        ShareJobCandidateDialogComponent,
        ShareCandidateDialogComponent,
        ShareCandidateComponent,
        DocumentPreviewDialogComponent,
        CandidateDialogComponent,
        MessageDialogComponent,
        UsernameChangeDialogComponent,
        PassedOnDialogComponent,
        DocumentLinkPreviewDialogComponent,
        KeepInTheRunningComponent,
        RequestTalentDialogComponent,
        LookingForDialogComponent
    ],
    providers: [
        CanDeactivateGuard,
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { horizontalPosition: 'end', duration: 100000 } }
    ]
})
export class ClientModule {}
