<div class="maintenance-page">
    <div class="image-container">
        <img class="logo" alt="Maintenance Logo" src="../../../../assets/images/logo-email-2.png" />
        <img class="hero" alt="Maintenance Logo" src="../../../../assets/images/maintenance_page_hero_image.svg" />
    </div>

    <h3>We're temporarily down for scheduled maintenance.</h3>
    <p>
        We apologize for any inconvenience and appreciate your patience. If you need immediate assistance, please message us on social:
    </p>

    <div class="social-container">
        <a href="https://www.facebook.com/CreativeCircleStaffing" aria-label="Facebook" target="_blank" rel="noopener">
            <img class="logo" alt="Facebook" src="../../../../assets/images/maintenance-facebook.png" />
        </a>

        <a href="https://twitter.com/Creative_Circle" aria-label="Twitter" target="_blank" rel="noopener">
            <img class="logo" alt="Twitter" src="../../../../assets/images/maintenance-twitter.png" />
        </a>

        <a href="https://www.instagram.com/creative.circle/" aria-label="Instagram" target="_blank" rel="noopener">
            <img class="logo" alt="Instagram" src="../../../../assets/images/maintenance-instagram.png" />
        </a>

        <a href="https://www.linkedin.com/company/creative-circle/" aria-label="LinkedIn" target="_blank" rel="noopener">
            <img class="logo" alt="LinkedIn" src="../../../../assets/images/maintenance-linkedin.png" />
        </a>
    </div>
</div>
