<div class="notes-row">
    <div class="header-font">
        <div class="title">
            <p>Notes</p>
        </div>
        <div class="buttons" *ngIf="details.sunsetFlag === 0 || patchCandidateNotes">
            <button aria-label="Add A Note" *ngIf="!showNotesForm" class="view-all-btn" (click)="toggleNotesSection()">
                <span class="view-all-btn-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-edit">
                        <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                        <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                    </svg>
                </span>
                <span>ADD A NOTE</span>
            </button>
            <button aria-label="Cancel Note" *ngIf="showNotesForm" class="view-all-btn" (click)="toggleNotesSection()">
                <span>CANCEL NOTE</span>
            </button>
            <button aria-label="Write Review" *ngIf="!pastHire(details.statusId) && !patchCandidateNotes" class="view-all-btn write-review inactive-review">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-flag">
                        <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
                        <line x1="4" y1="22" x2="4" y2="15"></line>
                    </svg>
                </span>
                <span>WRITE REVIEW</span>
            </button>
            <button aria-label="Write Review" *ngIf="pastHire(details.statusId) && !patchCandidateNotes" class="view-all-btn write-review" (click)="openPerformanceReviewDialog(details)">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-flag">
                        <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
                        <line x1="4" y1="22" x2="4" y2="15"></line>
                    </svg>
                </span>
                <span>WRITE REVIEW</span>
            </button>
        </div>
    </div>
    <div *ngIf="details.notes && details.notes.length == 0 && !showNotesForm && (details.sunsetFlag == 0 || patchCandidateNotes)" class="no-notes">
        <p>Once you add notes on this candidate, you'll find the notes here.</p>
    </div>

    <div *ngIf="details.notes && details.notes.length == 0 && !showNotesForm && (details.sunsetFlag == 1 && !patchCandidateNotes)" class="no-notes">
        <p>No notes exist for this candidate.</p>
    </div>
    <app-loading-error [apiError]="loadingError" (onRetry)="refreshCandidate()"></app-loading-error>
    <div #notes_form>
        <div class="timeline">
            <div class="recent-activity" [class.empty-notes]="details.notes && details.notes.length == 0">
                <div class="timeline__group" *ngIf="showNotesForm">
                    <div class="timeline__box">
                        <div class="timeline__date">
                            <span class="timeline__day"></span>
                        </div>
                        <div class="timeline__post timeline-notes-field">
                            <div class="timeline__content">
                                <div class="submit-notes-field">
                                    <form [formGroup]="form">
                                        <textarea (keyup)="formUpdated()" formControlName="note" class="noteForm"
                                            placeholder="Write your note about {{ details.firstName }} {{ details.lastName }} here..."
                                            value="{{ defaultValue }}" autofocus></textarea>
                                        <hr />
                                        <div class="action-container">
                                            <div class="access-control">
                                                <div class="flex">
                                                    <div class="control">
                                                        <input type="checkbox" id="keep-private"
                                                            formControlName="private" value="private"
                                                            (click)="determineInput($event)" />
                                                        <label for="keep-private">Keep Private</label>
                                                    </div>
                                                    <div class="control" [ngClass]="{ overlay: keepPrivate }">
                                                        <input type="checkbox" id="share-creative-circle"
                                                            formControlName="sharedCC" value="creative-circle"
                                                            (click)="determineInput($event)" />
                                                        <label for="share-creative-circle">Share with Creative
                                                            Circle</label>
                                                    </div>
                                                    <div class="control" *ngIf="!patchCandidateNotes"
                                                        [ngClass]="{ overlay: keepPrivate }">
                                                        <input type="checkbox" id="share-job-contacts"
                                                            formControlName="sharedHiringManagers"
                                                            value="hiring-managers" (click)="determineInput($event)" />
                                                        <label for="share-job-contacts">Share with Job Contacts</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="submit">
                                                <div class="counter">
                                                    <span [class.error]="countMax < 0">{{countMax}}</span>
                                                </div>
                                                <button aria-label="Submit" [disabled]="isLimitedImpersonation || !form.valid" (click)="submitNote()">
                                                    <span class="not-submitting">SUBMIT</span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngFor="let note of details.notes">
                    <div *ngIf="note.type == 'performance-review' && note.sunsetFlag == 0" class="timeline__group"
                        [class.read-more-background]="note.comments.length > 50"
                        [class.show-all]="showWholeNote == note.noteId && expanded">
                        <div class="timeline__box">
                            <div class="timeline__date">
                                <span class="timeline__day"></span>
                            </div>
                            <div class="timeline__post">
                                <div class="arrow-left"></div>
                                <div class="timeline__content performance-review-background">
                                    <div class="not-grouped">
                                        <div class="performance-review-header">
                                            <p>Performance Review</p>
                                            <div class="rating-wrapper">
                                                <span [ngClass]="{ checked: note.rating > 0 }">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        viewBox="0 0 24 24" fill="none" stroke="#f1f1f1"
                                                        stroke-width="`" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-star">
                                                        <polygon
                                                            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                                                        </polygon>
                                                    </svg>
                                                </span>
                                                <span [ngClass]="{ checked: note.rating > 1 }">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        viewBox="0 0 24 24" fill="none" stroke="#f1f1f1"
                                                        stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-star">
                                                        <polygon
                                                            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                                                        </polygon>
                                                    </svg>
                                                </span>
                                                <span [ngClass]="{ checked: note.rating > 2 }">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        viewBox="0 0 24 24" fill="none" stroke="#f1f1f1"
                                                        stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-star">
                                                        <polygon
                                                            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                                                        </polygon>
                                                    </svg>
                                                </span>
                                                <span [ngClass]="{ checked: note.rating > 3 }">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        viewBox="0 0 24 24" fill="none" stroke="#f1f1f1"
                                                        stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-star">
                                                        <polygon
                                                            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                                                        </polygon>
                                                    </svg>
                                                </span>
                                                <span [ngClass]="{ checked: note.rating > 4 }">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        viewBox="0 0 24 24" fill="none" stroke="#f1f1f1"
                                                        stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-star">
                                                        <polygon
                                                            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2">
                                                        </polygon>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="detail-header">
                                            <div class="detail-container">
                                                <div class="detail">
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                            viewBox="0 0 24 24" fill="none" stroke="#7B868B"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-clock">
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                            <polyline points="12 6 12 12 16 14"></polyline>
                                                        </svg>
                                                    </span>
                                                    <p class="detail">
                                                        {{ note.dateCreated | date: "MMM d, yyyy" }} @
                                                        {{ note.dateCreated | date: "h:mm a" }}
                                                    </p>
                                                </div>
                                                <div class="detail">
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                            viewBox="0 0 24 24" fill="none" stroke="#7B868B"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-user">
                                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                            <circle cx="12" cy="7" r="4"></circle>
                                                        </svg>
                                                    </span>
                                                    <p class="detail">{{ note.clientFirstName }}
                                                        {{ note.clientLastName }}
                                                    </p>
                                                </div>
                                                <div class="detail">
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                            viewBox="0 0 24 24" fill="none" stroke="#7B868B"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-briefcase">
                                                            <rect x="2" y="7" width="20" height="14" rx="2" ry="2">
                                                            </rect>
                                                            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                                                        </svg>
                                                    </span>
                                                    <p class="detail">Job #{{ note.jobId }}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p *ngIf="note.comments.length >= 150" class="read-more-note review"
                                                    (click)="readMoreReview(note)">
                                                    {{ expandedReviews.includes(note.reviewId) ? "Collapse" : "Read More" }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="message" [class.expand]="expandedReviews.includes(note.reviewId)"
                                            *ngIf="note.comments.length >= 350">
                                            <p class="truncated-note" [innerHTML]="truncateHTML(note.comments)"></p>
                                            <p class="whole-note" [innerHTML]="note.comments"></p>
                                        </div>
                                        <div class="message" *ngIf="note.comments.length < 350">
                                            <p [innerHTML]="note.comments"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="note.type == 'note'" class="timeline__group">
                        <div class="timeline__box">
                            <div class="timeline__date">
                                <span class="timeline__day"></span>
                            </div>
                            <div class="timeline__post">
                                <div class="timeline__content">
                                    <div class="not-grouped">
                                        <div class="detail-header">
                                            <div class="header-group">
                                                <div class="left-side">
                                                    <div class="detail">
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                height="12" viewBox="0 0 24 24" fill="none"
                                                                stroke="#7B868B" stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round" class="feather feather-clock">
                                                                <circle cx="12" cy="12" r="10"></circle>
                                                                <polyline points="12 6 12 12 16 14"></polyline>
                                                            </svg>
                                                        </span>
                                                        <p class="detail">{{ note.dateCreated | date: "MMM d, yyyy" }}
                                                        </p>
                                                    </div>
                                                    <div class="detail"
                                                        *ngIf="note.createdByFirstName || note.createdByLastName">
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                height="12" viewBox="0 0 24 24" fill="none"
                                                                stroke="#7B868B" stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round" class="feather feather-user">
                                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2">
                                                                </path>
                                                                <circle cx="12" cy="7" r="4"></circle>
                                                            </svg>
                                                        </span>
                                                        <p class="detail">{{ note.createdByFirstName }}
                                                            {{ note.createdByLastName }}</p>
                                                    </div>
                                                    <div class="detail" *ngIf="!patchCandidateNotes">
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                height="12" viewBox="0 0 24 24" fill="none"
                                                                stroke="#7B868B" stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather feather-briefcase">
                                                                <rect x="2" y="7" width="20" height="14" rx="2" ry="2">
                                                                </rect>
                                                                <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16">
                                                                </path>
                                                            </svg>
                                                        </span>
                                                        <p class="detail">Job #{{ note.jobId }}</p>
                                                    </div>
                                                    <div class="detail" *ngIf="patchCandidateNotes && note.jobId">
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                height="12" viewBox="0 0 24 24" fill="none"
                                                                stroke="#7B868B" stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather feather-briefcase">
                                                                <rect x="2" y="7" width="20" height="14" rx="2" ry="2">
                                                                </rect>
                                                                <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16">
                                                                </path>
                                                            </svg>
                                                        </span>
                                                        <p class="detail">Job# {{ note.jobId }}</p>
                                                    </div>
                                                    <div class="detail edit">
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12"
                                                                height="12" viewBox="0 0 24 24" fill="none"
                                                                stroke="#7B868B" stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round" class="feather feather-lock">
                                                                <rect x="3" y="11" width="18" height="11" rx="2" ry="2">
                                                                </rect>
                                                                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                            </svg>
                                                        </span>
                                                        <p *ngIf="!note.sharedHiringManagers && !note.sharedCC"
                                                            class="detail">Private (Only You)</p>
                                                        <p *ngIf="note.sharedHiringManagers && note.sharedCC"
                                                            class="detail">Shared with Creative Circle and Job Contacts
                                                        </p>
                                                        <p *ngIf="note.sharedHiringManagers && !note.sharedCC"
                                                            class="detail">Shared with Job Contacts</p>
                                                        <p *ngIf="!note.sharedHiringManagers && note.sharedCC"
                                                            class="detail">Shared with Creative Circle</p>
                                                        <div class="detail update-sharing"
                                                            (click)="updateSharingPreferences(note.noteId)">
                                                            <svg width="14" height="14" viewBox="0 0 20 20"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M14 1l5 5L6 19H1v-5z" stroke="#1C2B39"
                                                                    stroke-width="1.988" fill="none" fill-rule="evenodd"
                                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p *ngIf="note.note.length >= 350" class="read-more-note"
                                                        (click)="readMoreNote(note)">
                                                        {{ expandedNotes.includes(note.noteId) ? "Collapse" : "Read More" }}
                                                    </p>
                                                </div>

                                            </div>
                                            <div class="share-container" *ngIf="showSharingForm" #preferences_box>
                                                <div *ngIf="noteId == note.noteId" class="share-preference-form">
                                                    <div class="close" (click)="closeSharedForm()">
                                                        <span class="close-form">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" viewBox="0 0 24 24" fill="none"
                                                                stroke="#1C2B39" stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round" class="feather feather-x">
                                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <span>ADJUST PRIVACY SETTINGS</span>
                                                    <div>
                                                        <div class="checkbox" [class.overlay]="note.sharedCC">
                                                            <app-loading-spinner
                                                                *ngIf="sharePreferencesLoading.sharedCC">
                                                            </app-loading-spinner>
                                                            <input *ngIf="!sharePreferencesLoading.sharedCC"
                                                                [disabled]="note.sharedCC"
                                                                (click)="changePreferences($event, note, 'sharedCC')"
                                                                type="checkbox" class="preference-input creative-circle"
                                                                value="creative-circle" [checked]="note.sharedCC"
                                                                id="shareCC" />
                                                            <label for="shareCC">Share with Creative Circle</label>
                                                        </div>
                                                        <div class="checkbox" *ngIf="!patchCandidateNotes">
                                                            <app-loading-spinner
                                                                *ngIf="sharePreferencesLoading.sharedHiringManagers">
                                                            </app-loading-spinner>
                                                            <input *ngIf="!sharePreferencesLoading.sharedHiringManagers"
                                                                [disabled]="note.sharedHiringManagers"
                                                                (click)="changePreferences($event, note, 'sharedHiringManagers')"
                                                                type="checkbox" class="preference-input hiring-managers"
                                                                id="shareJC" [checked]="note.sharedHiringManagers"
                                                                value="hiring-managers" />
                                                            <label for="shareJC">Share with Job Contacts</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="message" [class.expand]="expandedNotes.includes(note.noteId)"
                                                *ngIf="note.note.length >= 350">
                                                <p class="truncated-note" [innerHTML]="truncateHTML(note.note)"></p>
                                                <p class="whole-note" [innerHTML]="note.note"></p>
                                            </div>
                                            <div class="message" *ngIf="note.note.length < 350">
                                                <p [innerHTML]="note.note"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
