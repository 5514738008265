import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, Form, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from '../../services/api.service';
import { ActionItem } from '../../models/action-item.model';
import { JobOrderCandidate } from '../../models/job-order-candidate.model';
import { ApiError } from '../../models/api-error.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { UserService } from '../../services';
import { NetworkErrorSnackbarService } from "../../../shared/services/network-error-snackbar.service";

export const CLOSE_SUCCESS = 'SUCCESS';

export class PerfReviewDialogData {
    public officeId: number;
    public jobId: number;
    public candidateId: number;
    public firstName: string;
    public lastName: string;
    public favorited: boolean;

    constructor(
        officeId: number,
        jobId: number,
        candidateId: number,
        firstName: string,
        lastName: string,
        favorited: boolean
    ) {
        this.officeId = officeId;
        this.jobId = jobId;
        this.candidateId = candidateId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.favorited = favorited;
    }
}

@Component({
    selector: 'app-perf-review-dialog',
    templateUrl: './perf-review-dialog.component.html',
    styleUrls: ['./perf-review-dialog.component.scss']
})
export class PerfReviewDialogComponent implements OnInit {
    public form: UntypedFormGroup;
    public isSubmitting: boolean = false;
    public formError: string;
    public isLimitedImpersonation: boolean = false;
    public countMax: number = 4000;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: PerfReviewDialogData,
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<PerfReviewDialogComponent>,
        private apiService: ApiService,
        private dialog: MatDialog,
        private userService: UserService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
    ) {
        this.form = this.fb.group({
            candidateId: [
                this.data.candidateId,
                Validators.compose([Validators.required])
            ],
            officeId: [this.data.officeId, Validators.compose([Validators.required])],
            jobId: [this.data.jobId, Validators.compose([Validators.required])],
            rating: [
                null,
                Validators.compose([Validators.required, Validators.pattern(/^[1-5]$/)])
            ],
            feedback: [
                null,
                Validators.compose([Validators.required, Validators.maxLength(4000)])
            ],
            favorited: false
        });

        this.dialogRef.disableClose = true;

        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });

        this.form.controls['feedback'].valueChanges.subscribe(content => {
            this.countMax = 4000 - content.length;
        });
    }

    ngOnInit() {
        if (this.data.favorited === true) {
            this.form.get('favorited').setValue(true);
        }
    }

    toolTip() {
        let dialog = this.dialog.open(AlertDialogComponent, {
            width: '400px',
            data: {
                message:
                    'Your review will be shared with Creative Circle and displayed to the other contacts who are related to this job, as listed in the job detail.'
            }
        });
    }

    cancel() {
        if (this.form.dirty) {
            const dialog = this.dialog.open(ConfirmDialogComponent, {
                width: '400px',
                data: {
                    title: 'Are you sure you want to close without submitting?',
                    message: 'Your review information will be lost.',
                    okText: 'Yes'
                }
            });

            dialog.afterClosed().subscribe(reason => {
                if (reason) {
                    this.dialogRef.close();
                }
            });
        } else {
            this.dialogRef.close();
        }
    }

    submit() {
        if (this.form.valid && !this.isSubmitting) {
            this.isSubmitting = true;

            this.apiService
                .post(
                    `/job/${this.data.officeId}/${this.data.jobId}/${this.data.candidateId}/performance-review`,
                    this.form.value
                )
                .subscribe(/* TODO handle timeout */
                    _ => this.dialogRef.close(CLOSE_SUCCESS),
                    (err: ApiError) => {
                        this.isSubmitting = false;
                        if (err.statusCode === 400) {
                            this.formError = 'Please make sure the form is complete and try again.';
                        } else {
                            this.networkErrorSnackbarService.networkError(err,'')
                        }
                    }
                );
        }
    }
}
