<div class="header">
    <svg (click)="close()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
    <div>
        <h1 mat-dialog-title class="bold">ADJUST END DATE</h1>
        <h1 mat-dialog-title>Adjust the end date for {{this.data.candidate.firstName}} {{this.data.candidate.lastName}}</h1>
    </div>
</div>
<div mat-dialog-content>
    <mat-calendar #calendar [selected]="dateControl.value" (selectedChange)="dateValueChange($event)"
                  [minDate]="todaysDate" [startAt]="dateControl.value" (click)="checkChangedValue()"></mat-calendar>
    <p class="end-date" [class.error]="sameDateError">Current End Date: <span class="bold">{{this.data.currentDate | date: 'MMM d, yyyy'}}</span></p>
    <p class="warning" *ngIf="sameDateError">
        <span>
            {{ "To adjust the end date, please select a date that is different than the current end date." }}
        </span>
    </p>
    <form [formGroup]="requestForm" (submit)="submit()">
        <input type="hidden" formControlName="date"/>
        <div class="group">
            <label for="note">Add a Note:</label>
            <textarea id="note" formControlName="note" maxlength="4000"></textarea>
        </div>
        <div class="counter">
            <span [class.error]="countMax < 0">Characters remaining: {{countMax}}</span>
        </div>
    </form>
    <div class="error">
        <p *ngIf="formError" class="form-error">{{ formError }}</p>
    </div>
</div>
<div mat-dialog-controls>
    <button aria-label="Submit" class="submit-button" [disabled]="isLimitedImpersonation || !requestForm.valid"
            (click)="submit()">
        <span class="not-submitting">Submit</span>
    </button>
</div>
