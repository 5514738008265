export class HorizontalScrollHelper {
    public smoothScrollTo(element, to, duration) {
        const
            start = element.scrollLeft,
            change = to - start,
            startDate = +new Date(),
            easeInOutQuad = (currentTime, initialValue, nextValue, animationDuration) => {
                currentTime /= animationDuration / 2;
                if (currentTime < 1) {
                    return nextValue / 2 * currentTime * currentTime + initialValue;
                }
                currentTime--;
                return -nextValue / 2 * (currentTime * (currentTime - 2) - 1) + initialValue;
            },
            animateScroll = () => {
                const currentDate = +new Date();
                const currentTime = currentDate - startDate;
                element.scrollLeft = parseInt(easeInOutQuad(currentTime, start, change, duration), 10);
                if (currentTime < duration) {
                    requestAnimationFrame(animateScroll);
                } else {
                    element.scrollLeft = to;
                }
            };
        animateScroll();
    }
}
