import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';

@Component({
    selector: 'app-team-member-mobile',
    templateUrl: './team-member-mobile.component.html',
    styleUrls: ['./team-member-mobile.component.scss']
})
export class TeamMemberMobileComponent implements OnInit {
    @Input() title: string;
    @Input() name: string;
    @Input() phone: string;
    @Input() mobile: string;
    @Input() email: string;
    @Input() phoneExtension: string;
    @Input() details: any;
    public safeMobileNumber: SafeUrl;

    constructor(private sanitizer: DomSanitizer, private analyticsService: AnalyticsService) {}

    formatPhoneNumber(number) {
        const cleaned = ('' + number).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        return (!match) ? null : '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    contactRecruiter(type) {
        this.analyticsService.fireEvent(
            'Jobs',
            'Recruiter Contact Click',
            type,
            {'dimension5': this.details.jobId.toString() + "." + this.details.officeId.toString()}
        );
    }

    ngOnInit() {
        this.safeMobileNumber = this.sanitizer.bypassSecurityTrustUrl('sms:' + this.mobile);
    }

}
