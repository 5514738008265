import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { of, merge } from "rxjs";
import {
    catchError,
    debounceTime,
    map,
    startWith,
    switchMap
} from "rxjs/operators";
import { ApiService, ROLE_CLIENT, User, UserService } from "../../shared";
import { AlertDialogComponent } from "../../shared/components/alert-dialog/alert-dialog.component";
import { Subscribing } from "../../shared/components/subscribing.component";
import { AdminUser } from "../../shared/models/admin-user.model";
import { ApiError } from "../../shared/models/api-error.model";
import { LbTitle } from "../../shared/services/title.service";
import { PaginatedResponse } from "../../shared/models/paginated-response.model";

@Component({
    selector: "app-admin-users",
    templateUrl: "./users.component.html",
    styleUrls: ["./users.component.scss"]
})
export class UsersComponent extends Subscribing implements OnInit {
    public displayedColumns = [
        "FirstName",
        "LastName",
        "Email",
        "Username",
        "action"
    ];
    public dataSource = new MatTableDataSource<AdminUser>();

    public resultsLength: number = 0;
    public isLoadingResults: boolean = true;
    public loadingError: string = null;
    public limit: number = 10;

    public firstNameControl: UntypedFormControl = new UntypedFormControl();
    public lastNameControl: UntypedFormControl = new UntypedFormControl();
    public emailControl: UntypedFormControl = new UntypedFormControl();

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild("firstNameElm")
    firstNameElm: ElementRef;

    private userType: string;

    constructor(
        private apiService: ApiService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private userService: UserService,
        private dialog: MatDialog,
        titleService: LbTitle,
        private router: Router
    ) {
        super();

        titleService.setTitleFromKeywords(["Clients", "Admin"]);

        this.subscriptions.push(
            this.userService.currentUser.subscribe((user: User) => {
                this.userType = user.userType;
            })
        );

        iconRegistry.addSvgIcon(
            "x",
            sanitizer.bypassSecurityTrustResourceUrl("assets/icons/x.svg")
        );
        iconRegistry.addSvgIcon(
            "activate",
            sanitizer.bypassSecurityTrustResourceUrl(
                "assets/icons/user-plus.svg"
            )
        );
        iconRegistry.addSvgIcon(
            "deactivate",
            sanitizer.bypassSecurityTrustResourceUrl(
                "assets/icons/user-minus.svg"
            )
        );
        iconRegistry.addSvgIcon(
            "impersonate",
            sanitizer.bypassSecurityTrustResourceUrl("assets/icons/log-in.svg")
        );
    }

    ngOnInit() {
        // If the user changes the sort order, reset back to the first page.
        this.subscriptions.push(
            this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0))
        );
        this.sort.sort(<MatSortable>{
            id: "Email",
            start: "asc"
        });

        this.subscriptions.push(
            merge(
                this.sort.sortChange,
                this.paginator.page,
                this.firstNameControl.valueChanges.pipe(debounceTime(800)).pipe(
                    map(val => {
                        this.paginator.firstPage();

                        return val;
                    })
                ),
                this.lastNameControl.valueChanges.pipe(debounceTime(800)).pipe(
                    map(val => {
                        this.paginator.firstPage();

                        return val;
                    })
                ),
                this.emailControl.valueChanges.pipe(debounceTime(800)).pipe(
                    map(val => {
                        this.paginator.firstPage();

                        return val;
                    })
                )
            )
                .pipe(
                    startWith({}),
                    switchMap(() => {
                        this.isLoadingResults = true;
                        return this.apiService.get("/admin/user", {
                            sort: this.sort.active,
                            sortDirection: this.sort.direction,
                            limit: this.limit.toString(),
                            offset: (
                                this.paginator.pageIndex * this.limit
                            ).toString(),
                            first: this.firstNameControl.value
                                ? this.firstNameControl.value.trim()
                                : "",
                            last: this.lastNameControl.value
                                ? this.lastNameControl.value.trim()
                                : "",
                            email: this.emailControl.value
                                ? this.emailControl.value.trim()
                                : "",
                            userType: ROLE_CLIENT
                        });
                    }),
                    map((data: PaginatedResponse<AdminUser>) => {
                        this.isLoadingResults = false;
                        this.resultsLength = data.count;

                        return data.results;
                    }),
                    catchError((err: ApiError) => {
                        this.isLoadingResults = false;

                        if (err.timeout) {
                            this.loadingError =
                                "The server did not respond in time while trying to retrieve messages. Please check your connection and refresh the page.";
                        } else {
                            this.loadingError =
                                "An occurred while trying to retrieve messages. Please try again.";
                        }

                        return of([]);
                    })
                )
                .subscribe(
                    (results: Array<AdminUser>) =>
                        (this.dataSource.data = results)
                )
        );
    }

    isAdmin() {
        return this.userType === "admin";
    }

    impersonate(row: AdminUser, elm: HTMLButtonElement) {
        const impersonateBtns: NodeListOf<
            HTMLButtonElement
        > = document.querySelectorAll(".impersonate-button");
        elm.classList.add("is-loading");
        [].forEach.call(impersonateBtns, elm => (elm.disabled = true));

        this.subscriptions.push(
            this.userService.currentUser.subscribe(user => {
                if (user.userId == row.userId) {
                    elm.classList.remove("is-loading");
                    [].forEach.call(
                        impersonateBtns,
                        (elm: HTMLButtonElement) => (elm.disabled = false)
                    );

                    this.router.navigateByUrl("/dashboard");
                }
            })
        );

        this.subscriptions.push(
            this.userService.impersonate(row.userId).subscribe({
                error: (err: ApiError) => {
                    elm.classList.remove("is-loading");
                    [].forEach.call(
                        impersonateBtns,
                        (elm: HTMLButtonElement) => (elm.disabled = false)
                    );

                    let errorMessage: string;

                    if (err.timeout) {
                        errorMessage =
                            "The server did not respond in time while trying to impersonate this user. Please check your connection and refresh the page.";
                    } else {
                        errorMessage =
                            "An occurred while trying to impersonate this user. Please try again.";
                    }

                    this.dialog.open(AlertDialogComponent, {
                        width: "450px",
                        data: {
                            title: "Impersonation Error",
                            message: errorMessage
                        }
                    });
                }
            })
        );
    }

    activate(row: AdminUser) {
        this.toggleActivation(row.userId, true);
    }

    deactivate(row: AdminUser) {
        this.toggleActivation(row.userId, false);
    }

    toggleActivation(userId: number, active: boolean) {
        this.subscriptions.push(
            this.apiService
                .patch("/admin/user/" + String(userId), { active })
                .subscribe({
                    next: _ => {
                        const dialog = this.dialog.open(AlertDialogComponent, {
                            width: "300px",
                            data: {
                                title: "Success!",
                                message:
                                    "User " +
                                    (active ? "activated" : "deactivated") +
                                    " successfully."
                            }
                        });

                        this.subscriptions.push(
                            dialog.afterClosed().subscribe(_ => {
                                this.dataSource.data = this.dataSource.data.map(
                                    (user: AdminUser) => {
                                        if (user.userId == userId) {
                                            user.active = active;
                                        }

                                        return user;
                                    }
                                );
                            })
                        );
                    },
                    error: () => {
                        this.dialog.open(AlertDialogComponent, {
                            width: "300px",
                            data: {
                                title: "Error!",
                                message:
                                    "An error occurred while trying to " +
                                    (active ? "activate" : "deactivate") +
                                    " this user. Please refresh the page and try again."
                            }
                        });
                    }
                })
        );
    }

    clearSearch() {
        this.firstNameControl.setValue("");
        this.lastNameControl.setValue("");
        this.emailControl.setValue("");

        this.firstNameElm.nativeElement.focus();
    }
}
