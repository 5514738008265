import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class UpdateCandidateListService {

    private populateList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    update$: Observable<boolean> = this.populateList.asObservable();

    updateBoolean(changed: boolean) {
        this.populateList.next(changed);
    }
}
