<div>
    <div *ngIf="candidate.interviewRound === 0 && !candidate.nextInterview">
        <p class="info">You have no upcoming interviews with this candidate.</p>
    </div>
    <div>
        <div *ngIf="!futureInterview">
            <span class="info" *ngIf="candidate.interviewRound === 1">You've had <span class="bold">one</span> round of
                interviews with this candidate.</span>
            <span class="info" *ngIf="candidate.interviewRound === 2">You've had <span class="bold">two</span> rounds of
                interviews with this candidate.</span>
            <span class="info" *ngIf="candidate.interviewRound === 3">You've had <span class="bold">three</span> rounds
                of interviews with this candidate.</span>
            <span class="info" *ngIf="candidate.interviewRound === 4">You've had <span class="bold">four</span> rounds
                of interviews with this candidate.</span>
        </div>
        <div *ngIf="futureInterview">
            <div class="date">
                <p class="info" *ngIf="candidate.interviewRound == 1">Your first round of interviews with this candidate
                    is:</p>
                <p class="info" *ngIf="candidate.interviewRound == 2">Your second round of interviews with this
                    candidate is:</p>
                <p class="info" *ngIf="candidate.interviewRound == 3">Your third round of interviews with this candidate
                    is:</p>
                    <p class="info" *ngIf="candidate.interviewRound == 4">Your fourth round of interviews with this candidate
                        is:</p>
                <div class="next-interview" [class.justify-end]="candidate.interviewRescheduledFlag">
                    <div class="details-container">
                        <div class="time" *ngIf="!candidate.interviewRescheduledFlag">
                            <span class='icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                    fill="none" stroke="#1c2b39" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-clock">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <polyline points="12 6 12 12 16 14"></polyline>
                                </svg>
                            </span>
                            <span class="bold">
                                {{ candidate.nextInterview | date: 'MMM d, y h:mm a' }}
                                {{candidate.nextInterviewTimeZone}}
                            </span>
                        </div>
                        <div class="location" *ngIf="interviewType === 'phone' && !candidate.interviewRescheduledFlag">
                            <span class='icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                    fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-phone">
                                    <path
                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                    </path>
                                </svg>
                            </span>
                            <span class="bold">
                                By Phone
                            </span>
                        </div>
                        <div class="location" *ngIf="interviewType === 'person' && !candidate.interviewRescheduledFlag">
                            <span class='icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                    fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-eye">
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                            </span>
                            <span class="bold">
                                In Person
                            </span>
                        </div>
                        <div [ngClass]="{'location': true, 'clickable': candidate.interviewUrl}" class="location" *ngIf="interviewType === 'video' && !candidate.interviewRescheduledFlag" (click)="candidate.interviewUrl && openInterviewDialog(candidate)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-video margin-right">
                                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                                <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
                            </svg>
                            <span class="bold" [ngClass]="{underlined: candidate.interviewUrl}">Video Conference {{candidate.interviewIsToday? '(Today)' : ''}}</span>
                        </div>

                    </div>
                    <div class="details-container interviewer-details">
                        <div class="time">
                            <span class='icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                    fill="none" stroke="#1c2b39" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-clock">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                            </span>
                            <span class="bold">
                                Interviewer: {{ candidate.interviewWith }}
                            </span>
                        </div>
                    </div>

                    <div class="reschedule-container" *ngIf="!candidate.interviewRescheduledFlag">
                        <p (click)="openRescheduleInterviewDialog()">RESCHEDULE</p>
                    </div>
                    <div class="reschedule-container" *ngIf="candidate.interviewRescheduledFlag">
                        <p (click)="openRescheduledAlert()">RESCHEDULED <span>(PENDING)</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
