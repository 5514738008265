<div class="settings-container" [class.overlay]="showOverlay">
    <div class="background-container">
        <div class="content">
            <div class="account-settings-container scrollable" *ngIf="activePanel == 'account'">
                <p class="title">My Account</p>
                <div class="was-edited-rows rows" *ngIf="isImpersonation && currentUser.userChanged">
                    <div class="row">
                        <span class="success">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check">
                                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                <circle cx="8.5" cy="7" r="4"></circle>
                                <polyline points="17 11 19 13 23 9"></polyline>
                            </svg>
                            This information was last updated by the client on {{currentUser.updated | date:'MMM d, yyyy'}}
                        </span>
                    </div>
                </div>
                <div class="rows">
                    <form [formGroup]="form">
                        <div class="row">
                            <span *ngIf="successUpdate" class="success">Success! Your settings have been updated.</span>
                            <div class="show-field">
                                <div class="field">
                                    <p class="attribute">Username</p>
                                    <p class="candidate-info" *ngIf="!editForm">{{ currentUser.username }}</p>
                                    <div class="field form" *ngIf="editForm">
                                        <input type="text" formControlName="username" class="form-control candidate-info" [class.invalid-entry]="username.invalid && username.dirty">
                                        <p class="warning">
                                            <span class="hide" [class.show]="username.invalid && username.dirty">
                                                {{ username.errors && username.errors['serverError'] || "Username must be at least 8 characters and no more than 20" }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div *ngIf="!isLimitedImpersonation">
                                    <p *ngIf="!editForm && currentUserRole === clientRole" class="edit" (click)="editFormChange()">EDIT</p>
                                    <p *ngIf="editForm" class="edit" (click)="editFormChange()">CANCEL</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="show-field">
                                <div class="name-container">
                                    <p class="attribute">Name</p>
                                    <p class="candidate-info" *ngIf="!editForm">{{ currentUser.firstName }} {{
                                        currentUser.lastName }}</p>
                                    <div class="field form" *ngIf="editForm">
                                        <input type="text" formControlName="firstName" class="form-control candidate-info name-input" [class.invalid-entry]="firstName.invalid && firstName.dirty">
                                        <input type="text" formControlName="lastName" class="form-control candidate-info name-input" [class.invalid-entry]="lastName.invalid && lastName.dirty">
                                        <div class="subtitle">
                                            <p>First</p>
                                            <p>Last</p>
                                        </div>
                                        <div class="subtitle-mobile">
                                            <p>First and last name</p>
                                        </div>
                                        <p class="warning">
                                            <span class="hide" [class.show]="(firstName.invalid) || (lastName.invalid)">
                                                {{ firstName.errors && firstName.errors['serverError'] || lastName.errors && lastName.errors['serverError'] || "First and last
                                                name are required and must be only letters." }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="show-field">
                                <div class="field">
                                    <p class="attribute">Phone</p>
                                    <div *ngIf="editForm" class="radio-container">
                                        <div class="radio" (click)="phoneSelect($event,'us')">
                                            <label>
                                                <input type="radio" name="location" [checked]="phonePrimary === 'us'">
                                                US or Canada
                                            </label>
                                        </div>
                                        <div class="radio" (click)="phoneSelect($event,'international')">
                                            <label>
                                                <input type="radio" name="location" [checked]="phonePrimary === 'international'">
                                                International
                                            </label>
                                        </div>
                                    </div>
                                    <p class="candidate-info" *ngIf="!editForm && !showExtension && phonePrimary === 'us'">{{
                                        formatPhoneNumber(currentUser.phoneOffice) }}
                                    </p>
                                    <p class="candidate-info" *ngIf="!editForm && !showExtension && phonePrimary === 'international'">{{
                                        currentUser.phoneOffice }}
                                    </p>
                                    <p class="candidate-info" *ngIf="!editForm && showExtension && phonePrimary === 'international'">
                                        {{currentUser.phoneOffice}} x{{currentUser.phoneExt}}
                                    </p>
                                    <p class="candidate-info" *ngIf="!editForm && showExtension && phonePrimary === 'us'">{{
                                        formatPhoneNumber(currentUser.phoneOffice) }} x{{currentUser.phoneExt}}
                                    </p>
                                    <div class="field form" *ngIf="editForm">
                                        <input *ngIf="phonePrimary === 'us'" type="text" formControlName="phone" class="form-control candidate-info name-input" [imask]="{mask: '000-000-0000'}" [class.invalid-entry]="phone.invalid && phone.dirty">
                                        <input *ngIf="phonePrimary === 'international'" type="text" formControlName="phoneInternational" (keydown)="_keydown($event, false)" class="form-control candidate-info international-input" [class.invalid-entry]="phoneInternational.invalid && phoneInternational.dirty">
                                        <input *ngIf="phonePrimary === 'us'" type="text" formControlName="phoneExtUS" class="form-control candidate-info name-input" [class.invalid-entry]="phoneExt.invalid && phoneExt.dirty">
                                        <input *ngIf="phonePrimary === 'international'" type="text" formControlName="phoneExtInternational" class="form-control candidate-info name-input" [class.invalid-entry]="phoneExt.invalid && phoneExt.dirty">
                                        <div class="subtitle">
                                            <p>Phone</p>
                                            <p>Extension</p>
                                        </div>
                                        <div class="subtitle-mobile">
                                            <p>Phone number and extension</p>
                                        </div>
                                        <p class="warning">
                                            <span class="hide" [class.show]="phone.invalid && phone.dirty">
                                                {{ phone.errors && phone.errors['serverError'] || "Phone number must be in valid format and cannot be deleted." }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="show-field">
                                <div class="field">
                                    <p class="attribute">Mobile</p>
                                    <div *ngIf="editForm" class="radio-container">
                                        <div class="radio" (click)="phoneSelectMobile($event, 'us')">
                                            <label>
                                                <input type="radio" name="location-mobile" [checked]="phoneMobile === 'us'">
                                                US or Canada
                                            </label>
                                        </div>
                                        <div class="radio" (click)="phoneSelectMobile($event, 'international')">
                                            <label>
                                                <input type="radio" name="location-mobile" [checked]="phoneMobile === 'international'">
                                                International
                                            </label>
                                        </div>
                                    </div>
                                    <p class="candidate-info" *ngIf="!editForm && phoneMobile === 'us'">{{ formatPhoneNumber(currentUser.phoneCell) }} </p>
                                    <p class="candidate-info" *ngIf="!editForm && phoneMobile === 'international'">{{ currentUser.phoneCell }}</p>
                                    <div class="field form" *ngIf="editForm">
                                        <input type="text" formControlName="mobile" *ngIf="phoneMobile === 'us'" class="form-control candidate-info" [imask]="{mask: '000-000-0000'}"[class.invalid-entry]="mobile.invalid && mobile.dirty">
                                        <input *ngIf="phoneMobile === 'international'" type="text" formControlName="phoneInternationalMobile" (keydown)="_keydown($event, true)" class="form-control candidate-info international-input-mobile" [class.invalid-entry]="phoneInternationalMobile.invalid && phoneInternationalMobile.dirty">
                                        <p class="warning">
                                            <span class="hide" [class.show]="mobile.invalid && mobile.dirty">
                                                {{ mobile.errors && mobile.errors['serverError'] || "Must be in valid format" }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="save" *ngIf="editForm">
                                <button aria-label="Save" [class.invalid]="!form.valid" (click)="submitPreferences()">SAVE</button>
                                <p class="warning" *ngIf="form.errors && form.errors['serverError']">
                                    <span>
                                        An unknown error has occurred. Please try again later.
                                    </span>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="password-field rows">
                    <div class="row">
                        <span *ngIf="successPasswordUpdate" class="success">Success! Your password has been updated.</span>
                        <div class="show-field">
                            <div class="field">
                                <p class="attribute password">Password</p>
                            </div>
                            <div *ngIf="!isLimitedImpersonation">
                                <p *ngIf="!editPassword && currentUserRole === clientRole" class="edit" (click)="passwordChange()">EDIT</p>
                                <p *ngIf="editPassword" class="edit" (click)="passwordChange()">CLOSE</p>
                            </div>
                        </div>
                        <div *ngIf="editPassword" class="form">
                            <form [formGroup]="passwordForm" class="password-form">
                                <div class="fields">
                                    <div class="field">
                                        <p>Current Password</p>
                                        <input type="password" formControlName="currentPassword">
                                        <p class="warning">
                                            <span class="hide" [class.show]="currentPassword.touched && currentPassword.invalid">
                                                {{ "Current Password is required." }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="field">
                                        <p>New Password</p>
                                        <input type="password" formControlName="password">
                                    </div>
                                    <div class="field">
                                        <p>Confirm New Password</p>
                                        <input type="password" formControlName="passwordConfirmation">
                                    </div>
                                </div>
                                <div class="form-card">
                                    <div class="requirement-text">
                                        <h6>Password Requirements</h6>
                                        <div class="checkbox">
                                            <label>
                                                <span *ngIf="!eightChar">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" class="feather feather-x">
                                                        <rect x=".3" y=".3" width="11.4" height="11.4" rx="5.7" transform="translate(.6 .8)" stroke="#C8C8C8" stroke-width=".6" fill="none" fill-rule="evenodd" opacity=".88"/>
                                                    </svg>
                                                </span>
                                                <span *ngIf="eightChar">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                        <g transform="translate(.6 .6)" fill="none" fill-rule="evenodd">
                                                            <rect fill="#3C9E87" opacity=".88" width="12" height="12" rx="6" class="feather feather-x"/>
                                                            <path stroke="#131E27" stroke-width="1.3333" stroke-linecap="round" stroke-linejoin="round" d="M8.6667 4.6667 5 8 3.3333 6.4848"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span></span> At least 8 characters
                                            </label>
                                        </div>
                                        <div class="checkbox">
                                            <label>
                                                <span *ngIf="!oneUpperCase">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" class="feather feather-x">
                                                        <rect x=".3" y=".3" width="11.4" height="11.4" rx="5.7" transform="translate(.6 .8)" stroke="#C8C8C8" stroke-width=".6" fill="none" fill-rule="evenodd" opacity=".88"/>
                                                    </svg>
                                                </span>
                                                <span *ngIf="oneUpperCase">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                        <g transform="translate(.6 .6)" fill="none" fill-rule="evenodd">
                                                            <rect fill="#3C9E87" opacity=".88" width="12" height="12" rx="6" class="feather feather-x"/>
                                                            <path stroke="#131E27" stroke-width="1.3333" stroke-linecap="round" stroke-linejoin="round" d="M8.6667 4.6667 5 8 3.3333 6.4848"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span></span> At least 1 upper-case character
                                            </label>
                                        </div>
                                        <div class="checkbox">
                                            <label>
                                                <span *ngIf="!oneLowerCase">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" class="feather feather-x">
                                                        <rect x=".3" y=".3" width="11.4" height="11.4" rx="5.7" transform="translate(.6 .8)" stroke="#C8C8C8" stroke-width=".6" fill="none" fill-rule="evenodd" opacity=".88"/>
                                                    </svg>
                                                </span>
                                                <span *ngIf="oneLowerCase">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                        <g transform="translate(.6 .6)" fill="none" fill-rule="evenodd">
                                                            <rect fill="#3C9E87" opacity=".88" width="12" height="12" rx="6" class="feather feather-x"/>
                                                            <path stroke="#131E27" stroke-width="1.3333" stroke-linecap="round" stroke-linejoin="round" d="M8.6667 4.6667 5 8 3.3333 6.4848"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span></span> At least 1 lower-case character
                                            </label>
                                        </div>
                                        <div class="checkbox">
                                            <label>
                                                <span *ngIf="!numeral">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" class="feather feather-x">
                                                        <rect x=".3" y=".3" width="11.4" height="11.4" rx="5.7" transform="translate(.6 .8)" stroke="#C8C8C8" stroke-width=".6" fill="none" fill-rule="evenodd" opacity=".88"/>
                                                    </svg>
                                                </span>
                                                <span *ngIf="numeral">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                        <g transform="translate(.6 .6)" fill="none" fill-rule="evenodd">
                                                            <rect fill="#3C9E87" opacity=".88" width="12" height="12" rx="6" class="feather feather-x"/>
                                                            <path stroke="#131E27" stroke-width="1.3333" stroke-linecap="round" stroke-linejoin="round" d="M8.6667 4.6667 5 8 3.3333 6.4848"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span></span> At least 1 numeral
                                            </label>
                                        </div>
                                        <div class="checkbox">
                                            <label>
                                                <span *ngIf="!passwordMatch">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" class="feather feather-x">
                                                        <rect x=".3" y=".3" width="11.4" height="11.4" rx="5.7" transform="translate(.6 .8)" stroke="#C8C8C8" stroke-width=".6" fill="none" fill-rule="evenodd" opacity=".88"/>
                                                    </svg>
                                                </span>
                                                <span *ngIf="passwordMatch">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                        <g transform="translate(.6 .6)" fill="none" fill-rule="evenodd">
                                                            <rect fill="#3C9E87" opacity=".88" width="12" height="12" rx="6" class="feather feather-x"/>
                                                            <path stroke="#131E27" stroke-width="1.3333" stroke-linecap="round" stroke-linejoin="round" d="M8.6667 4.6667 5 8 3.3333 6.4848"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span></span> New Passwords Must Match
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="row">
                                <div class="save" *ngIf="editPassword">
                                    <span *ngIf="!validCurrentPassword" class="invalid-current-password">Your current password is incorrect.</span>
                                    <button aria-label="Save" [class.invalid]="!passwordForm.valid" [class.no-match]="!passwordMatch"
                                            (click)="submitPasswordChange()">SAVE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="email-field rows">
                    <div class="show-field">
                        <div class="field">
                            <p class="attribute">Email</p>
                            <p class="candidate-info">{{ currentUser.email }}</p>
                            <p class="disclaimer">To update email address, please contact us.</p>
                        </div>
                    </div>
                </div>
                <div class="version-number">
                    <p>Version {{versionNumber}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
