import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, Form, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from '../../services/api.service';
import { JobService } from '../../services/job-orders.service';
import { JobOrderCandidate } from '../../models/job-order-candidate.model';
import { ApiError } from '../../models/api-error.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UserService } from '../../services';
import { AlertDialogComponent } from "../../../shared/components/alert-dialog/alert-dialog.component";
import { NetworkErrorSnackbarService } from "../../../shared/services/network-error-snackbar.service";
import { RescheduledInterviewService } from "../../../shared/services/rescheduled-interview.service";

export const CLOSE_SUCCESS = 'SUCCESS';


@Component({
    selector: 'app-reschedule-interview-dialog',
    templateUrl: './reschedule-interview-dialog.component.html',
    styleUrls: ['./reschedule-interview-dialog.component.scss']
})
export class RescheduleInterviewDialogComponent {

    public form: UntypedFormGroup;
    public isSubmitting: boolean = false;
    public formError: string;
    public isLimitedImpersonation: boolean = false;
    public countMax: number = 4000;
    public typingLength = 0;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<RescheduleInterviewDialogComponent>,
        private jobOrdersService: JobService,
        private dialog: MatDialog,
        private userService: UserService,
        private networkErrorSnackbarService: NetworkErrorSnackbarService,
        private rescheduledInterviewService: RescheduledInterviewService
    ) {
        this.form = this.fb.group({
            availability: ['', Validators.compose([
                Validators.required,
            ])],
            details: [''],
            jobCandidateId: [this.data.jobCandidateId, Validators.compose([
                Validators.required,
            ])],
            officeId: [this.data.officeId, Validators.compose([
                Validators.required,
            ])],
            jobId: [this.data.jobId, Validators.compose([
                Validators.required,
            ])],
            statusId: [this.data.statusId, Validators.compose([
                Validators.required,
            ])],
            contactMethod: [null, Validators.compose([
                Validators.required,
            ])],
        });

        this.userService.isLimitedImpersonation.subscribe(isLimited => {
            this.isLimitedImpersonation = isLimited;
        });

        this.form.controls['availability'].valueChanges.subscribe(() => {
            this.lengthCounter();
        });

        this.form.controls['details'].valueChanges.subscribe(() => {
            this.lengthCounter();
        });
    }

    lengthCounter() {
        this.typingLength = this.form.controls['availability'].value.length + this.form.controls['details'].value.length;
        this.countMax = 4000 - this.typingLength;
    }

    cancel() {
        this.dialogRef.close();
    }

    submit() {
        if (this.form.valid && !this.isSubmitting && this.typingLength <= 4000) {
            this.isSubmitting = true;
            let details =
                "Reschedule Availability: " +
                this.form.value["availability"] +
                "<br />Contact Method: " +
                this.form.value["contactMethod"] +
                "<br />Reason for Reschedule: " +
                this.form.value["details"];
            let params = {
                'statusId': this.form.value['statusId'],
                'details': details,
                'availability': this.form.value['availability'],
                'interviewRescheduledFlag': true
            };
            this.jobOrdersService.patchJobCandidate(
                this.form.value['officeId'],
                this.form.value['jobId'],
                this.form.value['jobCandidateId'],
                params)
                .subscribe(/* TODO handle timeout */
                    _ => {
                        this.dialogRef.close(CLOSE_SUCCESS)
                        this.rescheduledInterviewService.updateBoolean(true);
                        this.dialog.open(AlertDialogComponent, {
                            width: "500px",
                            data: {
                                rescheduleConfirmMessage: true,
                                buttonText: 'Got It'
                            }
                        });
                    },
                    (err: ApiError) => {
                        this.isSubmitting = false;
                        if (err.statusCode === 400) {
                            this.formError = 'Error! Please close the dialog and try again.';
                        } else {
                            this.networkErrorSnackbarService.networkError(err,'')
                        }
                    }
                );
        }
    }
}
