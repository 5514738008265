<div class="inner-container">
    <div class="nav" [class.dark-bg]="fixedNav" [class.shared]="onSharePage">
        <a class="logo-link" routerLink="/dashboard">
            <img alt="Creative Circle Logo" src="../../../assets/images/logo-updated.svg" />
        </a>

        <div class="right-side-nav">
            <a href="https://www.creativecircle.com/roles-we-place/" (click)="linkOutClick('ROLES WE PLACE', 'https://www.creativecircle.com/roles-we-place/')" target="_blank" rel="noopener noreferrer">
                ROLES WE PLACE
            </a>

            <form target="_blank" [formGroup]="newForm" #form method="POST" action="{{ timecardLoginUrl }}">
                <input type="text" formControlName="token" class="hidden" name="token">
                <input type="text" formControlName="email" class="hidden" name="email">
                <input type="submit" (click)="linkOutClick('TIMECARD LOGIN', timecardLoginUrl); form.submit()" class="submit-link" value="TIMECARD LOGIN">
            </form>

            <a (click)="openRequestTalentDialog()" class="requestTalent" rel="noopener noreferrer">
                REQUEST TALENT
            </a>

            <div class="account-dropdown" (clickOutside)="closeMyAccount()">
                <div class="text-container">
                    <p (click)="toggleDropdown()" class="main-header" [ngClass]="{ impersonating: isImpersonation }">
                        {{ isImpersonation ? "IMPERSONATING" : "MY ACCOUNT" }}
                    </p>
                    <span (click)="openMyAccount()" *ngIf="!showDropdown" class="down-chevron">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                    </span>
                    <span (click)="closeMyAccount()" *ngIf="showDropdown" class="up-chevron">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg>
                    </span>
                </div>
                <div class="account-content" [ngClass]="showDropdown ? 'show' : 'hide'">
                    <div class="triangle"></div>
                    <p class="name">{{ currentUser.firstName }} {{ currentUser.lastName }}</p>
                    <p class="email">{{ currentUser.email }}</p>
                    <hr />
                    <div class="settings" *ngIf="!isImpersonation">
                        <a routerLink="/settings">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#1C2B39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings">
                                <circle cx="12" cy="12" r="3"></circle>
                                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                            </svg>
                            <p class="settings">
                                Settings
                            </p>
                        </a>
                    </div>

                    <div class="help" *ngIf="!isImpersonation">
                        <a href="{{ helpUrl }}" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#1C2B39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-help-circle">
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                <line x1="12" y1="17" x2="12" y2="17"></line>
                            </svg>
                            <p class="help">
                                Help
                            </p>
                        </a>
                    </div>
                    <div class="sign-out" (click)="!isImpersonation ? logout() : backToAdmin()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#1C2B39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out">
                            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                            <polyline points="16 17 21 12 16 7"></polyline>
                            <line x1="21" y1="12" x2="9" y2="12"></line>
                        </svg>
                        <p class="sign-out">
                            {{ isImpersonation ? "Back To Admin" : "Sign Out" }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="fixed-nav-hamburger">
    <div [class.expanded-hamburger]="expandedHamburger" (click)="toggleHamburger()" class="grid-container" #dropdown>
        <img *ngIf="currentRoute == '/' || currentRoute.includes('/dashboard')" class="dropdown-icon" alt="Grid" src="../../../assets/icons/grid.svg" />
        <img *ngIf="currentRoute.includes('/jobs')" class="dropdown-icon" alt="Briefcase" src="../../../assets/icons/briefcase.svg" />
        <img *ngIf="currentRoute.includes('/candidates')" class="dropdown-icon" alt="Users" src="../../../assets/icons/users.svg" />
        <img *ngIf="currentRoute.includes('/settings')" class="dropdown-icon" alt="Settings" src="../../../assets/icons/settings.svg" />
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="#131e27" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
            <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
    </div>
    <div class="logo-container">
        <img alt="Logo" src="assets/images/logo-mobile.svg" />
    </div>
</div>
<div [class.expanded-dropdown]="expandedHamburger" class="dropdown">
    <div class="oval-background">
        <div class="content-container">
            <div>
                <a routerLink="/dashboard" routerLinkActive="active-route">
                    <div class="row">
                        <div class="svg-container" [class.selected]="currentRoute == '/dashboard'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid">
                                <rect x="3" y="3" width="7" height="7"></rect>
                                <rect x="14" y="3" width="7" height="7"></rect>
                                <rect x="14" y="14" width="7" height="7"></rect>
                                <rect x="3" y="14" width="7" height="7"></rect>
                            </svg>
                        </div>
                        <p>Dashboard</p>
                    </div>
                </a>
                <a routerLink="/jobs" routerLinkActive="active-route">
                    <div class="row">
                        <div class="svg-container" [class.selected]="currentRoute == '/jobs'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase">
                                <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                                <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                            </svg>
                        </div>
                        <p>Jobs</p>
                    </div>
                </a>
                <a routerLink="/candidates" routerLinkActive="active-route">
                    <div class="row">
                        <div class="svg-container" [class.selected]="currentRoute == '/candidates'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users">
                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                <circle cx="9" cy="7" r="4"></circle>
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                            </svg>
                        </div>
                        <p>Candidates</p>
                    </div>
                </a>
                <a routerLink="/settings" routerLinkActive="active-route">
                    <div class="row">
                        <div class="svg-container" [class.selected]="currentRoute == '/settings'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings">
                                <circle cx="12" cy="12" r="3"></circle>
                                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                            </svg>
                        </div>
                        <p>Settings</p>
                    </div>
                </a>
            </div>
            <div>
                <a class="link" href="https://www.creativecircle.com/roles-we-place/')" (click)="linkOutClick('ROLES WE PLACE', 'https://www.creativecircle.com/roles-we-place/')" target="_blank" rel="noopener">
                    Roles We Place
                </a>
                <form target="_blank" [formGroup]="newForm" #form method="POST" action="{{ timecardLoginUrl }}">
                    <input type="text" formControlName="token" class="hidden" name="token">
                    <input type="text" formControlName="email" class="hidden" name="email">
                    <input type="submit" (click)="linkOutClick('TIMECARD LOGIN', timecardLoginUrl); form.submit()" class="submit-link" value="Timecard Login">
                </form>
                <a class="link request-talent" target="_blank" (click)="openRequestTalentDialog(); toggleHamburger()" rel="noopener">
                    Request Talent
                </a>
                <a class="link" href="{{ helpUrl }}" target="_blank" rel="noopener">
                    Help
                </a>
                <a (click)="!isImpersonation ? logout() : backToAdmin()" class="link" [ngClass]="{ impersonating: isImpersonation }">
                    {{ isImpersonation ? "Back To Admin" : "Sign Out" }}
                </a>
            </div>
        </div>
        <div class="close-hamburger">
            <button aria-label="Close Menu" (click)="toggleHamburger()">
                <span>
                    CLOSE MENU
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24" fill="none" stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </button>
        </div>
    </div>
</div>
