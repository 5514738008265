import { ILBTemplateConfiguration } from './template-configuration.model';
import { SafeHtml } from '@angular/platform-browser';

export class SiteTemplateConfiguration implements ILBTemplateConfiguration {
    public Type: string = "Site";
    public Subject: SafeHtml = null;

    constructor(
        public DisplayName: string,
        public MainTemplateKey: string,
        public Variables: Array<string>,
        public Updated: Date = null,
        public UpdatedBy: string = null,
    ) {

    }
}