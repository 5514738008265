<div class="loading-error-container" *ngIf="apiError" [ngClass]="{'shade': showShade, 'light': lightMode}">
    <svg width="41" height="39" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 10)" stroke="#b3e1ea" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                <rect width="30" height="21.368" rx="3" />
                <path d="M9 27.474h12M15 21.368v6.106" />
            </g>
            <g transform="translate(21)">
                <circle fill="#b2282d" cx="10" cy="10" r="10" />
                <path d="M13 7l-6 6M7 7l6 6" stroke="#FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </g>
    </svg>
    <p class="large-text">
        <span *ngIf="!apiError.timeout && !apiError.offline">
            Sorry, a server error occurred.
        </span>
        <span *ngIf="apiError.offline">
            You appear to be offline.
        </span>
        <span *ngIf="apiError.timeout && !apiError.offline">
            Sorry, a timeout error has occurred.
        </span>
    </p>
    <p class="small-text">
        <span *ngIf="apiError.offline && !apiError.timeout">
            Please check your network connection.
        </span>
        <span *ngIf="apiError.timeout && !apiError.offline">
            Your content has taken too long to load. Please check your network connection.
        </span>
        <span *ngIf="!apiError.timeout && !apiError.offline && apiError.requestId">
            Please try again. If this continues happening, please contact Creative Circle with reference {{ apiError.requestId }}.
        </span>
        <span *ngIf="!apiError.timeout && !apiError.offline && !apiError.requestId">
            Please try again. If this continues happening, please contact Creative Circle.
        </span>
    </p>
    <button aria-label="Retry" mat-button (click)="retry()">Retry</button>
</div>
