import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { JobService } from '../../shared/services';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<any> {
    constructor(
        private dialog: MatDialog,
        private router: Router,
        private jobOrdersService: JobService
    ) {
    }

    canDeactivate(
        component: any,
        route: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): boolean {
        if (component.formChanged) {
            const dialog = this.dialog.open(ConfirmDialogComponent, {
                width: '420px',
                data: {
                    title: 'Are you sure?',
                    message: 'If you leave this area, your changes will be lost.'
                }
            });

            dialog.afterClosed().subscribe(res => {
                if (res) {
                    if (component.hasOwnProperty('formChanged')) {
                        component.formChanged = false;
                        this.jobOrdersService.formChangedSubject.next(false);
                    }

                    this.router.navigateByUrl(nextState.url);
                }
            });

            return false;
        }

        return true;
    }
}
