import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { JobService } from '../../services/job-orders.service';
import { ViewSDKClient } from '../../services/view-sdk.service';

@Component({
    selector: 'app-document-preview-dialog',
    templateUrl: './document-preview-dialog.component.html',
    styleUrls: ['./document-preview-dialog.component.scss']
})
export class DocumentPreviewDialogComponent {
    public url: SafeResourceUrl;
    public isImage: boolean = false;
    public isPdf: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<DocumentPreviewDialogComponent>,
        private jobOrdersService: JobService,
        private dialog: MatDialog,
        public sanitizer: DomSanitizer,
        private viewSDKClient: ViewSDKClient
    ) {
    }

    cancel() {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.determineFileType(this.data.document.url);
    }

    determineType(type) {
        if (type == 'R' || type == 'Resume_CC' || type == 'Resume_orig') {
            return 'Resume';
        } else if (type == 'P' || type == 'Portfolio') {
            return 'Portfolio';
        } else if (type == 'Sample') {
            return 'Sample';
        } else if (type == 'Other') {
            return 'Other';
        } else {
            return 'Link';
        }
    }

    safeUrl() {
        return this.url;
    }

    determineFileType(urlFile) {
        let extensionPattern = /\.([0-9a-z]+)(?:[\?#]|$)/i;
        let extension = urlFile.match(extensionPattern)[0];
        let microsoftDocuments = [
            '.xls',
            '.doc',
            '.ppt',
            '.pptx',
            '.docx',
            '.xlsx',
            '.ppsx'
        ];
        let imageExtensions = ['.jpg', '.jpeg', '.png'];

        if (microsoftDocuments.includes(extension)) {
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
                'https://view.officeapps.live.com/op/view.aspx?src=' +
                urlFile +
                '&embedded=true'
            );
        } else if (extension == '.pdf') {
            this.isPdf = true;
            this.url = urlFile;

            this.viewSDKClient.ready().then(() => {
                /* Invoke file preview */
                this.viewSDKClient.previewFile('pdf-div', {
                    /* Pass the embed mode option here */
                    embedMode: 'SIZED_CONTAINER'
                }, urlFile);
            });
        } else if (imageExtensions.includes(extension)) {
            this.isImage = true;
            this.url = urlFile;
        } else {
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(urlFile);
        }
    }
}
