import { Injectable } from "@angular/core";
import { ApiService } from "../../shared";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SysTemplate } from "../../shared/models/sys-template.model";
import { ILBTemplateConfiguration } from "./models/template-configuration.model";
import { SiteTemplateConfiguration } from "./models/site-template-configuration.model";
import { EmailTemplateConfiguration } from "./models/email-template-configuration.model";
import { DomSanitizer } from "@angular/platform-browser";
import { PaginatedResponse } from "../../shared/models/paginated-response.model";

export class TemplateVariable {
    constructor(public Display: string, public Variable: string) {}
}

@Injectable()
export class TemplatesService {
    private templates: Array<SysTemplate>;

    public get currentTemplates(): Array<SysTemplate> {
        return this.templates;
    }

    private templateConfigurations: Array<ILBTemplateConfiguration> = [
        new SiteTemplateConfiguration(
            "Equal Opportunity Text",
            "EQUAL_OPPORTUNITY",
            []
        ),
        new SiteTemplateConfiguration("Legal Text", "LEGAL_COPY", []),
        new SiteTemplateConfiguration(
            "Email Legal Text",
            "EMAIL_LEGAL_COPY",
            []
        ),
        new SiteTemplateConfiguration(
            "Login Legal Text",
            "LOGIN_LEGAL_COPY",
            []
        ),
        new EmailTemplateConfiguration(
            "Registered Already",
            "REG__ALREADY",
            "REG__ALREADY__SUBJ",
            "REG__ALREADY__LURL",
            "REG__ALREADY__LTXT",
            []
        ),
        new EmailTemplateConfiguration(
            "Candidate Call Requested",
            "CAND_CALL_REQ",
            "CAND_CALL_REQ__SUBJ",
            "CAND_CALL_REQ__LURL",
            "CAND_CALL_REQ__LTXT",
            [
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME",
                "COMPANY NAMES",
                "NOTES"
            ]
        ),
        new EmailTemplateConfiguration(
            "Job Candidate Call Requested",
            "JCAND_CALL_REQ",
            "JCAND_CALL_REQ__SUBJ",
            "JCAND_CALL_REQ__LURL",
            "JCAND_CALL_REQ__LTXT",
            [
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME",
                "JOB ID",
                "NOTES"
            ]
        ),
        new EmailTemplateConfiguration(
            "Candidate Block Alert",
            "CAND_BLK",
            "CAND_BLK__SUBJ",
            "CAND_BLK__LURL",
            "CAND_BLK__LTXT",
            [
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME"
            ]
        ),
        new EmailTemplateConfiguration(
            "Candidate Favorite Alert",
            "CAND_FAV",
            "CAND_FAV__SUBJ",
            "CAND_FAV__LURL",
            "CAND_FAV__LTXT",
            [
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME"
            ]
        ),
        new EmailTemplateConfiguration(
            "Cannot Register",
            "REG__CANNOT",
            "REG__CANNOT__SUBJ",
            "REG__CANNOT__LURL",
            "REG__CANNOT__LTXT",
            []
        ),
        new EmailTemplateConfiguration(
            "Cannot Reset Password",
            "RESET__CANNOT",
            "RESET__CANNOT__SUBJ",
            "RESET__CANNOT__LURL",
            "RESET__CANNOT__LTXT",
            ["URL"]
        ),
        new EmailTemplateConfiguration(
            "Cannot Reset Password Must Register",
            "RESET__NOTREG",
            "RESET__NOTREG__SUBJ",
            "RESET__NOTREG__LURL",
            "RESET__NOTREG__LTXT",
            ["URL"]
        ),
        new EmailTemplateConfiguration(
            "Can Register",
            "REG__CAN",
            "REG__CAN__SUBJ",
            "REG__CAN__LURL",
            "REG__CAN__LTXT",
            ["URL"]
        ),
        new EmailTemplateConfiguration(
            "Can Reset Password",
            "RESET__CAN",
            "RESET__CAN__SUBJ",
            "RESET__CAN__LURL",
            "RESET__CAN__LTXT",
            ["URL"]
        ),
        new EmailTemplateConfiguration(
            "End Date Contraction",
            "END_DATE_CNT",
            "END_DATE_CNT__SUBJ",
            "END_DATE_CNT__LURL",
            "END_DATE_CNT__LTXT",
            [
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME",
                "CLIENT EMAIL",
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "JOB ID",
                "JOB TITLE",
                "PREVIOUS END DATE",
                "NEW END DATE",
                "NOTES"
            ]
        ),
        new EmailTemplateConfiguration(
            "End Date Extension",
            "END_DATE_EXT",
            "END_DATE_EXT__SUBJ",
            "END_DATE_EXT__LURL",
            "END_DATE_EXT__LTXT",
            [
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME",
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "JOB ID",
                "JOB TITLE",
                "PREVIOUS END DATE",
                "NEW END DATE",
                "COMPANY NAMES",
                "NOTES"
            ]
        ),
        new EmailTemplateConfiguration(
            "End Date Extension Alert",
            "ENDDATE_EXTED",
            "ENDDATE_EXTED__SUBJ",
            "ENDDATE_EXTED__LURL",
            "ENDDATE_EXTED__LTXT",
            [
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME",
                "CLIENT EMAIL",
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "JOB ID",
                "JOB TITLE",
                "PREVIOUS END DATE",
                "NEW END DATE",
                "COMPANY NAME",
                "NOTES"
            ]
        ),
        new EmailTemplateConfiguration(
            "Job Candidate Block Alert",
            "JOB_CAND_BLK",
            "JOB_CAND_BLK__SUBJ",
            "JOB_CAND_BLK__LURL",
            "JOB_CAND_BLK__LTXT",
            [
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME",
                "JOB ID",
                "JOB TITLE"
            ]
        ),
        new EmailTemplateConfiguration(
            "Job Candidate Favorite Alert",
            "JOB_CAND_FAV",
            "JOB_CAND_FAV__SUBJ",
            "JOB_CAND_FAV__LURL",
            "JOB_CAND_FAV__LTXT",
            [
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME",
                "JOB ID",
                "JOB TITLE"
            ]
        ),
        new EmailTemplateConfiguration(
            "Job Candidate Status Change",
            "JCAND_STS_CHNG",
            "JCAND_STS_CHNG__SUBJ",
            "JCAND_STS_CHNG__LURL",
            "JCAND_STS_CHNG__LTXT",
            [
                "START STATUS",
                "END STATUS",
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME",
                "JOB TITLE",
                "NOTES",
                "JOB ID"
            ]
        ),
        new EmailTemplateConfiguration(
            "More Like This",
            "MORE_LIKE_THIS",
            "MORE_LIKE_THIS__SUBJ",
            "MORE_LIKE_THIS__LURL",
            "MORE_LIKE_THIS__LTXT",
            [
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME",
                "NOTES"
            ]
        ),
        new EmailTemplateConfiguration(
            "Note Added to Candidate",
            "NEW_NOTE_CAND",
            "NEW_NOTE_CAND__SUBJ",
            "NEW_NOTE_CAND__LURL",
            "NEW_NOTE_CAND__LTXT",
            [
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME",
                "NOTES",
                "COMPANY NAMES"
            ]
        ),
        new EmailTemplateConfiguration(
            "Note Added to Job Candidate",
            "NEW_NOTE_JCAND",
            "NEW_NOTE_JCAND__SUBJ",
            "NEW_NOTE_JCAND__LURL",
            "NEW_NOTE_JCAND__LTXT",
            [
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME",
                "NOTES",
                "JOB ID",
                "JOB TITLE",
                "COMPANY NAME"
            ]
        ),
        new EmailTemplateConfiguration(
            "Performance Review Added",
            "REVIEW_ADDED",
            "REVIEW_ADDED__SUBJ",
            "REVIEW_ADDED__LURL",
            "REVIEW_ADDED__LTXT",
            [
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME",
                "CANDIDATE FAVORITED",
                "JOB ID",
                "JOB TITLE",
                "RATING",
                "NOTES"
            ]
        ),
        new EmailTemplateConfiguration(
            "Share Candidate",
            "SHARE__CAND",
            "SHARE__CAND__SUBJ",
            "SHARE__CAND__LURL",
            "SHARE__CAND__LTXT",
            [
                "MESSAGE",
                "URL",
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "SHARED FIRST NAME",
                "SHARED LAST NAME"
            ]
        ),
        new EmailTemplateConfiguration(
            "Candidate Shared Alert",
            "SHARED__CAND",
            "SHARED__CAND__SUBJ",
            "SHARED__CAND__LURL",
            "SHARED__CAND__LTXT",
            [
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME",
                "SHARE FIRST NAME",
                "SHARE LAST NAME",
                "SHARE EMAIL",
                "SHARE MESSAGE",
                "COMPANY NAMES"
            ]
        ),
        new EmailTemplateConfiguration(
            "Job Candidate Shared Alert",
            "SHARED__JOBCND",
            "SHARED__JOBCND__SUBJ",
            "SHARED__JOBCND__LURL",
            "SHARED__JOBCND__LTXT",
            [
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "CANDIDATE FIRST NAME",
                "CANDIDATE LAST NAME",
                "SHARE FIRST NAME",
                "SHARE LAST NAME",
                "SHARE EMAIL",
                "SHARE MESSAGE",
                "JOB ID",
                "JOB TITLE"
            ]
        ),
        new EmailTemplateConfiguration(
            "Share Job Candidate",
            "SHARE__JOBCAND",
            "SHARE__JOBCAND__SUBJ",
            "SHARE__JOBCAND__LURL",
            "SHARE__JOBCAND__LTXT",
            [
                "MESSAGE",
                "URL",
                "CLIENT FIRST NAME",
                "CLIENT LAST NAME",
                "CLIENT EMAIL",
                "SHARED FIRST NAME",
                "SHARED LAST NAME"
            ]
        ),
        new EmailTemplateConfiguration(
            'Interview Reschedule Request',
            'INTR_RESCH_REQ',
            'INTR_RESCH_REQ__SUBJ',
            'INTR_RESCH_REQ__LURL',
            'INTR_RESCH_REQ__LTXT',
            [
                'CLIENT FIRST NAME',
                'CLIENT LAST NAME',
                'CLIENT EMAIL',
                'CANDIDATE FIRST NAME',
                'CANDIDATE LAST NAME',
                'JOB TITLE',
                'ORIGINAL INTERVIEW TIME',
                'NOTES',
                'JOB ID'
            ]
        ),
    ];

    constructor(
        private apiService: ApiService,
        private domSanitizer: DomSanitizer
    ) {}

    getTemplateConfigurations(): Observable<Array<ILBTemplateConfiguration>> {
        return this.apiService.get("/admin/template").pipe(
            map((res: PaginatedResponse<SysTemplate>) => {
                const results = res.results;
                const sortByTemplateKeyAsc = (a: SysTemplate, b: SysTemplate) =>
                    a.templateKey.localeCompare(b.templateKey);

                results.sort(sortByTemplateKeyAsc);

                results.forEach(sysTmpl => {
                    let tmplConfig = this.templateConfigurations.find(
                        tmplConfig =>
                            this.doesSysTemplateMatchTemplateConfiguration(
                                sysTmpl,
                                tmplConfig
                            )
                    );

                    if (!tmplConfig) return;

                    tmplConfig.Updated = sysTmpl.dateUpdated;
                    tmplConfig.UpdatedBy = sysTmpl.updatedBy;

                    if (sysTmpl.templateKey.slice(-4) === "SUBJ")
                        tmplConfig.Subject = this.domSanitizer.bypassSecurityTrustHtml(
                            sysTmpl.template
                        );
                });

                return this.templateConfigurations;
            })
        );
    }

    private doesSysTemplateMatchTemplateConfiguration(
        sysTmpl: SysTemplate,
        tmplConfig: ILBTemplateConfiguration
    ): boolean {
        const substring = sysTmpl.templateKey.slice(
            0,
            tmplConfig.MainTemplateKey.length
        );

        const nextChar = sysTmpl.templateKey.charAt(
            tmplConfig.MainTemplateKey.length
        );
        if (nextChar && nextChar !== "_")
            // this prevents REG_CAN from matching REG_CANNOT
            return false;

        // todo memoization?

        return substring === tmplConfig.MainTemplateKey;
    }

    getTemplates(): Observable<PaginatedResponse<SysTemplate>> {
        return this.apiService.get("/admin/template").pipe(
            map((res: PaginatedResponse<SysTemplate>) => {
                this.templates = res.results;

                return res;
            })
        );
    }

    updateTemplate(templateID: number, data: any): Observable<any> {
        return this.apiService.patch(
            "/admin/template/" + String(templateID),
            data
        );
    }

    sendTestEmail(
        templateKeyGroup: string,
        toAddress: string,
        subjectTemplate: string,
        bodyTemplate: string,
        linkTextTemplate: string,
        linkUrlTemplate: string
    ) {
        return this.apiService.post("/admin/email/sample", {
            templateKeyGroup,
            toAddress,
            subjectTemplate,
            bodyTemplate,
            linkTextTemplate,
            linkUrlTemplate
        });
    }
}
