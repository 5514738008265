import { Injectable } from "@angular/core";
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, LegacySimpleSnackBar as SimpleSnackBar } from "@angular/material/legacy-snack-bar";
import { ApiError } from "../models/api-error.model";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class NetworkErrorSnackbarService {
    constructor(private snackbar: MatSnackBar) {}

    networkError(err: ApiError, errorOccurredWhile: string): Observable<void> {
        let errorMsg: string;

        if (err.offline) {
            errorMsg = "You appear to be offline.";
        } else if (err.timeout) {
            errorMsg = "Your connection timed out while " + errorOccurredWhile;
        } else {
            errorMsg = "Sorry, a server error occurred while submitting this action. Please try again. If this continues happening, please contact Creative Circle.";
        }

        return this.snackbar.open(errorMsg, "Dismiss").onAction();
    }
}
