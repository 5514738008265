<app-search-bar *ngIf="!isLoadingResults && !loadingError" [formControl]="searchFormControl" placeHolder="Search Jobs"
    ngDefaultControl></app-search-bar>

<div *ngIf="!isLoadingResults && !loadingError" class="filter-and-sort-container"
    (clickOutside)="closeFilterDropdown()">
    <button aria-label="Filter" class="filters" [class.view-all-btn-inactive]="!allJobs.length"
        [class.active]="filterDropdownActive" (click)="allJobs.length && toggleFilterDropdown()">
        <span class="svg-container">
            <svg width="20px" height="11px" viewBox="0 0 20 11" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs></defs>
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.600000024">
                    <g id="Panel:-Talent-Requests" transform="translate(-36.000000, -85.000000)" fill="#131e27">
                        <g id="Search+Dropdowns" transform="translate(21.000000, 20.000000)">
                            <g id="Group-15" transform="translate(0.000000, 50.000000)">
                                <g id="Group-13" transform="translate(15.000000, 15.000000)">
                                    <rect id="Rectangle-6" x="0" y="0" width="20" height="2.2" rx="1.1"></rect>
                                    <rect id="Rectangle-6-Copy" x="3.57142857" y="4.4" width="12.8571429" height="2.2"
                                        rx="1.1"></rect>
                                    <rect id="Rectangle-6-Copy-2" x="6.42857143" y="8.8" width="7.14285714" height="2.2"
                                        rx="1.1"></rect>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </span>
        <span>FILTER BY <span *ngIf="totalFiltered > 0">({{totalFiltered}})</span></span>
    </button>
    <button aria-label="Sort" class="sorts" [class.active]="sortDropdownActive"
        [class.view-all-btn-inactive]="!allJobs.length" (click)="allJobs.length && toggleSortDropdown()">
        <span class="svg-container">
            <svg width="12px" height="14px" viewBox="0 0 15 17" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs></defs>
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.600000024"
                    stroke-linecap="round" stroke-linejoin="round">
                    <g id="Panel:-Talent-Requests" transform="translate(-182.000000, -82.000000)" stroke="#131e27"
                        stroke-width="1.8">
                        <g id="Search+Dropdowns" transform="translate(21.000000, 20.000000)">
                            <g id="Group-14" transform="translate(140.000000, 50.000000)">
                                <g id="Group-11-Copy" transform="translate(22.000000, 13.000000)">
                                    <g id="arrow-up-circle">
                                        <polyline id="Shape" points="8.66666667 4.28571429 4.33333333 0 0 4.28571429">
                                        </polyline>
                                        <path d="M4.33333333,5.35714286 L4.33333333,0" id="Shape"></path>
                                    </g>
                                    <g id="arrow-up-circle-copy"
                                        transform="translate(8.500000, 12.000000) rotate(-180.000000) translate(-8.500000, -12.000000) translate(4.000000, 9.000000)">
                                        <polyline id="Shape" points="8.66666667 4.28571429 4.33333333 0 0 4.28571429">
                                        </polyline>
                                        <path d="M4.33333333,5.35714286 L4.33333333,-3.55271368e-15" id="Shape"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </span>
        SORT BY
    </button>

    <div [class.active]="filterDropdownActive" class="filter-container">
        <div class="filter-by-dropdown-container" [class.many-areas]="firstColumnAreas.length > 8">
            <div class="view-all-area">
                <button (click)="closeFilterDropdown()">
                    VIEW RESULTS
                </button>
            </div>
            <div class="filter-dropdown" #filter_dropdown_container>
                <div class="filter-on-mobile row">
                    <span class="filter-header">FILTER</span>
                    <button aria-label="Close" class="close-btn" (click)="clearAllFilters('ALL')">
                        CLEAR ALL
                    </button>
                </div>
                <div class="radio-btn-container flex-container filter-inputs" #area_filters>
                    <div class="area-container">
                        <p class="section-header multiple-section">AREA</p>
                        <div *ngIf="firstColumnAreas">
                            <div *ngFor="let jobArea of firstColumnAreas; let i = index">
                                <div class="area">
                                    <label [for]="'job-area-1-' + i">
                                        <input type="checkbox" class="filter-check area-select"
                                            (change)="applyJobFilters(true)" [id]="'job-area-1-' + i"
                                            [attr.data-value]="jobArea">
                                        {{ jobArea }}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="secondColumnAreas" class="second-column">
                            <div *ngFor="let jobArea of secondColumnAreas; let i = index">
                                <div class="area">
                                    <label [for]="'job-area-2-' + i">
                                        <input type="checkbox" class="filter-check area-select"
                                            (change)="applyJobFilters(true)" [id]="'job-area-2-' + i"
                                            [attr.data-value]="jobArea">
                                        {{ jobArea }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="clear-all" (click)="clearAllFilters('area-select')">CLEAR</p>
                </div>
                <hr>
                <div class="job-type flex-container">
                    <div>
                        <p class="section-header multiple-section">Placement Type</p>
                        <div class="area">
                            <label for="full-time">
                                <input type="checkbox" class="filter-check placement-select"
                                    [(ngModel)]="activeFilters.jobTypes['Full Time']" (change)="applyJobFilters(true)"
                                    id="full-time">
                                Full-Time
                            </label>
                        </div>
                        <div class="area">
                            <label for="freelance">
                                <input type="checkbox" class="filter-check placement-select"
                                    [(ngModel)]="activeFilters.jobTypes['Freelance']" (change)="applyJobFilters(true)"
                                    id="freelance">
                                Freelance
                            </label>
                        </div>
                    </div>
                    <p class="clear-all" (click)="clearAllFilters('placement-select')">CLEAR</p>
                </div>
                <hr>
                <div class="job-status flex-container">
                    <div>
                        <p class="section-header multiple-section">JOB STATUS</p>
                        <div class="area">
                            <label for="open">
                                <input type="checkbox" class="filter-check job-status-select"
                                    [(ngModel)]="activeFilters.jobStatuses['open']" (change)="applyJobFilters(true)"
                                    id="open">
                                Open Placements
                            </label>
                        </div>
                        <div class="area">
                            <label for="active">
                                <input type="checkbox" class="filter-check job-status-select"
                                    [(ngModel)]="activeFilters.jobStatuses['active']" (change)="applyJobFilters(true)"
                                    id="active">
                                Active Placements
                            </label>
                        </div>
                        <div class="area">
                            <label for="past">
                                <input type="checkbox" class="filter-check job-status-select"
                                    [(ngModel)]="activeFilters.jobStatuses['past']" (change)="applyJobFilters(true)"
                                    id="past">
                                Past Placements
                            </label>
                        </div>
                        <div class="area">
                            <label for="canceled">
                                <input type="checkbox" class="filter-check job-status-select"
                                    [(ngModel)]="activeFilters.jobStatuses['cancelled']"
                                    (change)="applyJobFilters(true)" id="canceled">
                                Canceled Placements
                            </label>
                        </div>
                        <div class="area">
                            <label for="closed">
                                <input type="checkbox" class="filter-check job-status-select"
                                    [(ngModel)]="activeFilters.jobStatuses['closed']" (change)="applyJobFilters(true)"
                                    id="closed">
                                Closed Placements
                            </label>
                        </div>
                    </div>
                    <p class="clear-all" (click)="clearAllFilters('job-status-select')">CLEAR</p>
                </div>
                <hr>
                <div class="submittals flex-container">
                    <p class="section-header">SUBMITTALS</p>
                    <div class="area">
                        <label for="submittals">
                            <input type="checkbox" class="filter-check" [(ngModel)]="activeFilters.onlyShowNew"
                                (change)="applyJobFilters(true)" id="submittals">
                            <p>Only Show New</p>
                        </label>
                    </div>
                </div>
                <hr>
                <div class="flex">
                    <p class="section-header">Job ID</p>
                    <div class="area">
                        <input type="text" class="input-field" placeholder="Job ID" [(ngModel)]="activeFilters.jobId"
                            (input)="applyJobFilters(true)">
                    </div>
                </div>
                <hr>
                <div class="po-number flex">
                    <p class="section-header">PO Number</p>
                    <div class="area">
                        <input type="text" class="input-field" [(ngModel)]="activeFilters.poNumber"
                            placeholder="PO Number" (input)="applyJobFilters(true)">
                    </div>
                </div>
                <hr>
                <div class="candidate-name flex">
                    <p class="section-header">Candidate Name <br> <span class="active-only">Active/Past Placements
                            Only</span></p>
                    <div class="area">
                        <input type="text" class="input-field" placeholder="Candidate Name"
                            [(ngModel)]="activeFilters.candidateName" (input)="applyJobFilters(true)">
                    </div>
                </div>
            </div>
            <div class="view-all-results">
                <button (click)="closeFilterDropdown()">VIEW RESULTS</button>
            </div>
        </div>
    </div>
    <div [class.active]="sortDropdownActive" class="sort-container popover">
        <div class="sort-by-dropdown-container">
            <div class="sort-by-dropdown" #sort_dropdown>
                <div class="filter-on-mobile">
                    <div>
                        <span>SORT</span>
                    </div>
                    <button aria-label="Close" class="close-btn" (click)="closeSortDropdown()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b3e1ea" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </div>
                <div class="radio-btn-container filter-inputs">
                    <div class="curr-openings-desc area" [class.active-sort]="activeSort === 'curr-openings-desc'"
                        (click)="sortBy('curr-openings-desc')">
                        <div class="check-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                fill="none" stroke="#000000" stroke-width="4" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-check">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </div>
                        <p>Current Openings - New to Old</p>
                    </div>

                    <div class="curr-openings-desc area" [class.active-sort]="activeSort === 'curr-openings-asc'"
                        (click)="sortBy('curr-openings-asc')">
                        <div class="check-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                fill="none" stroke="#000000" stroke-width="4" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-check">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </div>
                        <p>Current Openings - Old to New</p>
                    </div>

                    <div class="num-candidates-asc area" [class.active-sort]="activeSort === 'num-candidates-asc'"
                        (click)="sortBy('num-candidates-asc')">
                        <div class="check-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                fill="none" stroke="#000" stroke-width="4" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-check">
                                <path d="M20 6L9 17l-5-5" />
                            </svg>
                        </div>
                        <p># of Candidates to Review - Low to High</p>
                    </div>

                    <div class="num-candidates-asc area" [class.active-sort]="activeSort === 'num-candidates-desc'"
                        (click)="sortBy('num-candidates-desc')">
                        <div class="check-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                fill="none" stroke="#000" stroke-width="4" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-check">
                                <path d="M20 6L9 17l-5-5" />
                            </svg>
                        </div>
                        <p># of Candidates to Review - High to Low</p>
                    </div>

                    <div class="end-date-asc area" [class.active-sort]="activeSort === 'end-date-asc'"
                        (click)="sortBy('end-date-asc')">
                        <div class="check-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                fill="none" stroke="#000" stroke-width="4" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-check">
                                <path d="M20 6L9 17l-5-5" />
                            </svg>
                        </div>
                        <p>End Date - Ending Sooner</p>
                    </div>

                    <div class="end-date-desc area" [class.active-sort]="activeSort === 'end-date-desc'"
                        (click)="sortBy('end-date-desc')">
                        <div class="check-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                fill="none" stroke="#000" stroke-width="4" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-check">
                                <path d="M20 6L9 17l-5-5" />
                            </svg>
                        </div>
                        <p>End Date - Ending Later</p>
                    </div>

                    <div class="status area" [class.active-sort]="activeSort === 'status'" (click)="sortBy('status')">
                        <div class="check-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                fill="none" stroke="#000000" stroke-width="4" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-check">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </div>
                        <p>Status</p>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="clearfix"></div>
</div>

<hr *ngIf="!isLoadingResults && !loadingError">

<div class="job-container" [class.scrollbar-jobs]="isWindows" [class.fixed-height]="filterDropdownActive" #scroll_jobs>
    <div class="loading-shade" *ngIf="isLoadingResults">
        <svg class="feather feather-loader" xmlns="http://www.w3.org/2000/svg" width="48" height="48"
            viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round">
            <path
                d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83" />
        </svg>
    </div>

    <app-loading-error [apiError]="loadingError" (onRetry)="refreshJobs()"></app-loading-error>

    <div class="no-orders" *ngIf="!isLoadingResults && !loadingError && !allJobs.length">
        <p>You have no current openings listed with us. Get started with a talent request.</p>
        <button aria-label="Request Talent" (click)="openRequestTalentDialog()">REQUEST TALENT</button>
    </div>

    <div [class.opacity-change]="filterDropdownActive || sortDropdownActive">
        <ng-container *ngIf="!isLoadingResults && allJobs.length && activeSort === 'status'">
            <div *ngIf="!openRequests.length && !activePlacements.length && !pastPlacements.length && !cancelledRequests.length"
                class="no-results-state">
                <p>There were no results based on your search and filter criteria</p>
            </div>
            <div class="job-orders-vertical-list open-requests"
                *ngIf="(activeFilters.jobGroup === 'ALL' || activeFilters.jobGroup === 'OPEN') && openRequests.length">
                <h2 class="label">Current Openings</h2>
                <div *ngFor="let job of openRequests">
                    <app-job-order-card class="always-mobile"
                        [class.active]='activeJob?.jobId == job.jobId && activeJob?.officeId == job.officeId'
                        (click)="(filterDropdownActive || sortDropdownActive) || handleCardClick(job)" [job]='job' [attr.data-job-id]="job.jobId">
                    </app-job-order-card>
                    <hr>
                </div>
            </div>
            <div class="job-orders-vertical-list active-placements"
                *ngIf="(activeFilters.jobGroup === 'ALL' || activeFilters.jobGroup === 'ACTIVE') && activePlacements.length">
                <h2 class="label">Active Placements</h2>
                <div *ngFor="let job of activePlacements">
                    <app-job-order-card class="always-mobile"
                        [class.active]='activeJob?.jobId == job.jobId && activeJob?.officeId == job.officeId'
                        (click)="(filterDropdownActive || sortDropdownActive) || handleCardClick(job)" [job]='job' [attr.data-job-id]="job.jobId">
                    </app-job-order-card>
                    <hr>
                </div>
            </div>
            <div class="job-orders-vertical-list past-placements"
                *ngIf="(activeFilters.jobGroup === 'ALL' || activeFilters.jobGroup === 'PAST') && pastPlacements.length">
                <h2 class="label">Past Placements</h2>
                <div *ngFor="let job of pastPlacements">
                    <app-job-order-card class="always-mobile"
                        [class.active]='activeJob?.jobId == job.jobId && activeJob?.officeId == job.officeId'
                        (click)="(filterDropdownActive || sortDropdownActive) || handleCardClick(job)" [job]='job' [attr.data-job-id]="job.jobId">
                    </app-job-order-card>
                    <hr>
                </div>
            </div>
            <div class="job-orders-vertical-list cancelled-requests"
                *ngIf="(activeFilters.jobGroup === 'ALL' || activeFilters.jobGroup === 'CANCELLED') && cancelledRequests.length">
                <h2 class="label">Canceled Openings</h2>
                <div *ngFor="let job of cancelledRequests">
                    <app-job-order-card class="always-mobile"
                        [class.active]='activeJob?.jobId == job.jobId && activeJob?.officeId == job.officeId'
                        (click)="(filterDropdownActive || sortDropdownActive) || handleCardClick(job)" [job]='job' [attr.data-job-id]="job.jobId">
                    </app-job-order-card>
                    <hr>
                </div>
            </div>
        </ng-container>
    </div>
    <div [class.opacity-change]="filterDropdownActive || sortDropdownActive">
        <ng-container *ngIf="allJobs.length && activeSort !== 'status' && !isLoadingResults">
            <div *ngIf="!filteredJobs.length" class="no-results-state">
                <p>There were no results based on your search and filter criteria</p>
            </div>
            <div class="job-orders-vertical-list open-requests">
                <h2 *ngIf="filteredJobsGroup !== null" class="label">{{filteredJobsGroup}}</h2>
                <div *ngFor="let job of filteredJobs">
                    <app-job-order-card *ngIf="job.status === filteredJobsType" class="always-mobile"
                        [class.active]='activeJob?.jobId == job.jobId && activeJob?.officeId == job.officeId'
                        (click)="(filterDropdownActive || sortDropdownActive) || handleCardClick(job)" [job]='job'
                        [sortedBy]='activeSort' [attr.data-job-id]="job.jobId">
                    </app-job-order-card>
                    <hr *ngIf="job.status === filteredJobsType">
                </div>

                <div class="job-orders-vertical-list past-placements"
                    *ngIf="filteredJobsGroup !== null && pastPlacements.length && (activeSort === 'end-date-asc' || activeSort === 'end-date-desc')">
                    <h2 class="label">Past Placements</h2>
                    <div *ngFor="let job of pastPlacements">
                        <app-job-order-card class="always-mobile"
                            [class.active]='activeJob?.jobId == job.jobId && activeJob?.officeId == job.officeId'
                            (click)="(filterDropdownActive || sortDropdownActive) || handleCardClick(job)" [job]='job' [attr.data-job-id]="job.jobId">
                        </app-job-order-card>
                        <hr *ngIf="activeSort === 'end-date-asc' || activeSort === 'end-date-desc'">
                    </div>
                </div>

                <div class="job-orders-vertical-list past-placements"
                    *ngIf="filteredJobsGroup !== null && cancelledRequests.length && (activeSort === 'end-date-asc' || activeSort === 'end-date-desc')">
                    <h2 class="label">Canceled Openings</h2>
                    <div *ngFor="let job of cancelledRequests">
                        <app-job-order-card class="always-mobile"
                            [class.active]='activeJob?.jobId == job.jobId && activeJob?.officeId == job.officeId'
                            (click)="(filterDropdownActive || sortDropdownActive) || handleCardClick(job)" [job]='job' [attr.data-job-id]="job.jobId">
                        </app-job-order-card>
                        <hr *ngIf="activeSort === 'end-date-asc' || activeSort === 'end-date-desc'">
                    </div>
                </div>


                <div
                    *ngIf="filteredJobs.length > 0 && filteredJobsGroup !== null && activeSort !== 'end-date-asc' && activeSort !== 'end-date-desc'">
                    <h2 class="label">Other Jobs</h2>
                    <div *ngFor="let job of filteredJobs">
                        <app-job-order-card *ngIf="job.status !== filteredJobsType" class="always-mobile"
                            [class.active]='activeJob?.jobId == job.jobId && activeJob?.officeId == job.officeId'
                            (click)="(filterDropdownActive || sortDropdownActive) || handleCardClick(job)" [job]='job'
                            [sortedBy]='activeSort' [attr.data-job-id]="job.jobId">
                        </app-job-order-card>
                        <hr
                            *ngIf="job.status !== filteredJobsType && activeSort !== 'end-date-asc' && activeSort !== 'end-date-desc'">
                    </div>
                </div>


                <div *ngIf="filteredJobs.length > 0 && filteredJobsGroup !== null && (activeSort === 'end-date-asc' || activeSort === 'end-date-desc')"
                    [class.hide]="hideOtherJobs">
                    <h2 class="label">Other Jobs</h2>
                    <div *ngFor="let job of filteredJobs">
                        <app-job-order-card *ngIf="job.status !== filteredJobsType && job.status === 'OPEN'"
                            class="always-mobile"
                            [class.active]='activeJob?.jobId == job.jobId && activeJob?.officeId == job.officeId'
                            (click)="(filterDropdownActive || sortDropdownActive) || handleCardClick(job)" [job]='job'
                            [sortedBy]='activeSort' [attr.data-job-id]="job.jobId">
                        </app-job-order-card>
                        <hr *ngIf="job.status !== filteredJobsType && job.status === 'OPEN'">
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="buffer"></div>
</div>
