<div class="share-and-close">
  <div *ngIf="!candidate.blocked && candidateDetails.sunsetFlag === 0" (click)="isLimitedImpersonation || favoritedCandidate(candidate.candidateId, candidate.officeId, candidate.favorited)" class="star" [class.disable]="isLimitedImpersonation">
    <svg width="24" height="24" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg"><path d="M9 2.314C13.438-2.248 24.534 5.735 9 16-6.534 5.736 4.562-2.248 9 2.314Z" fill-rule="nonzero" stroke="#979797" fill="none" [ngClass]="{'favored' : candidate.favorited}"/></svg>
    <span *ngIf="!candidate.favorited">FAVORITE?</span>
    <span *ngIf="candidate.favorited">FAVORITED</span>
  </div>
  <div *ngIf="candidateDetails.sunsetFlag === 0" (click)="isLimitedImpersonation || blockCandidate(candidate.candidateId, candidate.officeId, candidate.blocked, candidate.favorited)" class="blocked" [class.disable]="isLimitedImpersonation">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="#1C2B39" stroke-width="2" stroke-linecap="round" [ngClass]="{'blocked-icon' : candidate.blocked}" stroke-linejoin="round" class="feather feather-slash">
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line>
    </svg>
    <span *ngIf='!candidate.blocked' class="hover">BLOCK?</span>
    <span *ngIf='candidate.blocked' class="hover">BLOCKED</span>
  </div>
  <div *ngIf="candidateDetails.sunsetFlag === 0" class="share" (click)="openShareDialog()">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 24" fill="none" stroke="#1C2B39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-share">
      <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
      <polyline points="16 6 12 2 8 6"></polyline>
      <line x1="12" y1="2" x2="12" y2="15"></line>
    </svg>
    <span>SHARE</span>
  </div>
  <div class="close" (click)="closeCandidateDetails()">
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="-5 0 24 24" fill="none" stroke="#1C2B39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  </div>
</div>
<div class="status-and-favorite" *ngIf="candidateDetails.sunsetFlag === 0">
<div class="flex">
    <span *ngIf="candidateHired" class="status green">HIRED</span>
    <span *ngIf="!candidateHired" [ngClass]="determineColor(jobOrderCandidate.statusId)">{{ determineStatus(jobOrderCandidate.statusId) }}</span>
    <div class="hurry-flag" *ngIf="jobOrderCandidate.hurry" (click)="toggleToolTip()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-flag tool-tip"><path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path><line x1="4" y1="22" x2="4" y2="15"></line></svg>
      <div class="text" [class.show-tip]="showToolTip">
        <p class="header">WHAT DOES THIS FLAG MEAN?</p>
        <p class="body">Other hiring managers are considering this candidate for other jobs—act fast if you want to hire them!</p>
      </div>
    </div>
  </div>
</div>
