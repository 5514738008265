import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoadingErrorComponent } from "./loading-error.component";

@NgModule({
    declarations: [LoadingErrorComponent],
    imports: [CommonModule],
    exports: [LoadingErrorComponent]
})
export class LoadingErrorModule {}
