<div class="background-container">
    <div class="small-container">
        <mat-card>
            <div class="logo-container">
                <img src="../assets/images/circle-logo.png" alt="Creative Circle Lightbox" />
            </div>
            <mat-card-title>
                Set New Password
            </mat-card-title>
            <mat-card-content>
                <app-messages></app-messages>
                <div *ngIf="invalidToken">
                    <p class="help">
                        This link has expired. Please click below to request another link.
                    </p>
                    <div class="button-container">
                        <button aria-label="Request Another" routerLink="/login/forgot" mat-raised-button class="btn" color="primary">Request Another</button>
                    </div>
                </div>
                <div *ngIf="!invalidToken">
                    <form [formGroup]="passwordResetForm" (ngSubmit)="submitForm()">
                        <mat-form-field>
                            <input matInput formControlName="password" placeholder="New Password" type="password" required autofocus="autofocus" />
                            <a [class.active]="hasActivePopover" class="popover-icon">
                                <div class="popover-content">
                                    <h6>Password Requirements</h6>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" disabled formControlName="minimumChar" />
                                            <span></span>
                                            At least 8 characters
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" disabled formControlName="upperCaseChar" />
                                            <span></span>
                                            At least 1 upper-case character
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" disabled formControlName="lowerCaseChar" />
                                            <span></span>
                                            At least 1 lower-case character
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" disabled formControlName="numericChar" />
                                            <span></span>
                                            At least 1 numeral
                                        </label>
                                    </div>
                                </div>
                            </a>

                            <mat-error *ngIf="password.invalid && password.touched">
                                Please enter a valid new password.
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput formControlName="passwordConfirmation" placeholder="Confirm Password" type="password" required />
                            <mat-error *ngIf="passwordConfirmation.invalid && passwordConfirmation.touched">
                                This field is required and needs to match your new password.
                            </mat-error>
                        </mat-form-field>

                        <button aria-label="Set Password" mat-raised-button class="btn" [class.invalid-btn]="passwordResetForm.invalid" [class.is-submitting]="isSubmitting" color="primary" type="submit" [disabled]="!passwordResetForm.valid || isSubmitting">
                            <span class="not-submitting">Set Password</span>
                            <span class="submitting">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                                    <line x1="12" y1="2" x2="12" y2="6"></line>
                                    <line x1="12" y1="18" x2="12" y2="22"></line>
                                    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                                    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                                    <line x1="2" y1="12" x2="6" y2="12"></line>
                                    <line x1="18" y1="12" x2="22" y2="12"></line>
                                    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                                    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                                </svg>
                            </span>
                        </button>
                    </form>

                    <div class="text-danger text-center mt-3" *ngIf="passwordResetForm.hasError('invalid')">
                        The request does not validate according to validation rules
                    </div>

                    <div class="text-danger text-center mt-3" *ngIf="passwordResetForm.hasError('server')">
                        A server error has occurred
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="show-on-mobile">
        <div class="container">
            <div class="link">
                <a class="nav-link" href="https://www.creativecircle.com/roles-we-place/" target="_blank" rel="noopener">
                    ROLES WE PLACE
                </a>
            </div>
            <div class="link">
                <a class="nav-link" [href]="timecardLoginUrl" target="_blank" rel="noopener">
                    TIMECARD LOGIN
                </a>
            </div>
            <div class="link">
                <a class="nav-link" [href]="requestTalentUrl" target="_blank" rel="noopener">
                    REQUEST TALENT
                </a>
            </div>
        </div>
    </div>
</div>
